import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import noConnectionAnimation from "../assets/lottie/no-connection.json";

export const NoConnection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkOnlineStatus = () => {
      if (navigator.onLine) {
        navigate(-1); // Go back when internet is restored
      }
    };

    window.addEventListener("online", checkOnlineStatus);
    return () => window.removeEventListener("online", checkOnlineStatus);
  }, [navigate]);

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen tw-bg-gray-100 tw-text-center tw-p-4">
      <Lottie
        animationData={noConnectionAnimation}
        className="tw-w-64 tw-h-64"
      />

      <h1 className="tw-text-2xl tw-font-bold tw-text-gray-800 tw-mt-4 tw-font-righteous">
        Oops! No Internet Connection
      </h1>
      <p className="tw-text-gray-600 tw-mt-2 tw-font-righteous">
        It looks like you're offline. Check your connection and try again.
      </p>
    </div>
  );
};
