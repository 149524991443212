import {Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert} from "reactstrap";
import React, {useEffect, useState} from 'react';
import L from "leaflet";
import { MapContainer, TileLayer, Marker, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faMapLocation, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import Button from "../../shared/Button";
import WindowedSelect from "react-windowed-select";
import httpClientPy from "../../../utils/httpClientPy";

library.add(faCopy, faMapLocation, faTriangleExclamation);

const boreholeRed = new L.Icon({
    iconUrl: "assets/borehole-red.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 16], // Adjust these values
    popupAnchor: [16, 16], // Adjust these values
});

const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
       map.setView([lat, lng], 19, { animate: true });
     }, [lat, lng, map]);
     return null;
}

const AgsUploaderVerify = (props) => {

    const [modalAgsVerify, setModalAgsVerify] = useState(true);
    const [errorEpsg, setErrorEpsg] = useState(false);
    const [epsgList, setEpsgList] = useState([]); // Full list
    const [epsgIndex, setEpsgIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [agsInfo, setAgsInfo] = useState(null);
    

    const toggleDigitiseBorehole = () => {
        if(modalAgsVerify){
            props.toggleModal(!modalAgsVerify)
        }
        setModalAgsVerify(!modalAgsVerify);
    }

    function createLabelIcon(labelText) {
        return new L.DivIcon({
            html: `<div className="marker-label">${labelText}</div>`,
            className: 'marker-label-container',
            iconSize: L.point(0, 0),
            iconAnchor: [8, 0],
        });
    }
      
    useEffect(() => {
        httpClientPy.get(`/epsg`).then((response) => {
            let epsgList = response.data
            let epsglist = [];
            // loop epsgList
            for(let i = 0; i < epsgList.length; i++){
                // add the epsg to the list
                epsglist.push({value: i, label: epsgList[i].epsg + " - " + epsgList[i].name.substring(0, 12)})
            }
            setEpsgList(epsglist);
        })
        setAgsInfo(props.agsInfo);
    }, [])

    useEffect(() => {
        let index = epsgList.findIndex((item) => item.label.split(" - ")[0] === agsInfo[0].gref);
        if (index === -1){
            index = 0;
        }
        setEpsgIndex(index);
    }, [agsInfo, loading, epsgList])

    const handleEpsgSelecter = (e) => {
        setEpsgIndex(e.value);
        setErrorEpsg(false);
    }

    const updateAgsInfo = () => {
        setLoading(true);
        let data = []
        for(let i = 0; i < props.agsInfo.length; i++){
            data.push({
                "ref_id": props.agsInfo[i].ref_id,
                "gref": epsgList[epsgIndex].label.split(" - ")[0],
                "lat": props.agsInfo[i].lat,
                "lon": props.agsInfo[i].lon,
                "easting": props.agsInfo[i].easting,
                "northing": props.agsInfo[i].northing,
                "geo_record_id": props.agsInfo[i].geo_record_id
            })
        }
        httpClientPy
        .post(`/geo/record/ags/update/position`, {
            info: data
        })
        .then((response) => {
            if(response.data.status !== false) {
                setAgsInfo(response.data.info);
            } else {
                setErrorEpsg(true);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const confirmUpdateAgsInfo = () => {
        setLoading(true);
        httpClientPy
        .post(`/geo/record/ags/update/position/confirm`, {
            info: props.agsInfo
        })
        .then(() => {
            setLoading(false);
            toggleDigitiseBorehole();
        })
    }

    return (
        <>  
            <Modal id="agsVerifierModal" size="xl" isOpen={modalAgsVerify} centered={true} backdrop="static" keyboard={false}>
                <ModalHeader className="border-0 mb-0 pb-0">
                    <div>
                    <FontAwesomeIcon className="icon-sideMenu" icon={faTriangleExclamation}/> Confirm your coordinate system
                    </div>
                </ModalHeader>
                <hr className="mb-1 mt-1 pb-0 pt-0"/>
                <ModalBody>
                    <div className="container-fluid-adjusted">
                        <div className="row">
                            { agsInfo !== null &&
                            <>
                                <div className="mb-2 px-2 px-3">
                                    
                                    &nbsp; We've detected <span className="title-bold">{agsInfo.length} boreholes</span> in the AGS file. Check the below location preview and update the EPSG coordinate system if needed.
                                </div>
                                <div className="col-9" id="mapContainer">
                                    <MapContainer
                                            style={{height: '50vh'}}
                                            center={[agsInfo[0].lat, agsInfo[0].lon]}
                                            zoom={19}
                                            scrollWheelZoom={false}
                                            preferCanvas={true} 
                                        >
                                        <RecenterAutomatically lat={agsInfo[0].lat} lng={agsInfo[0].lon}/>
                                        <TileLayer
                                            attribution="Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
                                            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_nolabels/{z}/{x}/{y}.png"
                                            />
                                            {agsInfo.map((item, index) => {
                                                return(
                                                    <Marker 
                                                        key={`marker-${index+1}`}
                                                        position={[item.lat, item.lon]}
                                                        icon={boreholeRed}
                                                    >
                                                    <Marker
                                                        position={[item.lat, item.lon]}
                                                        icon={createLabelIcon(`${item.ref_id}`)}
                                                        />
                                                    </Marker>
                                            )})}
                                    </MapContainer>
                                </div>
                                <div className="col-3">
                                    {/* <div className="form-floating mb-2">
                                        <WindowedSelect 
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="ref_id"
                                            options={bhList}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => setBoreholeSelected(e.value)}
                                            value={bhList[boreholeSelected]}
                                        />
                                    </div> */}
                                    <div className="form-floating mb-2">
                                        <input
                                            name="lat"
                                            type="text"
                                            className="form-control"
                                            id="lat"
                                            value={agsInfo[0].lat}
                                            disabled={true}
                                            autoComplete="off"
                                        />
                                        <label htmlFor="lat">Latitude</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input
                                            name="lon"
                                            type="text"
                                            className="form-control"
                                            id="lon"
                                            value={agsInfo[0].lon}
                                            disabled={true}
                                            autoComplete="off"
                                        />
                                        <label htmlFor="lon">Longitude</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input
                                            name="easting"
                                            type="text"
                                            className="form-control"
                                            id="easting"
                                            value={agsInfo[0].easting}
                                            disabled={true}
                                            autoComplete="off"
                                        />
                                        <label htmlFor="easting">Easting</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input
                                            name="northing"
                                            type="text"
                                            className="form-control"
                                            id="northing"
                                            value={agsInfo[0].northing}
                                            disabled={true}
                                            autoComplete="off"
                                        />
                                        <label htmlFor="northing">Northing</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <WindowedSelect 
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="gref"
                                            options={epsgList}
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => handleEpsgSelecter(e)}
                                            value={epsgList[epsgIndex]}
                                        />
                                    </div>
                                    <div className="form-floating mb-2">
                                        {!errorEpsg ?
                                            <Button
                                                label="Update"
                                                className="btn btn-primary w-100"
                                                loading={loading}
                                                onClick={() => updateAgsInfo()}
                                            ></Button>
                                        :
                                            <UncontrolledAlert color="danger">
                                                <span>Selected EPSG is causing </span><span className="title-bold">out of bound error. Please recheck the selected CRS </span>
                                            </UncontrolledAlert>
                                        }
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="text-center">
                    <div className="w-100">
                        {!errorEpsg &&
                            <Button
                                label="Confirm"
                                className="btn btn-primary"
                                loading={loading}
                                onClick={() => confirmUpdateAgsInfo()}
                            ></Button>
                        }
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );

}

export default AgsUploaderVerify;