import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NetworkListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkOffline = () => {
      if (!navigator.onLine) {
        navigate("/no-connection");
      }
    };

    window.addEventListener("offline", checkOffline);
    return () => window.removeEventListener("offline", checkOffline);
  }, [navigate]);

  return null;
};
