/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import httpClientPy from "../../utils/httpClientPy";

const VerifyPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // get the token from the url if there is one
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      httpClientPy
        .post(`/user/verify`, { token: token })
        .then((result) => {
          localStorage.setItem("auth_token", result.data.token);
          localStorage.setItem("onboarded", true);
        })
        .catch((errorResponse) => {
          navigate("/log-in");
        })
        .finally(() => {
          navigate("/projects");
        });
    }
  }, [navigate]);

  return null;
};

export default VerifyPage;
