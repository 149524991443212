/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import httpClientPy from "../../utils/httpClientPy";
import Button from "../shared/Button";
import AuthFormHeader from "../shared/AuthFormHeader";
import { Alert } from "reactstrap";

const ResetPw = () => {

  const MAPPING = {
    0: {'message': 'An email has been sent to you with instructions on how to reset your password', 'class': 'success'},
    1: {'message': 'Token does not exist', 'class': 'warning'},
    2: {'message': 'Internal error, please contact info@civils.ai for futher assistance', 'class': 'danger'},
    3: {'message': 'Token has expired', 'class': 'warning'},
    4: {'message': 'Passwords do not match', 'class': 'warning'},
  }

  const RESET_MAPPING = {
    0: {'message': 'Password has been reset', 'class': 'success'},
    1: {'message': 'Token does not exist', 'class': 'warning'},
    2: {'message': 'Internal error, please contact info@civils.ai for futher assistance', 'class': 'danger'},
    3: {'message': 'Token has expired', 'class': 'warning'},
    4: {'message': 'Passwords do not match', 'class': 'warning'},
  }

  const [verified, setVerified] = useState(false);
  const [verifiedCode, setVerifiedCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetCode, setResetCode] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    // get the token from the url if there is one
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
        httpClientPy
        .post(`/user/forgot/password/verify`, { token: token })
        .then((response) => {
          if (response.data.code === 0) {
              setVerified(true);
          }
          setVerifiedCode(response.data.code);
        })
        .catch((errorResponse) => {
            setVerified(false);
            setVerifiedCode(errorResponse.response.data.code);
        })
    }
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "pw") {
      setPassword(e.target.value);
    } else {
      setConfirmPassword(e.target.value);
    }
  }

  const handleSubmit = (event) => {
    setLoading(true);
    setResetCode(null);
    event.preventDefault();
    
    if (password !== confirmPassword) {
      setResetCode(4);
      setLoading(false);
      return;
    }

    httpClientPy
      .post(`/user/reset/password`, { token: new URLSearchParams(window.location.search).get("token"), new_password: password })
      .then((response) => {
        setResetCode(response.data.code);
      })
      .catch((errorResponse) => {
        setResetCode(errorResponse.response.data.code);
      })
      .finally(() => {
        setLoading(false);
      })

    setTimeout(() => {
      navigate("/log-in");
    }, 3000);

  }

  return (
    <>
      <main className="form-signin mt-5 m-auto container text-center">
        <AuthFormHeader />
        <h1 className="title-bold pt-4">Reset Password</h1>
        {verifiedCode !== 0 && verifiedCode !== null &&
          <Alert color={MAPPING[verifiedCode].class} isOpen={true} toggle={() => setResetCode(null)}>
            <span className="title-bold">{MAPPING[verifiedCode].message}</span>
          </Alert>
        }
        {verified &&
          <div className="mt-3">
            <form onSubmit={handleSubmit}>
              <div className="form-floating mb-2">
                <input
                  name="pw"
                  type="password"
                  className="form-control"
                  id="pw"
                  value={password}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="pw">Password</label>
              </div>
              <div className="form-floating mb-2">
                <input
                  name="confirmPw"
                  type="password"
                  className="form-control"
                  id="confirmPw"
                  value={confirmPassword}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="confirmPw">Confirm Password</label>
              </div>
              <div className="vstack gap-2">
                <Button
                  type="submit"
                  label="Reset Password"
                  className="w-100 btn btn-primary"
                  loading={loading}
                />

                <div>
                  {resetCode !== null &&
                    <Alert color={RESET_MAPPING[resetCode].class} isOpen={true} toggle={() => setResetCode(null)}>
                      <span className="title-bold">{RESET_MAPPING[resetCode].message}</span>
                    </Alert>
                  }
                </div>
                <div className="text-secondary">
                  <small>
                    Already have an account? <NavLink to="/log-in">Log in now</NavLink>
                  </small>
                </div>
                <div className="text-secondary">
                  <small>
                    Don't have an account? <NavLink to="/sign-up">Sign up now</NavLink>
                  </small>
                </div>
              </div>
            </form>
          </div>
        }
      </main>
    </>
  );
};

export default ResetPw;
