import React, { useState, useEffect } from "react";
import { Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { faCaretDown, faFilter, faFilterCircleXmark, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import httpClientPy from "../../../utils/httpClientPy";
import UploadModal from "../../shared/UploadModal";
import { NavLink } from "react-router-dom";
import DocumentsTable from "./DocumentsTable";
import Select, { components } from 'react-select'

const MainLayout = (props) => {
    const [cnt, setCnt] = useState(0);
    const [reload, setReload] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [uploadModal, setUploadModal] = useState(false);
    const [initialUploadType, setInitialUploadType] = useState(0);
    const [uploadDropdownOpen, setUploadDropdownOpen] = useState(false);
    const [displaySearch, setDisplaySearch] = useState(false);

    const [documentNameSelector, setDocumentNameSelector] = useState([]);
    const [searchFilter, setSearchFilter] = useState({file_names: []});
    const [selectedCategory, setSelectedCategory] = useState(-1);

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
    }

    const toggleUploadDropdown = () => {
        setUploadDropdownOpen(!uploadDropdownOpen);
    }

    const handleUploadSelect = (uploadType) => {
        setInitialUploadType(uploadType);
        setUploadModal(true);
        setUploadDropdownOpen(false);
    }

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const uploadOptions = [
        { type: 0, label: "Specifications" },
        { type: 1, label: "Contracts" },
        { type: 2, label: "Site Investigations" },
        { type: 3, label: "Drawings" },
        { type: 4, label: "Health & Safety" },
        { type: 6, label: "Plans & Methods" },
        { type: 5, label: "Other" }

    ];

    function filterRecords(selections) {
        if (selections.length > 0) {
            selections.forEach((item) => {
                setSearchFilter({...searchFilter, file_names: [...searchFilter.file_names, item.value]})
            })
        } else {
            setSearchFilter({"file_names": []})
        }
    }

    const fetchDocumentsNames = async () => {
        return httpClientPy
        .get(`/documents/meta?project_id=${props.project.id}&doc_type=${activeTab - 1}`)
        .then((result) => {
            let options = result.data.file_names.map((item) => {
                return {
                    value: item,
                    label: item
                }
            });
            setDocumentNameSelector(options);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        fetchDocumentsNames();
        props.getProjectData(props.project.id);
    }, [cnt]);

    useEffect(() => {
        if (selectedCategory < 0) { return; }
        toggleUploadModal();
    }, [selectedCategory]);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <h5 className="mb-0"><b>Project Repository</b></h5>
                <div>
                    <Dropdown 
                        isOpen={uploadDropdownOpen} 
                        toggle={toggleUploadDropdown}
                        className='d-inline-block'
                    >
                        <DropdownToggle color="primary" className={`btn-sm d-inline-block my-2 me-2`}>
                            <FontAwesomeIcon className="me-2" icon={faUpload}/>
                                Upload docs
                            <FontAwesomeIcon className="ms-2" icon={faCaretDown}/>
                        </DropdownToggle>
                        <DropdownMenu>
                            {uploadOptions.map((option) => (
                                <DropdownItem 
                                    key={option.type} 
                                    onClick={() => handleUploadSelect(option.type)}
                                >
                                    {option.label}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            <div className="pb-2">
                <Nav tabs className="custom-nav-tabs">
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 0 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(0)}
                        >
                            All <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_data}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 1 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(1)}
                        >
                            Specs <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_specs_data}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 2 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(2)}
                        >
                            Contracts <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_contracts_data}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 3 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(3)}
                        >
                            Site Investigations <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_site_inv_data}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 4 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(4)}
                        >
                            Drawings <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_drawings_data}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 5 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(5)}
                        >
                            Health & Safety<span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_risk_regs_data}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 7 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(7)}
                        >
                            Plans & Methods <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_plans_data}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="custom-nav-item">
                        <NavLink
                            className={`${activeTab === 6 ? 'custom-nav-link-active' : 'custom-nav-link '}`}
                            onClick={() => toggleTab(6)}
                        >
                            Other <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_others_data}</span>
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
            <div className="border rounded shadow-sm d-flex flex-column flex-grow-1" style={{overflowY:'auto'}}>
                <div className="text-start mt-2 mx-2">
                {documentNameSelector.length > 0 &&
                <>
                    {displaySearch ?
                        <>
                            <Select
                                className="basic-multi-select d-inline-block filter-record-select-sm text-center me-2"
                                classNamePrefix="select"
                                isSearchable={true}
                                name="searchDocs"
                                isMulti={true}
                                placeholder={"Search Document"}
                                components={{ ValueContainer }}
                                closeMenuOnSelect={false}
                                isRtl={false}
                                options={documentNameSelector}
                                onChange={filterRecords}
                                autoFocus={true}
                            />
                            <div className="btn btn-sm btn-outline-dark rounded"
                                onClick={() => setDisplaySearch(!displaySearch)}
                            >
                                <FontAwesomeIcon icon={faFilterCircleXmark}/>
                            </div>
                        </>
                        :
                        <div className="btn btn-sm btn-outline-secondary"
                            onClick={() => setDisplaySearch(!displaySearch)}
                        >
                            <FontAwesomeIcon className="pe-2" icon={faFilter}/>
                            Filter File(s)
                        </div>
                    }
                </>
                }
                </div>


                <DocumentsTable docType = {activeTab} 
                                project = {props.project}
                                searchFilter = {searchFilter} 
                                reload = {reload}
                                setCnt = {setCnt}
                                updateTask={(task)=>props.updateTask(task)}
                                updateSelectedData={(data)=>props.updateSelectedData(data)}
                />
            </div>

            {props.userInfo && uploadModal &&
                <UploadModal 
                    isOpen={uploadModal} 
                    toggle={toggleUploadModal} 
                    userInfo={props.userInfo}
                    project={props.project}
                    extraDetails={props.extraDetails}
                    initialUploadType={initialUploadType}
                    onComplete={() => setReload(cnt + 1)}
                >
                </UploadModal>
            }
        </>
    );
};

export default MainLayout;

const ValueContainer = ({ children, ...props }) => {
    if (children[0] !== null) {
      const length = children[0].length
      let tmpChildren = [...children];
      if(length >= 2){
        tmpChildren[0] = `${length} options selected`
      }
  
      return (
        <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
      );
    }
};