import httpClientPy from "../../utils/httpClientPy";
import { useNavigate, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loader from "../shared/Loader";
import authenticationHandler from "../../utils/authenticationHandler";
import { setMixpanelID, logEvent } from "../shared/Mixpanel";
import { UncontrolledAlert } from "reactstrap";

const loadGoogleScript = () =>
  new Promise((resolve, reject) => {
    if (document.getElementById("google-client-script")) return resolve();
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = () => resolve();
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);
  });

const GoogleSignIn = (props) => {
  const navigate = useNavigate();
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleGoogleSignIn = (response) => {
    setLoading(true);
    props.setPendingActivation(false);
    props.setErrors([]);
    if (!response.clientId || !response.credential) return;
    httpClientPy
      .post(`/user/google/auth`, {
        id_token: response.credential,
        invite_code: props.inviteCode,
      })
      .then((result) => {
        let user_profile_id = result.data.data.user_profile_id;
        let email = result.data.data.email;
        let enterprise = result.data.data.enterprise;
        let enterprise_name = result.data.data.enterprise_name;
        setMixpanelID(user_profile_id, email, enterprise, enterprise_name);
        if (result.data.data.first_login === true) {
          logEvent("Sign Up", { "Provider": "Google" });
          localStorage.removeItem("map_state");
          localStorage.setItem("new_user", true);
        } else {
          logEvent("Log In", { "Provider": "Google" });
          localStorage.setItem("onboarded", true);
        }
        localStorage.setItem("auth_token", result.data.data.token.access_token);
        localStorage.setItem("displayName", result.data.data.display_name);
        if (result.data.data.total_projects === 0 && result.data.data.total_libraries > 0) {
          localStorage.setItem("selectedPage", 'LIBR');
          navigate("/library")
        } else {
          localStorage.setItem("selectedPage", 'PROJ');
          navigate("/projects");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        props.setErrors([error.response.data.detail]);
        authenticationHandler.logout();
        throw error;
      });
  };

  useEffect(() => {
    if (gsiScriptLoaded) return;

    loadGoogleScript().then(() => {
      window.google.accounts.id.initialize({
        client_id:
          "249683471997-hfj6r9nl4dc3782dm88k1eajjne0dt55.apps.googleusercontent.com",
        callback: handleGoogleSignIn,
        ux_mode: "popup",
        context: "signin",
        auto_select: false,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        {
          theme: "default",
          size: "large",
          text: "continue_with",
          shape: "rectangular",
          type: "standard",
          width: 334,
        }
      );


      setGsiScriptLoaded(true);
      setLoading(false);
    });

    return () => {
      if (
        window &&
        window.google &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google?.accounts.id.cancel();
      }
      document.getElementById("google-client-script")?.remove();
    };
  }, [handleGoogleSignIn, gsiScriptLoaded]);

  return authenticationHandler.isAuthenticated() === false ? (
    <div>
      <div className="btn-google text-dark">
        <Loader loading={loading} />
        <div className="mt-2">
          <div id="g_id_onload"></div>
          <div id="g_id_signin"></div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={props.redirectTo} />
  );
};

export default React.memo(GoogleSignIn);
