import Loader from "../../../shared/Loader";
import moment from "moment";
import { useEffect, useRef, useState} from "react";
import ProgressCounter from "../../../map/dataTab/ProgressCounter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Card, CardBody} from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, faDatabase, faList, faClock,faTriangleExclamation, faCloudArrowUp, faRefresh} from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import httpClientPy from "../../../../utils/httpClientPy";
import { toast, ToastContainer } from "react-toastify";
import PdfViewer from "../../../project/detailComponents/PdfViewer";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const FileSelectorTable = (props) => {
    const [documents, setDocuments] = useState([]);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const [loading, setLoading] = useState(false);
    const [currentSortBy, setCurrentSortBy] = useState('uploaded');
    const [currentSortOrder, setCurrentSortOrder] = useState('desc');
    const [selectedDocuments, setSelectedDocuments] = useState(props.filesSelected)
    const [showPdf, setShowPdf] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [pageNumberInput, setPageNumberInput] = useState(1);
    const [initialPage, setInitialPage] = useState(1);
    const [task, setTask] = useState(4);

    const currentPageRef = useRef(currentPage);
    const currentRowsPerPageRef = useRef(currentRowsPerPage);
    const currentSortByRef = useRef(currentSortBy);
    const currentSortOrderRef = useRef(currentSortOrder);
    const selectAllRef = useRef(null);
    
    useEffect(() => {
        currentPageRef.current = currentPage;
    }, [currentPage]);

    useEffect(() => {
        currentRowsPerPageRef.current = currentRowsPerPage;
    }, [currentRowsPerPage]);

    useEffect(() => {
        currentSortByRef.current = currentSortBy;
    }, [currentSortBy]);

    useEffect(() => {
        currentSortOrderRef.current = currentSortOrder;
    }, [currentSortOrder]);
        
    const fetchRecords = async (page, rowsPerPage, sortBy, sortOrder, is_trigger) => {
        let trig = is_trigger ? is_trigger : false;
        setLoading(!trig)
        props.setOuterLoading(!trig)
        if (!trig) {
            setDocuments([]);
        }
        
        return httpClientPy
        .post('/documents',
            {
                project_id: props.project.id,
                doc_type: props.docType -1,
                limit: rowsPerPage,
                offset: (page-1)*rowsPerPage,
                sort_by: sortBy ? sortBy : currentSortBy,
                sort_order: sortOrder ? sortOrder : currentSortOrder,
                search: props.searchFilter
            })
        .then((result) => {
            setDocuments(result.data.data);
            setTotalRows(result.data.total_rows.length);
            setLoading(false);
            props.setOuterLoading(false);
        })
        .catch((err) => {
            toast.error('Failed to load the list of documents, please contact info@civils.ai.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })
    }

    const handleRowClick = (row) => {
        if (row.status !== 'fail') {
            
            let sRow = {id: row.id, file_name: row.file_name_updated}
            if (selectedDocuments.find((doc) => doc.id === sRow.id)) {
                setSelectedDocuments(selectedDocuments.filter((doc) => doc.id !== sRow.id));
            } else {
                setSelectedDocuments([...selectedDocuments, sRow]);
            }

        }
        setFileUrl(row.pdf_url)
        setShowPdf(true)
    };

    const handleSort = (column, sortDirection) => {
        fetchRecords(currentPage, currentRowsPerPage, column.id, sortDirection, false);
        setCurrentSortBy(column.id);
        setCurrentSortOrder(sortDirection);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchRecords(page, currentRowsPerPage);
    }

    const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
        setCurrentRowsPerPage(newRowsPerPage);
        fetchRecords(currentPage, newRowsPerPage);
    }

    const handleSelectDocument = (e, row) => {

        if (e.target.checked) {
            setSelectedDocuments([...selectedDocuments, {id: row.id, file_name: row.file_name_updated}]);
        } else {
            setSelectedDocuments(selectedDocuments.filter((doc) => doc.id !== row.id));
        }
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            // Select all visible ready document
            const newSelectedDocs = documents
                .filter(doc => doc.status !== 'fail')
                .map(doc => ({
                    id: doc.id,
                    file_name: doc.file_name_updated,
            }));

            // if selectedDocuments and newSelectedDocs has the same id then deduced the selectedDocuments

            const dedupedSelectedDocs = selectedDocuments.filter(
                selected => !newSelectedDocs.find(doc => doc.id === selected.id)
            );

            setSelectedDocuments([...dedupedSelectedDocs, ...newSelectedDocs]);
        } else {
            // Deselect visible documents.
            const remaining = selectedDocuments.filter(
                selected => !documents.find(doc => doc.id === selected.id)
            );
            setSelectedDocuments(remaining);
        }
    };

    useEffect(() => {
        fetchRecords(currentPage, currentRowsPerPage, currentSortBy, currentSortOrder);
        // Set up interval to fetch records every 1 minute
        const intervalId = setInterval(() => {
            fetchRecords(currentPageRef.current, currentRowsPerPageRef.current, currentSortByRef.current, currentSortOrderRef.current, true);
        }, 60000); // 60000 milliseconds = 1 minute

        // Clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, [props.docType, props.project, props.searchFilter]);

    useEffect(() => {
        props.setFileSelected(selectedDocuments)
    }, [selectedDocuments])

    const columns = []

    columns.push({
        name: (
            <input
                type="checkbox"
                ref={selectAllRef}
                className="form-check-input cursor-pointer"
                onChange={handleSelectAll}
                checked={
                    documents.length > 0 &&
                    documents.every((doc) =>
                        selectedDocuments.length === documents.filter((doc) => doc.status !== 'fail').length
                    )
                }
            />
        ),
        id: 'select',
        width: showPdf ? '10%' : '5%',
        center: true,
        cell: (row) => (
            row.status != 'fail' ? (
                <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    checked={!!selectedDocuments.find((doc) => doc.id === row.id)}
                    onChange={(e) => handleSelectDocument(e, row)}
                />
            ) : (
                null
            )
        ),
    });

    columns.push({
        name: 'File name',
        selector: row => {
            return row.file_name_updated
        },
        width: showPdf ? '30%' : '50%',
        center: false,
        sortable: true,
        id: 'file_name',
        cell: (row) => {
            return row.file_name_updated
        },
    })

    columns.push({
        name: 'Category',
        id: 'category',
        selector: row => row.category_name,
        width: showPdf ? '15%' : '15%',
        center: true,
        sortable: false
    })

    columns.push({
        name: 'Added',
        id: 'uploaded',
        selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
        width: showPdf ? '15%' : '10%',
        center: true,
        sortable: true,
        cell: (row) => (
            <span className="badge rounded-pill darkGrey documentBadge">
                <span><FontAwesomeIcon icon={faClock} /></span>
                    <span className="ms-1">
                        {row.created_datetime
                        ? moment(row.created_datetime).format('DD/MM/YYYY')
                        : 'Unknown'}
                    </span>
            </span>
        ),
    })

    columns.push({
        name: 'Status',
        id: 'status',
        width: showPdf ? '15%' : '10%',
        selector: row => row.status,
        center: true,
        sortable: true,
        cell: (row) => <ProgressCounter status={row.status} requestedDate={row.created_datetime} />,
    })

    columns.push({
        name: 'Preview',
        id: 'preview',
        width: '10%',
        center: true,
        sortable: false,
        cell: (row) => 
            <span
                className="btn btn-sm"
                onClick={() => handleRowClick(row)}
            >
                <FontAwesomeIcon icon={faEye} />
            </span>
    })
  
    const customStyles = {
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
                zIndex: 0,
                backgroundColor: '#fff', // Bootstrap light grey for table headers
                textAlign: 'left', // Ensure header text is also left-aligned
            },
        },
        cells: {
            style: {
                textAlign: 'left', // Left-align text
                justifyContent: 'flex-start', // Ensure content starts from the left
                whiteSpace: 'normal', // Allow text wrapping
                wordBreak: 'break-word', // Ensure long words break properly
            },
        },
        rows: {
            style: {
                cursor: 'pointer',
                backgroundColor: '#fff', // Bootstrap white for table rows
            },
        },
    };
    

    return (
        <>
        <ToastContainer />
        <div className="d-flex flex-grow-1 flex-column">
            {loading &&

                <div className="my-5">
                    <Loader loading={loading} />
                </div>

            }
            {documents && documents.length > 0 && (
            <>
                <Card
                    className="w-100 border-0 d-flex flex-grow-1 flex-column"
                    style={{ overflowY: 'auto', overflowX: 'hidden' }}
                >
                    <CardBody className='row h-100'>
                        <div id='pdfview' className={`border-end ${showPdf ? 'd-block col-6' : 'd-none'}`} style={{height: '60vh' }}>
                            <PdfViewer
                                project={props.project} 
                                task={task}
                                closePdfViewer={() => setShowPdf(false)}
                                fileUrl={fileUrl}
                                pageNumberInput={pageNumberInput}
                                initialPage={initialPage}
                                fileType={null}
                                setInitialPage={(page)=>setInitialPage(page)}
                                highlightArea = {null} 
                                updateTask={(id)=>setTask(id)}
                                currentFileName={null}
                                updatePageNumber={(page)=>setPageNumberInput(page)}
                                updateBase64img={null}
                            />
                        </div>
                        <div className={`${showPdf ? 'col-6' : 'col-12'}`}>
                            <DataTable
                                className={`sticky-top text-center geo-records-list-holder custom-nav doc-table-full`}
                                fixedHeader
                                pagination
                                paginationServer={true}
                                paginationTotalRows={totalRows}
                                paginationRowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                                paginationPerPage={currentRowsPerPage}
                                paginationDefaultPage={currentPage}
                                persistTableHead
                                sortServer
                                onSort={handleSort}
                                highlightOnHover
                                defaultSortFieldId={currentSortBy}
                                defaultSortAsc={currentSortOrder === 'asc'}
                                columns={columns}
                                data={documents}
                                onRowClicked={handleRowClick}
                                customStyles={customStyles}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                onChangePage={handlePageChange}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
            )}

            {(!documents || documents.length === 0) && !loading && (
            <>
                <div className="container">
                    <div className="row">
                        <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '50vh' }}>
                            <h3><b>You've not uploaded any documents of this type yet</b></h3>
                            <div className="pt-2">Upload some PDF files first to get started, processing takes about 15 minutes per file.</div>
                        </div>
                    </div>
                </div>
            </>
            )}
        </div>
        </>
    );
};

export default FileSelectorTable;