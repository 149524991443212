import { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import GenericUploader from "./uploaders/GenericUploader";

interface Project {
  id: string | number;
}

interface UserInfo {
  allow_drawing?: boolean;
  allow_civilsgpt?: boolean;
  allow_digitise?: boolean;
  is_enterprise?: boolean;
  subscription_details?: {
    subscription_type: string;
  };
}

interface UploaderProps {
  project: Project;
  userInfo?: UserInfo;
  className?: string;
  uploadUrl?: string;
  allDocs?: boolean;
  projectId?: string | number;
  onComplete?: () => void;
  extraDetails?: any;
  initialUploadType?: number | null;
}

const Uploader: React.FC<UploaderProps> = (props) => {
  const [uploadType, setUploadType] = useState<number | null>(props.initialUploadType ?? null);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    if (selectedId !== "") {
      setUploadType(parseInt(selectedId, 10));
    } else {
      setUploadType(null);
    }
  };

  return (
    <>
      <Card className="w-100 d-flex flex-grow-1 flex-column">
        <CardHeader className="bg-light px-2 automationbutton">
          <span className="me-3">
            <b>Document type you want to upload</b>
          </span>
          <select
            className="form-select d-inline"
            value={uploadType?.toString() ?? ""}
            onChange={handleSelectChange}
            style={{ width: "auto", minWidth: "200px" }}
            aria-label="Default select example"
          >
            <option value="">Select document type</option>
            <option value="0">Specifications</option>
            <option value="1">Contracts</option>
            <option value="2">Site Investigation</option>
            <option value="3">Drawings</option>
            <option value="4">Health and Safety</option>
            <option value="6">Plans & Methods</option>
            <option value="5">Others</option>
          </select>
        </CardHeader>
        <CardBody className={`show bgworkflow overflow-auto`} id="automation1">
          <GenericUploader
            {...props}
            projectId={props.project.id}
            mode={uploadType}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Uploader;
