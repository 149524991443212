import { faCity, faBook, faGrip, faRobot, faGraduationCap, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { useEffect, useState } from 'react';
import AccountDropdown from '../shared/AccountDropdown';
import UploadModal from '../shared/UploadModal';
import QuotaCard from '../shared/QuotaCard';


const SideBar = (props) => {

    const navigate = useNavigate();
    const [toggleNewProjectModal, setToggleNewProjectModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedBtnMain, setSelectedBtnMain] = useState(null);
    const [btnloaded, setBtnLoaded] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [quotaView, setQuotaView] = useState('documents');

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const toggleUploadModal = () => setUploadModal(!uploadModal);

    useEffect(() => {
        // get the url
        const url = window.location.pathname;
        // get the last part of the url
        const urlArray = url.split('/');
        const lastUrl = urlArray[urlArray.length - 1];
        // set the selected button
        setSelectedBtnMain(lastUrl);
    }, [props.page]);

    useEffect(() => {
        if (props.page === 'detail' || props.page === 'umanage' || props.page === 'training') { return }
        let selectedPage = localStorage.getItem("selectedPage");
        if (selectedPage) {
            if (selectedPage === 'PROJ') {
                navigateToProjects();
            }
            else if (selectedPage === 'LIBR') {
                navigateToLibraries();
            }
        }
    }, []);

    const navigateToProjectDetails = (id) => {
        navigate('/project', { state: {id: props.project.id} });
        props.closeChat()
        if (props.page === 'detail') {
            props.setSubpage('overview')
            props.setType(null)
        }
    }

    const navigateToProjects = () => {
        setSelectedBtnMain('projects');
        localStorage.setItem("selectedPage", 'PROJ');
        navigate(`/projects`);
    }

    const navigateToTraining = () => {
        setSelectedBtnMain(null);
        navigate(`/training`);
    }

    const navigateToLibraries = () => {
        setSelectedBtnMain('library');
        localStorage.setItem("selectedPage", 'LIBR');
        navigate(`/library`);
    }

    const navigateToGeotech = () => {
        if (props.subpage === 'workflows') {
            navigate('/project', { state: {id: props.project.id, type: 1, subpage: 'geotech'} });
        } else {
            props.setType(1);
            props.setSubpage('geotech');
        }
    }

    const navigateToWorkflows = () => {
        navigate('/project/workflows', { state: {project_id: props.project.id} });
    }

    const navigateToWorkflowsOutput = () => {
        navigate('/project/workflows/outputs', { state: {project_id: props.project.id} });
    }

    const toggleNewProject = () => {
        setToggleNewProjectModal(!toggleNewProjectModal);
    }

    const subscriptionSettings = () => {
        // window.open('https://www.civils.ai/create-portal-session') with userInfo.customer as input parameter
        window.open(`https://www.civils.ai/create-portal-session?customer=${props.userInfo.customer}`);
    };

    // check if libbtn is loaded
    useEffect(() => {
        
        if (document.getElementById('libbtn')) {
            setBtnLoaded(true);
        }

    }, [props.userInfo])
    

    return (
        <>
            <Offcanvas show={true} backdrop={false} scroll={true} className='project-sidebar d-none d-md-flex'>
                <Offcanvas.Body className="d-flex flex-column px-2">
                    <div className='tw-flex tw-flex-row tw-items-center tw-mb-4'>
                        <img src="assets/logo_white.png" alt="Civils.ai" className='tw-w-[22px] tw-h-6 tw-ml-1' />
                        <span className="fs-4 tw-font-extrabold tw-text-white tw-ml-1.5 tw-font-righteous">Civils.ai</span>
                    </div>
                    {props.userInfo && 
                        <>
                        {(props.userInfo.subscription.subscription_id !== 1 || props.userInfo.is_enterprise) ?
                            <>
                            {props.page === 'list' || props.page === 'umanage' || props.page === 'training' ? (
                                <>
                                <a className={`${selectedBtnMain === 'projects' ? 'sub-item-disabled mb-1' : 'sub-item mb-1'}`} onClick={() => navigateToProjects()}>
                                    <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
                                    <span className="">Projects</span>
                                </a>
                                {props.userInfo && props.userInfo.is_enterprise ? (
                                    <a id='libbtn' className={`${selectedBtnMain === 'library' ? 'sub-item-disabled mb-1' : 'sub-item mb-1'}`} onClick={() => navigateToLibraries()}>
                                        <span><FontAwesomeIcon className="me-3" icon={faBook}/></span>
                                        <span className="">Libraries</span>
                                    </a>
                                ) : (
                                    <>
                                        <a id='libbtn' className={`${selectedBtnMain === 'library' ? 'sub-item-disabled mb-1' : 'sub-item mb-1'}`} onClick={() => null}>
                                            <span><FontAwesomeIcon className="me-3" icon={faBook}/></span>
                                            <span className="">Libraries</span>
                                        </a>
                                        {btnloaded === true && (
                                            <Tooltip target="libbtn" isOpen={tooltipOpen} placement="right" toggle={toggle}>
                                                This feature is only available for enterprise users. Please contact info@civils.ai for more information.
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                                </>
                            ) : (
                                <>
                                    <a className={`${props.subpage === 'overview' || props.subpage === undefined ? 'sub-item-disabled' : 'sub-item'}`} onClick={() => navigateToProjectDetails()}>
                                        <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
                                        <span className="">Project</span>
                                    </a>
                                    {props.userInfo && props.userInfo.allow_workflow &&
                                        <>
                                        <a className={`${props.subpage === 'workflows' ? 'sub-item-disabled' : 'sub-item'}`}  
                                            onClick={() => navigateToWorkflows()}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span><FontAwesomeIcon className="me-2" icon={faRobot}/>Workflows</span>
                                                {props.projectExtraDetails && 
                                                    <small><span className='badge custom-nav-link-count-box-light ms-2'>{props.projectExtraDetails.workflow_cnt}</span></small>
                                                }
                                            </div>
                                        </a>
                                        <a className={`${props.subpage === 'workflows-outputs' ? 'sub-item-disabled' : 'sub-item'}`}  
                                            onClick={() => navigateToWorkflowsOutput()}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <span><FontAwesomeIcon className="me-2" icon={faTable}/>Outputs</span>
                                                {props.projectExtraDetails &&
                                                    <>
                                                    {(props.projectExtraDetails.workflow_out_cnt && props.projectExtraDetails.workflow_out_cnt>0) ?
                                                        <small><span className='badge custom-nav-link-count-box-light ml-auto'>{props.projectExtraDetails.workflow_out_cnt}</span></small>
                                                        :
                                                        null
                                                    }
                                                    </>
                                                }
                                            </div>
                                        </a>
                                        </>
                                    }
                                    {/* <hr className='hr-custom'></hr>
                                    {props.userInfo && props.userInfo.is_enterprise && props.userInfo.allow_digitise && 
                                        <a className={`${props.subpage === 'geotech' ? 'sub-item-disabled' : 'sub-item'} mb-1`} onClick={() => navigateToGeotech()}>
                                            <span><FontAwesomeIcon className="me-2" icon={faTable}/></span>
                                            <span className="">Geotech</span>
                                        </a>
                                    } */}
                                </>
                            )}
                            </>
                        : (
                            <>
                                <a className={`sub-item mb-1`} onClick={() => props.page === 'umanage' || props.page === 'training' ? navigateToProjects() : props.togglePricingModal()} >
                                    <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
                                    <span className="">Projects</span>
                                </a>
                                <a className={`sub-item mb-1`} onClick={() => props.page === 'umanage' || props.page === 'training' ? navigateToProjects() : props.togglePricingModal()}>
                                    <span><FontAwesomeIcon className="me-3" icon={faBook}/></span>
                                    <span className="">Libraries</span>
                                </a>
                            </>
                        )}
                        </>
                    }
                    <div className="mt-auto w-100"> {/* This pushes the logout button to the bottom */}
                        
                        <hr className='hr-custom'></hr>
                        {props.userInfo && (props.userInfo.subscription.subscription_id !==1 || props.userInfo.is_enterprise) ?
                        <>
                            {

                                (props.page === 'detail' || props.page === 'data' || props.page === 'umanage') ? (
                                    <a className={`sub-item mb-1`} onClick={() => navigateToProjects()}>
                                        <span><FontAwesomeIcon className="me-2" icon={faGrip}/></span>
                                        <span className="">Home</span>
                                    </a>
                                ) : (
                                    null
                                )
                            }
                            {
                                (props.page === 'training') ? (
                                    <a className={`sub-item-disabled mb-1`} onClick={() => navigateToTraining()}>
                                        <span><FontAwesomeIcon className="me-2" icon={faGraduationCap}/></span>
                                        <span className="">Training</span>
                                    </a>
                                ) : (
                                    props.page === 'list' &&
                                    <a className={`sub-item mb-1`} onClick={() => navigateToTraining()}>
                                        <span><FontAwesomeIcon className="me-2" icon={faGraduationCap}/></span>
                                        <span className="">Training</span>
                                    </a>
                                )
                            }
                        </>
                        :
                            <a className={`sub-item mb-1`} onClick={() => navigateToTraining()}>
                                <span><FontAwesomeIcon className="me-2" icon={faGraduationCap}/></span>
                                <span className="">Training</span>
                            </a>
                        }

                        <AccountDropdown 
                            userInfo={props.userInfo}
                            admin={props.admin}
                        />
                        {props.userInfo &&
                            <QuotaCard quotaView={quotaView}
                                    setQuotaView={setQuotaView}
                                    userInfo={props.userInfo}
                            />
                        }
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            {/* <Modal id="newProject" size="l" isOpen={toggleNewProjectModal} toggle={toggleNewProject} centered={true}>
                <NewProjectModal title="Your new project is starting..."
                                toggleModal = {()=> toggleNewProject()} 
                                handleChoiceClick = {(project_type) => handleAddProjectClick(project_type)}/>
            </Modal> */}
            {props.userInfo &&
                <UploadModal 
                    isOpen={uploadModal} 
                    toggle={toggleUploadModal} 
                    userInfo={props.userInfo}
                    project={props.project}
                    extraDetails={props.projectExtraDetails}
                    onComplete={() => props.onComplete()}
                >
                </UploadModal>
            }
        </>
    )
}

export default SideBar;