import { Outlet } from "react-router-dom";
import React, { Component } from "react";
import Header from "../Header";
import NavFooter from "../NavFooter";
import SideBar from "../../project/SideBar";

export class ProjectLayout extends Component {
  render() {
    return (
        <div className="container-fluid">
          <Outlet/>
        </div>
    );
  }
}
