import WorkFlowCard from "./WorkFlowCard";
import httpClientPy from "../../../utils/httpClientPy";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";


const WorkFlowListCards = (props) => {

    const [loading, setLoading] = useState(false);
    const [workflows, setWorkflows] = useState([]);

    const fetchRecords = async () => {
        
        setLoading(true);
        setWorkflows([]);
        
        return httpClientPy
        .post('/workflows',
            {
                project_id: props.project.id,
                category_id: props.selectedCategory,
            })
        .then((result) => {
            setWorkflows(result.data.workflows);
        })
        .catch((err) => {
            toast.error('Failed to load the list of workflows, please contact info@civils.ai.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchRecords();
    }, [props.project, props.selectedCategory])

    useMemo(() => {
        props.getWorkflowMeta(props.project.id);
    }, [workflows])

    return (
        <>
            <ToastContainer />
            {loading ? (

                <div className="d-flex justify-content-center align-items-center pt-4">
                    <Spinner animation="border" role="status"/>
                </div>
                

            ) : workflows.length > 0 ? (
                <div className="row mt-0 g-3">
                    

                        {workflows.map((workflow, index) => (

                            <WorkFlowCard key={index} 
                                          workflow={workflow} 
                                          setAction={props.setAction}
                                          fetchRecords={fetchRecords}
                                          project={props.project}
                                          superAdmin={props.superAdmin}
                            />

                        ))}

                   
                </div>

            ) : (
                <div className="container">
                    <div className="row">
                        <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '50vh' }}>
                            <h3><b>No Workflows Found</b></h3>
                            <div className="pt-2">Contact info@civils.ai if you don't see any workflows.</div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )

}

export default WorkFlowListCards;