import React, { useState } from "react";
import { ModalHeader, ModalBody } from "react-bootstrap";
import Button from "../shared/Button";
import httpClientPy from "../../utils/httpClientPy";

const AdminUpdateRecord = ({ ...props }) => {
  const [geoRecordIds, setGeoRecordIds] = useState("");
  const [userId, setUserId] = useState("");
  const [geoRecordIdsDelete, setGeoRecordIdsDelete] = useState("");
  const [loading, setLoading] = useState(false);

  const updateValues = (event, field) => {
    if (field === "geo_records") {
      setGeoRecordIds(event.target.value);
    } else if (field === "user_id") {
      setUserId(event.target.value);
    } else if (field === "geo_records_delete") {
      setGeoRecordIdsDelete(event.target.value);
    }
  };

  const closeModal = () => {
    props.toggleModal();
  };

  const handleSubmit = () => {
    setLoading(true);

    // create array from the geoRecordIds
    const geoRecordIdsArray = geoRecordIds.split(",");
    // create array from the geoRecordIdsDelete if it is not empty else send an empty array
    const geoRecordIdsDeleteArray = geoRecordIdsDelete
      ? geoRecordIdsDelete.split(",")
      : [];
    // cast userId to int
    const userIdInt = parseInt(userId);

    httpClientPy
      .post("/geo/reassign/data", {
        geo_record_id: geoRecordIdsArray,
        user_id: userIdInt,
        geo_record_id_delete: geoRecordIdsDeleteArray,
      })
      .then((response) => {
        //console.log(response);
        setLoading(false);
        closeModal();
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
        closeModal();
      });
  };

  return (
    <>
      <ModalHeader
        className="border-0 mb-0 pb-0"
        toggle={() => props.toggleModal()}
      >
        {" "}
        {props.displayName && props.displayName}
      </ModalHeader>
      <ModalBody>
        <hr className="mb-4" />
        <div className="container-fluid w-100 pb-2 mb-1">
          <div>
            This form is to reassign geo records to their respective original
            users
          </div>
        </div>
        <hr className="mb-4" />
        <div className="form-floating mb-2">
          <textarea
            name="geo_records"
            className="form-control"
            id="geo_records"
            value={geoRecordIds}
            onChange={(event) => updateValues(event, "geo_records")}
            autoComplete="off"
          />
          <label htmlFor="geo_records">Geo Record Ids To Reassign</label>
        </div>
        <div className="form-floating mb-2">
          <input
            name="user_id"
            type="text"
            className="form-control"
            id="user_id"
            value={userId}
            onChange={(event) => updateValues(event, "user_id")}
            autoComplete="off"
          />
          <label htmlFor="user_id">User Id To Reassign</label>
        </div>
        <div className="form-floating mb-2">
          <textarea
            name="geo_records_delete"
            className="form-control"
            id="geo_records_delete"
            value={geoRecordIdsDelete}
            onChange={(event) => updateValues(event, "geo_records_delete")}
            autoComplete="off"
          />
          <label htmlFor="geo_records_delete">
            Geo Record Ids To Delete (if any)
          </label>
        </div>
        <Button
          onClick={handleSubmit}
          label="Submit"
          className="w-100 btn btn-primary"
          loading={loading}
        />
      </ModalBody>
    </>
  );
};

export default AdminUpdateRecord;
