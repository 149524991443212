import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useRef, useEffect, useState} from 'react';
import { Card, CardHeader, ListGroupItem } from 'reactstrap';
import Loader from "../../../shared/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as d3 from "d3";
import { faCopy, faFilePdf, faMapLocation, faFileExport, faTriangleExclamation, faDrawPolygon} from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import 'svg2pdf.js'
import L from "leaflet";
import { MapContainer, TileLayer, Polyline, Marker} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import html2canvas from 'html2canvas';
import httpClientPy from "../../../../utils/httpClientPy";
import { logEvent } from "../../../shared/Mixpanel";
import BoreholeLogDetails from "../../dataTab/BoreholeLogDetails";

library.add(faCopy, faMapLocation);

const boreholeBlack = new L.Icon({
    iconUrl: "assets/borehole-black.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 16], // Adjust these values
    popupAnchor: [16, 16], // Adjust these values
});

const boreholeRed = new L.Icon({
    iconUrl: "assets/borehole-red.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 16], // Adjust these values
    popupAnchor: [16, 16], // Adjust these values
});

const ChartGeoSectionCut = (props) => {
    const svgRef = useRef();
    const svgLegendRef = useRef();

    const [boreholesData, setBoreholesData] = useState([]);
    const [boreholes, setBoreholes] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [togglefullBhName, setToggleFullBhName] = useState(true);
    const [toggleDetails, setToggleDetails] = useState(false);
    const [toggleMap, setToggleMap] = useState(false);
    const [toggleSgl, setToggleSgl] = useState(false);
    const [boreholeSelection, setBoreholeSelection] = useState(0);
    const [satelliteDataLoaded, setSatelliteDataLoaded] = useState(false);
    const [satelliteData, setSatelliteData] = useState([]);
    const [maxTop, setMaxTop] = useState(0);
    const [minTop, setMinTop] = useState(0);
    const [satelliteMaxTop, setSatelliteMaxTop] = useState(0);
    const [downloadingPDF, setDownloadingPDF] = useState(false);
    const [downloadingDXF, setDownloadingDXF] = useState(false);
    const [tests, setTests] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedDataStrata, setSelectedDataStrata] = useState([]);
    const [selectedDataTests, setSelectedDataTests] = useState([]);
    const [selectedRecordId, setSelectedRecordId] = useState(null);
    const [infoUpdated, setInfoUpdated] = useState(false);
    
    const [toggleClassification, setToggleClassification] = useState(false);

    
    const generateBhChart = (bhData) => {
        
        const svg = d3.select(svgRef.current);
        const svgLegend = d3.select(svgLegendRef.current)

        // check if svg is empty or not and remove all elements if not empty
        if (!svg.empty()) {
            svg.selectAll('*').remove();
            svgLegend.selectAll('*').remove();
        }

        // copy bhData to temp
        let tempData = JSON.parse(JSON.stringify(bhData));

        // remove rows where the visible is false from temp_data
        tempData = tempData.filter(d => d.visible === true);

        const width = svg.node().parentNode.clientWidth - 50;
        const height = svg.node().parentNode.parentNode.parentNode.clientHeight - 40;

        const mTop = toggleSgl ? satelliteMaxTop : maxTop;
        const mBottom = minTop;
        
        const xScale = d3.scaleLinear().domain([0, props.distance + 5]).range([0, width]);
        
        const yScale = d3.scaleLinear().domain([mBottom - 2, mTop + 5]).range([height, 0]);

        const xAxis = d3.axisTop().scale(xScale).ticks(10).tickSize(-height);
        const yAxis = d3.axisLeft().scale(yScale).ticks(10).tickSize(-width);
        
        svg.append('g').attr('transform', 'translate(30, 30)').call(xAxis).selectAll('line').style('stroke', '#ccc');
        svg.append('g').attr('transform', 'translate(30, 30)').call(yAxis).selectAll('line').style('stroke', '#ccc');

        // Append x-axis label
        svg.append('text')
            .attr('class', 'x-axis-label')
            .attr('text-anchor', 'middle')
            .attr('x', width / 2)
            .attr('y', 15)
            .text('DISTANCE ALONG SECTION BASELINE (m)');

        // Append y-axis label
        svg.append('text')
            .attr('class', 'y-axis-label')
            .attr('text-anchor', 'middle')
            .attr('x', -height / 2)
            .attr('y', 12)
            .attr('transform', 'rotate(-90)')
            .text('ELEVATION (m AOD)');

        if(toggleSgl) {
            // create a line on current svg chart based on the data
            const line = d3.line()
                .x(d => xScale(d[0]))
                .y(d => yScale(d[1]))
                .curve(d3.curveMonotoneX);

            // append the line to the svg
            svg.append('path')
                .datum(satelliteData)
                .attr('d', line)
                .attr('fill', 'none')
                .attr('stroke', 'purple')
                .attr('stroke-width', 2)
                .attr('stroke-linejoin', 'round')
                .attr('stroke-linecap', 'round')
                .attr('transform', 'translate(30, 30)');
        }

        // Create new array for stacked bar chart
        const barData = tempData.map((d) => {
            return d.top.map((value, index) => {
                return {
                    top: value,
                    bottom: d.bottom[index],
                    distance_to_first_point: d.distance_to_first_point,
                    distance_to_line: d.distance_to_line,
                    color: toggleClassification ? d.colors[index] : d.colors_classification[index],
                    visible: d.visible
                };
            });
        }).flat();

        svg.append('defs')
            .append('pattern')
            .attr('id', 'diagonal-pattern')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 4)
            .attr('height', 4)
            .append('path')
            .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
            .attr('stroke', 'black')
            .attr('stroke-width', 0.5);

        // Define the stack function
        const stack = d3.stack().keys(['top']);

        // Stack the data
        const stackedBarData = stack(barData);

        const bars = svg.append('g').attr('transform', 'translate(30, 30)');

        bars.selectAll('g')
            .data(stackedBarData)
            .enter()
            .append('g')
            .selectAll('rect')
            .data(d => d)
            .enter()
            .append('rect')
            .attr('x', d => xScale(d.data.distance_to_first_point))
            .attr('y', d => yScale(d.data.top))
            .attr('height', d => Math.round(yScale(d.data.bottom) - yScale(d.data.top)))
            .attr('width', 10)
            .attr("fill", d => d.data.color === '#FFF' ? 'url(#diagonal-pattern)' : d.data.color)
            .attr("stroke", "black")
            .attr("stroke-width", 0.5)
        

        // Create new array for stacked bar chart but only taking the distance and first top value
        const lineData = tempData.map((d) => {
            return {
                top: d.top[0],
                distance_to_first_point: d.distance_to_first_point,
                distance_to_line: d.distance_to_line,
                reference: d.reference,
                alt_name: d.alt_name,
                visible: d.visible
            };
        });
        
        svg.append('g')
            .selectAll('text')
            .data(lineData)
            .enter()
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', d => xScale(d.distance_to_first_point))
            .attr('y', d => yScale(d.top) - 5)
            .attr('transform', 'translate(30, 30)')
            .text(d => `${togglefullBhName? d.reference : d.alt_name} [${d.distance_to_line} m]`)
            .attr('transform', (d, i) => `rotate(-30, ${xScale(d.distance_to_first_point)}, ${yScale(d.top) - 5}) translate(10, 45)`)
            .attr('font-size', '10px')
            .attr('font-weight', 'bold')
            .attr("fill", "black");

        svg.append('g')
            .selectAll('line')
            .data(lineData)
            .enter()
            .append('line')
            .attr('x1', d => xScale(d.distance_to_first_point))
            .attr('y1', d => yScale(d.top))
            .attr('x2', d => xScale(d.distance_to_first_point) + 40)
            .attr('y2', d => yScale(d.top))
            .attr('transform', 'translate(30, 30)')
            .attr("stroke-width", 0.5)
            .attr("stroke", "purple");

        svg.append('g')
            .selectAll('text')
            .data(lineData)
            .enter()
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', d => xScale(d.distance_to_first_point) + 40)
            .attr('y', d => yScale(d.top))
            .attr('transform', 'translate(30, 30)')
            .text(d => `GL [${d.top} m]`)
            .attr('font-size', '10px')
            .attr("fill", "purple");

        // Create new array of tests that has the distance test name and test depth
        const testData = tempData.map((d) => {
            return d.tests.map((value) => {
                return {
                    test: value.name,
                    depth: value.depth,
                    result: value.result,
                    distance_to_first_point: d.distance_to_first_point,
                    distance_to_line: d.distance_to_line,
                    visible: d.visible
                };
            });
        }).flat();

        // remove elements where the test is WL
        const otherTest = testData.filter(d => d.test !== "WL");

        // get the key for test that has value true
        let testKeys = Object.keys(tests).filter(key => tests[key] === true);

        // only test that are in testKeys
        const filteredTest = otherTest.filter(d => testKeys.includes(d.test));


        svg.append('g')
            .selectAll('line')
            .data(filteredTest)
            .enter()
            .append('line')
            .attr('x1', d => xScale(d.distance_to_first_point))
            .attr('y1', d => yScale(d.depth))
            .attr('x2', d => xScale(d.distance_to_first_point) + 20)
            .attr('y2', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .attr("stroke", "green")
            .attr("stroke-width", 0.5);

        svg.append('g')
            .selectAll('text')
            .data(filteredTest)
            .enter()
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', d => xScale(d.distance_to_first_point) + 20)
            .attr('y', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .text(d => `${d.test} [${d.result}]`)
            .attr('font-size', '10px')
            .attr("fill", "green")

        // only test that have WL
        const wlTest = testData.filter(d => d.test === "WL");

        // only test that are in testKeys
        const FwlTest = wlTest.filter(d => testKeys.includes(d.test));

        svg.append('g')
            .selectAll('line')
            .data(FwlTest)
            .enter()
            .append('line')
            .attr('x1', d => xScale(d.distance_to_first_point))
            .attr('y1', d => yScale(d.depth))
            .attr('x2', d => xScale(d.distance_to_first_point) - 10)
            .attr('y2', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .attr("stroke-width", 0.5)
            .attr("stroke", "blue");

        svg.append('g')
            .selectAll('text')
            .data(FwlTest)
            .enter()
            .append('text')
            .attr('text-anchor', 'end')
            .attr('x', d => xScale(d.distance_to_first_point) - 10)
            .attr('y', d => yScale(d.depth))
            .attr('transform', 'translate(30, 30)')
            .text(d => `${d.test} [${d.depth} m]`)
            .attr('font-size', '10px')
            .attr("fill", "blue");

        let layers = {}
        if(toggleClassification) {
            tempData.map((d) => {
                // append the label of the index and color of the index to layers
                d.layers.map((value, index) => {
                    if (d.visible) {
                        // split value by space if there is ( in the value
                        if (value.includes('(')) {
                            value = value.split('(')[0]
                        }
                        layers[value] = d.colors[index]
                    }
                })
            });
        } else {
            tempData.map((d) => {
                tempData.map((d) => {
                    // append the label of the index and color of the index to layers
                    d.layers_classification.map((value, index) => {
                        if (d.visible) {
                            // split value by space if there is ( in the value
                            if (value.includes('(')) {
                                value = value.split('(')[0]
                            }
                            layers[value] = d.colors_classification[index]
                        }
                    })
                });
            });
        }


        // Create a legend where there will be maximum 5 box on one side, so if the left side has 5 boxes, then the 6th and onward box will be on right side
        let i = 0;
        let j = 0;
        for (const [key, value] of Object.entries(layers)) {
            const box = svgLegend.append('rect')
                .attr('x', i < 5 ? -20 : 80)
                .attr('y', i < 5 ? i * 20 : j * 20)
                .attr('width', 20)
                .attr('height', 20)
                .attr('transform', 'translate(30, 5)')
                .attr('fill', value === '#FFF' ? 'url(#diagonal-pattern)' : value)
                .attr('stroke', 'black')
                .attr('stroke-width', 0.5)
                
            const text = svgLegend.append('text')
                .attr('x', i < 5 ? 5 : 105)
                .attr('y', i < 5 ? i * 20 + 15 : j * 20 + 15)
                .attr('transform', 'translate(30, 5)')
                .text(key.split('(')[0])
                .attr('font-size', '12px')
                .attr('text-anchor', 'start')
                .attr('fill', 'black')
                // .attr('font-weight', 'bold')
                

            if (i < 5) {
                i++;
            } else {
                j++;
            }
        }

        svgLegend.attr('height', svgLegend.node().getBBox().height + 10);

    }

    const generateDxf = async () => {
        // make api call to backend with body sending the props.distance and boreholesData
        setDownloadingDXF(true);
        const body = [{
            distance: props.distance,
            info: boreholesData
        }]

        httpClientPy
        .post(`/geo/2d/export/dxf`, {
            datas: body
        })
        .then((response) => {
            logEvent('2D Section', {"Step": "DXF Exported"})
            const data = response.data.content;
            const binaryData = window.atob(data);

            const blob = new Blob([binaryData], { type: 'application/octet-stream' });

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'civilsai-2d-section.dxf';

            // Append the link to the body
            document.body.appendChild(downloadLink);

            // Simulate a click to trigger the download
            downloadLink.click();

            // Clean up by removing the link
            document.body.removeChild(downloadLink);
            setDownloadingDXF(false);

        })
    }

    const generatePDF = async () => {       
        setDownloadingPDF(true); 
        const svgChart = document.getElementById('chartSvg').firstElementChild;
        const width = svgChart.width.baseVal.value;
        const height = svgChart.height.baseVal.value;
      
        const svgLegend = document.getElementById('svgLegend').firstElementChild;

        // get the map element
        const map = document.getElementById('mapContainer');
        // remove d-none class
        map.classList.remove('d-none');
        map.classList.add('mt-5');

        // create a deep copy of the element
        const svgChartCopy = svgChart.cloneNode(true);
        const svgLegendCopy = svgLegend.cloneNode(true);

        // remove pattern for rect that has fill url(#diagonal-pattern) and change fill to white
        const rect = svgLegendCopy.querySelector('rect[fill="url(#diagonal-pattern)"]');
        if (rect) {
            rect.setAttribute('fill', '#FFF');
        }

        const canvasW = width + 200;
        let a3_ratio = 297/420
        const canvasH = width * a3_ratio
      
        const pdf = new jsPDF(width > height ? 'l' : 'p', 'pt', [canvasW, canvasH]);
      
        pdf.setFont('times', 'normal'); // Times New Roman font


        pdf.svg(svgChartCopy, {x:20, y: 20, width, height})
        .then(() => {
            // add text before the legend
            pdf.text(width + 15, 60, 'Legend');
            pdf.svg(svgLegendCopy, { x: width + 9, y: 75, width, height })
            .then(() => {
                // add new page to pdf
                pdf.addPage();
                // add map to pdf
                html2canvas(map, {useCORS: true, allowTaint: true}).then((canvas) => {
                    // Convert the canvas to a data URL
                    const mapImage = canvas.toDataURL('image/png');
                
                    // Add the map image to the new PDF page
                    pdf.addImage(mapImage, 'PNG', 20, 20, width, height);
                    
                    // add d-none class back to map
                    map.classList.add('d-none');

                    // Save the PDF
                    pdf.save('civilsai-2d-section.pdf');
                    map.classList.remove('mt-5');
                    setDownloadingPDF(false); 
                });
            });
        });
        logEvent('2D Section', {"Step": "PDF Exported"})
    };

    const handleBhVisibility = (bh) => {
        // create new boreholes array with updated visibility
        const newBoreholes = boreholes.map(b => {
            if (b.geo_record_id === bh) {
                return { ...b, visible: !b.visible };
            } else {
                return b;
            }
        });
      
        // update boreholes state
        setBoreholes(newBoreholes);

        // create new boreholesData array with updated visibility
        const newBoreholesData = boreholesData.map(b => {
            if (b.geo_record_id === bh) {
                return { ...b, visible: !b.visible };
            } else {
                return b;
            }
        });

        // update boreholesData state
        setBoreholesData(newBoreholesData);
    };

    const toggleDetailsModal = () => {
        setToggleDetails(!toggleDetails);
        if (toggleDetails) {
            setSelectedRecordId(null);
        }
    };

    const handleToggleMap = () => {
        // get the map element
        const map = document.getElementById('mapContainer');
        // remove d-none class
        map.classList.remove('d-none');
        setToggleMap(!toggleMap);
    };

    const handleToggleSgl = () => {
        setToggleSgl(!toggleSgl);
    }

    function createLabelIcon(labelText) {
        return new L.DivIcon({
            html: `<div className="marker-label">${labelText}</div>`,
            className: 'marker-label-container',
            iconSize: L.point(0, 0),
            iconAnchor: [8, 0],
        });
    }

    const updateBoreholeData = (data) => {
        let boreholesDataHolder = structuredClone(boreholesData);
        // update boreholesDataHolder layer, colors and ground level to match from layer changer
        data.forEach(d => {
            boreholesDataHolder.forEach(b => {
                if (b.geo_record_id === d.geo_record_id) {
                    b.top = d.top;
                    b.bottom = d.bottom;
                    b.tests = d.tests;
                    b.layers = d.layers;
                    b.colors = d.colors;
                }
            });
        });

        //get max of all top
        setMaxTop(Math.max(...boreholesDataHolder.map(d => Math.max(...d.top))));
        //get miin of all bottom
        setMinTop(Math.min(...boreholesDataHolder.map(d => Math.min(...d.bottom))));
        
        setBoreholesData(boreholesDataHolder);
    };

    const setBhSelectionOpenModal = (index) => {
        setBoreholeSelection(index);
        setToggleDetails(!toggleDetails);
    };

    useEffect(() => {
        setBoreholesData(props.info);
        //get max of all top
        setMaxTop(Math.max(...props.info.map(d => Math.max(...d.top))));
        //get miin of all bottom
        setMinTop(Math.min(...props.info.map(d => Math.min(...d.bottom))));
    }, [props.info]);    

    useEffect(() => {
        // create array of all layers in data array to use in borehole listgroup, the array will have distance, reference and visible
        if (boreholes.length === 0) {
            const boreholes = boreholesData.map(d => {
                return {distance_to_first_point: d.distance_to_first_point, distance_to_line: d.distance_to_line, 
                        reference: d.reference, visible: d.visible, geo_record_id: d.geo_record_id}
            });

            // set boreholes state
            setBoreholes(boreholes);
            // wait for generateBhChart to finish before setting firstLoad to false
            setTimeout(() => {
                setFirstLoad(false);
            }, 1000);
        } else {
            const boreholes = boreholesData.map(d => {
                return {distance_to_first_point: d.distance_to_first_point, distance_to_line: d.distance_to_line, 
                        reference: togglefullBhName? d.reference : d.alt_name, visible: d.visible, geo_record_id: d.geo_record_id}
            });

            // set boreholes state
            setBoreholes(boreholes);
        }

        let testDict = {};
        let c = 0;
        boreholesData.forEach(d => {
            if(d.visible === false) { return; }
            d.tests.forEach(t => {
                if (!(t.name in testDict)) {
                    if(c === 0) {
                        testDict[t.name] = true;
                    } else {
                        testDict[t.name] = false;
                    }
                    c++;
                }
            });
        });

        if('WL' in testDict) {
            testDict['WL'] = true;
        }

        if (Object.keys(tests).length !== Object.keys(testDict).length) {
            setTests(testDict);
        }

        if(!toggleMap) {
            const svg = d3.select(svgRef.current);
            const svgLegend = d3.select(svgLegendRef.current);

            // get the map element
            const map = document.getElementById('mapContainer');
            // remove d-none class
            map.classList.add('d-none');
        
            // set the svg width and height to parent width and height
            const width = svg.node().parentNode.clientWidth;
            const height = svg.node().parentNode.parentNode.parentNode.clientHeight;

            const legendHeight = svgLegend.node().parentNode.clientHeight;

            // Set the width and height of the SVG
            svg.attr('width', width).attr('height', height);
            svgLegend.attr('width', 200).attr('height', legendHeight);
            generateBhChart(boreholesData);
        }
    }, [boreholesData, togglefullBhName, toggleMap, toggleSgl, toggleClassification]);

    useEffect(() => {
        generateBhChart(boreholesData);
    }, [tests])

    useEffect(() => {
        
        httpClientPy.post('/geo/2d/sections/satellite/gl', {
            "points": props.granularPoints
        })
        .then((response) => {
            // console.log(response.data);
            setSatelliteData(response.data.points);
            setSatelliteMaxTop(Math.max(...response.data.points.map(d => d[1])));
            setSatelliteDataLoaded(true);   
        })
        .catch((error) => {
            console.log(error);
        });        
    }, []);

    const selectBorehole = (record_id) => {
        setSelectedRecordId(record_id);
    }

    useEffect(() => {
        if (selectedRecordId) {
            httpClientPy.get(`/geo/record?geo_record_id=${selectedRecordId}&project_id=${props.project.id}&format=json`)
            .then((response) => {
                setSelectedData(response.data);
                let strata_holder = response.data.geo_record_strata
                if(strata_holder[0].top > strata_holder[0].base){
                    strata_holder.sort(function(a, b) {
                    return parseFloat(b.top) - parseFloat(a.top);
                    });
                }
                else{
                    strata_holder.sort(function(a, b) {
                    return parseFloat(a.top) - parseFloat(b.top);
                    });
                }
                setSelectedDataStrata(strata_holder);
                setSelectedDataTests(response.data.geo_record_test);
                setInfoUpdated(false);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, [selectedRecordId, infoUpdated]);

    useEffect(() => {
        if (selectedData) {
            toggleDetailsModal();
        }
    }, [selectedData]);

    return (
        <>  
            <ModalHeader className="border-0 mb-0 pb-0" toggle={props.toggleModal()}>
            <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                {props.displayName && props.displayName}
            </h4>
            </ModalHeader>
            {/* <hr className="mb-1 mt-1 pb-0 pt-0"/> */}
            <ModalBody>
                <div className="container-fluid-adjusted">
                    <div className="row g-0">
                        {/* {!toggleMap ? */}
                            <div className={`col-10 ${!toggleMap ? '': 'd-none'}`} id='chartSvg' >
                                <svg ref={svgRef} />
                            </div>
                            <div className="col-10" id="mapContainer">
                                <MapContainer
                                        style={{height: '65vh'}}
                                        center={[props.middlePoint[0], props.middlePoint[1]]}
                                        zoom={(props.offset && props.distance) < 101 ? 19 : 15}
                                        scrollWheelZoom={false}
                                        preferCanvas={true} 
                                    >
                                    <TileLayer
                                        attribution="Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
                                        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_nolabels/{z}/{x}/{y}.png"
                                        />
                                        <Polyline pathOptions={{color: 'purple'}} positions={[[props.coords[0].lat, props.coords[0].lng], 
                                                                                            [props.coords[1].lat, props.coords[1].lng]]} />
                                        {boreholesData.map((item, i) => {
                                            return (
                                                <Marker 
                                                key={`marker-${i+1}`}
                                                position={[item.coords[0], item.coords[1]]}
                                                icon={!item.is_private ? boreholeBlack : boreholeRed}
                                                >
                                                <Marker
                                                    position={[item.coords[0], item.coords[1]]}
                                                    icon={createLabelIcon(`${togglefullBhName ? item.reference: item.alt_name}`)}
                                                    />
                                                </Marker>
                                                
                                            )
                                        })}
                                </MapContainer>
                            </div>
                        {/* } */}
                        <div className="col-2">
                            <div className="pt-0">
                                <Card style={{width: '100%'}}>
                                    <CardHeader>
                                        Boreholes within offset ({boreholes.length})
                                    </CardHeader>
                                    <div className="scrollable">
                                    {boreholes.map((borehole, index) => {
                                        return (
                                            <ListGroupItem key={index} className="border-0 w-100">
                                                <div className="row px-2 list-group-scrollable  w-100">
                                                    <div className="col-12">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" 
                                                                   id="flexCheckDefault" checked={borehole.visible}
                                                                   onChange={() => handleBhVisibility(borehole.geo_record_id)} />

                                                            <label type="button" className="form-check-label clickable-label" onClick={()=>selectBorehole(borehole.geo_record_id)}>
                                                                <small>
                                                                {borehole.reference.length > 15 ? 
                                                                 borehole.reference.substring(0, 15) + "... [" + borehole.distance_to_line + ' m]' : 
                                                                 borehole.reference + " [" + borehole.distance_to_line + ' m]'}
                                                                 </small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        )
                                    })}
                                    </div>
                                </Card>
                            </div>
                            <div className="pt-2">
                                <Card style={{width: '100%'}}>
                                    <CardHeader>Configuration</CardHeader>
                                    <div className="row g-0 px-2">
                                        <div className="col-12">
                                            <div className="form-check form-control-sm form-switch d-inline-block ms-1 me-2">
                                                <input className="form-check-input" type="checkbox" id="fullRefName" 
                                                checked={!toggleClassification} onChange={() => setToggleClassification(!toggleClassification)}/>
                                                <label className="form-check-label" htmlFor="fullRefName">
                                                    <small> Model by classification</small>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check form-control-sm form-switch d-inline-block ms-1 me-2">
                                                <input className="form-check-input" type="checkbox" id="fullRefName" 
                                                checked={toggleClassification} onChange={() => setToggleClassification(!toggleClassification)}/>
                                                <label className="form-check-label" htmlFor="fullRefName">
                                                    <small> Model by description</small>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check form-control-sm form-switch d-inline-block ms-1 me-2">
                                                <input className="form-check-input" type="checkbox" id="virtualMap" 
                                                checked={toggleMap} onClick={() => handleToggleMap()}/>
                                                <label className="form-check-label" htmlFor="virtualMap">
                                                    <small> Map</small>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check form-control-sm form-switch d-inline-block ms-1 me-2">
                                                <input className="form-check-input" type="checkbox" id="fullRefName" 
                                                checked={!togglefullBhName} onChange={() => setToggleFullBhName(!togglefullBhName)}/>
                                                <label className="form-check-label" htmlFor="fullRefName">
                                                    <small> BH No</small>
                                                </label>
                                            </div>
                                        </div>
                                        { satelliteDataLoaded &&
                                        <div className="col">
                                            <div className="form-check form-control-sm form-switch d-inline-block ms-1 me-2">
                                                <input className="form-check-input" type="checkbox" id="satelliteGL" 
                                                checked={toggleSgl} onClick={() => handleToggleSgl()}/>
                                                <label className="form-check-label" htmlFor="satelliteGL">
                                                    <small> Auto GL</small>
                                                </label>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </Card>
                            </div>
                            <div className="pt-2">
                                <Card style={{width: '100%'}}>
                                    <CardHeader>Strata Legend</CardHeader>
                                        <div id='svgLegend'>
                                            <svg id='svgLegend' ref={svgLegendRef} />
                                        </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {boreholesData.length < 1 &&
                    <div className="text-center text-danger mt-2 title-bold">
                        <FontAwesomeIcon
                        className="icon-view"
                        icon={faTriangleExclamation}
                        />
                        &nbsp; No boreholes found near this section cut
                    </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter className="text-end border-0">
                {satelliteDataLoaded &&
                    <div className="w-100 row">

                        <div className="col text-start">
                                    {tests && 
                                        Object.keys(tests).map((test, index) => {
                                            return (
                                                <div className="form-check form-switch d-inline-block ms-1 me-2" key={index}>
                                                    <input className="form-check-input" type="checkbox" id={test} 
                                                    checked={tests[test]} onChange={() => setTests({...tests, [test]: !tests[test]})}/>
                                                    <label className="form-check-label" htmlFor={test}>
                                                        <> {test === 'N' ? 'SPT' : test === 'WL' ? 'Water' : test}</>
                                                    </label>
                                                </div>
                                            )
                                        }
                                    )}
                        </div>
                        <div className="col">
                            <a className="btn btn-outline-secondary btn-sm mr-4" onClick={()=>props.offsetDistanceChange()}>
                                <FontAwesomeIcon
                                    className="icon-view"
                                    icon={faDrawPolygon}
                                />
                                <> Edit offset ({props.offset}m)</>
                            </a>
                            <span className="btn-spacing"/>
                            <a className={`btn btn-outline-secondary btn-sm ${downloadingPDF && 'disabled'}`} onClick={generatePDF}>
                                {downloadingPDF ?
                                (
                                    <span style={{display: 'inline-flex', alignItems: 'center'}}>
                                        <Loader className="d-inline" loading={downloadingPDF} />
                                    </span>
                                ) : (
                                    <FontAwesomeIcon
                                        className="icon-view"
                                        icon={faFilePdf}
                                    />
                                )}
                                <> Download as PDF</>
                            </a>
                            <span className="btn-spacing"/>
                            <a className={`btn btn-outline-secondary btn-sm ${downloadingDXF && 'disabled'}`} onClick={generateDxf}>
                                {downloadingDXF ?
                                    (
                                        <span style={{display: 'inline-flex', alignItems: 'center'}}>
                                            <Loader className="d-inline" loading={downloadingDXF} />
                                        </span>
                                    ) : (
                                        <FontAwesomeIcon
                                        className="icon-view"
                                            icon={faFileExport}
                                        />
                                    )}

                                <> Download as DXF</>
                            </a>
                        </div>
                    </div>
                }
            </ModalFooter>
            <Modal id="boreholeDetails" fullscreen={true} isOpen={toggleDetails} toggle={toggleDetailsModal} centered={true}>
                <BoreholeLogDetails data={selectedData} 
                                    onSearchGeoRecord={(lat, lng, id, type)=>{props.onSearchGeoRecord(lat, lng, id, type);}}
                                    strata={selectedDataStrata} 
                                    tests={selectedDataTests} 
                                    project={props.project}
                                    projectId={props.project ? props.project.id : null}
                                    toggleModal={()=>toggleDetailsModal()}
                                    setGeoRecordPosition = {(position) => props.setGeoRecordPosition(position)}
                                    toggleUpdateBorehole = {(status) => props.toggleUpdateBorehole(status)}
                                    setGeoRecord = {(record) => props.setGeoRecord(record)}
                                    boreHoleInfoUpdate={() => {props.boreHoleInfoUpdate(); setInfoUpdated(true)}}
                                    setRecordUpdated={(status) => console.log(status)}
                />
            </Modal>
        </>
    );
};

export default ChartGeoSectionCut;