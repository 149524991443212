import { Outlet } from "react-router-dom";
import React, { Component } from "react";

export class ProjectLayout extends Component {
  render() {
    return (
      <div className="">
        <Outlet />
      </div>
    );
  }
}
