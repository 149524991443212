import React, { useState, useEffect } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import httpClientPy from "../../../utils/httpClientPy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser,} from "@fortawesome/free-solid-svg-icons";
import { logEvent } from '../../shared/Mixpanel';
import Button from '../../shared/Button';

const NewProjectModal = (props) => {
    const [loading, setLoading] = useState(true);
    const [projectOwner, setProjectOwner] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const [sharedCount, setSharedCount] = useState(0);
    const [notSharedCount, setNotSharedCount] = useState(0);
    const [generalAccess, setGeneralAccess] = useState('0');
    

    const updateSharing = (event) => {
        setGeneralAccess(event.target.value);
    }

    const updateUserPermission = (id, value) => {
        setAllUsers(allUsers.map((user) => {
            if(user.george_id === id){
                user.access_type = parseInt(value);
            }
            return user;
        }));
    }

    const updateBulkUser = (value) => {
        if (value === 3) {
            return;
        }
        setAllUsers(allUsers.map((user) => {
            if(user.george_id !== projectOwner){
                user.access_type = parseInt(value);
            }
            return user;
        }));
    }

    useEffect(() => {

        httpClientPy.post('/project/share/list', {
            project_id: props.project.id,
        })
        .then((response) => {
            setAllUsers(response.data.users);
            setProjectOwner(response.data.project_owner);
            setNotSharedCount(response.data.number_of_not_shared);
            setSharedCount(response.data.number_of_shared);
            if (response.data.number_of_shared < 1){
                setGeneralAccess('1');
            }
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });

    }, []);

    const handleUpdatePermissions = () => {
        setLoading(true);
        httpClientPy.post('/project/permissions', {
            project_id: props.project.id,
            users: allUsers,
        })
        .then((response) => {
            logEvent('Project Permissions Updated', { 'Project id': props.project.id});  
            setAllUsers(response.data.users);
            setProjectOwner(response.data.project_owner);
            setNotSharedCount(response.data.number_of_not_shared);
            setSharedCount(response.data.number_of_shared);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <ModalHeader className="" toggle={()=>props.toggleModal()}>
                <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                    {props.title}
                </h4>
            </ModalHeader>
            <ModalBody
                className="pb-0">
                {!loading && allUsers ?
                    <>
                        {allUsers.length > 1 ?
                            <>
                            <h6 className="title-bold">Access Management</h6>
                            <select className="form-select w-100 mb-3"
                                onChange={(event) => updateBulkUser(event.target.value)} 
                                value="3"
                                disabled={props.userInfo.george_id !== props.project.owner_id}
                                aria-label="Default select example"
                            >
                                <option value="3">Bulk Operation</option>
                                <option value="0">Set All No Access</option>
                                <option value="1">Set All Viewer Access</option>
                                <option value="2">Set All Editor Access</option>
                            </select>
                            {allUsers.map((user, index) => {
                                if (!props.userInfo.enterprise_admin) {
                                    if (props.userInfo.email.split('@')[1] === user.email.split('@')[1]) {
                                        return (
                                            <div key={index} className='d-flex justify-content-between align-items-center'>
                                                <span className='fs-6'>
                                                    <span><FontAwesomeIcon icon={faCircleUser}></FontAwesomeIcon></span>
                                                    <span className='ms-2'>{user.email}</span>
                                                </span>
                                                <select className="form-select w-50 my-1"
                                                    onChange={(event) => updateUserPermission(user.george_id, event.target.value)} 
                                                    value={user.george_id === projectOwner ? "3" : user.access_type.toString()}
                                                    disabled={user.george_id === projectOwner || props.userInfo.george_id !== props.project.owner_id}
                                                    aria-label="Default select example"
                                                >
                                                    <option value="0">No Access</option>
                                                    <option value="1">Viewer</option>
                                                    <option value="2">Editor</option>
                                                    {user.george_id === projectOwner &&
                                                        <option value="3">Owner</option>
                                                    }
                                                </select>
                                            </div>
                                        )
                                    }
                                } else {
                                    return (
                                        <div key={index} className='d-flex justify-content-between align-items-center'>
                                            <span className='fs-6'>
                                                <span><FontAwesomeIcon icon={faCircleUser}></FontAwesomeIcon></span>
                                                <span className='ms-2'>{user.email}</span>
                                            </span>
                                            <select className="form-select w-50 my-1"
                                                onChange={(event) => updateUserPermission(user.george_id, event.target.value)} 
                                                value={user.george_id === projectOwner ? "3" : user.access_type.toString()}
                                                disabled={user.george_id === projectOwner || props.userInfo.george_id !== props.project.owner_id}
                                                aria-label="Default select example"
                                            >
                                                <option value="0">No Access</option>
                                                <option value="1">Viewer</option>
                                                <option value="2">Editor</option>
                                                {user.george_id === projectOwner &&
                                                    <option value="3">Owner</option>
                                                }
                                            </select>
                                        </div>
                                    )
                                }
                            }
                            )}
                            </>
                        :
                            <>
                                <div>You have no other accounts associated with your company, ask your admin to invite other users to <b>Civils.ai</b> and add them to your enterprise plan to start sharing data with them.</div>
                            </>
                        }
                    </>
                    :
                        <div className='w-100 pt-3 text-center'>
                            <Spinner size="lg"/>
                        </div>
                }
            </ModalBody>
            <ModalFooter className="border-0 justify-content-center">
                {(allUsers && allUsers.length > 1) ?
                    <>
                        <Button className='btn btn-primary' color="primary" onClick={()=>handleUpdatePermissions()} loading={loading} label="Update Permissions"/>
                    </>
                : 
                    null
                }
            </ModalFooter>
        </>
    )   
}

export default NewProjectModal;