import React, { useState, useEffect } from "react";
import { faFilter, faFilterCircleXmark, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import httpClientPy from "../../../utils/httpClientPy";
import UploadModal from "../../shared/UploadModal";
import DocumentsTable from "./DocumentsTable";
import Select, { components } from 'react-select'

const MainLayout = (props) => {
    const [cnt, setCnt] = useState(0);
    const [reload, setReload] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [uploadModal, setUploadModal] = useState(false);
    const [initialUploadType, setInitialUploadType] = useState(0);
    const [uploadDropdownOpen, setUploadDropdownOpen] = useState(false);
    const [displaySearch, setDisplaySearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [documentNameSelector, setDocumentNameSelector] = useState([]);
    const [searchFilter, setSearchFilter] = useState({file_names: []});
    const [selectedCategory, setSelectedCategory] = useState(-1);

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
    }

    const toggleUploadDropdown = () => {
        setUploadDropdownOpen(!uploadDropdownOpen);
    }

    const handleUploadSelect = (uploadType) => {
        setInitialUploadType(uploadType);
        setUploadModal(true);
        setUploadDropdownOpen(false);
    }

    const toggleTab = (tab) => {
        if (activeTab !== tab && !loading) {
            setLoading(true)
            setActiveTab(tab);
        }
    }

    const uploadOptions = [
        { type: 0, label: "Specifications" },
        { type: 1, label: "Contracts" },
        { type: 2, label: "Site Investigations" },
        { type: 3, label: "Drawings" },
        { type: 4, label: "Health & Safety" },
        { type: 6, label: "Plans & Methods" },
        { type: 5, label: "Other" }

    ];

    function filterRecords(selections) {
        if (selections.length > 0) {
            selections.forEach((item) => {
                setSearchFilter({...searchFilter, file_names: [...searchFilter.file_names, item.value]})
            })
        } else {
            setSearchFilter({"file_names": []})
        }
    }

    const fetchDocumentsNames = async () => {
        return httpClientPy
        .get(`/documents/meta?project_id=${props.project.id}&doc_type=${activeTab - 1}`)
        .then((result) => {
            let options = result.data.file_names.map((item) => {
                return {
                    value: item,
                    label: item
                }
            });
            setDocumentNameSelector(options);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        fetchDocumentsNames();
        props.getProjectData(props.project.id);
    }, [cnt]);

    useEffect(() => {
        if (selectedCategory < 0) { return; }
        toggleUploadModal();
    }, [selectedCategory]);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                    <h5 className="fw-bold mb-0 me-2">Repository</h5>
                </div>
                <div>
                        {documentNameSelector.length > 0 &&
                        <>
                            {displaySearch ?
                                <>
                                    <Select
                                        className="basic-multi-select d-inline-block filter-record-select-sm text-center me-2"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name="searchDocs"
                                        isMulti={true}
                                        placeholder={"Search Document"}
                                        components={{ ValueContainer }}
                                        closeMenuOnSelect={false}
                                        isRtl={false}
                                        options={documentNameSelector}
                                        onChange={filterRecords}
                                        autoFocus={true}
                                    />
                                    <div className="btn btn-sm btn-outline-dark rounded me-2"
                                        onClick={() => setDisplaySearch(!displaySearch)}
                                    >
                                        <FontAwesomeIcon icon={faFilterCircleXmark}/>
                                    </div>
                                </>
                                :
                                <span className="btn btn-outline-secondary me-2"
                                    onClick={() => setDisplaySearch(!displaySearch)}
                                >
                                    <FontAwesomeIcon icon={faFilter}/>
                                </span>
                            }
                        </>
                        }
                    <span className="btn btn-primary" onClick={toggleUploadModal}>
                        <FontAwesomeIcon icon={faUpload}/>
                        <span className="ps-2">Upload docs</span>    
                    </span>
                </div>
            </div>
                <div className="d-flex mx-0 w-100 border">
                    {/* Sidebar - Fixed Width */}
                    <div className="col-auto border-end" style={{ width: '190px', minWidth: '190px' }}>
                        <div className="row mx-0 d-flex flex-column gap-1">
                            <a className={`${activeTab === 0 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`} 
                                disabled={loading}
                                onClick={() => toggleTab(0)}>All <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_data}</span></a>
                            <a className={`${activeTab === 1 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                disabled={loading}
                                onClick={() => toggleTab(1)}>Specs <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_specs_data}</span></a>
                            <a className={`${activeTab === 2 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                disabled={loading}
                                onClick={() => toggleTab(2)}>Contracts <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_contracts_data}</span></a>
                            <a className={`${activeTab === 3 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                disabled={loading}
                                onClick={() => toggleTab(3)}>Site Investigations <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_site_inv_data}</span></a>
                            <a className={`${activeTab === 4 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                disabled={loading}
                                onClick={() => toggleTab(4)}>Drawings <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_drawings_data}</span></a>
                            <a className={`${activeTab === 5 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                disabled={loading}
                                onClick={() => toggleTab(5)}>Health & Safety<span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_risk_regs_data}</span></a>
                            <a className={`${activeTab === 7 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                disabled={loading}
                                onClick={() => toggleTab(7)}>Plans & Methods <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_plans_data}</span></a>
                            <a className={`${activeTab === 6 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                disabled={loading}
                                onClick={() => toggleTab(6)}>Other <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_others_data}</span></a>
                        </div>
                    </div>

                    {/* Main Content - Flexible */}
                    <div id="main-out" className="col flex-grow-1 px-0 chatList-open">
                        <DocumentsTable 
                            docType={activeTab} 
                            project={props.project}
                            searchFilter={searchFilter} 
                            reload={reload}
                            setCnt={setCnt}
                            updateTask={(task) => props.updateTask(task)}
                            updateSelectedData={(data) => props.updateSelectedData(data)}
                            setOuterLoading={(state) => setLoading(state)}
                        />
                    </div>
                </div>


            {props.userInfo && uploadModal &&
                <UploadModal 
                    isOpen={uploadModal} 
                    toggle={toggleUploadModal} 
                    userInfo={props.userInfo}
                    project={props.project}
                    extraDetails={props.extraDetails}
                    initialUploadType={initialUploadType}
                    onComplete={() => setReload(cnt + 1)}
                >
                </UploadModal>
            }
        </>
    );
};

export default MainLayout;

const ValueContainer = ({ children, ...props }) => {
    if (children[0] !== null) {
      const length = children[0].length
      let tmpChildren = [...children];
      if(length >= 2){
        tmpChildren[0] = `${length} options selected`
      }
  
      return (
        <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
      );
    }
};