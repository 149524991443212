import {Card, CardBody, Spinner, Tooltip} from "reactstrap";
import { useEffect, useState, useRef } from "react";
import httpClientPy from "../../../utils/httpClientPy";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUsers, faClockRotateLeft, faLock, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "reactstrap";
import NewProjectModal from "../SideBarComponents/NewProjectModal";
import ShareProjectModal from "./ShareProjectModal";
import EditProjectDropdown from "./EditProjectDropdown";
import SearcHistory from "./SearchHistory";
import MainLayout from "../DocumentLayouts/MainLayout";
import RecentWorkflowRuns from "../detailComponents/workflow/RecentWorkflowRuns.js";

const ProjectOverview = (props) => {

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [project, setProject] = useState(props.project);
    const [editProject, setEditProject] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [sharingOpen, setSharingOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [toggleUpdateProjectLocation, setToggleUpdateProjectLocation] = useState(false);
    const [chatInput, setChatInput] = useState('');
    const inputRef = useRef(null);
    const [searching, setSearching] = useState(false);
    const [toastDisplay, setToastDisplay] = useState(true);
    const [projectName, setProjectName] = useState(project.name);


    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
              }
        }, 1000);

        // use the handleKeyPress
        window.addEventListener('keypress', handleKeyPress);
        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('keypress', handleKeyPress);
        };
    },[]);

    const handleUpdateProjectLocationClick = (project_type) => {
        navigate('/map', { state: {project_update_location: true, project_type: project_type, project_detail: project} });
    }

    const saveProject = () => {

        if(projectName && projectName.length > 1) {
            httpClientPy.post(
                `/project/update`, 
                {
                    project_id: project.id,
                    update_type: "name",
                    name: projectName
                }
            )
            .then((response) => {
                setProjectName(projectName);
            })
            .catch((err) => {
                setError(true);
                setErrorMessage(err.response.data.detail);
            }
            );
        }
        props.getProjectData(project.id);
        setEditProject(false);
    }


    const handleEditProjectName = (e) => {
        setProjectName(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setSearching(true)
            e.preventDefault();
            let chatInput = document.getElementById('question_chat');
            if(chatInput.value.length > 0) {
                props.handleChat(chatInput.value);
            }
        }
    }

    const handleChatClick = () => {
        setSearching(true)
        props.handleChat(chatInput);
    }

    const toggleToast = () => {
        setToastDisplay(!toastDisplay)
    }
    

    const handleHistory = () => {
        toggleHistory();
    }


    const toggleSharing = () => {
        setSharingOpen(!sharingOpen);
    }

    const toggleHistory = () => {
        setHistoryOpen(!historyOpen);
    }


    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    const toggleUpdateProjectLocationModal = () => {
        setToggleUpdateProjectLocation(!toggleUpdateProjectLocation);
    }

    return (
        <>
            <Card 
                className={`default-card d-flex flex-column vh-100-true overflow-auto pt-0`}
            >
                <CardBody className="d-flex flex-column flex-grow-1 container">
                    {!error ? (
                        <div className="d-flex flex-column flex-grow-1" style={{overflowY:'auto',overflowX:"hidden"}}>
                                            <div className="card">
                                                <div class="card-body">
                                                <div className="border-0">
                                                    {editProject ? (
                                                        <>
                                                        <div id="chat_input_container" className="d-block d-md-flex input-group-lg input-group text-dark">
                                                            <input type="text" className="form-control gpt_input title-bold" 
                                                                        value={projectName} 
                                                                        onChange={handleEditProjectName}
                                                                        />
                                                            <button className="btn btn-primary border-0" type="button" onClick={() => saveProject()}>
                                                                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                                                            </button>
                                                        </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="d-block d-md-flex text-center text-md-start justify-content-between">
                                                                <span className="d-block d-md-flex text-center text-md-start align-items-center  pb-2 pb-md-0">
                                                                    <h4 className="title-bold d-inline mb-0"><b>{projectName}</b></h4>
                                                                </span>
                                                                <span id="project_buttons" className="ms-3 text-end">
                                                                    {props.userInfo && props.userInfo.george_id === project.owner_id &&
                                                                        <EditProjectDropdown
                                                                            editProjectName={() => setEditProject(true)}
                                                                            editProjectLocation={() => toggleUpdateProjectLocationModal()}
                                                                        />
                                                                    }


                                                                    {props.userInfo && props.userInfo.allow_civilsgpt && props.extraDetails && props.extraDetails.all_ready_data > 0 ?

                                                                        <button className="btn border-secondary btn-sm overview_button ms-2" onClick={() => handleHistory()}>
                                                                        <FontAwesomeIcon icon={faClockRotateLeft}></FontAwesomeIcon>
                                                                        <span className="ps-2">Search history</span>
                                                                        </button>
                                                                        : null
                                                                    }
                                                                    {props.userInfo && props.userInfo.george_id === project.owner_id && props.userInfo.is_enterprise &&
                                                                        <button className="btn border-secondary btn-sm overview_button ms-2" onClick={() => toggleSharing()}>
                                                                            <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                                                                            <span className="ps-2">Share</span>
                                                                        </button>
                                                                    }

                                                                </span>
                                                            </div>

                                                        </>
                                                    )}                                                                                            
                                                </div>

                                                    <div className={`collapse show pt-3`}>

                                                        <div id="chat_input_container" className="flex-container input-group input-group-lg input-group text-dark">

                                                            {props.extraDetails.all_ready_data > 0  && props.userInfo && props.userInfo.allow_civilsgpt ? (
                                                                <>
                                                                    <input id='question_chat' ref={inputRef} type="text" autoComplete="off" placeholder="Quick search your project for information" className="form-control border border-primary gpt_input" onChange={(e) => handleChangeQuery(e)} />
                                                                    {!searching ? 
                                                                    <button className={`${chatInput.length >= 1 ? `btn btn-primary`: 'btn btn-disabled btn-primary'} border-0`} type="button" onClick={() => handleChatClick()} disabled={chatInput.length < 1}>
                                                                        <FontAwesomeIcon icon={faMagnifyingGlass} fontSize="1rem"></FontAwesomeIcon>
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-primary disabled border-0" type="button" disabled>
                                                                        <Spinner size="sm" color="light" />
                                                                    </button>
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <input id='no-knowledge-data-help' disabled type="text" className="form-control gpt_input" placeholder="Quick search your project for information" />
                                                                    <button id='no-knowledge-data-help' className="input-group-text">
                                                                        <FontAwesomeIcon icon={faLock} fontSize="1rem"></FontAwesomeIcon>
                                                                    </button>
                                                                    {props.userInfo && props.userInfo.allow_digitise && !props.userInfo.allow_civilsgpt ? (
                                                                        <Tooltip
                                                                            isOpen={tooltipOpen}
                                                                            placement="bottom"
                                                                            target="no-knowledge-data-help"
                                                                            toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                                                                                Instantly search for information across all your PDF project documents - contact Civils.ai to unlock this feature.
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip
                                                                            isOpen={tooltipOpen}
                                                                            placement="bottom"
                                                                            target="no-knowledge-data-help"
                                                                            toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                                                                                Instantly search all your project documents for information. Upload a document to get started.
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                
                                <RecentWorkflowRuns project={project} 
                                />

                                <>
                                    <div className="card mt-3">
                                        <div className="card-body py-2">
                                        <MainLayout 
                                            project={project}
                                            userInfo={props.userInfo}
                                            updateTask={(task)=>props.updateTask(task)}
                                            updateSelectedData={(data)=>props.updateSelectedData(data)}
                                            extraDetails={props.extraDetails}
                                            getProjectData={(id)=>props.getProjectData(id)}
                                        />
                                        </div>
                                    </div>
                                </>
                                            
                        </div>
                    ) : (
                        <div className='d-flex justify-content-center flex-column align-items-center'>
                            <img src="assets/build_img.png" alt="Civils.ai" className="img-fluid" />
                            <h2><b>Oops!</b></h2>
                            <h4>{errorMessage}</h4>
                            <button className="btn btn-primary mt-2" onClick={() => navigate('/projects')}>All Projects</button>
                        </div>
                    )}
                </CardBody>
            </Card>
            {toggleUpdateProjectLocation &&
            <Modal id="updateProject" size="l" isOpen={toggleUpdateProjectLocation} toggle={toggleUpdateProjectLocationModal} centered={true}>
                <NewProjectModal title="Add a location for your project"
                                project={props.project}
                                toggleModal = {()=> toggleUpdateProjectLocationModal()} 
                                getProjectData = {() => props.getProjectData(project.id)}
                                handleChoiceClick = {(project_type) => handleUpdateProjectLocationClick(project_type)}/>
            </Modal>
            }
            {sharingOpen &&
                <Modal id="projectSharing" size="l" isOpen={sharingOpen} toggle={toggleSharing} centered={true}>
                    <ShareProjectModal title="Share your project with others"
                                    project={props.project}
                                    userInfo={props.userInfo}
                                    toggleModal = {()=> toggleSharing()} 
                                    getProjectData = {() => props.getProjectData(project.id)}
                                    handleChoiceClick = {(project_type) => handleUpdateProjectLocationClick(project_type)}/>
                </Modal>
            }
            {historyOpen &&
                <Modal id="searchHistory" size="xl" isOpen={historyOpen} toggle={toggleHistory}>
                    <SearcHistory project_id={project.id}
                                handleSearchHistoryClicked={(id)=>props.handleSearchHistoryClick(id)} />
                </Modal>
            }
        </>
    );
}

export default ProjectOverview;