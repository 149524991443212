/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import authenticationHandler from "../../utils/authenticationHandler";
import httpClientPy from "../../utils/httpClientPy";
import GoogleSignIn from "../googleSignIn/GoogleSignIn";
import { setMixpanelID, logEvent } from "../shared/Mixpanel";
import AuthFormHeader from "../shared/AuthFormHeader";
import Button from "../shared/Button";
import ValidationSummary from "../shared/ValidationSummary";
import { UncontrolledAlert, Alert, Card, CardHeader, CardBody, CardText, CardTitle } from "reactstrap";
import CheckListItem from "./CheckListItem";
import ArrowListItem from "./ArrowListItem";


const SignUpPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formView, setFormView] = useState(true);
  const [pendingActivation, setPendingActivation] = useState(null);
  const firstNameInput = useRef();
  const secondNameInput = useRef();
  const [createAccountRequest, setCreateAccountRequest] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    invite_code: null,
  });
  
  useEffect(() => {
    if(formView){
      firstNameInput.current.focus();
    }
  }, [formView]);

  useEffect(() => {
    // check if there is ic= param in the url
    const urlParams = new URLSearchParams(window.location.search);
    const ic = urlParams.get("ic");
    if (ic) {
      setCreateAccountRequest({
        ...createAccountRequest,
        invite_code: ic,
      });
    }
  }, []);

  const handleChange = (e) => {
    setCreateAccountRequest({
      ...createAccountRequest,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    setLoading(true);
    setPendingActivation(false);
    setErrors([]);
    event.preventDefault();
    httpClientPy
      .post(`/user/register`, createAccountRequest)
      .then((result) => {
          setMixpanelID(result.data.data.user_profile_id,  
            result.data.data.email,
            result.data.data.enterprise,
            result.data.data.enterprise_name
          );
          logEvent("Sign Up", { "Provider": "Civils.ai" });
          setPendingActivation(true);
      })
      .catch((errorResponse) => {
        setErrors(errorResponse.response.data.detail);
        setLoading(false);
        authenticationHandler.logout();
        throw errorResponse;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className={`sign-up-container container min-vh-100 d-flex flex-column justify-content-center ${!formView && "d-none"}`}>
          <div className="text-center">
            <AuthFormHeader />
            <h1 className="title-bold mt-3 text-center display-5 text-secondary">Sign up to access Civils.ai</h1>
          </div>
          <div className="mt-4 mx-auto text-dark container text-center">
            <div className="row g-4">
              <div className="col-12 col-md-6 d-none d-md-block">
                <Card className='h-100 text-start shadow'>
                  <CardHeader className='title-bold h5 bg-white p-4'>
                      Why start using Civils.ai?
                  </CardHeader>
                  <CardBody className="p-4">

                      <CardText>
                          <ul className='custom-pricing-list'>
                              <h5 className="title-bold">For tendering</h5>
                              <CheckListItem>Upload project specs, contracts and the site investigations into Civils.ai and extract requirements and risks.</CheckListItem>
                              <CheckListItem>Automatically organize requirements by discipline and generate detailed breakdowns to streamline your bid preparation.</CheckListItem>
                              <h5 className="title-bold pt-3">For compliance checking</h5>
                              <CheckListItem>Access and query all your project requirements instantly from a centralized source.</CheckListItem>
                          </ul>
                      </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="col-12 col-md-6">
                <div className="mx-auto form-signin">
                <ValidationSummary errors={errors} />
                <form onSubmit={handleSubmit}>
                {pendingActivation ?
                  <Alert className="mt-3" color="success">
                    <div className="title-bold">Check your email inbox for your login link</div>
                    <div className="mt-2">We've just sent you a verification email. To complete the sign-up process and confirm your email address, click on the link provided in the email. If you don't see our email shortly check your spam folder.</div>
                  </Alert>
                  :
                  <>
                  <div className="form-floating mt-3 mb-2">
                    <input
                      ref={firstNameInput}
                      name="first_name"
                      type="text"
                      className="form-control"
                      id="firstNameInput"
                      placeholder="e.g. Lisa"
                      value={createAccountRequest.first_name}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <label htmlFor="firstNameInput">First name</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input
                      ref={secondNameInput}
                      name="last_name"
                      type="text"
                      className="form-control"
                      id="lastNameInput"
                      placeholder="e.g. Lisa"
                      value={createAccountRequest.last_name}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <label htmlFor="lastNameInput">Last name</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      id="emailInput"
                      placeholder="lisa@company.com"
                      value={createAccountRequest.email}
                      onChange={handleChange}
                      required
                      autoComplete="off"
                    />
                    <label htmlFor="emailInput">Email address</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input
                      name="password"
                      type="password"
                      className="form-control"
                      id="passwordInput"
                      placeholder="Password"
                      value={createAccountRequest.password}
                      onChange={handleChange}
                      required
                      autoComplete="off"
                    />
                    <label htmlFor="passwordInput">Password</label>
                  </div>
                  <div>
                      <Button
                        type="submit"
                        label="Continue with email"
                        className="w-100 btn btn-primary btn-lg"
                        loading={loading}
                      />
                  <div>
                      
                      {/* <div className="text-center mt-2 w-100">
                          <div className="position-relative d-inline-block w-100">
                              <hr></hr>
                              <span className="position-absolute top-50 start-50 translate-middle px-2 defaultBackground"> OR </span>
                          </div>
                      </div> */}

                      <div className="pt-2 text-secondary"><small>Or</small></div>

                      <div class="d-flex align-items-center justify-content-center">
                        <GoogleSignIn setPendingActivation={(status) => setPendingActivation(status)}
                                      setErrors={(errors) => setErrors(errors)} redirectTo="/projects" 
                                      inviteCode={createAccountRequest.invite_code}
                        />
                      </div>
                                          
                    
                  </div>

                    <div className="text-secondary">
                        <small>
                          By continuing you agree to the {" "}
                          <a
                            href="https://civils.ai/user-license-agreement"
                            target="_blank"
                          >
                            terms of service
                          </a>
                          .
                        </small>
                    </div>

                    <div className="text-secondary pt-1">
                      <small>
                        Already have access? <NavLink to="/log-in">log in</NavLink>
                      </small>
                    </div>

                  </div>
                </>
                }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      

        
    </>

  );
};

export default SignUpPage;
