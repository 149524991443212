import { ModalBody, ModalHeader } from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCopy,
  faCheck,
  faRobot,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

library.add(faCopy, faCheck, faRobot, faCircleInfo);

interface SaveConfirmModalProps {
  displayName: string;
  text: string;
  toggleModal: () => void;
  saveConfirmed: (status: boolean) => void;
}

const SaveConfirmModal = (props: SaveConfirmModalProps) => {
  // function to toggle the modal
  const closeModal = () => {
    props.toggleModal();
  };

  // function to handle the delete confirmation
  const saveConfirmed = () => {
    props.saveConfirmed(true);
    closeModal();
  };

  return (
    <>
      <ModalHeader className="border-0 mb-0 pb-0" toggle={props.toggleModal}>
        {props.displayName && props.displayName}
      </ModalHeader>
      <ModalBody>
        <hr className="mb-4" />
        <div className="container-fluid w-100 pb-2 mb-1">
          <div>{props.text}</div>
        </div>
        <hr className="mb-4" />
        <div className="container-fluid w-100 ">
          <button
            className="btn btn-primary w-100 mb-2"
            onClick={() => saveConfirmed()}
            type="button"
            data-testid="confirm-btn"
          >
            Confirm
          </button>
          <button
            className="btn btn-outline-dark w-100"
            onClick={() => closeModal()}
            type="button"
          >
            Cancel
          </button>
        </div>
      </ModalBody>
    </>
  );
};

export default SaveConfirmModal;
