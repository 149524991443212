import React, { useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

const LibraryPromptSelector = (props) => {
  const [prompts] = useState(props.prompts);

  return (
    <Card
      className="d-flex mt-3 overflow-hidden mx-auto"
      style={{ maxWidth: "90%" }}
    >
      <CardHeader className="bg-light border-0">
        <span
          style={{ maxWidth: "100%" }}
          className="d-inline-block title-bold text-truncate"
        >
          Select a prompt from the library below or write your own
        </span>
      </CardHeader>
      <CardBody className="bg-light">
        <div style={{ overflowY: "auto", maxHeight: "50vh" }}>
          {prompts.map((p) => (
            <div
              key={p.id}
              className="bg-white text-start mb-1 btn btn-light w-100"
              onClick={() => props.setSelectedPrompt(p)}
            >
              <td>
                <div className="d-flex justify-content-between">
                  <span className="text-wrap">{p.prompt}</span>
                </div>
              </td>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default LibraryPromptSelector;
