import { faAnglesDown, faArrowUp, faBan, faBook, faFolderTree, faImage, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";


const GeneralLayout = (props) => {

    const [modeText, setModeText] = useState(null);

    useEffect(() => {
        if (props.mode === 0) {
            setModeText('Specifications');
        } else if (props.mode === 1) {
            setModeText('Contracts');
        } else if (props.mode === 2) {
            setModeText('Site Investigations');
        } else if (props.mode === 3) {
            setModeText('Drawings');
        } else if (props.mode === 4) {
            setModeText('Health & Safety Docs');
        } else if (props.mode === 5) {
            setModeText('Others');
        } else if (props.mode === 6) {
            setModeText('Plans & Methods');
        }
    }, [props.mode]);

    return (
        <>
            {props.userInfo &&
                <div className="uploader_holder w-100 py-5  mx-auto">
                    {props.mode !== null && props.mode !== undefined ? (
                    <div className="row justify-content-center align-items-center g-0">
                        <div className="col-12 col-lg-4">
                            <Card className="shadow bg-light">
                                <CardHeader>
                                    <h6 className="title-bold mt-2">{props.mode === 0 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Specifications</> 
                                                                    : props.mode === 1 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Contracts</> 
                                                                    : props.mode === 2 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Site Investigations</> 
                                                                    : props.mode === 3 ? <><FontAwesomeIcon className="me-2" icon={faImage} /> Drawings</> 
                                                                    : props.mode === 4 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Health & Safety</> 
                                                                    : props.mode === 5 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Other</>
                                                                    : props.mode === 6 ? <><FontAwesomeIcon className="me-2" icon={faBook} /> Plans & Methods</> : null}
                                    </h6>
                                </CardHeader>
                                <CardBody>

                                    <div className="mb-3">
                                        {props.mode !== 3 ?
                                            <>
                                                {(props.userInfo.allow_civilsgpt || props.userInfo.subscription.subscription_id!==1) ?
                                                    <small><FontAwesomeIcon className="me-2" icon={faAnglesDown} />Upload project <b>{modeText}</b> here</small>
                                                :
                                                    <span><FontAwesomeIcon className="me-2" icon={faBan} />Your account currently doesn't have permission to upload this filetype</span>
                                                }
                                            </>
                                        : props.mode === 3 ?
                                            <>
                                                {(props.userInfo.allow_drawing || props.userInfo.subscription.subscription_id !==1 ) ?
                                                    <small><FontAwesomeIcon className="me-2" icon={faAnglesDown} />Upload <b>{modeText}</b> here</small>
                                                :
                                                    <span><FontAwesomeIcon className="me-2" icon={faBan} />Your account currently doesn't have permission to upload this filetype</span>
                                                }
                                            </>
                                        : null
                                        } 
                                    </div>

                                    <>
                                    
                                        <div className="drag-drop-uploader-holder">
                                            {!props.showError ?
                                            <>
                                                {(props.loading || (props.progress > 0 && props.progress < 100)) &&
                                                    <div className="drag-drop-uploader disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                                                        <span>
                                                            <Spinner color="dark" size="sm" className="mx-auto" />
                                                            <span>File upload in progress</span>
                                                        </span>
                                                    </div>
                                                }
                                                { props.userInfo && (props.userInfo.allow_drawing || props.userInfo.allow_civilsgpt) ? (
                                                    <FileUploader
                                                    classes={`drag-drop-uploader w-100 mb-2 ${(props.loading || (props.progress > 0 && props.progress < 100)) && 'd-none'}`}
                                                    style={{ minWidth: "100%" }}
                                                    label="Drop files here"
                                                    hoverTitle="Drop files here"
                                                    name="file"
                                                    types={["pdf"]}
                                                    handleChange={props.handleChange}
                                                    maxSize={1000}
                                                    onSizeError={()=>props.setErrorText("File size has exceeded the limit")}
                                                    onTypeError={()=>props.setErrorText("File type is not supported")}
                                                    multiple={true}
                                                    />
                                                ) : (
                                                    <FileUploader
                                                    classes='drag-drop-uploader w-100 mb-2'
                                                    style={{ minWidth: "100%" }}
                                                    label="Drop files here"
                                                    hoverTitle="Drop files here"
                                                    name="file"
                                                    types={["pdf"]}
                                                    handleChange={props.handleChange}
                                                    maxSize={1000}
                                                    onSizeError={()=>props.setErrorText("File size has exceeded the limit")}
                                                    onTypeError={()=>props.setErrorText("File type is not supported")}
                                                    multiple={true}
                                                    disabled={props.mode !== null ? true : false}
                                                    />
                                                )}
                                            </>
                                            :
                                                <div className="drag-drop-uploader disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                                                    <span>Upload error, please try again</span>
                                                    <button onClick={()=>{props.setErrorText(""); props.setShowError(false);}} className="btn mt-2 btn-sm btn-primary">Try again</button>
                                                </div>
                                            }
                                        </div>
                                    </>
                                </CardBody>
                            </Card>

                        </div>
                        <div className="d-none d-lg-block col-2">
                            {!props.showError ? (
                            <div
                                className="progress my-2"
                                style={{ height: '1rem', borderRadius: 0, margin: 'auto', transition: 'none' }}
                            >
                                <div
                                    className={`progress-bar custom-progress-bar ${props.progress === 100 && 'bg-success'}`}
                                    role="progressbar"
                                    aria-valuenow={props.progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: `${props.progress}%` }}
                                >
                                {props.progress}%
                                </div>
                            </div>
                            ) : (
                            <div
                                className="progress my-2"
                                style={{ height: '1rem', borderRadius: 0, margin: 'auto', transition: 'none' }}
                            >
                                <div
                                    className="progress-bar custom-progress-bar"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: '0%' }}
                                >
                                {props.progress}%
                                </div>
                            </div>
                            )}
                        </div>


                        <div className="d-none d-lg-block col-3">
                            <Card className="shadow-sm bg-light">
                                <CardHeader>
                                    <h6 className="title-bold pt-2"><span><FontAwesomeIcon className="me-2" icon={faPen} /> Output</span></h6>
                                </CardHeader>
                                <CardBody>

                                <div class="input-group input-group-sm">
                                    <label class="input-group-text" for="inputGroupSelect01">
                                    <FontAwesomeIcon icon={faFolderTree} />
                                    </label>
                                    <input type="text" class="form-control" disabled value={modeText} aria-label="Knowledge base" aria-describedby="basic-addon1"/>
                                </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    ) : (
                        <div className="row justify-content-center align-items-center g-0">
                            <div className="col-12 col-lg-4">
                                <Card className="shadow bg-light">
                                    <CardHeader>
                                        <h6 className="title-bold mt-2">Information</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="mb-3">
                                            <small><FontAwesomeIcon className="me-2" icon={faArrowUp} />Please select the <b>document type</b> in order to upload document(s)</small>
                                        </div>
                                    </CardBody>
                                </Card>

                            </div>
                        </div>
                    )}
                </div>
            }
        </>
    )
}

export default GeneralLayout;