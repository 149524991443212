import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  faClockRotateLeft,
  faRedo,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, Modal, Spinner, Tooltip } from "reactstrap";
import httpClientPy from "../../utils/httpClientPy";
import LibraryDocSelector from "./LibraryDocSelector";
import PdfViewer from "../project/detailComponents/PdfViewer";
import LibrarySearch from "./LibrarySearch";
import LibrarySearchHistory from "./LibrarySearchHistory";
import { toast, ToastContainer } from "react-toastify";

const LibraryDetailsModal = (props) => {
  const [documentLoading, setDocumentLoading] = useState(false);
  const [libraryDetails, setLibraryDetails] = useState({});
  const [libraryDocuments, setLibraryDocuments] = useState([]);
  const [numberOfReadyDocs, setNumberOfReadyDocs] = useState(0);
  const [pageNumberInput, setPageNumberInput] = useState(1);
  const [initialPage, setInitialPage] = useState(1);
  const [fileUrl, setFileUrl] = useState(null);
  //FIXME: use state
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [highlightArea, setHighlightArea] = useState(null);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyResult, setHistoryResult] = useState(null);

  const pdfview = useRef(null);

  const [tooltipOpen, setTooltipOpen] = useState({
    checkHistory: false,
    reselectDoc: false,
  });

  const toggle = (tooltip) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [tooltip]: !prevState[tooltip],
    }));
  };

  const getLibraryData = useCallback(async () => {
    setDocumentLoading(true);
    try {
      let response = await httpClientPy.get(
        `/library?library_id=${props.selectedLibrary}`
      );
      let library_details = {
        id: response.data.library.id,
        name: response.data.library.name,
      };
      setLibraryDetails(library_details);
      let files = response.data.library_documents.map((file) => {
        return {
          id: file.id,
          record_id: file.record_id,
          name: file.original_file_name,
          updated_at: file.updated_at,
          file_size: file.file_size,
          status: file.status,
          is_new: file.is_new,
        };
      });
      setNumberOfReadyDocs(
        files.filter((file) => file.status === "ready").length
      );
      setLibraryDocuments(files);
    } catch (error) {
      let error_code = error.response.data.detail;
      if (error_code === 4) {
        toast.error(
          "Document limit has been exceeded, contact your admin in order to check limit balance.",
          { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
        );
      } else if (error_code === 3) {
        toast.error("User is not part of enterprise account.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else if (error_code === 5) {
        toast.error(
          "Library does not exist or user is not part of the enterprise.",
          { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
        );
      } else {
        toast.error("An error occurred while fetching data.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
    setDocumentLoading(false);
  }, [props.selectedLibrary]);

  useEffect(() => {
    getLibraryData();
  }, [getLibraryData, props.selectedLibrary]);

  const toggleHistory = () => setHistoryOpen(!historyOpen);

  return (
    <>
      <ToastContainer />
      <Card
        className={`chat-card chat-card-open shadow`}
        style={{ height: "100vh" }}
      >
        <CardBody className={`py-0 bg-white w-100`}>
          <div className="row h-100">
            <div
              id="pdfview"
              ref={pdfview}
              className={`border-end col-6 px-0 d-none d-sm-block`}
              style={{ height: "90vh" }}
            >
              {!documentLoading ? (
                <>
                  {fileUrl ? (
                    <PdfViewer
                      fileUrl={fileUrl}
                      pageNumberInput={pageNumberInput}
                      initialPage={initialPage}
                      fileType={3}
                      setInitialPage={(page) => setInitialPage(page)}
                      highlightArea={highlightArea}
                      updatePageNumber={(page) => setPageNumberInput(page)}
                    />
                  ) : (
                    <LibraryDocSelector files={libraryDocuments} />
                  )}
                </>
              ) : (
                <div
                  className="disabled d-flex flex-column align-items-center justify-content-center"
                  style={{ height: "100%" }}
                >
                  <span>
                    <Spinner color="dark" size="sm" className="mx-auto" />
                  </span>
                </div>
              )}
            </div>
            <div
              className={`d-flex col-12 col-sm-6 shadow bg-offwhite px-0 flex-column  chatList-open d-flex`}
            >
              <div className="d-flex justify-content-between align-items-center border-bottom bg-light p-1">
                <div className="px-3" style={{ margin: 0 }}>
                  <h5 className="d-inline py-0 my-0">
                    <b>{libraryDetails.name}</b>
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                  {numberOfReadyDocs > 1 && (
                    <>
                      {/* FIXME: a tag */}
                      <a
                        id="reselectDoc"
                        className={`btn btn-link text-dark btn-sm me-2 ${!fileUrl && "d-none"}`}
                        onClick={() => setFileUrl(null)}
                      >
                        <FontAwesomeIcon icon={faRedo} />
                      </a>
                      <Tooltip
                        target="reselectDoc"
                        isOpen={tooltipOpen.reselectDoc}
                        toggle={() => toggle("reselectDoc")}
                        placement="bottom"
                      >
                        View All Uploaded Documents
                      </Tooltip>
                    </>
                  )}
                  <a
                    id="checkHistory"
                    className="btn btn-link text-dark btn-sm me-2  d-none d-sm-inline"
                    onClick={() => toggleHistory()}
                  >
                    <FontAwesomeIcon icon={faClockRotateLeft} />
                  </a>
                  <Tooltip
                    target="checkHistory"
                    isOpen={tooltipOpen.checkHistory}
                    toggle={() => toggle("checkHistory")}
                    placement="bottom"
                  >
                    View History of Searches
                  </Tooltip>
                  <a
                    className="btn btn-sm btn-danger"
                    type="button"
                    onClick={() => props.handleClose()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </a>
                </div>
              </div>
              <div className="d-flex flex-column" style={{ height: "100%" }}>
                <LibrarySearch
                  fileUrl={fileUrl}
                  selectedLibrary={props.selectedLibrary}
                  selectedDocId={selectedDocId}
                  initialPage={initialPage}
                  highlightedAreas={highlightArea}
                  setFileUrl={(url) => setFileUrl(url)}
                  setInitialPage={(page) => setInitialPage(page)}
                  setHighlightArea={(area) => setHighlightArea(area)}
                  historyResult={historyResult}
                  pageNumberInput={pageNumberInput}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Modal
        id="searchHistory"
        size="xl"
        isOpen={historyOpen}
        toggle={toggleHistory}
      >
        <LibrarySearchHistory
          libraryId={props.selectedLibrary}
          setHistoryResult={(result) => setHistoryResult(result)}
          toggleHistory={toggleHistory}
        />
      </Modal>
    </>
  );
};

export default LibraryDetailsModal;
