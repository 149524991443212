import { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Modal, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import httpClientPy from '../../utils/httpClientPy';
import SideBar from './SideBar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEllipsis, faLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from '../shared/Loader';
import ProjectDropdown from '../shared/ProjectDropdown';
import LoadingCivils from '../shared/LoadingCivils';
import authenticationHandler from '../../utils/authenticationHandler';
import NewProjectModal from './SideBarComponents/NewProjectModal';
import { faUsers } from '@fortawesome/fontawesome-free-solid';
import PricingModal from '../shared/payments/Pricing';

const ProjectsList = (props) => {

    const navigate = useNavigate();
    // variable to handle if the api has returned error
    const [error, setError] = useState(false);
    // variable to store the error message
    const [errorMessage, setErrorMessage] = useState(null);
    // variable to store the recent project
    const [recentProject, setRecentProject] = useState(null);
    // variable to store all the projects
    const [allProjects, setAllProjects] = useState(null);
    // variable to handle loading
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);

    const [toggleNewProjectModal, setToggleNewProjectModal] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [modalPricingOpen, setModalPricingOpen] = useState(false);
    const [hasLibs, setHasLibs] = useState(false);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const togglePricingModal = () => setModalPricingOpen(!modalPricingOpen);

    const toggleNewProject = () => {
        setToggleNewProjectModal(!toggleNewProjectModal);
    }

    const toggleCancel = () => {
        setToggleNewProjectModal(false);
        localStorage.setItem("selectedPage", 'LIBR');
        navigate(`/library`);
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const handleLogout = () => {
        authenticationHandler.logout();
        navigate("/log-in");
      };


    const handleAddProjectClick = (project_type) => {
        navigate('/map', { state: {project: true, project_type: project_type} });
    }


    const fetchInitialData = async () => {
        setError(false);
        setErrorMessage(null);
        
        try {
            // Make both API calls in parallel
            const [projectsResponse, userResponse] = await Promise.all([
                httpClientPy.get('/project/index'),
                httpClientPy.get('/user')
            ]);

            // Handle projects data
            const { project_recent, all_project } = projectsResponse.data;
            setRecentProject(project_recent);
            
            // Sort projects by last updated date
            const sortedProjects = [...all_project].sort((a, b) => 
                new Date(b.last_updated_datetime) - new Date(a.last_updated_datetime)
            );
            setAllProjects(sortedProjects);
            
            // Handle user data
            const userData = userResponse.data;
            const processedUserData = {
                ...userData,
                allow_digitise: userData.is_enterprise 
                    ? userData.enteprise_is_digitiser 
                    : (userData.subscription_details?.is_digitise || false),
                allow_civilsgpt: userData.is_enterprise 
                    ? userData.enterprise_is_civilsgpt 
                    : (userData.subscription_details?.is_civilsgpt || false)
            };
            
            setUserInfo(processedUserData);
            setAdmin(userData.enterprise_admin);

            if (processedUserData.subscription.subscription_id === 1 && !processedUserData.is_enterprise) {
                setRecentProject([]);
                setAllProjects([]);
            }

            if (project_recent.length === 0 && processedUserData.is_enterprise) {
                httpClientPy.post('/library/index', {})
                .then(response => {
                    if (response.data.libraries.length > 0) {
                        setHasLibs(true);
                    }
                }).catch(error => {
                    setError(true);
                    setErrorMessage(error.response?.data?.detail || 'An error occurred');
                });
            }

        } catch (error) {
            setError(true);
            setErrorMessage(error.response?.data?.detail || 'An error occurred');
        } finally {
            setLoading(false);
            setLoadingUser(false);
        }
    };

    // Function to refresh only projects data if needed later
    const refreshProjects = async () => {
        try {
            const response = await httpClientPy.get('/project/index');
            setRecentProject(response.data.project_recent);
            
            const sortedProjects = [...response.data.all_project].sort((a, b) => 
                new Date(b.last_updated_datetime) - new Date(a.last_updated_datetime)
            );
            setAllProjects(sortedProjects);
        } catch (error) {
            setError(true);
            setErrorMessage(error.response?.data?.detail || 'An error occurred');
        }
    };    


    const handleViewProjectClick = (id) => {
        navigate('/project', { state: {id: id, type:null, subpage: 'overview'}, });
    }
    
    return (
        <div className="d-flex">
            <div id="sidebarParent" className=' d-none d-md-block' style={{minWidth:'190px'}}>
                <SideBar 
                    page={'list'} 
                    loading={loading} 
                    admin={admin}
                    userInfo={userInfo}
                    togglePricingModal = {()=> togglePricingModal()}
                />
            </div> 
            {!loading && !loadingUser ? (
                <>  
                    <div  className="flex-fill px-3 py-3" style={{maxWidth:'100vw'}}>
                        {!error && recentProject !== null ? (
                        <>
                            {recentProject.length !== 0 ? (
                            <>
                                <div>
                                    <div className='container mt-0 mt-lg-2' >
                                        {loading ? (
                                        <Loader loading={loading} />
                                        ) : (
                                            <>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <h3 className='title-bold mb-0'>My Projects</h3>
                                                    <a className="btn btn-primary" onClick={() => toggleNewProject()}>
                                                        <span><FontAwesomeIcon className="me-2" icon={faPlus}/></span>
                                                        <span className="">Project</span>
                                                    </a>

                                                </div>
                                                {/* <div className='row align-items-center d-none d-lg-block'>
                                                    <div className='col'>
                                                        <h3 className='title-bold'>My Projects</h3>
                                                    </div>
                                                    <div className='col text-end'>
                                                        <a className="btn btn-primary" onClick={() => toggleNewProject()}>
                                                            <span><FontAwesomeIcon className="me-2" icon={faPlus}/></span>
                                                            <span className="">Project</span>
                                                        </a>
                                                    </div>
                                                </div> */}
                                                <div className='d-block d-lg-none'>
                                                    <hr/>
                                                    <select
                                                        id="projectLibrarySelect"
                                                        className="form-select  form-select-lg"
                                                        onChange={(e) => {
                                                            if (e.target.value === 'libraries') {
                                                                localStorage.setItem("selectedPage", 'LIBR');
                                                                navigate('/library');
                                                            }
                                                        }}
                                                        defaultValue="projects"
                                                    >
                                                        <option value="projects">My Projects</option>
                                                        <option value="libraries">My Libraries</option>
                                                    </select>
                                                    <hr/>
                                                </div>



                                                <div id="project_list" className='row'> 
                                                    {allProjects.map((card, index) => (
                                                        <div className='col-sm-6 col-md-3 col-lg-3 pt-4 d-flex' key={index}>
                                                            <Card
                                                                className='project-list-card rounded flex-grow-1 d-flex flex-column overflow-hidden'
                                                                onClick={() => handleViewProjectClick(card.id)}
                                                                style={{ minHeight: '100%' }}
                                                            >
                                                                <CardBody
                                                                    className="project-list-card-img-background"
                                                                    style={card.project_type !== 2 ? { backgroundImage: `url(${card.image_url})` } : {}}
                                                                >
                                                                    {/* Overlay for text readability */}
                                                                    <div className="project-list-card-content">
                                                                        <CardTitle tag="h6">
                                                                            <span style={{ maxWidth: '100%' }} className="d-inline-block title-bold text-truncate">{card.name}</span>
                                                                        </CardTitle>

                                                                        <CardBody className="mt-2 p-0">
                                                                            <div>
                                                                                <small><FontAwesomeIcon icon={faClock}></FontAwesomeIcon></small>
                                                                                <small>&nbsp; Last updated:&nbsp; {card.last_updated_datetime}</small>
                                                                            </div>
                                                                            {card.shared &&
                                                                                <div>
                                                                                    <small><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon></small>
                                                                                    <small>&nbsp; Team project</small>
                                                                                </div>
                                                                            }

                                                                        </CardBody>
                                                                    </div>
                                                                    <div className="project-list-card-button position-absolute bottom-0 end-0 p-3">
                                                                        {(card.shared && userInfo.george_id !== card.owner_id) ? 
                                                                            <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                                                                            :
                                                                            <ProjectDropdown project_id={card.id} refreshProjects={()=>refreshProjects()} shared={true} />
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </Card>

                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='d-block d-sm-none'>
                                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                    <DropdownToggle className="btn btn-light border w-100 mt-3" tag="div">
                                                        <span><FontAwesomeIcon className="me-2" icon={faEllipsis}/></span>
                                                        <span>Account</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={handleLogout}>Sign out</DropdownItem>
                                                    </DropdownMenu>
                                                    </Dropdown>
                                                </div>

                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                            ) : (
                                null
                            )}
                        </>
                        ) : (
                        <>
                            <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                                <img src="assets/build_img.png" alt="Civils.ai" />
                                <h2><b>Oops!</b></h2>
                                <h4>{errorMessage}</h4>
                            </div>
                        </>
                        )}

                        {(allProjects && allProjects.length===0) &&
                            <>
                                <LoadingCivils 
                                    userInfo={userInfo}
                                    loading = {loading}
                                    hasLibs = {hasLibs}
                                    toggleModal = {()=> toggleNewProject()}
                                    togglePricingModal = {()=> togglePricingModal()}
                                    toggleCancel = {()=> toggleCancel()}
                                >
                                </LoadingCivils>
                                <PricingModal isOpen={modalPricingOpen} title={'Access your No-Code AI Software Suite and build your own custom automations'} toggle={()=>togglePricingModal()}/>
                            </>
                        }

                    </div>

                    <Modal id="newProject" size="l" isOpen={toggleNewProjectModal} toggle={toggleNewProject} centered={true}>
                        <NewProjectModal title="Your new project is starting..."
                                        toggleModal = {()=> toggleNewProject()} 
                                        handleChoiceClick = {(project_type) => handleAddProjectClick(project_type)}/>
                    </Modal>
                </>
            ) : (
                <>
                    <div className="flex-fill px-3 pe-lg-0 py-4 row text-center d-flex justify-content-center align-items-center" style={{ maxWidth: '100vw', height: '70vh' }}>
                        <span>
                            <span
                                className="spinner-border spinner-border-md mx-auto"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </span>
                    </div>
                </>
            )}
            </div>
    )
    
}

export default ProjectsList;