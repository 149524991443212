import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import { Tooltip } from 'reactstrap';

const QuotaCard = (props) => {

    const [limitUsed, setLimitUsed] = useState(props.userInfo.is_enterprise ? props.userInfo.enterprise_usage.total_documents : props.userInfo.usage.total_documents);
    const [totalLimit, setTotalLimit] = useState(props.userInfo.is_enterprise ? props.userInfo.enterprise_subs_detail.document_limit : props.userInfo.subscription_details.document_limit);
    const [usagePercent, setUsagePercent] = useState(0);
    const [infoTooltip, setInfoTooltip] = useState(false);

    const toggleInfoTooltip = () => setInfoTooltip(!infoTooltip);

    const handleClick = () => {
        if (props.quotaView === 'documents') {
            props.setQuotaView('query')
            setLimitUsed(props.userInfo.is_enterprise ? props.userInfo.enterprise_usage.total_queries : props.userInfo.usage.total_queries)
            setTotalLimit(props.userInfo.is_enterprise ? props.userInfo.enterprise_subs_detail.queries_limit : props.userInfo.subscription_details.queries_limit)
        } else {
            props.setQuotaView('documents')
            setLimitUsed(props.userInfo.is_enterprise ? props.userInfo.enterprise_usage.total_documents : props.userInfo.usage.total_documents)
            setTotalLimit(props.userInfo.is_enterprise ? props.userInfo.enterprise_subs_detail.document_limit : props.userInfo.subscription_details.document_limit)
        }
    }

    const formatNumber = (num) => {
        if (num < 1000) return num.toString();
        const units = ["K", "M", "B", "T"];
        let unitIndex = -1;
        let formattedNum = num;
        while (formattedNum >= 1000 && unitIndex < units.length - 1) {
            formattedNum /= 1000;
            unitIndex++;
        }
        return formattedNum % 1 === 0 ? `${formattedNum}${units[unitIndex]}` : `${formattedNum.toFixed(1)}${units[unitIndex]}`;
    };

    useEffect(() => {
        setUsagePercent((limitUsed / totalLimit) * 100)
    }, [limitUsed, totalLimit])

    return (
        <div className="card shadow-sm border-0 rounded-3 pt-0 mt-0" style={{ backgroundColor: '#292B2C'}} >
            <div className="card-body p-2">
                <div className="d-flex justify-content-between">
                    
                        {props.quotaView === 'query' && 
                            <>
                                {/* <span>
                                    <FontAwesomeIcon icon={faArrowLeft} className="me-2 quota-btn" size='sm' onClick={handleClick}/>                                
                                </span> */}
                                <small className="mb-1 fw-semibold text-light">Queries Usage</small>
                            </>
                        }
                        {props.quotaView === 'documents' &&
                            <>
                                <small className="mb-1 fw-semibold text-light title-bold">Files Used</small>
                                <span id='info-btn'><FontAwesomeIcon icon={faInfoCircle} className='text-light' /></span>
                                <Tooltip isOpen={infoTooltip} placement={'right'} target="info-btn" toggle={toggleInfoTooltip}>
                                    <p className="text-light">File Usage Info</p>
                                    <small>
                                        <ul className="text-light small text-start">
                                            <li><b>1 file quota</b> will be consumed for <b>1 file upload.</b></li>
                                            <li>For the case of <b>borehole</b> or <b>drawing</b> digitisation, each borehole or drawing will consume <b>1 file quota.</b></li>
                                        </ul>
                                    </small>
                                </Tooltip>
                                {/* <span>
                                    <FontAwesomeIcon icon={faArrowRight} className="ms-2 quota-btn" size='sm' onClick={handleClick}/>
                                </span> */}
                            </>
                        }
                </div>

                <div className="d-flex justify-content-between small text-muted">
                    <small className='text-light'><b>{formatNumber(limitUsed)}</b> used</small>
                    <small className='text-light'>{formatNumber(totalLimit)} total</small>
                </div>

                <div className="progress" style={{ height: '8px', borderRadius: '4px' }}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ 
                            width: `${usagePercent}%`, 
                            backgroundColor: '#6366f1',
                            borderRadius: '4px'
                        }}
                        aria-valuenow={usagePercent}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                </div>

                {/* <div className="d-flex justify-content-between small mt-1">
                    <span id='info-btn'><FontAwesomeIcon icon={faInfoCircle} className='text-light' /></span>
                    <Tooltip isOpen={infoTooltip} placement={'right'} target="info-btn" toggle={toggleInfoTooltip}>
                        <p className="text-light">File Usage Info</p>
                        <small>
                            <ul className="text-light small text-start">
                                <li><b>1 file quota</b> will be consumed for <b>1 file upload.</b></li>
                                <li>For the case of <b>borehole</b> or <b>drawing</b> digitisation, each borehole or drawing will consume <b>1 file quota.</b></li>
                            </ul>
                        </small>
                    </Tooltip>
                    <small className="fw-semibold text-light">
                        <b>{usagePercent.toFixed(0)}</b>% utilized
                    </small>
                </div> */}
            </div>
        </div>
    );
};

export default QuotaCard;