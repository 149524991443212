import { useEffect, useRef, useState } from "react";
import { CardHeader, Button, Tooltip, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCity, faCopy, faDownload, faList, faMagnifyingGlass, faTable, faThumbsDown, faThumbsUp, faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import httpClientPy from "../../../../utils/httpClientPy";
import { marked } from "marked";
import { logEvent } from '../../../shared/Mixpanel';
import { toast, ToastContainer } from "react-toastify";
import PdfViewer from "../PdfViewer";

marked.setOptions({
    gfm: true,
    tables: true,
});

const SearchAll = (props) => {

    const [chatInput, setChatInput] = useState('');
    const [query, setQuery] = useState('');
    const [generating, setGenerating] = useState(false);
    const [answer, setAnswer] = useState('');
    const [allRefAnswer, setAllRefAnswer] = useState('');
    const [isUpVote, setIsUpVote] = useState(null);
    const [copyStatus, setCopyStatus] = useState(false);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);
    const [selectedReferenceType, setSelectedReferenceType] = useState(-1);
    const [isMinimised, setisMinimised] = useState(false);
    const [typingCivilsGPT, setTypingCivilsGPT] = useState(false);
    const [lastSearchId, setLastSearchId] = useState(null);
    const [openPreview, updateOpenPreview] = useState(false);

    const pdfview = useRef(null);

    const doc0Count = useRef(null)
    const doc1Count = useRef(null)
    const doc2Count = useRef(null)
    const doc3Count = useRef(null)
    const doc4Count = useRef(null)
    const doc5Count = useRef(null)
    const doc6Count = useRef(null)

    const docMapper = {
        "0": 'Specifications',
        "1": 'Contracts',
        "2": 'Surveys',
        "3": 'Drawings',
        "4": 'Risk Registers',
        "5": 'Others',
        "6": "Plans"
    }

    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

    useEffect(() => {

        if(allRefAnswer && allRefAnswer !== '' && allRefAnswer!==null){
            const timer = setTimeout(() => {
                setisMinimised(true);
            }, 5000); 
        
            return () => clearTimeout(timer); // Cleanup timeout on unmount
        }
      }, [allRefAnswer]);


    // to handle in the case where there was search history clicked
    useEffect(() => {
        if (props.query !== null && props.lastId === null) {
            submitMessage(props.query);
        } 
        if (props.query !== null && props.lastId !== null) {
            setQuery(props.query);
            setAnswer(props.result);
            setAllRefAnswer(props.allRefs);
            setIsUpVote(props.vote);
            setLastSearchId(props.lastId);
            
            try {
                const jsonStart = props.result.indexOf('{');
                const jsonEnd = props.result.lastIndexOf('}') + 1;
                if (jsonStart !== -1 && jsonEnd !== -1) {
                    const jsonString = props.result.substring(jsonStart, jsonEnd);
                    const json = JSON.parse(jsonString);
                    delete json.all;

                    // get the first key
                    let key = Object.keys(json)[0];
                    let file = Object.keys(json[key])[0];
                    let page = Object.keys(json[key][file])[0];
                    let ref_ids = json[key][file][page];
                    handleReferenceClick(file, page, ref_ids, docMapper[key]);
                }
            } catch (error) {
                toast.error('Internal problem while parsing the result, please contact info@civils.ai. Thank you for your understanding.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
        }

    }, [props.query, props.result, props.allRefs, props.vote, props.lastId]);

    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    // detect if user pressed enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !generating) {
            e.preventDefault();
            if(typingCivilsGPT){
                submitMessage(chatInput);
                setTypingCivilsGPT(false);
            }
        }
    }

    // this is the useEffect to handle the keypress event listener
    useEffect(() => {

        // use the handleKeyPress
        window.addEventListener('keypress', handleKeyPress);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('keypress', handleKeyPress);
        };

    });

    const submitMessage = async (input) => {
        
        setQuery(input);
        setChatInput('');
        setAnswer(null);
        setGenerating(true);
        setAllRefAnswer(null);
        setIsUpVote(null);
        setCopyStatus(false);
        setLastSearchId(null);

        let chat_query = input;

        let requestBody
        let api
        const token = localStorage.getItem('auth_token');

        api = '/search/all/result'
        requestBody = JSON.stringify({
            project_id: props.project.id,
            search_query: chat_query,
        });


        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL_PY + api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestBody,
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let text = '';
            let lastId = null;
            let key_ref = null;

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }
                
                let curr_text = decoder.decode(value);
                try {

                    if (!isNaN(curr_text)) {
                        throw new Error("Input is a number, not valid JSON");
                    }

                    let json = JSON.parse(curr_text.trim());
                    if (json.all === true) {
                        setAllRefAnswer(curr_text);
                    } 
                    
                    if (json.all === false) {
                        text += curr_text;
                        key_ref = json;
                    }
                    
                    if (json.hasOwnProperty('search_query_id')) {
                        lastId = json.search_query_id;
                        setLastSearchId(lastId);
                    }
                    
                } catch {

                    text += curr_text;
                    if(!curr_text.startsWith('{') && !curr_text.endsWith('}')) {
                        const renderedHtml = marked.parse(text);
                        setAnswer(renderedHtml);
                    }

                }
            }

            setAnswer(text);
            
            if (key_ref !== null) {
                let key = Object.keys(key_ref)[0];
                let file = Object.keys(key_ref[key])[0];
                let page = Object.keys(key_ref[key][file])[0];
                let ref_ids = key_ref[key][file][page];
                handleReferenceClick(file, page, ref_ids, docMapper[key]);
            }

        } catch (error) {
            toast.error('Internal problem while fetching the result, please contact info@civils.ai. Thank you for your understanding.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        }

        setGenerating(false);
        logEvent('Search', { 'Query': chat_query });
        
    }

    const genAiAnswer = (prompt) => {
        submitMessage(prompt);
    }

    function capitalizeFirstLetter(string) {
        if (!string) return ''; // Handle empty string case
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleCopy = () => {
        const copyText = document.getElementById('copy_this').innerText;
        navigator.clipboard.writeText(copyText).then(() => {
            setCopyStatus(true)
            // set a timeout before setting the copy status back to false
            setTimeout(() => {
                setCopyStatus(false)
            }
            , 3000);
        }).catch(err => {
            setCopyStatus(false)
        });
    };

    const handleUpvote = () => {
        httpClientPy.post('/search/all/feedback', {
            query_id: lastSearchId,
            vote: true
        })
        .then((response) => {
            if (response.data.status === true) {
                setIsUpVote(true);
            }
        })
        .catch((error) => {
            toast.error('Internal problem while voting, please contact info@civils.ai. Thank you for your understanding.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        });
    }

    const handleDownvote = () => {
        httpClientPy.post('/search/all/feedback', {
            query_id: lastSearchId,
            vote: false
        })
        .then((response) => {
            if (response.data.status === true) {
                setIsUpVote(false);
            }
        })
        .catch((error) => {
            toast.error('Internal problem while voting, please contact info@civils.ai. Thank you for your understanding.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        });
    }

    const changeSelectedReferenceType = (type) => {
        setSelectedReferenceType(type);
        setisMinimised(false)
    }

    const renderer = (text) => {
        try {
            const json = JSON.parse(text);
            if (!json.hasOwnProperty('all')) {
                const html = marked.parse(text);
                return <div className="markdown-content" dangerouslySetInnerHTML={{ __html: html }} />;
            } else if (json.all === false) {
                delete json.all
                let keys = Object.keys(json);
                console.log(json['3'])
                return( 
                    <>
                        <div>
                            {keys.map((key) => {
                                if (key !== '3') {
                                    return Object.entries(json[key]).map(([file, pages]) => (
                                        <div key={file}>
                                            {file} - {' '}
                                            {Object.entries(pages).map(([page_num], index, arr) => (
                                                <span key={`${file}-${page_num}`}>
                                                    <a
                                                        className='reference-link circle-owner'
                                                        style={{ backgroundColor: '#5b5fcc' }}
                                                        onClick={() => handleReferenceClick(file, page_num, pages[page_num], docMapper[key])}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {page_num}
                                                    </a>
                                                    {index < arr.length - 1 ? '' : ''}
                                                </span>
                                            ))}
                                        </div>
                                    ));
                                } else {
                                    return (
                                        <div className="my-1">
                                            <div className='py-1' style={{ display: 'flex', overflowX: 'auto', gap: '10px', whiteSpace: 'wrap'}}>
                                                {Object.entries(json[key]).map(([file, pages]) => (
                                                    Object.entries(pages).map(([page_num, image_url]) => (
                                                        page_num !== 'image_url' &&
                                                        <img 
                                                            key={`${file}-${page_num}`}
                                                            src={pages['image_url']}
                                                            alt={`Image ${file} - Page ${page_num}`}
                                                            loading='lazy'
                                                            className="card-img-top cad-preview"
                                                            ContentType="image/jpeg"
                                                            onClick={() => handleReferenceClick(file, page_num, pages[page_num], docMapper["3"])}
                                                            style={{ objectFit: 'contain', width: '150px',}} 
                                                        />
                                                    ))
                                                ))}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </>
                )

            }
        } catch (error) {
            const html = marked.parse(text);
            return <div className="markdown-content" dangerouslySetInnerHTML={{ __html: html }} />;
        }
    }

    const all_ref_renderer = (text) => {
        try {
            let json = JSON.parse(text);
            delete json['all'];
            let keys = []
            let drawing_keys = []
            
            if (selectedReferenceType === -1) {
                keys = ['0', '1', '2', '4', '5', '6'];
                drawing_keys = ['3'];
            } else if (selectedReferenceType === 3) {
                keys = []
                drawing_keys = ['3'];
            } else {
                keys = [selectedReferenceType.toString()];
                drawing_keys = []
            }
            
            doc0Count.current = json['0'] ? Object.keys(json['0']).length : 0;
            doc1Count.current = json['1'] ? Object.keys(json['1']).length : 0;
            doc2Count.current = json['2'] ? Object.keys(json['2']).length : 0;
            doc3Count.current = json['3'] ? Object.keys(json['3']).length : 0;
            doc4Count.current = json['4'] ? Object.keys(json['4']).length : 0;
            doc5Count.current = json['5'] ? Object.keys(json['5']).length : 0;
            doc6Count.current = json['6'] ? Object.keys(json['6']).length : 0;
            
            return (
                <>
                    {keys.length > 0 && (
                        <div>
                            <table className="table mt-2">
                                <thead>
                                    <tr>
                                        <td><b>Document name</b></td>
                                        <td><b>Document type</b></td>
                                        <td><b>Page references</b></td>
                                    </tr>
                                </thead>
                                <tbody className="small">
                                    {keys.map((key) => {
                                        if (key !== '3' && json[key] !== undefined) {
                                            let docs = json[key];
                                            return Object.entries(docs).map(([file, pages]) => (
                                                <tr key={file} className="bg-white">
                                                    <td>{file}</td>
                                                    <td>{docMapper[key]}</td>
                                                    <td>
                                                        {Object.entries(pages).map(([page_num], index, arr) => (
                                                            <span key={`${file}-${page_num}`}>
                                                                <a
                                                                    className="reference-link circle-owner"
                                                                    style={{ backgroundColor: '#5b5fcc' }}
                                                                    onClick={() => handleReferenceClick(file, page_num, pages[page_num], docMapper[key])}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {page_num}
                                                                </a>
                                                                {index < arr.length - 1 ? '' : ''}
                                                            </span>
                                                        ))}
                                                    </td>
                                                </tr>
                                            ));
                                        }
                                        return null; // Return null for keys that are skipped
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {drawing_keys.length > 0 && json["3"] !== undefined && (
                        <div className="my-1">
                            <div className='py-1' style={{ display: 'flex', overflowX: 'auto', gap: '10px', whiteSpace: 'wrap'}}>
                                {Object.entries(json["3"]).map(([file, pages]) => (
                                    Object.entries(pages).map(([page_num, image_url]) => (
                                        page_num !== 'image_url' &&
                                        <img 
                                            key={`${file}-${page_num}`}
                                            src={pages['image_url']}
                                            alt={`Image ${file} - Page ${page_num}`}
                                            loading='lazy'
                                            className="card-img-top cad-preview"
                                            ContentType="image/jpeg"
                                            onClick={() => handleReferenceClick(file, page_num, pages[page_num], docMapper["3"])}
                                            style={{ objectFit: 'contain', width: '150px',}} 
                                        />
                                    ))
                                ))}
                            </div>
                        </div>
                    )}
                </>
            );
        } catch (error) {
            // do nothing
        }
    };

    const handleReferenceClick = (file, page, ref_ids, type) => {
        httpClientPy.post('/search/reference', {
            file_name: file,
            ref_ids: ref_ids,
            project_id: props.project.id
        }).then((response) => {
            props.updateFileUrl(response.data.file_path);
            props.updateHighlights(response.data.highlight_area);
            props.updateRecordId(response.data.record_id)
            props.updateFileName(file);
            props.updatePageNumber(parseInt(page));
            props.updateInitialPage(parseInt(page));
            props.updateFileType(type);
        }).catch((error) => {
            toast.error('Internal problem while loading reference, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })
        
        logEvent('Reference Clicked', { 'File': file, 'Page': page, 'Type': type });  
    }

    const handleDownload = () => {
        if (props.fileUrl) {
            // Create an anchor element dynamically
            const link = document.createElement('a');
            link.href = props.fileUrl;
            link.target = '_blank'; // Open in a new tab or window
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <>
            <ToastContainer />
            <div id="chatList" className="d-flex flex-column w-100">
                <div id="copy_parent" 
                    className={`bg-offwhite ${typingCivilsGPT ? 'd-sm-flex flex-column flex-grow-1' : 'd-flex flex-column flex-grow-1'}`} 
                    style={{ 
                        overflowY: 'auto',
                        minHeight: '30dvh', 
                    }}
                >
                    {!generating && !answer && !query && (
                        <div className="h-100 d-flex align-items-center px-1 px-lg-3">
                            <div key={0}>
                            <div className='text-center text-muted mb-4'><small>Select a prompt below to start searching this <strong>project</strong> or write your own.</small></div>
                                <div className='row w-100'>
                                    <div className='col-12 col-lg-4 pb-3 pb-lg-0'>
                                        <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('Summarise the project.')}>
                                            <div class="card-body">
                                                <h5 class="card-title primary"><FontAwesomeIcon icon={faCity}></FontAwesomeIcon></h5>
                                                Create a summary of key details from this project.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-4 pb-3 pb-lg-0'>
                                        <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('Create a table of key risks.')}>
                                            <div class="card-body">
                                                <h5 class="card-title primary"><FontAwesomeIcon icon={faTable}></FontAwesomeIcon></h5>
                                                Create a table of key construction risks.
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-4 pb-3 pb-lg-0'>
                                        <div className='card h-100 card-button' type="button" onClick={()=>genAiAnswer('List a construction sequence')}>
                                            <div class="card-body">
                                                <h5 class="card-title primary"><FontAwesomeIcon icon={faList}></FontAwesomeIcon></h5>
                                                List out any constraints affecting this project.
                                            </div>
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                    )}
                    {query && !openPreview &&
                        <CardHeader className="py-2 bg-light">
                            <div className="d-flex justify-content-between align-items-center">
                                {capitalizeFirstLetter(query)}
                                <Button id='copy_all_btn' className="btn btn-link text-dark" onClick={handleCopy}>
                                    {!copyStatus ? <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
                                </Button>
                                <Tooltip isOpen={tooltipOpen1} placement={'bottom'} target="copy_all_btn" toggle={toggle1}>
                                    Copy answer
                                </Tooltip>
                            </div>
                        </CardHeader>
                    }
                    {query && answer && !generating && (
                        <>
                            <div className={openPreview && `d-none d-sm-block`} style={{ position: 'relative' }}>
                                <div className='px-2' style={{ flex: 1 }}>
                                    <div id='copy_this' className="search_msg">
                                        {answer.split('\n\n').map((paragraph, index) => (
                                            <div className="message" key={index}>
                                                {renderer(paragraph, 0)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div id='pdfview' ref={pdfview} className={openPreview ? `d-block d-sm-none` : 'd-none'} style={{height: '90vh' }}>
                                <PdfViewer 
                                    project={props.project} 
                                    fileUrl={props.fileUrl} 
                                    task={props.task}
                                    initialPage={props.initialPage}
                                    fileType={props.fileType}
                                    setInitialPage={(page)=>props.updateInitialPage(page)}
                                    highlightArea = {props.highlightedAreas} 
                                    updateTask={(id)=>null}
                                    currentFileName={props.currentFileName}
                                    updatePageNumber={(page)=>props.updatePageNumber(page)}
                                    updateBase64img={(img)=>null}
                                />
                            </div>
                        </>
                    )}
                    {generating && answer && (
                        <div className='px-2' style={{ flex: 1 }}>
                            <div className="search_msg">
                                <div className="message markdown-content" dangerouslySetInnerHTML={{ __html: answer }} />
                            </div>
                        </div>
                    )}
                    {generating && !answer && (
                        <div className="search_msg mt-2 px-2">
                            <button className="btn border-0 disabled" type="button">
                                <Spinner size="sm" color="primary" /><span className='ps-2'>I'm searching your project...</span>
                            </button>
                        </div>
                    )}
                    {query && answer && !generating && !openPreview &&
                        <div className={`d-flex justify-content-right mt-0 px-2 ${openPreview && 'd-none d-sm-block'}`}>
                            <button className={isUpVote && isUpVote !== null ? `btn-vote-active` : `btn-vote`} onClick={handleUpvote}><FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon></button>
                            <button className={!isUpVote && isUpVote !== null ? `btn-vote-active` : `btn-vote`} onClick={handleDownvote}><FontAwesomeIcon icon={faThumbsDown}></FontAwesomeIcon></button>
                        </div>
                    }
                </div>
                {allRefAnswer &&
                    <>
                        <div className='border-top bg-secondary shadow-sm border-bottom bg-light mt-2 d-none py-2 d-sm-flex align-items-center justify-content-center'>
                            <span className="title-bold px-3 mb-0 pb-0 me-3"><b>References found</b></span>
                            <FormGroup className="d-flex align-items-center justify-content-center noMargin">
                                <Input type="select" bsSize="sm" value={selectedReferenceType} onChange={(e) => changeSelectedReferenceType(parseInt(e.target.value))}>
                                    {(() => {
                                        let docCountsArray = [];
                                        let totalCount = 0;

                                        try {
                                            const json = JSON.parse(allRefAnswer);
                                            delete json['all'];

                                            docCountsArray = [
                                                { key: -1, label: 'All', count: 0 },
                                                { key: 0, label: 'Specifications', count: json['0'] ? Object.keys(json['0']).length : 0 },
                                                { key: 1, label: 'Contracts', count: json['1'] ? Object.keys(json['1']).length : 0 },
                                                { key: 2, label: 'Surveys', count: json['2'] ? Object.keys(json['2']).length : 0 },
                                                { key: 3, label: 'Drawings', count: json['3'] ? Object.keys(json['3']).length : 0 },
                                                { key: 4, label: 'Health & Safety Docs', count: json['4'] ? Object.keys(json['4']).length : 0 },
                                                { key: 6, label: 'Plans & Methods', count: json['6'] ? Object.keys(json['6']).length : 0 },
                                                { key: 5, label: 'Others', count: json['5'] ? Object.keys(json['5']).length : 0 },
                                            ];

                                            totalCount = docCountsArray.slice(1).reduce((sum, { count }) => sum + count, 0);

                                            docCountsArray[0].count = totalCount; // Set count for 'All'
                                        } catch (e) {
                                            // Handle error
                                        }

                                        return docCountsArray.map(({ key, label, count }) => (
                                            <option key={key} value={key} disabled={count === 0}>
                                                {label} ({count})
                                            </option>
                                        ));
                                    })()}
                                </Input>
                            </FormGroup>
                            <a id="minimise_button" className='btn btn-link text-dark ms-auto me-2' onClick={() => setisMinimised(!isMinimised)}>
                                <FontAwesomeIcon icon={faWindowMaximize}></FontAwesomeIcon>
                            </a>
                            <Tooltip isOpen={tooltipOpen2} placement={'bottom'} target="minimise_button" toggle={toggle2}>
                                {isMinimised ?
                                    <span>Open references</span>
                                    :
                                    <span>Minimise references</span>
                                }
                            </Tooltip>
                        </div>
                        <div className={`border-top bg-secondary border-bottom py-3 bg-light mt-2 align-items-center justify-content-between ${typingCivilsGPT ? 'd-none' : 'd-flex d-sm-none'}`}>
                            <div className="d-flex align-items-center">
                                <span className="title-bold px-3 mb-0 pb-0"><b>Answer</b></span>
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        checked={openPreview}
                                        onClick={() => {
                                            updateOpenPreview(!openPreview);
                                        }}
                                        disabled={generating} 
                                        />
                                </FormGroup>
                                <span className="title-bold px-3 mb-0 pb-0"><b>Reference</b></span>
                            </div>
                            <div className="d-flex align-items-center pe-3">
                                {openPreview &&
                                    <a className={`btn btn-sm overview_button border-secondary mb-0 ${!props.fileUrl && 'disabled'}`} onClick={() => handleDownload()}>
                                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                    </a>
                                }
                            </div>
                        </div>
                        <div 
                            className={`px-3 bg-white flex-grow-1 d-flex flex-column position-relative ${(openPreview) && 'd-none d-sm-block'}`}
                            style={{ 
                                flex: 1, 
                                overflowY: 'scroll', 
                                minHeight: isMinimised ? '0dvh' : '20dvh', 
                                maxHeight: isMinimised ? '0dvh' : '20dvh',
                                transition: 'min-height 0.3s, max-height 0.3s' // Optional: for smooth transition
                            }}
                            >
                            {allRefAnswer && (
                                <div className="flex-grow-1 pt-2">
                                    <div>
                                        {allRefAnswer.split('\n\n').map((paragraph, index) => (
                                            <div className="" key={index}>
                                                {all_ref_renderer(paragraph, 0)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                )
                            }
                        </div>
                    </>
                }
            </div>


            <div className='container bg-light px-0 mx-auto px-3 py-2'>
                <div className="d-flex align-items-center">
                    <div className="form-floating text-dark flex-grow-1 me-2">
                        <input 
                            id='question_chat' 
                            autoComplete="off" 
                            onFocus={()=>setTypingCivilsGPT(true)}
                            onBlur={()=>setTypingCivilsGPT(false)}
                            onChange={(e) => handleChangeQuery(e)} 
                            type="text" 
                            value={chatInput}
                            className="form-control gpt_input" 
                            placeholder={"Search for info in all your project docs"} 
                        />
                        <label htmlFor='question_chat' className='text-secondary'>
                            Search for info in all your project docs
                        </label>
                    </div>
                    {(generating || chatInput.length < 10) ? (
                        <button className="btn btn-secondary disabled border-0 btn-lg" disabled type="button">
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>  
                    ) : (
                        <button className="btn btn-primary border-0 btn-lg" type="button" onClick={() => submitMessage(chatInput)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>    
                    )}
                </div>

            </div>
        </>
    )
}

export default SearchAll;