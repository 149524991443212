import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import httpClientPy from "../../utils/httpClientPy";
import Button from "../shared/Button";
import { Card, CardBody, CardHeader } from "reactstrap";
import DataTable from "react-data-table-component";

const AdminUsers = (props) => {
  const navigate = useNavigate();

  const [users, setUsers] = useState(null);
  const [subs, setSubs] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchOptionSelected, setSearchOptionSelected] = useState("email");
  const [searchType, setSearchType] = useState("eq");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    httpClientPy.get(`/user`).then((response) => {
      let userData = response.data;
      if (![12, 19, 22, 50, 51, 30].includes(userData.george_id)) {
        navigate("/");
      }
    });
  }, [navigate]);

  const navigateToEnterprise = () => {
    navigate("/admin/enterprise");
  };

  useEffect(() => {
    httpClientPy.get(`/users`).then((response) => {
      setUsers(response.data.users);
    });
    httpClientPy.get(`/subscriptions?ent=0`).then((response) => {
      setSubs(response.data.subs);
    });
  }, [loading]);

  useEffect(() => {
    if (users) {
      if (searchText === "") {
        setFilteredUsers(users);
      } else {
        handleSearch({ target: { value: searchText } });
      }
    }
  }, [users]);

  const handleSearch = (e) => {
    setLoading(true);

    let searchValue = e.target.value;

    if (searchOptionSelected === "email") {
      if (searchType === "eq") {
        setFilteredUsers(users.filter((user) => user.email === searchValue));
      } else {
        setFilteredUsers(
          users.filter((user) => user.email.includes(searchValue))
        );
      }
    }

    if (searchOptionSelected === "id") {
      if (searchType === "eq") {
        setFilteredUsers(
          users.filter((user) => user.id === parseInt(searchValue))
        );
      } else {
        setFilteredUsers(
          users.filter((user) => user.id.toString().includes(searchValue))
        );
      }
    }

    if (searchOptionSelected === "name") {
      if (searchType === "eq") {
        setFilteredUsers(
          users.filter(
            (user) => user.first_name + " " + user.last_name === searchValue
          )
        );
      } else {
        setFilteredUsers(
          users.filter((user) =>
            (user.first_name + " " + user.last_name).includes(searchValue)
          )
        );
      }
    }

    if (searchOptionSelected === "e_name") {
      if (searchType === "eq") {
        setFilteredUsers(
          users.filter((user) => user.enterprise_name === searchValue)
        );
      } else {
        setFilteredUsers(
          users.filter((user) => user.enterprise_name.includes(searchValue))
        );
      }
    }

    setSearchText(searchValue);

    setLoading(false);
  };

  const handleSubscriptionChange = (id, type) => {
    if (type === "0") {
      type = "1";
    }
    setLoading(true);
    httpClientPy
      .put("/user/update/access", {
        user_id: id,
        type: type,
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateToBasic = (id) => {
    setLoading(true);
    httpClientPy
      .put("/subscriptions/user/basic/update", {
        user_id: id,
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "# ID",
      selector: "id",
      center: true,
      cell: (row) => {
        return <small>{row.id}</small>;
      },
    },
    {
      name: "Email Address",
      selector: "email_address",
      center: true,
      cell: (row) => {
        return <small>{row.email}</small>;
      },
    },
    {
      name: "Name",
      selector: "name",
      center: true,
      cell: (row) => {
        return <small>{row.first_name + " " + row.last_name}</small>;
      },
    },
    {
      name: "Last Login",
      selector: "last_login",
      center: true,
      cell: (row) => {
        return <small>{row.last_login}</small>;
      },
    },
    {
      name: "Joined Date",
      selector: "joined_date",
      center: true,
      cell: (row) => {
        return <small>{row.date_joined}</small>;
      },
    },
    {
      name: "Subscription",
      selector: "update",
      center: true,
      cell: (row) => {
        return (
          <>
            {subs && (
              <select
                name="subscription"
                id="subscription"
                className="form-select"
                onChange={(e) =>
                  handleSubscriptionChange(row.id, e.target.value)
                }
                value={row.subscription_id ? row.subscription_id : 0}
              >
                <option value={0}>None</option>
                {subs.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub.subscription_type} | {sub.subscription_period}
                  </option>
                ))}
              </select>
            )}
          </>
        );
      },
    },
    {
      name: "Is Enterprise",
      selector: "is_enterprise",
      center: true,
      cell: (row) => {
        return <small>{row.is_enterprise === true ? "True" : "False"}</small>;
      },
    },
    {
      name: "Enterprise Name",
      selector: "enterprise_name",
      center: true,
      cell: (row) => {
        return <small>{row.enterprise_name ? row.enterprise_name : ""}</small>;
      },
    },
    {
      name: "Actions",
      selector: "actions",
      center: true,
      cell: (row) => {
        return (
          <>
            {row.is_enterprise === false && row.subscription_id !== 2 ? (
              <Button
                className="btn btn-primary"
                onClick={() => handleUpdateToBasic(row.id)}
                label="Upgrade to Basic"
              ></Button>
            ) : null}
          </>
        );
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "1rem",
        fontWeight: "bold",
        zIndex: 0,
      },
    },
  };

  return (
    <>
      {!loading && filteredUsers ? (
        <Card className="h-100 mt-2 mx-2">
          <CardHeader className="bg-white">
            <div className="row g-0 h-100 px-2">
              <div className="col-12">
                <div className="btn mb-2 btn-primary disabled mt-1">
                  User Management
                </div>
                <div
                  className="btn mb-2 ms-2 btn-primary mt-1"
                  onClick={navigateToEnterprise}
                >
                  Enterprise Management
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row justify-content-end">
              <div className="col-md-2 text-center">
                <select
                  className="form-select"
                  onChange={(e) => setSearchOptionSelected(e.target.value)}
                  value={searchOptionSelected}
                >
                  <option value="email">Email</option>
                  <option value="id">ID</option>
                  <option value="name">Name</option>
                  <option value="e_name">Enterprise Name</option>
                </select>
              </div>
              <div className="col-md-2 text-center">
                <select
                  className="form-select"
                  onChange={(e) => setSearchType(e.target.value)}
                  value={searchType}
                >
                  <option value="eq">Equals</option>
                  <option value="like">Similar</option>
                </select>
              </div>
              <div className="col-md-2 text-center">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={handleSearch}
                  value={searchText}
                />
              </div>
            </div>
            <div className="row g-0 h-100 px-2 mt-2">
              <DataTable
                className="sticky-top bg-white text-center"
                pagination
                searchable
                highlightOnHover
                columns={columns}
                data={filteredUsers}
                customStyles={customStyles}
              />
            </div>
          </CardBody>
        </Card>
      ) : (
        <>
          <div
            className="col-md-12 d-flex justify-content-center flex-column align-items-center"
            style={{ height: "70vh" }}
          >
            <span>
              <span
                className="spinner-border spinner-border-md"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default AdminUsers;
