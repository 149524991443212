import Loader from "../../shared/Loader";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Card, CardBody } from "reactstrap";
import {
  faFileExcel,
  faLocationDot,
  faMapPin,
  faFilePdf,
  faFileCode,
  faEye,
  faMapLocation,
  faDatabase,
  faList,
  faClock,
  faTriangleExclamation,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import httpClientPy from "../../../utils/httpClientPy";
import { toast, ToastContainer } from "react-toastify";
import { capitalize } from "lodash";

library.add(
  faFileExcel,
  faMapPin,
  faLocationDot,
  faFilePdf,
  faMapLocation,
  faFileCode,
  faEye,
  faDatabase,
  faTriangleExclamation,
  faList,
  faClock,
  faCloudArrowUp
);

const OutputListTable = (props) => {
  const [outputs, setOutputs] = useState([]);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(100);
  const [loading, setLoading] = useState(false);
  const [currentSortBy, setCurrentSortBy] = useState("created_at");
  const [currentSortOrder, setCurrentSortOrder] = useState("desc");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const currentPageRef = useRef(currentPage);
  const currentRowsPerPageRef = useRef(currentRowsPerPage);
  const currentSortByRef = useRef(currentSortBy);
  const currentSortOrderRef = useRef(currentSortOrder);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    currentRowsPerPageRef.current = currentRowsPerPage;
  }, [currentRowsPerPage]);

  useEffect(() => {
    currentSortByRef.current = currentSortBy;
  }, [currentSortBy]);

  useEffect(() => {
    currentSortOrderRef.current = currentSortOrder;
  }, [currentSortOrder]);

  const fetchRecords = useCallback(
    async (page, rowsPerPage, sortBy, sortOrder, is_trigger) => {
      let trig = is_trigger ? is_trigger : false;
      setLoading(!trig);

      if (!trig) {
        setOutputs([]);
      }

      return httpClientPy
        .post("/workflow/run/outputs", {
          project_id: props.project.id,
          limit: rowsPerPage,
          offset: (page - 1) * rowsPerPage,
          sort_by: sortBy ? sortBy : currentSortBy,
          sort_order: sortOrder ? sortOrder : currentSortOrder,
          categories_filter: props.categoryFilters.map((cat) => cat.value),
          names_filter: props.namesFilters.map((name) => name.value),
        })
        .then((result) => {
          setOutputs(result.data.outputs);
          setTotalRows(result.data.total_rows);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            "Failed to load the list of workflows, please contact info@civils.ai.",
            { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
          );
        });
    },
    [
      currentSortBy,
      currentSortOrder,
      props.categoryFilters,
      props.namesFilters,
      props.project.id,
    ]
  );

  const handleRowClick = (row) => {
    props.setAction(
      "run",
      row.workflow_id,
      row.run_id,
      row.is_digitisation,
      row.workflow_name
    );
  };

  const triggerDelete = (run_id) => {
    httpClientPy
      .delete(
        `/workflow/run/delete?run_id=${run_id}&project_id=${props.project.id}`
      )
      .then((response) => {
        fetchRecords(
          currentPage,
          currentRowsPerPage,
          currentSortBy,
          currentSortOrder
        );
      })
      .catch((err) => {
        toast.error(
          "Failed to delete the workflow, please contact info@civils.ai.",
          { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
        );
      });
  };

  const handleSort = (column, sortDirection) => {
    fetchRecords(
      currentPage,
      currentRowsPerPage,
      column.id,
      sortDirection,
      false
    );
    setCurrentSortBy(column.id);
    setCurrentSortOrder(sortDirection);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchRecords(page, currentRowsPerPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
    setCurrentRowsPerPage(newRowsPerPage);
    fetchRecords(currentPage, newRowsPerPage);
  };

  useEffect(() => {
    fetchRecords(
      currentPage,
      currentRowsPerPage,
      currentSortBy,
      currentSortOrder
    );
    // Set up interval to fetch records every 1 minute
    const intervalId = setInterval(() => {
      fetchRecords(
        currentPageRef.current,
        currentRowsPerPageRef.current,
        currentSortByRef.current,
        currentSortOrderRef.current,
        true
      );
    }, 30000); // 30 seconds

    // Clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [
    props.project,
    props.categoryFilters,
    props.namesFilters,
    fetchRecords,
    currentPage,
    currentRowsPerPage,
    currentSortBy,
    currentSortOrder,
  ]);

  const columns = [];

  columns.push({
    name: "Execution Time",
    id: "created_at",
    selector: (row) =>
      row.created_at ? new Date(row.created_at) : new Date(0), // Return raw datetime for sorting
    width: "15%",
    center: true,
    sortable: true,
    cell: (row) => (
      <span>
        <span>
          {row.created_at
            ? moment(row.created_at).format("DD MMM YYYY hh:mm:ss A")
            : "Unknown"}
        </span>
      </span>
    ),
  });

  columns.push({
    name: "Output Name",
    selector: (row) => {
      return row.output_name;
    },
    width: "20%",
    center: true,
    sortable: true,
    id: "output_name",
    cell: (row) => {
      return row.output_name;
    },
  });

  columns.push({
    name: "Workflow Name",
    selector: (row) => {
      return row.workflow_name;
    },
    width: "20%",
    center: true,
    sortable: true,
    id: "workflow_name",
    cell: (row) => {
      return row.workflow_name;
    },
  });

  columns.push({
    name: "Category",
    id: "category",
    selector: (row) => row.category_name,
    width: "10%",
    center: true,
    sortable: true,
    cell: (row) => (
      <span
        className="badge rounded-pill darkGrey documentBadge"
        onClick={() =>
          props.setCategoryFilters([
            { label: row.category_name, value: row.category_id },
          ])
        }
        style={{ fontSize: "0.7rem" }}
      >
        <span>
          <i class={`fa-solid ${row.icon} font-primary-darker`}></i>
        </span>
        <span className="ms-1">{row.category_name}</span>
      </span>
    ),
  });

  columns.push({
    name: "Results",
    selector: (row) => {
      return row.num_of_results;
    },
    width: "10%",
    center: true,
    sortable: true,
    id: "num_of_results",
    cell: (row) => {
      return row.num_of_results;
    },
  });

  columns.push({
    name: "Status",
    id: "status",
    width: "15%",
    center: true,
    sortable: true,
    cell: (row) => (
      <>
        <span
          className={`ms-1 badge rounded-pill documentBadge ${row.status === "running" ? "blue" : row.status === "completed" ? "green" : "red"}`}
        >
          <b>{capitalize(row.status)}</b>
        </span>
      </>
    ),
  });

  columns.push({
    name: "",
    width: "5%",
    center: true,
    cell: (row) => {
      return (
        <>
          {row.status !== "running" && (
            <span>
              <DeleteConfirmation
                itemId={row.run_id}
                type="workflow outputs"
                onDeleteConfirm={triggerDelete}
              />
            </span>
          )}
        </>
      );
    },
  });

  const customStyles = {
    headCells: {
      style: {
        fontSize: "1rem",
        fontWeight: "bold",
        zIndex: 0,
      },
    },
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  return (
    <>
      <ToastContainer />
      <div className="">
        {loading && (
          <div className="my-5">
            <Loader loading={loading} />
          </div>
        )}
        {outputs && outputs.length > 0 && (
          <>
            <Card className="" style={{ overflowY: "auto" }}>
              <CardBody className="">
                <div>
                  <DataTable
                    className="sticky-top bg-white text-center geo-records-list-holder"
                    pagination
                    paginationServer={true}
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5, 10, 15, 25]}
                    paginationPerPage={currentRowsPerPage}
                    paginationDefaultPage={currentPage}
                    persistTableHead
                    sortServer
                    onSort={handleSort}
                    highlightOnHover
                    defaultSortFieldId={currentSortBy}
                    defaultSortAsc={currentSortOrder === "asc"}
                    columns={columns}
                    data={outputs}
                    onRowClicked={handleRowClick}
                    customStyles={customStyles}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handlePageChange}
                  />
                </div>
              </CardBody>
            </Card>
          </>
        )}

        {(!outputs || outputs.length === 0) && !loading && (
          <>
            <div className="container">
              <div className="row">
                <div
                  className="col-md-12 d-flex justify-content-center flex-column align-items-center"
                  style={{ height: "50vh" }}
                >
                  <h3>
                    <b>No Workflows Outputs Found</b>
                  </h3>
                  <div className="pt-2">
                    To generate a new workflow output, click on the <b>Run</b>{" "}
                    button on the workflow you want to run.
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OutputListTable;
