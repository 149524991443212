import { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import httpClientPy from "../../../utils/httpClientPy.js";
import { toast } from "react-toastify";

const MultiDownload = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleDownload = async (format) => {

    setDownloading(true);
    
    httpClientPy.post('/workflows/output/export', {
      output_ids: props.selectedItems,
      project_id: props.project.id,
      export_type: format 
    }).then((response) => {
      if (response.data.b64_data) {

        const binaryString = window.atob(response.data.b64_data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = null;
        let fileName = '';

        if (format === 1) {
          blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fileName = `${response.data.output_name}_${new Date().toISOString()}.xlsx`;
        } else if (format === 2) {
          blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          fileName = `${response.data.output_name}_${new Date().toISOString()}.docx`;
        }

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();

      }
    }).catch((err) => {
      toast.error(err.response.data.detail, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
    }).finally(() => {
      setDownloading(false);
    })
  };

  return (
    <>
    <Dropdown isOpen={dropdownOpen} direction="down" className="w-100" toggle={toggleDropdown} onClick={(event) => event.stopPropagation()}>
      <DropdownToggle className="btn btn-outline-dark text-center w-100" tag="div">
        <span>
          <span> 
            &nbsp;Download 
            {props.selectedItems.length>=1 && 
              <span>&nbsp;({props.selectedItems.length})</span>} 
               &nbsp; &nbsp;
              {downloading ? 
                <Spinner size='sm' />
                :
                <FontAwesomeIcon className="icon-sideMenu" icon={faCaretUp}/>
              }
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="project-dropdown-modal" container="body">
        {!downloading ?
        <>
          <DropdownItem className={`btn btn-primary ${props.selectedItems.length < 1 && "disabled" }`} onClick={()=>{handleDownload(1)}}><span>
            <FontAwesomeIcon className="icon-excel" icon={faFileExcel}/>
            <span className="ms-2">Download as Excel</span>
          </span></DropdownItem>
          <DropdownItem className={`btn btn-primary ${props.selectedItems.length < 1 && "disabled" }`} onClick={()=>{handleDownload(2)}}><span>
            <FontAwesomeIcon className="icon-word" icon={faFileWord}/>
            <span className="ms-2">Download as Word Document</span>
          </span></DropdownItem>
        </>
        :
        <>
            <DropdownItem className="btn disabled btn-primary" label={
            <span style={{display: 'inline-flex', alignItems: 'center'}}>
                <Spinner size="sm" className="d-inline"/>
                <span style={{marginLeft: '8px'}}>Downloading</span>
            </span>
            }>
            </DropdownItem>
        </>
        }
      </DropdownMenu>
    </Dropdown>

    </>
  );
};

export default MultiDownload;
