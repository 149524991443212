import { useEffect, useState, useRef} from "react";
import httpClientPy from "../../../utils/httpClientPy.js";
import { Modal, Alert, ModalBody, ModalHeader, ModalFooter, Card, CardHeader, CardBody, CardFooter} from "reactstrap";
import Button from "../../shared/Button"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faPersonDigging, faFilter, faCloudArrowDown, faCircleInfo, faArrowsRotate, faWandMagicSparkles, faFilePdf, faTrash, faCloudArrowUp, faXmark, faUpRightAndDownLeftFromCenter} from "@fortawesome/free-solid-svg-icons";
import BoreholeLogDetails from "../../map/dataTab/BoreholeLogDetails.js";
import BoreHoleLogsList from "../../map/dataTab/BoreHoleLogsList.js";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../SideBar.js";
import localStorageService from "../../../utils/localStorageService.js";
import Select, { components } from 'react-select'
import DownloadDropdown from "./DownloadDropdown.js";
import Loader from "../../shared/Loader.js";
import { MapContainer} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import MapDataPreview from "../../map/MapDataPreview.js";
import DataList from "./DataList.js";


const loadGoogleMapsScript = () =>
  new Promise((resolve, reject) => {
    if (document.getElementById("google-maps-script")) return resolve();
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
    script.onload = () => resolve();
    script.async = true;
    script.id = "google-maps-script";
    document.querySelector("body")?.appendChild(script);
});

library.add(faCopy, faCheck, faCloudArrowDown, faPersonDigging);

const UploadedGeotechList = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  //boreHoleLogs is used to store the list of borehole logs associated to this user
  const [boreHoleLogs, setBoreHoleLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  //modal controls whether the borehole details modal is displayed
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);


  //the following states provide details for the borehole details modal, maybe we can consolidate these into one state variable?
  const [SelectedDataItem, setSelectedDataItem] = useState(null);
  const [clickedRecord, setClickedRecord] = useState(null);
  const [SelectedData, setSelectedData] = useState(null);
  const [SelectedDataStrata, setSelectedDataStrata] = useState(null);
  const [SelectedDataTest, setSelectedDataTest] = useState(null);

  const [downloadSelection, setDownloadSelection] = useState(null);

  //selected items array to keep track of items for downloading
  const [selectedItems, setSelectedItems] = useState([]);

  // variable to store the current project
  const [project, setProject] = useState(null);

  const [geoDataPresent, setGeoDataPresent] = useState(0);
  const [projectNames, setProjectNames] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  // variable to store if user wants all docs or geotech
  const [allDocs, setAllDocs] = useState(1);

  // variable to hold temp of boreholelogs
  const [boreHoleLogsTemp, setBoreHoleLogsTemp] = useState([]);

  const [mapLoading, setMapLoading] = useState(true);


  let styleHolder = [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          lightness: -25,
        },
        {
          saturation: -100,
        },
      ],
    },
  ]

  useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    if(boreHoleLogs.length > 0) {
      setLoading(false);
    }
  }, [boreHoleLogs]);

  const toggle = () => {
    setModal(!modal);
  }

  const loadDataItem = async (item) => {
    item.extraction_requested = true;
    setSelectedData(item)
    setModalLoading(true);
    setModal(true);

    if(item.geotech===1){
      fetchBoreholeData(item.id, props.project.id).then((response) => {
          let data = response.data;
          data.extraction_requested = true;
          
          setSelectedData(data);
          // order strata by id
          let strata_holder = response.data.geo_record_strata
          if(strata_holder[0].top > strata_holder[0].base){
            strata_holder.sort(function(a, b) {
              return parseFloat(b.top) - parseFloat(a.top);
            });
          }
          else{
            strata_holder.sort(function(a, b) {
              return parseFloat(a.top) - parseFloat(b.top);
            });
          }

          setSelectedDataStrata(strata_holder);
          setSelectedDataTest(response.data.geo_record_test);
          setModalLoading(false);
      });
    }
  };


  const fetchBoreholeData = (id, project_id) => {
    return httpClientPy.get(`/geo/record?geo_record_id=${id}&project_id=${project_id}&format=json`).then((response) => {
      return response;
    });
  };


  const navigateToMapWithDetails = () => {
    navigate(`/map`, {state: {project_detail: props.project, workFlowId: props.workFlowId, workFlowRunId: props.workFlowRunId}});
}


  const handleMapReady = () => {
    // Perform any actions you need after the map is loaded
    setMapLoading(false)
  };

  const onDismiss = () => setClickedRecord(null);


  // for geotech
  const handleCheckboxChange = (selected) => {
    setSelectedItems(selected);
  };

  // for geotech
  const downloadSelectionUpdate = (selected) => {

    if(downloadSelection === 'all' && selected === 'all'){
      setDownloadSelection(null);
    }
    else if (downloadSelection === 'visible' && selected === 'visible'){
      setDownloadSelection(null);
    }
    else{
      setDownloadSelection(selected);
    }
  }


  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#800080',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#800080',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const groupedOptions = [
    {
      label: 'Projects',
      options: projectNames,
    },
    {
      label: 'Files',
      options: fileNames,
    },
  ];

  return (
    <>
      {props.project && props.project.id ?
      <>
        <CardBody className="geotechcardbody px-2">
          <div className="row g-0 h-100">
            <div className="col-4 position-relative">
              {boreHoleLogs && boreHoleLogs.length > 0 &&
              <>
                <MapContainer 
                  id="mapHolder" 
                  zoomControl={false} 
                  dragging={false} 
                  whenReady={handleMapReady}
                  style={{height: '70vh', width: '100%', zIndex: 0}}
                >

                  <ReactLeafletGoogleLayer
                    type="roadmap"
                    styles={styleHolder}
                    useGoogMapsLoader={false}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  >
                  </ReactLeafletGoogleLayer>
          
                  <MapDataPreview 
                      projectDetails = {props.project}
                      mapLoading={mapLoading}
                      boreholeLogs={boreHoleLogs}
                      selectedBoreholes={selectedItems}
                      clickedRecord={clickedRecord}
                  />

                </MapContainer>
                <div className={`shadow map-preview-card bg-white rounded p-0`} style={{zIndex: 0}}>
                    <Button
                      label={<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter}/>}
                      className="btn btn-lg btn-outline-dark"
                      onClick={() => navigateToMapWithDetails()}
                    />
                </div>

                <div className={`map-alert-card w-100 rounded px-3`}>
                  <Alert color="warning" isOpen={clickedRecord === 0} toggle={onDismiss} className="text-start w-100 mx-0">
                    <span className="title-bold">Coordinates not found</span><span> - Edit borehole in details and add a location.</span>
                  </Alert>   
                </div>
              </>
              }
            </div>
            <div className={`${boreHoleLogs && boreHoleLogs.length < 1 ? 'col-12' : 'col-8 overflow-auto h-100'}`} style={{zIndex: 0}}>
              <DataList onSearchGeoRecord={(lat, lng, id, type)=>{props.onSearchGeoRecord(lat, lng, id, type);}} 
                            onViewDetails={(item)=>loadDataItem(item)} 
                            boreHoleInfoUpdate={(id) => {props.boreHoleInfoUpdate(id)}}
                            handleCheckboxChange={(selected) => {handleCheckboxChange(selected)}}
                            recordClicked={(record) => {setClickedRecord(record)}}
                            downloadSelection={downloadSelection}
                            project={props.project}
                            type={1}
                            setBoreHoleLogs={(data) => {setBoreHoleLogs(data)}}
                            recordFilters={props.recordFilters}
                            />
            </div>
          </div>
        </CardBody>
        <CardFooter id="footer" className="bg-light d-flex justify-content-end mt-4">
          <div className="row w-100 g-0">
            <div className="col-6 text-start"></div>
            <div className="col-6 text-end">
                <div className="btn-group" role="group" aria-label="Download options">
                  <Button className={`btn btn-outline-dark me-1`} onClick={()=>downloadSelectionUpdate('visible')} label={downloadSelection === 'visible' ? <span>Deselect visible</span> : <span>Select visible</span>} />
                  <Button className="btn btn-outline-dark me-1" onClick={()=>downloadSelectionUpdate('all')} label={downloadSelection === 'all' ? <span>Deselect all</span> : <span>Select all</span>}/>
                  {/* Ensure DownloadDropdown button styling matches other buttons in the group */}
                  <DownloadDropdown selectedItems={selectedItems} project={props.project} />
                </div>
            </div>
          </div>
        </CardFooter>
      </>
      :
        <div className="container">
          <div className="row">
            <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                <h3><b>You've not uploaded any documents of this type yet</b></h3>
            </div>
          </div>
        </div>
      }
      {props.project && props.project.id &&
        <Modal id="detailsModal" centered={true} fullscreen={true} isOpen={modal} toggle={()=>toggle()}>
          {SelectedData && 
            <BoreholeLogDetails data={SelectedData} 
                                onSearchGeoRecord={(lat, lng, id, type)=>{props.onSearchGeoRecord(lat, lng, id, type);}}
                                strata={SelectedDataStrata} 
                                tests={SelectedDataTest} 
                                project={props.project}
                                boreHoleInfoUpdate={(id) => {props.boreHoleInfoUpdate(id)}}
                                projectId={props.project ? props.project.id : null}
                                toggleModal={()=>toggle()}
            />
          }
        </Modal>
      }
    </>
  );
};

export default UploadedGeotechList;





const ValueContainer = ({ children, ...props }) => {
  if (children[0] !== null) {
    const length = children[0].length
    let tmpChildren = [...children];
    if(length >= 2){
      tmpChildren[0] = `${length} options selected`
    }

    return (
      <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
    );
  } else {
    return (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  }
};