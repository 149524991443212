import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import httpClientPy from "../../../utils/httpClientPy";
import { logEvent } from '../../shared/Mixpanel';

const NewProjectModal = (props) => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!props.project) {
            skipDrawArea();
        }
    }, []);


    const skipDrawArea = () => {
        const imgData = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP48fMXAAXYAuz7Q/K/AAAAAElFTkSuQmCC';

        if (props.project) {
            if (props.project.project_type===2) {
                props.toggleModal();
            }
            else{
                // update the project type to 2
                setLoading(true);
                const url = '/project/update';
                const body = {
                    project_id: props.project.id,
                    update_type: "project_type",
                    project_type: 2
                }
                httpClientPy
                .post(url, body)
                .then((response) => {
                    // console.log(response.data);
                    setLoading(false);
                    if(props.getProjectData) {
                        props.getProjectData();
                    }
                    props.toggleModal();
                }).catch((error) => {
                    props.updatePolygonState({current_step: 6, current_text: error.response.data.detail, current_text_add: "", reset: false})
                    setLoading(false);
                });
            }
        }
        else {
            setLoading(true);
            const url = '/project/create';
            const body = {}
            body['coords'] = [
                {
                    "lat": 55.55349545845371,
                    "lng": -4.855957031250001
                },
                {
                    "lat": 55.55349545845371,
                    "lng": 10.964355468750002
                },
                {
                    "lat": 50.90996067566236,
                    "lng": 10.964355468750002
                },
                {
                    "lat": 50.90996067566236,
                    "lng": -4.855957031250001
                }
            ]
            body['image_base64'] = imgData;
            body['project_type'] = 2;

            // send request to the API to create the project
            httpClientPy
            .post(url, body)
            .then((response) => {
                logEvent('Create Project', {})
                navigate('/project', { state: {id: response.data.project_id} });
                setLoading(false);
                if(props.getProjectData) {
                    props.getProjectData();
                }
                props.toggleModal();
            }).catch((error) => {
                console.log(error)
                setLoading(false);
            });
        }
    }



    return (
        <>
            <ModalHeader className="border-0 mb-0 pb-0" toggle={()=>props.toggleModal()}>
                <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
                    {props.title}
                </h4>
            </ModalHeader>
            <ModalBody>
                {props.project && (props.project_type===0 || props.project_type===1) ?
                    'Would you like to update the project location?'
                    :
                    <div className='w-100 pt-3 text-center'>
                        {loading && <><Spinner size="lg"/>&nbsp;</> }
                    </div>
                }
            </ModalBody>
            <ModalFooter className="border-0 justify-content-center">
                    {props.project &&
                        <>
                        <Button disabled={loading} color="primary" onClick={()=>props.handleChoiceClick(0)}>Yes</Button>{' '}
                        {props.project.project_type===2 ?
                            <Button disabled={loading} outline color="secondary" onClick={()=>skipDrawArea()}>
                                {loading && <><Spinner size="sm"/>&nbsp;</> }
                                Cancel
                            </Button>
                            :
                            <Button disabled={loading} outline color="danger" onClick={()=>skipDrawArea()}>
                                {loading && <><Spinner size="sm"/>&nbsp;</> }
                                Delete project area
                            </Button>
                        }
                        </>
                    }
            </ModalFooter>
        </>
    )   
}

export default NewProjectModal;