import {useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpClientPy from "../../../utils/httpClientPy";
import { Spinner } from "reactstrap";
import SideBar from "../../project/SideBar";
import WorkFlow from "./WorkFlow";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkFlowListCards from "./WorkFlowListCards";

const WorkFlowList = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState(null);
    const [projectExtraDetails, setProjectExtraDetails] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [workflowAction, setWorkflowAction] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [workflowRunId, setWorkflowRunId] = useState(null);
    const [selectedBtn, setSelectedBtn] = useState('editor');
    const [digitiseFlow, setDigitiseFlow] = useState(null);
    const [flowName, setFlowName] = useState(null);
    const [sourcePage, setSourcePage] = useState('templates');

    const getProjectData = (id) => {
        httpClientPy.get(`/project/${id}`)
          .then((response) => {
            setProject(response.data.project);
            setProjectExtraDetails(response.data.extra_data);
            setLoading(false);
          })
          .catch((err) => {
            setError(true);
            setErrorMessage(err.response.data.detail);
            setLoading(false);
          }
        );
    }

    const getWorkflowMeta = async (project_id) => {
        try {
            const response = await httpClientPy.get(`/workflows/filters/categories?project_id=${project_id}`);
            setCategories(response.data.categories);
        } catch (err) {
            setError(true);
            setErrorMessage(err.response.data.detail);
            setLoading(false);
        }
    }

    const handleCategoryClick = (id) => {
        setSelectedCategory(id);
    }

    const handleWorkflowClick = (action, workflow_id, run_id) => {
        if (action === 'run') {
            setWorkflowId(workflow_id);
            setWorkflowRunId(run_id);
        } else {
            setWorkflowId(workflow_id);
            setWorkflowRunId(run_id);
        }
        setWorkflowAction(action);
    }

    const closeCanvas = () => {
        if (sourcePage === 'templates') {
            handleWorkflowClick(null, null, null)
            setSelectedBtn('editor'); 
            navigate(location.pathname, { state: {project_id: project.id} });
        } else if (sourcePage === 'outputs') {
            navigate('/project/workflows/outputs', { state: {project_id: project.id} });
        } else if (sourcePage === 'project_detail') {
            navigate('/project', { state: {id: project.id} });
        }
        localStorage.removeItem('is_unsaved')
    }
    
    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            let userList = response.data
            if (userList.is_enterprise) {
                userList.allow_digitise = userList.enteprise_is_digitiser
                userList.allow_civilsgpt = userList.enterprise_is_civilsgpt
                userList.allow_drawing = userList.enterprise_is_drawing
                userList.allow_workflow = userList.enterprise_is_workflow
            } else {
                userList.allow_digitise = userList.subscription_details ?  userList.subscription_details.is_digitise : false
                userList.allow_civilsgpt = userList.subscription_details ? userList.subscription_details.is_civilsgpt : false
                userList.allow_drawing = userList.subscription_details ? userList.subscription_details.is_drawing : false
                userList.allow_workflow = userList.subscription_details ? userList.subscription_details.is_workflow : false
            }
            setUserInfo(userList)
            setAdmin(userList.enterprise_admin)
            setSuperAdmin(userList.admin)
        }).catch((err) => {
            setError(true);
            setErrorMessage(err.response.data.detail);
            setLoading(false);
        });
        localStorage.removeItem('is_unsaved')
    }, [])

    useEffect(() => {
    
        if (location.state === null) {

            setError(true);
            setErrorMessage("Project not found");
            setLoading(false);

        } else {
            
            getProjectData(location.state.project_id);

            if (location.state.action !== undefined) {

                setDigitiseFlow(location.state.is_digitise);
                setFlowName(location.state.name);
                setSelectedBtn('outputs')
                setSourcePage(location.state.source_page);
                handleWorkflowClick(location.state.action, location.state.workflow_id, location.state.run_id);

                if (location.state.workFlowId !== undefined) {
                    setWorkflowId(location.state.workFlowId);
                }

                if (location.state.workFlowRunId !== undefined) {
                    setWorkflowRunId(location.state.workFlowRunId);
                }
                
            }

        }
    }, []);        

    return (
        <>
            {!loading ? (
                !error ? (
                    <>  
                        {workflowAction === null ? (
                            <>
                                <div className="d-flex">
                                    <div id="sidebarParent" className='d-none d-md-block' style={{minWidth:'190px'}}>
                                        <SideBar page={'detail'} 
                                                loading={loading}
                                                subpage={'workflows'} 
                                                type={null} 
                                                project={project} 
                                                chatEnabled={false}
                                                userInfo={userInfo}
                                                admin={admin}
                                                closeChat = {() => {}}
                                                setSubpage={() => {}}
                                                setType={() => {}}
                                                projectExtraDetails={projectExtraDetails}
                                                onComplete={() => getProjectData(project.id)} />
                                    </div>
                                    <div  className="flex-fill px-3 pe-lg-0 py-3" style={{maxWidth:'100vw'}}>
                                        <div>
                                            <div className='container mt-0 mt-lg-2' >
                                                <h3 className='title-bold mb-0'>Select a workflow template</h3>
                                                <div className="pt-1" style={{animation: 'fadeIn 1s'}} >
                                                    <div className="d-flex flex-nowrap overflow-auto mx-0">
                                                        {categories.length > 0 && (
                                                            <>
                                                            {categories.map((category, index) => (

                                                                <div className={`card cs flex-shrink-0 ${selectedCategory === category.id ? 'shadow-sm border-dark bg-white' : 'bg-light' } me-3 my-3`} 
                                                                     style={{"width": '10rem', "cursor": 'pointer'}}
                                                                     onClick={() => handleCategoryClick(category.id)} key={index}>
                                                                    <div className="card-body d-flex flex-column justify-content-between">
                                                                        <div className="text-end">
                                                                            <i className={`fa-solid ${category.icon} fs-5 font-primary-darker`}></i>
                                                                        </div>
                                                                        <div>
                                                                            <h5 className="title-bold">{category.name}</h5>
                                                                            <p className="text-muted pb-0 mb-1"><small>{category.num_of_workflows} flow(s)</small></p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ))}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='text-end pe-4'>
                                                        <a className="btn btn-primary" onClick={() => handleWorkflowClick('create', -1, -1)}>
                                                            <FontAwesomeIcon className="me-2" icon={faPlus}/>
                                                            <span>Custom Workflow</span>
                                                        </a>
                                                </div>
                                                <div className="row py-1 g-1" style={{animation: 'fadeIn 1s'}}>
                                                    <WorkFlowListCards  setAction={(action, id, run_id) => handleWorkflowClick(action, id, run_id)}
                                                                        superAdmin={superAdmin}
                                                                        project={project}
                                                                        action={workflowAction}
                                                                        selectedCategory={selectedCategory}
                                                                        handleCategoryClick={handleCategoryClick}
                                                                        getWorkflowMeta={getWorkflowMeta}
                                                    />
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <WorkFlow project = {project}
                                      extraDetails = {projectExtraDetails}
                                      workFlowId = {workflowId}
                                      workFlowRunId = {workflowRunId}
                                      action={workflowAction}
                                      setAction={(action, id, run_id) => handleWorkflowClick(action, id, run_id)}
                                      defaultAction ={() => closeCanvas()}
                                      superAdmin={superAdmin}
                                      selectedBtn={selectedBtn}
                                      setSelectedBtn={setSelectedBtn}
                                      digitiseFlow={digitiseFlow}
                                      workflowName={flowName}
                                      setDigitiseFlow={setDigitiseFlow}
                                      setWorkflowName={setFlowName}
                            />
                        )}
                    </>
                ) : (
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                        <p>{errorMessage}</p>
                    </div>
                )
            ) : (
                <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                    <Spinner color='primary' />
                </div>
            )}
        </>
    );
}

export default WorkFlowList;
