import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../shared/Button";
import httpClientPy from "../../utils/httpClientPy";
import { toast, ToastContainer } from "react-toastify";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategoryEditor = (props) => {
  const [name, setName] = useState("");
  const [color, setColor] = useState("#000000");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.task === 1 && props.category !== null) {
      setName(props.category.name);
      setColor(props.category.color);
    } else {
      setName("");
      setColor("#000000");
    }
  }, [props.task, props.category]);

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const handleSubmitButton = () => {
    setLoading(true);
    let url =
      props.task === 0 ? "/library/category/add" : "/library/category/update";
    let data = {
      category_id: props.task === 0 ? null : props.category.id,
      category_name: name,
      category_color: color,
    };

    httpClientPy
      .post(url, data)
      .then((response) => {
        setLoading(false);
        props.toggle();
      })
      .catch((error) => {
        let error_code = error.response.data.error_code;
        if (error_code === 4) {
          toast.error(
            "Document limit has been exceeded, contact your admin in order to check limit balance.",
            { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
          );
        } else if (error_code === 3) {
          toast.error("User is not part of enterprise account.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else if (error_code === 5) {
          toast.error(
            "Categoy does not exist or user is not part of the enterprise.",
            { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
          );
        } else {
          toast.error(
            "An error occurred while performing process. Please contact info@civils.ai",
            { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
          );
        }
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <Modal isOpen={props.isOpen} toggle={props.toggle} size="l">
        <ModalHeader
          className="title-bold border-0 pb-0 mb-0"
          close={
            <Button
              label={
                <>
                  <FontAwesomeIcon icon={faX} size="sm" />
                </>
              }
              className="btn btn-sm btn-danger ms-1 me-2"
              onClick={() => props.toggle()}
            ></Button>
          }
        >
          {props.task === 0 ? "New Category" : "Edit Category"}
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label htmlFor="CategoryName">Name</label>
              <input
                type="text"
                className="form-control"
                id="CategoryName"
                aria-describedby="CategoryNameHelp"
                placeholder="Enter category name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <small id="CategoryNameHelp" className="form-text text-muted">
                This is the name of the category that will be created.
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="CategoryColor">Color</label>
              <div className="d-flex justify-content-start">
                <HexColorPicker color={color} onChange={setColor} />
                <div className="ms-2 d-flex align-items-end">
                  <div
                    className="circle me-2 my-1"
                    style={{ backgroundColor: color }}
                  ></div>
                  <input
                    type="text"
                    className="form-control"
                    id="CategoryColor"
                    aria-describedby="CategoryColorHelp"
                    placeholder="Enter category color"
                    required
                    value={color}
                    onChange={handleColorChange}
                    style={{ width: "150px" }} // Adjust the width as needed
                  />
                </div>
              </div>
              <small id="CategoryColorHelp" className="form-text text-muted">
                Select the color of the category.
              </small>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={props.toggle}
              >
                Cancel
              </button>
              <Button
                type="submit"
                className="btn btn-primary"
                label={props.task === 0 ? "Create Category" : "Update Category"}
                loading={loading}
                onClick={handleSubmitButton}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CategoryEditor;
