import React, { useState, useEffect} from "react";
import DataTable from 'react-data-table-component';
import httpClientPy from "../../utils/httpClientPy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faLink, faUserMinus, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import Button from "../shared/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader, Toast, ToastHeader, ToastBody, Card, CardBody, CardHeader} from "reactstrap";
import {useNavigate} from "react-router-dom";

const PartnerAdmin = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [userList, setUserList] = useState([]);
    const [addUserModal, setAddUserModal] = useState(false);
    const [emailAddressRows, setEmailAddressRows] = useState([]);
    const [emailIdCounter, setEmailIdCounter] = useState(1);
    const [errorValidation, setErrorValidation] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [inviteLink, setInviteLink] = useState(null);
    const [copyStatus, setCopyStatus] = useState(false);
    
    useEffect(() => {
        httpClientPy.get(`/user`).then((response) => {
            if(response.data.enterprise_admin === false)
            {
                navigate('/');
            }
            setUserInfo(response.data);
        });
    }, []);

    useEffect(() => {

        if(userInfo.enterprise_id === undefined) {
            return;
        }

        httpClientPy.get(`/partner/admin/users?enterprise_id=${userInfo.enterprise_id}`).then((response) => {
            setUserList(response.data.users);
            setInviteLink(response.data.link)
        });

    }, [userInfo, loading]);

    const addEmailAddressRow = () => {
        setErrorValidation(false);
        setLoading(false);
        // Create a new row object with a unique ID and blank email
        const newEmailAddressRow = {
            id: emailIdCounter + 1,
            email: ''
        };
        
        // Increment the ID counter.
        setEmailIdCounter(emailIdCounter + 1);
        
        // Use the setRows function to add the new row to the state.
        setEmailAddressRows([...emailAddressRows, newEmailAddressRow]);
    };

    const updateEmailAddressRow = (event, id, field) => {
        // Use the setRows function to create a new array of rows with the updated value.
        let inputValue = event.target.value
        setErrorValidation(false);
        setLoading(false);
        setEmailAddressRows (
            emailAddressRows.map((row) => {
            if (row.id === id) {
                return {
                    ...row,
                    [field]: inputValue,
                };
            }
            return row;
            })
        );
    };

    const removeEmailAddressRow = (id) => {
        // Use the setRows function to filter out the row with the specified ID.
        setEmailAddressRows(emailAddressRows.filter((row) => row.id !== id));
        setErrorValidation(false);
        setLoading(false);
    };

    const toggleAddUserModal = () => {
        setAddUserModal(!addUserModal)
    };

    const submitAddUserModal = () => {
        setLoading(true);
        let emailRows = emailAddressRows.map((row) => {
            return {
                email: row.email,
                first_name: row.first_name,
                last_name: row.last_name
            }
        });

        httpClientPy.post('/partner/admin/create', {
            enterprise_id: userInfo.enterprise_id,
            details: emailRows
        }).then((response) => {
            if(response.status === 200) {
                setEmailAddressRows([]);
                setLoading(false);
                toggleAddUserModal();
            }
        }).catch((error) => {
            // console.log(error.response.data.detail[0]);
            setError(true);
            setErrorCode(error.response.data.detail[0]);
            setLoading(false);
        });
    };      

    const submitAccessChange = (id) => {
        setLoading(true);
        httpClientPy.put('/partner/admin/update', {
            enterprise_id: userInfo.enterprise_id,
            user_id: id
        }).then((response) => {
            if(response.status === 200) {
                setLoading(false);
            }
        }).catch((error) => {
            // console.log(error);
            setError(true);
            setLoading(false);
        });
    };

    const handleCopyInviteLink = () => {
        navigator.clipboard.writeText(inviteLink).then(() => {
            setCopyStatus(true)
            // set a timeout before setting the copy status back to false
            setTimeout(() => {
                setCopyStatus(false)
            }
            , 3000);
        }).catch(err => {
            setCopyStatus(false)
        });
    };

    useEffect(() => {
        if(error) {
            setTimeout(() => {
                setError(false);
            } , 3000);
        }
    }, [error]);


    const columns = [
        {
          name: 'Email Address',
          selector: 'email_address',
          center: true,
          cell: (row) => {
            return <small>{row.email}</small>;
          },
        },
        {
            name: 'Name',
            selector: 'name',
            center: true,
            cell: (row) => {
              return <small>{row.name}</small>;
            },
          },
        {
          name: 'Last Updated Time',
          selector: 'last_updated_time',
          center: true,
          cell: (row) => {
            return <small>{row.updated_at}</small>;
          },
        },
        {
          name: 'Total Document Uploaded',
          selector: 'total_bh_digitised',
          center: true,
          cell: (row) => (
            <div className="btn">
                <small>{row.total_digitised}</small>
            </div>
          ),
        },
        {
            name: 'Total Queries',
            selector: 'total_queries',
            center: true,
            cell: (row) => (
              <div className="btn">
                  <small>{row.total_queries}</small>
              </div>
            ),
        },
        {
            name: 'Is Admin',
            selector: 'is_admin',
            center: true,
            cell: (row) => (
              <div className="btn">
                  <small>{row.is_admin === true ? "Yes": "No"}</small>
              </div>
            ),
        },
        {
          name: 'Status',
          selector: 'status',
          center: true,
          cell: (row) => {
                if (row.status.toLowerCase() === 'active') {
                    return (
                        <span className={`badge rounded-pill green `}>
                        Active
                        </span>
                    );
                } else {
                    return (
                        <span className={`badge rounded-pill red `}>
                        InActive
                        </span>
                    );
                }
            }
        },
        {
          name: 'Actions',
          selector: 'actions',
          center: true,
          cell: (row) => {
            return (
              <>
                {row.id != userInfo.george_id ? (
                    <>
                    {row.status.toLowerCase() === "active" ? (
                    <Button
                        label={<FontAwesomeIcon className="fa-solid fa-location-crosshairs" icon={faUserMinus}/>}
                        className="btn icon-view"
                        onClick={() => submitAccessChange(row.id)}
                    />
                    ) : (
                    <span>
                        <Button
                        label={<FontAwesomeIcon className="fa-solid fa-location-crosshairs" icon={faUserPlus}/>}
                        className="btn icon-view"
                        onClick={() => submitAccessChange(row.id)}
                        />
                    </span>
                    )}
                    </>
                ) : (
                    null
                )}
              </>
            );
          },
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
                zIndex: 0,
            },
        },
    };

    return (
        <>
        <div className='row justify-content-start mt-2 mb-2 mx-1'>
            <div className='col-md-2'>
                <Button
                    type="button"
                    onClick={()=>navigate('/')}
                    label= "To Projects"
                    className="w-50 btn btn-primary"
                    loading={loading}
                />
            </div>
        </div>
        <Card className="h-100 mt-2 mx-2">
            <CardHeader className="bg-white">
                <div className="row g-0 h-100 px-2">
                    <div className="col-12">
                        <h3 className="mb-2 mt-1">User Management</h3>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <div className='row justify-content-end'>
                    <div className='col-md-2 text-center'>
                        <Button
                            type="button"
                            onClick={()=>toggleAddUserModal()}
                            label= "Add User"
                            className="w-50 btn btn-primary"
                            loading={loading}
                        />
                    </div>
                </div>
                <div className="row g-0 h-100 px-2">
                    <DataTable
                        className="sticky-top bg-white text-center"
                        pagination
                        searchable
                        highlightOnHover
                        columns={columns}
                        data={userList}
                        customStyles={customStyles}

                    />
                </div>
            </CardBody>
        </Card>
        <Modal className="mt-5" id="addUser" size="lg" isOpen={addUserModal} toggle={()=>setAddUserModal(!addUserModal)} centered={true}>
            <ModalHeader toggle={()=>setAddUserModal(!addUserModal)}>Add User</ModalHeader>
            <ModalBody>
                <table className="table">
                    <thead>
                    <tr>
                        <th className="col-4">Email Address</th>
                        <th className="col-4">First Name</th>
                        <th className="col-4">Last Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {emailAddressRows.map((row) => (
                        <tr key={row.id}>
                            <td>
                                <div className="input-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    required
                                    value={row.email}
                                    onChange={(event) => updateEmailAddressRow(event, row.id, 'email')}
                                />
                                </div>
                            </td>
                            <td>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    value={row.first_name}
                                    onChange={(event) => updateEmailAddressRow(event, row.id, 'first_name')}
                                />
                                </div>
                            </td>
                            <td>
                                <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    value={row.last_name}
                                    onChange={(event) => updateEmailAddressRow(event, row.id, 'last_name')}
                                />
                                </div>
                            </td>
                            <td>
                                <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => removeEmailAddressRow(row.id)}
                                >
                                Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button type="button" className="btn btn-success" onClick={addEmailAddressRow}>
                    Add
                </button>
                { error &&
                <div className="toast-container position-fixed bottom-0 end-0 p-3 d-none d-sm-block">
                    <Toast className="mx-auto">
                        <ToastHeader>    
                        <div className="title-civils">            
                        <img
                        src="/assets/george_icon.svg"
                        alt="Digitise your geological records automatically"
                        className="d-inline-block georgeIcon primary"
                    />
                    <span>&nbsp;We encountered error while creating the records</span></div></ToastHeader>
                    {errorCode === 2 ? (
                        <ToastBody>User that you are adding is already belonging to other enterprise plan. If the problem still presist please contact us at info@civils.ai</ToastBody>
                    ) : (
                        <ToastBody>Please try again creating the record, do make sure all the information is correct and complete. If the problem still presist please contact us at info@civils.ai</ToastBody>
                    )}
                    </Toast>
                </div>
                }
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-between w-100">
                    <Button
                        type="button"
                        onClick={()=>handleCopyInviteLink()}
                        label={<><FontAwesomeIcon icon={copyStatus ? faCheck :faLink} size="sm" /><span className="ms-2">Copy invite link</span></>} 
                        className="w-20 btn btn-light border"
                        loading={loading}
                    />
                    <Button
                        type="button"
                        onClick={()=>submitAddUserModal()}
                        label= "Submit"
                        className="w-20 btn btn-primary"
                        loading={loading}
                    />
                </div>
            </ModalFooter>
        </Modal>
        </>
    );

};

export default PartnerAdmin;