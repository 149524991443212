import { useCallback, useEffect, useState } from 'react';
import { Handle, Position, useReactFlow } from '@xyflow/react';
import { Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'; // Import faTrash

const BaseCard = (props) => {
    
    const reactFlowInstance = useReactFlow();

    const handleRemove = useCallback(() => {
        reactFlowInstance.setNodes((nds) => nds.filter(node => node.id !== props.id));
        reactFlowInstance.setEdges((eds) => eds.filter(edge => edge.source !== props.id && edge.target !== props.id));
    }, [reactFlowInstance, props.id]);

    const isAllNodesRunning = (nodes) => {
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].data.is_running) {
                return true;
            }
        }
        return false;
    }

    const onHover = (id) => {
        // set all the edges to have a data key of 'is_hovered' to true
        reactFlowInstance.setEdges((edges) => edges.map(edge => {
            if (edge.source === id || edge.target === id) {
                return {
                    ...edge,
                    data: { ...edge.data, is_hovered: true },
                };
            }
            return edge;
        }));
    }

    const onLeave = () => {
        // set all the edges and remove the data key 'is_hovered'
        reactFlowInstance.setEdges((edges) => edges.map(edge => {
            const { is_hovered, ...data } = edge.data;
            return {
                ...edge,
                data: data,
            }
        }));
    }

    useEffect(() => {
        let state = isAllNodesRunning(reactFlowInstance.getNodes());
        props.setAreNodesRunning(state);

    }, [reactFlowInstance.getNodes()]);


    return (
        <>
            <Card className={`nodes_card ${props.data.is_error ? 'error' : ''}`} 
                  style={{ width: props.data.width }}
                  onMouseEnter={() => onHover(props.id)}
                  onMouseLeave={onLeave}
            >

                {props.data.node !== 'selector' &&
                <>
                    <Handle type="target" 
                            position={Position.Left} 
                            style={{ width: '10px', height: '10px' }} />
                </>
                }
                <div className='m-2'>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                        <span>{props.data.title}</span>
                        {props.data.is_running ? (
                            <Spinner animation="border" variant="primary" size="sm" />
                        ) : props.data.is_complete && props.areNodesRunning ? (
                            <FontAwesomeIcon 
                                icon={faCheckCircle} 
                                className='text-success fa-bounce'
                                style={{ animationIterationCount: 1 }}
                            />
                        ) : props.data.is_fail && props.areNodesRunning ? (
                            <FontAwesomeIcon 
                                icon={faXmarkCircle} 
                                className='text-danger fa-bounce'
                                style={{ animationIterationCount: 1 }}
                            />
                        ) : (
                            <>
                                {props.data.node !== 'selector' && 
                                 props.data.node !== 'output-main' && 
                                 !props.isDigitsationWf &&
                                 !props.areNodesRunning &&

                                    <FontAwesomeIcon 
                                        icon={faTrash} 
                                        className='cursor-pointer text-danger' 
                                        onClick={handleRemove} 
                                    />

                                }
                            </>
                        )}
                    </div>
                    {props.children}
                </div>
                {props.data.node !== 'output-main' &&
                    <Handle type="source" 
                            position={Position.Right}
                            style={{ width: '10px', height: '10px' }} />
                }
            </Card>
        </>
    );
}

export default BaseCard;