import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody } from "reactstrap";
import SearchUploader from "./uploaders/SearchUploader";
import GeotechUploader from "./uploaders/GeotechUploader";
import GenericUploader from "./uploaders/GenericUploader";

const Uploader = (props) => {
  const [uploadType, setUploadType] = useState(props.initialUploadType);
  const navigate = useNavigate();

  const navigateToProjectData = (type) => {
    navigate(`/project/data`, { state: { project: props.project, type: type } });
  };

  const handleSelectChange = (e) => {
    let selectedId = e.target.value;
    if (selectedId !== null) {
      selectedId = parseInt(selectedId, 10);
    }
    setUploadType(selectedId);
  };

  return (
    <>
      <Card className="w-100 d-flex flex-grow-1 flex-column">
        <CardHeader className="bg-light px-2 automationbutton">
          <span className="me-3">Document type you want to upload</span>
          <select
            className="form-select d-inline"
            value={uploadType ?? ""} // Ensure the select value is bound to uploadType
            onChange={handleSelectChange}
            style={{ width: "auto", minWidth: "200px" }} // Adjust minWidth as needed
            aria-label="Default select example"
          >
            <option value="0">Specifications</option>
            <option value="1">Contracts</option>
            <option value="2">Site Investigation</option>
            <option value="3">Drawings</option>
            <option value="4">Health and Safety</option>
            <option value="6">Plans & Methods</option>
            <option value="5">Others</option>
          </select>
        </CardHeader>
        <CardBody className={`show bgworkflow overflow-auto`} id="automation1">
          <GenericUploader {...props} projectId={props.project.id} mode={uploadType} />
        </CardBody>
      </Card>
    </>
  );
};

export default Uploader;
