import React from "react";

const Button = ({ loading = false, label, ...props }) => {
  return (
    <button {...props} disabled={props.disabled || loading}>
      {loading ? (
        <span>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Loading...</span>
        </span>
      ) : (
        <span>{label}</span>
      )}
    </button>
  );
};

export default Button;
