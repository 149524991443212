import { useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {faLocationCrosshairs, faMountain, faPersonDigging, faLocationDot, faCube, faPlus, faClockRotateLeft, faMound, faCloudArrowDown, faEye, faFileExport} from "@fortawesome/free-solid-svg-icons";
import { Table, Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import L from "leaflet";
import Loader from "../../shared/Loader";
import Model from "./ModelCanvas";
import Shrinkable from "../../shared/textHolders/Shrinkable.js";
import {estimateVirtualBorehole, findMaterial } from "../../shared/algorithms/geoRecordAlgorithms.js";
import { findBoreholeColor } from "../dataTab/findBoreholeColor";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import httpClientPy from "../../../utils/httpClientPy";
import Select from 'react-select'
import { logEvent } from "../../shared/Mixpanel.js";
var max_water = 0
var min_water = 10000

library.add(faMountain, faPersonDigging, faLocationCrosshairs, faLocationDot, faCube, faPlus, faClockRotateLeft, faMound);
var materials_visibility =[]

const boreholeBlack = new L.Icon({
    iconUrl: "assets/borehole-black.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 16], // Adjust these values
    popupAnchor: [16, 16], // Adjust these values
  });
  
  const boreholeGreen = new L.Icon({
    iconUrl: "assets/borehole-green.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 16], // Adjust these values
    popupAnchor: [16, 16], // Adjust these values
  });
  

const Modelling = (props) => {

    // hold the project_id
    const project_id = props.projectId;

    //boreholeData sets and resets the borehole data to be displayed in the 3D 'model' component
    const [boreholeData, setBoreholeData] = useState([])

    const [materialList, setMaterialList] = useState([])

    const [inputValue, setInputValue] = useState('')
    const [scale, setScale] = useState(1)
    const [topography, setTopography] = useState(false)

    const [allowResetModel, setAllowResetModel] = useState(false)

    const [displaySettings, setDisplaySettings] = useState(false)
    const [groundwater, setGroundwater] = useState(false)
    const [sensitivity, setSensitivity] = useState(1)


    //virtualBoreholeLoading displays a spinner for the table, is it based on a timer as I was having issues with components calling themselves and renrendering otherwise - it's a hack
    const [virtualBoreholeLoading, setVirtualBoreholeLoading] = useState(false);
    //boreholeSelection controls which borehole details are being displayed in the table within this component
    const [boreholeSelection, setBoreholeSelection] = useState();

    //boreholeSelection controls which borehole details are being displayed in the table within this component
    const [addNewOption, setAddNewOption] = useState({
        item:null,
        tableIdentifier: null
    });

    //modal display state
    const [modalDisplay, setModalDisplay] = useState(true);

    const [virtualBoreholeDisplay, setVirtualBoreholeDisplay] = useState(false);

    const [displayDimensions, setDisplayDimensions] = useState(false);
    const [displayMap, setDisplayMap] = useState(false);

    const [displayInterpolation, setDisplayInterpolation] = useState(false);

    const [downloadingDXF, setDownloadingDXF] = useState(false);

    const [boreholeName, setBoreholeName] = useState(false);

    const [bhList, setBhList] = useState([]);

    const max_depth = useRef(0);
    const max_ground_level= useRef(-100000);

    const mapRef = useRef();

    let max_lat = 0; // Your max latitude value
    let min_lat = 0; // Your min latitude value
    let max_lng = 0; // Your max longitude value
    let min_lng = 0; // Your min longitude value



    if(boreholeData.virtual && boreholeData.virtual.max_lat){
        max_lat = boreholeData.virtual.max_lat; // Your max latitude value
        min_lat = boreholeData.virtual.min_lat; // Your min latitude value
        max_lng = boreholeData.virtual.max_lng; // Your max longitude value
        min_lng = boreholeData.virtual.max_lng; // Your min longitude value
    }
    let bounds = [
        [min_lat, min_lng],
        [max_lat, max_lng],
    ];


    // if you drop a virtual borehole, create a 1 second timeout before returning results
    // this is a hack, I was having issues with the fetch await in the virtual borehole component
    useEffect(() => {
        setVirtualBoreholeLoading(true)

        setTimeout(function(){
            create_model()
            logEvent('3D Model', {"Step": "Virtual Borehole Created"})
        }, 500); 

    }, [props.virtualBoreholeData, props.fixVirtualBorehole]);

    useEffect(() => {
        let bhList = [{value: "summary", label: "Summary"}]
        if (virtualBoreholeDisplay) {
            bhList.push({value: "virtual", label: "Virtual"})
        }
        if (boreholeData && boreholeData.virtual && boreholeData.virtual.strata){
            initialiseShrinkables()
        }
        if (boreholeData && boreholeData.boreholes && boreholeData.boreholes.length > 0) {
            boreholeData.boreholes.map((item, i) => {
                    bhList.push({value: i, label: !boreholeName ? `BH${i+1}` : item.data.borehole_name})
                }
            )
            setBhList(bhList)
        }
      }, [boreholeData, boreholeName]);


    async function create_model() {

        if (props.virtualBoreholeData && props.virtualBoreholeData.virtual){

            setVirtualBoreholeLoading(false)
            setBoreholeSelection("summary")

            let boreholeDataCopy = structuredClone(props.virtualBoreholeData)

            const fetchAndUpdateBoreholeData = async (borehole) => {
                const response = await fetchBoreholeData(borehole.properties.id, project_id);
                    let strataHolder = response.data.geo_record_strata

                    // order by top reverse
                    strataHolder.sort(function(a, b) {
                        return parseFloat(a.top) - parseFloat(b.top);
                    });

                    if(strataHolder[0].top!==0){
                        strataHolder.sort(function(a, b) {
                            return parseFloat(b.top) - parseFloat(a.top);
                        });
                    }

                    let strataHolderCopy = structuredClone(strataHolder)


                    var water_level = null
                    borehole.properties.groundlevel = 0

                    let groundLevel = strataHolderCopy[0].top
                    if(groundLevel>max_ground_level.current){
                        max_ground_level.current = groundLevel
                    }
                    if (strataHolder.length){
                        strataHolder = calculate_strata_depths(strataHolder, groundLevel, false, true)
                    }
                    let tests = response.data.geo_record_test
                    if (tests.length){
                        //loop through tests list of dictionaries for 'test' == 'water_strike'
                        for (let i = 0; i < tests.length; i++) {
                            if (tests[i].test == "water_strike"){
                                water_level = tests[i].depth
                                if(water_level>max_water){
                                    max_water = water_level
                                }
                                if(water_level<min_water){
                                    min_water = water_level
                                }
                            }
                        }

                    }

                    
                    borehole.properties.borehole_name = response.data.borehole_name
                    borehole.properties.groundlevel = groundLevel
                    borehole.properties.water_level = water_level

                    let boreholeholder = {
                        data : borehole.properties,
                        strata : strataHolder
                    }

                    boreholeDataCopy.boreholes.push(boreholeholder)
                    
                }
        
            //loop through all the borehole id's within the selection radius
            const promises = boreholeDataCopy.virtual.nearest_boreholes.map(fetchAndUpdateBoreholeData);
            await Promise.all(promises);

            boreholeDataCopy.virtual.max_depth = max_depth.current

            let boreholeholder = {
                virtual: boreholeDataCopy.virtual,
                boreholes : boreholeDataCopy.boreholes,
                rerender: false,
                topography: true
             }


            for(let borehole of boreholeholder.boreholes){
                let water_level = borehole.data.water_level
                if(boreholeholder.topography){
                    borehole.data.water_level = water_level-borehole.data.groundlevel
                }
                else{
                    borehole.data.water_level = water_level+borehole.data.groundlevel
                }
    
                borehole.strata = calculate_strata_depths(borehole.strata, borehole.data.groundlevel, boreholeholder.topography, false)
                for (let strata of borehole.strata){
                    strata.visible = null
                }
            }

            let virtual_borehole_holder = estimateVirtualBorehole(boreholeholder, boreholeholder.virtual.location, boreholeholder.virtual.boreholeCount, boreholeholder.virtual.selectionRadius, boreholeholder.topography, sensitivity)



            boreholeholder.virtual.virtual_ground_level = virtual_borehole_holder.virtual_ground_level

            boreholeholder.virtual.strata = virtual_borehole_holder.predictedStrata

            boreholeholder = colorAndPointcloud(boreholeholder, boreholeholder.virtual.selectionRadius, boreholeholder.virtual.location)

            setBoreholeData(boreholeholder)


            let materials = []

            for (let borehole of boreholeholder.boreholes){
                if(borehole.strata){
                    for(let material of borehole.strata){
                        if(!materials.includes(material.material)){
                            materials.push(material.material)
                        }
                    }
                }
            }
            setMaterialList(materials)
        }
    
    }

    function calculate_strata_depths(strataHolder, groundLevel, topography, classify){
        let top_count = groundLevel
        for (var i = 0; i < strataHolder.length; i++) {
            if(topography){
                let thickness = Math.abs(strataHolder[i].base - strataHolder[i].top)
                strataHolder[i].top = Math.round((top_count) * 10) / 10
                top_count = top_count - thickness
                strataHolder[i].base = Math.round((top_count)*10) / 10
            }
            else{
                strataHolder[i].top = Math.abs(Math.round((strataHolder[i].top - groundLevel) * 10) / 10)
                strataHolder[i].base = Math.abs(Math.round((strataHolder[i].base - groundLevel)*10) / 10)
            }
            if(strataHolder[i].base > max_depth.current){
                max_depth.current = strataHolder[i].base
            }
            if(classify){
                let descriptionString = strataHolder[i].description.toLowerCase()
                // remove any non alphabetical characters but leave spaces
                descriptionString = descriptionString.replace(/[^a-zA-Z ]/g, "")
                let descriptionList = descriptionString.split(" ")
                let material = findMaterial(descriptionList, descriptionString)
                if(material.uscs !== '-'){
                    strataHolder[i].material = material.uscs
                }
                else{
                    strataHolder[i].material = material.grain
                }
            }
        }
        return strataHolder
    }


    function colorAndPointcloud(boreholeholder, selectionRadius, clickLocation){

        for (let borehole of boreholeholder.boreholes){
            borehole.strata = findBoreholeColor(borehole.strata)
        }
        boreholeholder.virtual.strata = findBoreholeColor(boreholeholder.virtual.strata)

        return boreholeholder
    }



    function createSelectItems(selected, boreholeIdentifier, selectedMaterial) {
        let items = []; 
    
        for (let i = 0; i < materialList.length; i++) {     
                    items.push(<option key={i} value={materialList[i]}>{materialList[i]}</option>);   
        }

        items.push(<option key={items.length} value={'add'}>Add new</option>);  
        return items;
    }

    function handleGroundChange(groundlevel) {
        let boreholeholder = structuredClone(boreholeData)

        setAllowResetModel(true)
        let existing_ground_level = boreholeholder.boreholes[boreholeSelection].data.groundlevel
        boreholeholder.boreholes[boreholeSelection].data.groundlevel = groundlevel
        for (let strata of boreholeholder.boreholes[boreholeSelection].strata){
            strata.top = strata.top - (existing_ground_level - groundlevel)
            strata.base = strata.base - (existing_ground_level - groundlevel)
        }

        let selection_radius = props.virtualBoreholeData.virtual.selectionRadius
        let location = props.virtualBoreholeData.virtual.location
        let boreholeCount = props.virtualBoreholeData.virtual.boreholeCount

        let virtual_borehole_holder = estimateVirtualBorehole(boreholeholder, location, boreholeCount, selection_radius, boreholeData.topography, sensitivity)

        boreholeholder.virtual.virtual_ground_level = virtual_borehole_holder.virtual_ground_level

        boreholeholder.virtual.strata = virtual_borehole_holder.predictedStrata

        boreholeholder = colorAndPointcloud(boreholeholder, boreholeholder.virtual.selectionRadius, boreholeholder.virtual.location)

        boreholeholder.rerender = true

        setBoreholeData(boreholeholder)
    }



    const submitHandler = (e, i) => {

        e.preventDefault();

        let boreholeholder = boreholeData

        boreholeholder.boreholes[boreholeSelection].strata[i].material = inputValue


        if(!materialList.includes(inputValue)){
            setMaterialList([...materialList, inputValue])
        }
        
        setAddNewOption({
            item:null,
            tableIdentifier: null
        })

        setAllowResetModel(true)

        let selection_radius = props.virtualBoreholeData.virtual.selectionRadius
        let location = props.virtualBoreholeData.virtual.location
        let boreholeCount = props.virtualBoreholeData.virtual.boreholeCount

        let virtual_borehole_holder = estimateVirtualBorehole(boreholeholder, location, boreholeCount, selection_radius, topography, sensitivity)
        
        boreholeholder.virtual.virtual_ground_level = virtual_borehole_holder.virtual_ground_level

        boreholeholder.virtual.strata = virtual_borehole_holder.predictedStrata

        boreholeholder = colorAndPointcloud(boreholeholder, boreholeholder.virtual.selectionRadius, boreholeholder.virtual.location)

        boreholeholder.rerender = true
        setBoreholeData(boreholeholder)
    }

    function resetModel(){
        setAllowResetModel(false)
        max_ground_level.current = -100000
        create_model()
    
    }

    function downloadPointcloud(){
        let pointcloudText = ""

        for (let borehole of boreholeData.virtual.strata){
            if (borehole.pointcloud && borehole.pointcloud.length > 0){
                for (let point_array of borehole.pointcloud){
                    let material = borehole.material
                    for (let point of point_array){
                        pointcloudText += `${point.lat},${point.lng},${point.top},${material}\n`
                        pointcloudText += `${point.lat},${point.lng},${point.base},${material}\n`
                    }
                }
            }
        }

        let element = document.createElement("a");
        let file = new Blob([pointcloudText], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "pointcloud.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        logEvent('3D Model', {"Step": "Pointcloud Downloaded"})
    }

    function handleTableRowClick(i, boreholeIdentifier) {
        let boreholeholder = structuredClone(boreholeData);
        let visible_material = boreholeholder[boreholeIdentifier].strata[i].material;

        let newMaterialsVisibility = [...materials_visibility]; // create a copy of materials_visibility
    
        if (!boreholeholder[boreholeIdentifier].strata[i].visible) {
            if (!newMaterialsVisibility.includes(visible_material)) {
                newMaterialsVisibility.push(visible_material);
            }
        } else {
            if (newMaterialsVisibility.includes(visible_material)) {
                newMaterialsVisibility.splice(newMaterialsVisibility.indexOf(visible_material), 1);
            }
        }
    
        let newBoreholeholder = {...boreholeholder}; // create a copy of boreholeholder
    
        const setVisible = (strata) => {
            if (newMaterialsVisibility.includes(strata.material)) {
                return {...strata, visible: true}; // return a copy of strata, with visible set to true
            } else {
                return {...strata, visible: false}; // return a copy of strata, with visible set to false
            }
        }
    
        if (newMaterialsVisibility.length > 0){
            newBoreholeholder.boreholes = newBoreholeholder.boreholes.map(borehole => {
                return {...borehole, strata: borehole.strata.map(setVisible)};
            });
    
            newBoreholeholder.virtual.strata = newBoreholeholder.virtual.strata.map(setVisible);
        } else {
            newBoreholeholder.boreholes = newBoreholeholder.boreholes.map(borehole => {
                return {...borehole, strata: borehole.strata.map(strata => ({...strata, visible: null}))};
            });
    
            newBoreholeholder.virtual.strata = newBoreholeholder.virtual.strata.map(strata => ({...strata, visible: null}));
        } 
    
        newBoreholeholder.rerender = true;
        setBoreholeData(newBoreholeholder);
    }
    

    function conditionalMaterial(item, i, boreholeIdentifier){
        return ( 
            <tr key={i}>
                {item.color ?
                <td style={{minWidth:"40px"}}><div className="stratakey border mx-auto" style={{backgroundColor:`rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div></td>
                :
                <td style={{minWidth:"40px"}}><div className="stratakey border mx-auto" style={{backgroundColor:`rgb(${0}, ${0}, ${0})`}}></div></td>
                }
                <td>{item.top.toFixed(2)}</td>
                <td>{item.base.toFixed(2)}</td>
                <td className={`${(boreholeSelection === "summary" || boreholeSelection === "virtual" ) && 'd-none'}`}>
                        <>
                        {addNewOption['item'] !== i && boreholeIdentifier !== addNewOption['tableIdentifier'] ?
                            <Input type="select" className="selectInputModel" defaultValue={item.material} onChange={(e) => handleChange(e, i)} label="Multiple Select">
                                {createSelectItems(i, boreholeIdentifier, item.material)}
                            </Input>
                            :
                            <div className="input-group">
                                <Input onChange={(e) => setInputValue(e.target.value)} autoFocus={addNewOption['item'] === i} type="text"></Input>
                                <Button type="submit" color="primary" onClick={(e) => submitHandler(e, i)}>Add</Button>
                            </div>
                        }          
                        </>                                                
                </td>
                <td className={`${boreholeSelection !== "summary" && 'd-none'}`}>{item.material == '-' ? 'Unknown' : <span>{item.material}</span>}</td>
                <td><Shrinkable key={`text${i}`} text={item.description || ''} /></td>
            </tr>
            )
    }

    function toggleModelModal () {
        if(modalDisplay){
            props.toggleModelling(!modalDisplay)
          }
        setModalDisplay(!modalDisplay);
      }


    function displayVirtualBorehole(e){
        if(e.target.checked){
            // update bhList to include virtual, but insert the virtual after summary label (index 0)
            setBhList([...bhList.slice(0, 1), {value: "virtual", label: "Virtual"}, ...bhList.slice(1)])
            setBoreholeSelection("virtual")
            setVirtualBoreholeDisplay(true)
            setDisplayMap(true);
        }
        else{
            // remove virtual from bhList
            setBhList(bhList.filter(obj => obj.value !== "virtual"))
            setBoreholeSelection("summary")
            setVirtualBoreholeDisplay(false)
            setDisplayMap(false);
        }
    }


    function createLabelIcon(labelText) {
        return new L.DivIcon({
            html: `<div className="marker-label">${labelText}</div>`,
            className: 'marker-label-container',
            iconSize: L.point(0, 0),
            iconAnchor: [20, -10],
        });
    }


    function MapListeners({ onClick, bounds }) {

    mapRef.current = useMap();

    const map_events = useMapEvents({
        click: (e) => {
        onClick(e.latlng);
        },
    });

    //set map zoom to 19
    // mapRef.current.setZoom(19);
    
    return null;
    }

    function handleMapClick(latlng) {
    if(virtualBoreholeDisplay){        
        let boreholeholder = structuredClone(boreholeData)
        boreholeholder.virtual.location = latlng

        let virtual_borehole_holder = estimateVirtualBorehole(boreholeholder, boreholeholder.virtual.location, boreholeholder.virtual.boreholeCount, boreholeholder.virtual.selectionRadius, boreholeData.topography, sensitivity)

        boreholeholder.virtual.virtual_ground_level = virtual_borehole_holder.virtual_ground_level
        boreholeholder.virtual.strata = virtual_borehole_holder.predictedStrata
            
        boreholeholder = colorAndPointcloud(boreholeholder, boreholeholder.virtual.selectionRadius, boreholeholder.virtual.location)

        setBoreholeData(boreholeholder)
    }
    }



    function handleChange(e, i) {
        let boreholeholder = structuredClone(boreholeData)

        if (e.target.value === "add") {

            setAddNewOption({
                item:i,
                tableIdentifier: boreholeSelection
            })
            //autofocus on the target input
            e.target.focus()
            
            
        } else {
            boreholeholder.boreholes[boreholeSelection].strata[i].material = e.target.value


            setAllowResetModel(true)

            
            setAddNewOption({
                item:null,
                tableIdentifier: null
            })

            let selection_radius = props.virtualBoreholeData.virtual.selectionRadius
            let location = props.virtualBoreholeData.virtual.location
            let boreholeCount = props.virtualBoreholeData.virtual.boreholeCount
    
            let virtual_borehole_holder = estimateVirtualBorehole(boreholeholder, location, boreholeCount, selection_radius, boreholeData.topography, sensitivity)
            
            boreholeholder.virtual.virtual_ground_level = virtual_borehole_holder.virtual_ground_level

            boreholeholder.virtual.strata = virtual_borehole_holder.predictedStrata

            boreholeholder = colorAndPointcloud(boreholeholder, boreholeholder.virtual.selectionRadius, boreholeholder.virtual.location)

            boreholeholder.rerender = true

            setBoreholeData(boreholeholder)
        }
    }



    function switchTopography(){

        let boreholeholder = structuredClone(boreholeData)

        let topography = false
        if (boreholeData.topography === true){
            topography = false
        }
        else{
            topography = true
        }

        boreholeholder = {
            ...boreholeholder,
            topography: topography
        }

        for(let borehole of boreholeholder.boreholes){
            let water_level = borehole.data.water_level
            if(topography){
                borehole.data.water_level = water_level-borehole.data.groundlevel
            }
            else{
                borehole.data.water_level = water_level+borehole.data.groundlevel
            }

            borehole.strata = calculate_strata_depths(borehole.strata, borehole.data.groundlevel, topography, false)
            for (let strata of borehole.strata){
                strata.visible = null
            }
        }


        let virtual_borehole_holder = estimateVirtualBorehole(boreholeholder, boreholeholder.virtual.location, boreholeholder.virtual.boreholeCount, boreholeholder.virtual.selectionRadius, topography, sensitivity)
        
        boreholeholder.virtual.virtual_ground_level = virtual_borehole_holder.virtual_ground_level

        boreholeholder.virtual.strata = virtual_borehole_holder.predictedStrata
            
        boreholeholder = colorAndPointcloud(boreholeholder, boreholeholder.virtual.selectionRadius, boreholeholder.virtual.location)
        
        boreholeholder.rerender = true

        setBoreholeData(boreholeholder)

    }
      

    function updateSensitivity(sensitivity_holder){

        let boreholeholder = structuredClone(boreholeData)

        let virtual_borehole_holder = estimateVirtualBorehole(boreholeholder, boreholeholder.virtual.location, boreholeholder.virtual.boreholeCount, boreholeholder.virtual.selectionRadius, topography, sensitivity_holder)
        
        boreholeholder.virtual.virtual_ground_level = virtual_borehole_holder.virtual_ground_level
    
        boreholeholder.virtual.strata = virtual_borehole_holder.predictedStrata
            
        boreholeholder = colorAndPointcloud(boreholeholder, boreholeholder.virtual.selectionRadius, boreholeholder.virtual.location)
        
        boreholeholder.rerender = true
    
        setSensitivity(sensitivity_holder)
        setBoreholeData(boreholeholder)

    }

    const handleExportDxf = () => {
        const ids = []
        boreholeData.boreholes.forEach(borehole => {
            ids.push(borehole.data.id)
        })
        setDownloadingDXF(true);

        httpClientPy
        .post(`/geo/3d/export/dxf`, {
            ids: ids
        })
        .then((response) => {
            logEvent('3D Model', {"Step": "DXF Exported"})
            const data = response.data.content;
            const binaryData = window.atob(data);

            const blob = new Blob([binaryData], { type: 'application/octet-stream' });

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'civilsai-3d-model.dxf';

            // Append the link to the body
            document.body.appendChild(downloadLink);

            // Simulate a click to trigger the download
            downloadLink.click();

            // Clean up by removing the link
            document.body.removeChild(downloadLink);
            setDownloadingDXF(false);
        }).catch((error) => {
            console.log(error);
            setDownloadingDXF(false);
        });
    }

    return (
        <>
        <Modal className="modellingModal fullscreen_modal" centered={true} fullscreen={true} isOpen={modalDisplay && props.virtualBoreholeData} toggle={()=>toggleModelModal()}>
          <ModalHeader className="d-flex pb-0 mb-0 align-items-center border-0" toggle={()=>toggleModelModal()}>                        
            <h4 className="d-inline-flex title-bold align-items-center mb-0 pb-0">
               <span>&nbsp;3D Geotechnical Model Viewer</span></h4>
          </ModalHeader>
          <ModalBody className="pt-2 pb-0 mb-0">

          <div className="row g-0 modellingModalBody3d">
            <div className="col-5">
                <div className="border border-dark rounded w-100 h-100">
                    <Model zoom={props.zoom} 
                            scale={scale}
                            boreholeName={boreholeName}
                            groundwater={groundwater}
                            boreholeData={boreholeData} 
                            displayInterpolation={displayInterpolation}
                            allBoreholeData={props.virtualBoreholeData} 
                            fixVirtualBorehole={props.fixVirtualBorehole} 
                            virtualBoreholeDisplay={virtualBoreholeDisplay} 
                            displayDimensions={displayDimensions}
                            max_ground_level={max_ground_level.current}
                            selectionRadiusSlider={props.selectionRadiusSlider}
                            id="geoModel"></Model>
                </div>
            </div>
            <div className="col-7 h-100 d-flex align-items-start flex-column px-2">
            {props.fixVirtualBorehole &&
                <>
                    <div className="w-100 p-1">
                        <div className="d-flex w-100 bg-light border">
                            <div className="py-4 w-100 v-stack px-3" style={{zIndex: '9999'}}>
                                <div className="w-70 px-1">
                                <Select 
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="virtualBoreholeSelector"
                                    options={bhList}
                                    isMulti={false}
                                    onChange={(e) => setBoreholeSelection(e.value)}
                                    closeMenuOnSelect={true}
                                    value={bhList.filter(obj => obj.value === boreholeSelection)}
                                    style={{zIndex: 9999}}
                                />
                                </div>
                            </div>
                            {(boreholeSelection !== "summary" && boreholeSelection !== "virtual") && boreholeData.topography &&
                                <div className="py-4 w-100 v-stack">
                                    <div className="input-group mx-auto" style={{maxWidth:"300px"}}>
                                        <span className="input-group-text" id="basic-addon1">Ground level</span>
                                        <input type="number" className="form-control" onChange={(event) => handleGroundChange(event.target.value)} value={boreholeData.boreholes[boreholeSelection].data.groundlevel}/>
                                        <span className="input-group-text" id="basic-addon1">m</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                

                <div className="tbodyDiv flex-grow-1 w-100" style={{zIndex: 0}}>
                    {virtualBoreholeLoading ?
                        <div className="container text-center mt-5">
                        <Loader loading={virtualBoreholeLoading} />
                        </div>
                    :
                    <>
                    {boreholeSelection === "summary" &&
                    <div className={`overflow-auto ${displayMap && 'h-50'}`} >
                        {!groundwater ?
                            <Table id="virtualBoreholeTable" className="mt-2 table-hover virtualBoreholeTable">

                            <thead id="virtualBoreholeTableHeader" className="sticky-top bg-white">
                            <tr>
                            <th className="title-bold text-center" style={{minWidth:"40px"}}></th>
                            <th className="title-bold">{boreholeData.topography ? <span>Max level (mAOD)</span>:<span>Min (mBGL)</span>}</th>
                            <th className="title-bold">{boreholeData.topography ? <span>Min level (mAOD)</span>:<span>Max (mBGL)</span>}</th>
                            <th className="title-bold">Group</th>
                            <th className="title-bold">Geological description</th>
                            <th className={`${boreholeSelection !== "summary" && 'd-none'}`}>
                                {/* <FontAwesomeIcon icon={faEye}/> */}
                            </th>
                            </tr>
                            </thead>
                            {boreholeData.virtual && boreholeData.virtual.strata &&
                            <>
                                <tbody className={`${boreholeSelection !== "summary" && 'd-none' }`}>
                                {boreholeData.virtual.strata.map((item, i) => {
                                    return (
                                        <>
                                            {item.description &&
                                            <tr key={i} style={{ cursor: 'pointer' }} onClick={() => handleTableRowClick(i, "virtual")}>
                                                {item.color ?
                                                <td style={{minWidth:"40px"}}><div className="stratakey border mx-auto" style={{backgroundColor:`rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`}}></div></td>
                                                :
                                                <td style={{minWidth:"40px"}}><div className="stratakey border mx-auto" style={{backgroundColor:`rgb(${0}, ${0}, ${0})`}}></div></td>
                                                }
                                                <td>{item.max_top}</td>
                                                <td>{item.min_base}</td>
                                                <td className={`${boreholeSelection !== "summary" && 'd-none'}`}>{item.material == '-' ? 'Unknown' : <span>{item.material}</span>}</td>
                                                <td><Shrinkable key={`text${i}`} text={item.description || ''} /></td>
                                                <td className={`${boreholeSelection !== "summary" && 'd-none'}`}>
                                                    <div className="form-check form-switch text-secondary">
                                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={item.visible}/>
                                                    </div>
                                                </td>
                                            </tr>
                                            }
                                        </>
                                        )
                                    })
                                }
                                </tbody>
                            </>
                            }
                            </Table> 
                            :
                            <Table id="virtualBoreholeTable" className="mt-2 table-hover virtualBoreholeTable">

                            <thead id="virtualBoreholeTableHeader" className="sticky-top bg-white">
                            <tr>
                            <th className="title-bold text-center" style={{minWidth:"40px"}}></th>
                            <th className="title-bold"><span>Min water level (mBGL)</span></th>
                            <th className="title-bold"><span>Max water level (mBGL)</span></th>
                            </tr>
                            </thead>
                            {boreholeData.virtual && boreholeData.virtual.strata &&
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>{min_water}</td>
                                    <td>{max_water}</td>
                                </tr>
                            </tbody>
                            }
                            </Table> 
                            }
                        </div>
                    }
                    {(boreholeSelection !== "summary" || boreholeSelection === "virtual") &&
                    <>
                        <div className={`${boreholeSelection === "virtual" && 'virtualBoreholeResults'} overflow-auto ${displayMap && 'h-50'}`}>
                        <Table id="virtualBoreholeTable2" className="mt-4 virtualBoreholeTable">
                            <thead id="virtualBoreholeTableHeader" className="sticky-top bg-white">
                                <tr>
                                <th className="title-bold text-center" style={{minWidth:"40px"}}></th>
                                <th className="title-bold">Top {boreholeData.topography ? <span>(mAOD)</span>:<span>(mBGL)</span>}</th>
                                <th className="title-bold">Bottom {boreholeData.topography ? <span>(mAOD)</span>:<span>(mBGL)</span>}</th>
                                <th className={`title-bold ${(boreholeSelection === "summary" || boreholeSelection === "virtual" ) && 'd-none'}`}>Group</th>
                                <th className="title-bold">Geological description</th>
                                </tr>
                            </thead>
                                {boreholeData.virtual && boreholeData.virtual.strata &&
                                <>
                                    <tbody className={`${boreholeSelection !== "virtual" && 'd-none' }`}>
                                    {boreholeData.virtual.strata.map((item, i) => {
                                        return (
                                            conditionalMaterial(item, i, "virtual")
                                        )
                                        })
                                    }
                                    </tbody>
                                    {boreholeData.boreholes.map((item, i) => {
                                        return (
                                            <tbody className={`${boreholeSelection !== i && 'd-none' }`}>
                                                <>
                                                    {item.strata.map((item2, j) => {
                                                        return (
                                                            conditionalMaterial(item2, j, `${i+1}`)
                                                        )
                                                        })
                                                    }
                                                </>
                                            </tbody>
                                            )
                                    })}
                                </>
                            }
                        </Table>
                        </div>
                        </>
                    }
                    {boreholeData.virtual && boreholeData.virtual.location && displayMap &&
                        <div style={{height: "calc(50%)"}}>
                                <MapContainer 
                                className="w-100 h-100" 
                                center={
                                    [boreholeData.virtual.location.lat, 
                                    boreholeData.virtual.location.lng]} 
                                    zoom={15} 
                                    scrollWheelZoom={false}
                                >
                                <MapListeners onClick={(latlng) => handleMapClick(latlng)} bounds={bounds} />
                                <TileLayer
                                    attribution="Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
                                    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_nolabels/{z}/{x}/{y}.png"
                                    />
                                    {virtualBoreholeDisplay &&   
                                        <Marker 
                                            key={`marker-${1000}`}
                                            position={[boreholeData.virtual.location.lat, boreholeData.virtual.location.lng]}
                                            icon={boreholeGreen}
                                        >
                                        </Marker>
                                    }
                                {boreholeData.boreholes.map((item, i) => {
                                    return (
                                        <Marker 
                                        key={`marker-${i+1}`}
                                        position={[item.data.coordinates.lat, item.data.coordinates.lng]}
                                        icon={boreholeBlack}
                                        >
                                        <Marker
                                            position={[item.data.coordinates.lat, item.data.coordinates.lng]}
                                            icon={createLabelIcon(`${boreholeName ? item.data.borehole_name : i + 1}`)}
                                            />
                                        </Marker>
                                        
                                    )
                                })
                                }
                                </MapContainer>
                            </div>
                            }

                    
                    </>
                    }
                </div>
                { !virtualBoreholeLoading &&
                    <div className="row g-0 container-fluid">
                        <div className="col-6 order-1 text-start text-muted">
                            <small className="d-none">All values given are estimates and are not for final design.</small>
                        </div>
                    </div>
                }
                </>
            }

            
            </div>
        </div>
        <div className="row pt-1 mb-0">
        <div className="col-5 text-center">
            <div className="row">

                <div className="col-12 pt-1">
                    <div>
                        <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="topographyCheckbox"
                                onChange={() => {switchTopography()}}
                                checked={boreholeData.topography}
                            />
                            <label className="form-check-label" htmlFor="topographyCheckbox">
                                <>Topography</>
                            </label>
                        </div>
                        <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="interpolateCheckbox"
                                onChange={() => {setDisplayInterpolation(!displayInterpolation)}}
                                checked={displayInterpolation}
                            />
                            <label className="form-check-label" htmlFor="interpolateCheckbox">
                                <>Interpolation</>
                            </label>
                        </div>
                        <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="dimensionsCheckbox" disabled={boreholeData.virtual && !boreholeData.virtual.water_pointcloud.length} checked={groundwater} onChange={()=>{setGroundwater(!groundwater)}}/>
                            <label className="form-check-label" htmlFor="dimensionsCheckbox">
                                <> Groundwater </>
                            </label>
                        </div>
                    </div>
                    <div className="pt-1">
                        <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="virtualBH" checked={virtualBoreholeDisplay} onChange={(e)=>{displayVirtualBorehole(e)}}/>
                            <label className="form-check-label" htmlFor="virtualBH">
                                <> Virtual borehole </>
                            </label>
                        </div>
                        <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="mapCheckbox" checked={displayMap} onChange={()=>{setDisplayMap(!displayMap);}}/>
                            <label className="form-check-label" htmlFor="mapCheckbox">
                                <> Map </>
                            </label>
                        </div>
                        <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="dimensionsCheckbox" checked={displayDimensions} onChange={()=>{setDisplayDimensions(!displayDimensions)}}/>
                            <label className="form-check-label" htmlFor="dimensionsCheckbox">
                                <> Dimensions </>
                            </label>
                        </div>
                        {/* <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="dimensionsCheckbox" checked={displaySettings} onChange={()=>{setDisplaySettings(!displaySettings)}}/>
                            <label className="form-check-label" htmlFor="dimensionsCheckbox">
                                <> Settings </>
                            </label>
                        </div> */}
                        <div className="form-check form-switch d-inline-block ms-1 me-2">
                            <input className="form-check-input" type="checkbox" id="nameCheckbox" checked={boreholeName} onChange={()=>{setBoreholeName(!boreholeName)}}/>
                            <label className="form-check-label" htmlFor="nameCheckbox">
                                <> Borehole name </>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>

          </ModalBody>
          <ModalFooter className="mt-0 pt-0 border-0">
          <div className="container-fluid w-100 text-end text-muted">
          <div className="row">
                <div className="col-5">
                    <div className={`row ${!displaySettings && 'd-none'}`}>
                        <div className="col-4 d-flex align-items-center">
                            <label htmlFor="customRange2" className="form-label text-secondary mb-0 pb-0 w-100 text-end pe-2">
                                <small>Scale:</small>
                            </label>
                            <input type="range" min="0" max="4" step="1" value={scale} onChange={(event) => setScale(event.target.value)} className="form-range pt-0 mt-0" id="customRange2" />
                        </div>
                        <div className="col-4 d-flex align-items-center">
                            <label htmlFor="customRange2" className="form-label text-muted text-sm mb-0 pb-0 w-100 text-end pe-2">
                                <small>Sensitivity:</small>
                            </label>
                            <input type="range" min="0" max="1" step="0.1" value={sensitivity} onChange={(event) => updateSensitivity(event.target.value)} className="form-range pt-0 mt-0" id="customRange2" />
                        </div>
                    </div>
                </div>
                <div className="col-7">
                    <a className={`btn btn-outline-secondary btn-sm ${downloadingDXF && 'disabled'}`} onClick={handleExportDxf}>
                        {downloadingDXF ?
                            (
                                <span style={{display: 'inline-flex', alignItems: 'center'}}>
                                    <Loader className="d-inline" loading={downloadingDXF} />
                                </span>
                            ) : (
                                <FontAwesomeIcon
                                className="icon-view"
                                    icon={faFileExport}
                                />
                            )}

                        <> Download as DXF</>
                    </a>
                    &nbsp;
                    <Button size="sm" onClick={()=>{downloadPointcloud()}} outline color="secondary">
                        <>
                            <FontAwesomeIcon
                            icon={faCloudArrowDown}
                            />
                            <> Pointcloud </>
                        </>
                    </Button>

                    <Button size="sm" disabled={!allowResetModel} onClick={()=>{resetModel()}} className="ms-1" outline color="secondary">
                        <>
                            <FontAwesomeIcon
                            icon={faClockRotateLeft}
                            />
                            <> Reset model</>
                        </>
                    </Button>
                </div>
            </div>

            </div>
          </ModalFooter>
        </Modal>
        </>
    );
  };
  
  export default Modelling;




  function initialiseShrinkables(){


    var len = 20;
    var shrinkables = document.getElementsByClassName('shrinkable');
    
    if (shrinkables.length > 0) {

        for (var i = 0; i < shrinkables.length; i++){
            var fullText = shrinkables[i].innerHTML;
            if(fullText.length > len){
                var trunc = fullText.substring(0, len).replace(/\w+$/, '');
                
            }
        }
    }
  }

      // fetch request for database borehole data
      const fetchBoreholeData = (id, project_id) => {
        return httpClientPy.get(`/geo/record?geo_record_id=${id}&project_id=${project_id}&format=json`).then((response) => {
          return response;
        });
    };

