import { capitalize } from "lodash";
import httpClientPy from "../../../utils/httpClientPy";
import { toast } from "react-toastify";
import { useState } from "react";
import WorkFlowCardDropDown from "./WorkFlowCardDropDown";

const WorkFlowCard = (props) => {

    const [error, setError] = useState(null);

    const handleRowClick = (id) => {
        props.setAction('edit', id);
    };

    const triggerDelete = (id) => {
        httpClientPy.delete(`/workflow/delete?workflow_id=${id}&project_id=${props.project.id}`)
        .then((response) => {
            props.fetchRecords();
        }).catch((err) => {
            toast.error('Failed to delete the workflow, please contact info@civils.ai.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            setError("Failed to delete the workflow, please contact info@civils.ai");
        });
    };

    return (
        <div className="col-12 col-lg-6 pb-2" onClick={() => handleRowClick(props.workflow.id)}>
            <div className="card workflow-card shadow-sm h-100 overflow-hidden category-extract">
                <div className="card-header bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <i className={`fa-solid ${props.workflow.icon}`}></i>&nbsp;
                            <span className="ms-1"><b>{props.workflow.category}</b></span>
                        </div>
                        {props.workflow.is_public ? (
                            props.superAdmin && (
                                <WorkFlowCardDropDown 
                                    id={props.workflow.id}
                                    triggerDelete={(id) => triggerDelete(id)}
                                    error={error}
                                    setError={(data) => setError(data)}
                                />
                            )
                        ) : (
                            <WorkFlowCardDropDown 
                                id={props.workflow.id}
                                triggerDelete={(id) => triggerDelete(id)}
                                error={error}
                                setError={(data) => setError(data)}
                            />
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <h5 className="card-title text-dark title-bold py-2 mb-0">{props.workflow.name}</h5>
                    <div className="card-text text-muted">
                        <small>
                            {props.workflow.description}
                        </small>
                    </div>
                    <div className="text-dark pt-3">
                        <small className="title-bold">Input(s) </small>
                        {props.workflow.inputs.map((input, index) => (
                            <span className="badge bg-light ms-2 border text-dark d-inline">
                                {capitalize(input.trim())}
                            </span>
                        ))}
                    </div>
                    <div className="text-dark pt-2">
                        <small className="title-bold">Output(s) </small>
                        <span className="badge bg-light ms-2 border text-dark d-inline">
                            Excel
                        </span>
                        {!props.workflow.is_digitsation ? (
                            <span className="badge bg-light ms-2 border text-dark d-inline">
                                Word
                            </span>
                        ) : (
                            <span className="badge bg-light ms-2 border text-dark d-inline">
                                Ags
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkFlowCard;