/* eslint-disable react/jsx-no-target-blank */
import { setMixpanelID, logEvent } from "../shared/Mixpanel";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import authenticationHandler from "../../utils/authenticationHandler";
import httpClientPy from "../../utils/httpClientPy";
import GoogleSignIn from "../googleSignIn/GoogleSignIn";
import Button from "../shared/Button";
import ValidationSummary from "../shared/ValidationSummary";
import AuthFormHeader from "../shared/AuthFormHeader";
import { UncontrolledAlert } from "reactstrap";
import Auth from "../sso/Auth";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [pendingActivation, setPendingActivation] = useState(null);
  const [loginRequest, setLoginRequest] = useState({
    email: "",
    password: "",
  });
  const [ssologinRequest, setSSOLoginRequest] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    // get the token from the url if there is one
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      localStorage.setItem("auth_token", token);
      localStorage.setItem("onboarded", true);
      navigate("/projects");
    }
  }, []);

  const handleChange = (e) => {
    setLoginRequest({ ...loginRequest, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    setLoading(true);
    setPendingActivation(false);
    setErrors([]);
    event.preventDefault();

    httpClientPy
      .post(`/user/login`, loginRequest)
      .then((result) => {
        if(result.data.data.is_active === false) {
          setPendingActivation(true);
        } else {
          localStorage.setItem("auth_token", result.data.data.token.access_token);
          localStorage.setItem("displayName", result.data.data.display_name);
          localStorage.setItem("digitiser", result.data.data.digitiser);
          localStorage.setItem("onboarded", true);
          setMixpanelID(result.data.data.user_profile_id, 
                        result.data.data.email,
                        result.data.data.enterprise,
                        result.data.data.enterprise_name
                      );
          logEvent("Log In", { "Provider": "Civils.ai" });
          navigate("/projects");
        }
      })
      .catch((errorResponse) => {
        setErrors(errorResponse.response.data.detail);
        setLoading(false);
        authenticationHandler.logout();
        throw errorResponse;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
    {!ssologinRequest ? (
      <main className="form-signin mt-5 m-auto container text-center">
        <AuthFormHeader />
        <h1 className="title-bold pt-4">Log in</h1>
        <div className='mt-3'><ValidationSummary errors={errors} /></div>
        <div className="mt-3">
        <GoogleSignIn setPendingActivation={(status) => setPendingActivation(status)} 
                      setErrors={(errors) => setErrors(errors)} redirectTo="/projects" />
        <hr></hr>
        <form onSubmit={handleSubmit}>
          <div className="form-floating mb-2">
            <input
              name="email"
              type="email"
              className="form-control"
              id="emailInput"
              placeholder="name@example.com"
              value={loginRequest.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="emailInput">Email address</label>
          </div>
          <div className="form-floating mb-2">
            <input
              name="password"
              type="password"
              className="form-control"
              id="passwordInput"
              placeholder="Password"
              value={loginRequest.password}
              onChange={handleChange}
              required
            />
            <label htmlFor="passwordInput">Password</label>
          </div>

          <div className="vstack gap-2">
            <Button
              type="submit"
              label="Continue with email"
              className="w-100 btn btn-primary"
              loading={loading}
            />

        <div>
          {pendingActivation &&
            <UncontrolledAlert color="warning">
              <span className="title-bold">Your account is still pending for email verification, verify your email by clicking on the link sent to your registered email address.</span>
            </UncontrolledAlert>
          }
        </div>
            <div className="text-secondary"><small>
              You can also continue with <NavLink onClick={() => {setSSOLoginRequest(!ssologinRequest); setErrors([])}}>SAML SSO</NavLink>
              </small>
            </div>
            <div className="text-secondary"><small>
              Don't have an account? <NavLink to="/sign-up">Sign up now</NavLink>
              </small>
            </div>
            <div className="text-secondary"><small>
              <NavLink to="/forgot-password">Forgot password?</NavLink>
              </small>
            </div>
            {/* <div className="checkbox text-secondary text"><small>
              <label>
                <input type="checkbox" value="remember-me" disabled checked />&nbsp; I agree to Civil.ai's{" "}
                <a
                  href="https://civils.ai/user-license-agreement"
                  target="_blank"
                >
                  license agreement
                </a>
                .
              </label></small>
            </div> */}
          </div>
        </form>
        </div>
      </main>
    ) : (
      <Auth setSSOLoginRequest={(status) => setSSOLoginRequest(status)} />
    )}
    </>
  );
};

export default LoginPage;
