import Loader from "../../shared/Loader";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Card, CardBody, CardHeader } from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, 
        faDatabase, faList, faClock, faTriangleExclamation, faCloudArrowUp, 
        faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import httpClientPy from "../../../utils/httpClientPy";
import { logEvent } from "../../shared/Mixpanel";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const SearcHistory = (props) => {

    const [history, setHistory] = useState([]);
    const [totalHistoryPages, setTotalHistoryPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const paginate = (pageNumber) => setCurrentPageNumber(pageNumber);

    const searchHistoryRetrieval = async () => {
        setLoading(true);
        try {
            let response = await httpClientPy.get(`/search/all/histories?project_id=${props.project_id}&limit=${10}&offset=${(currentPageNumber - 1) * 10}`);
            let history = response.data.messages;
            let totalHistoryPages = response.data.total_pages;
            logEvent('Search History');
            setHistory(history);
            setTotalHistoryPages(totalHistoryPages);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        searchHistoryRetrieval();
    }, [currentPageNumber]);

    const handleRowClick = (id) => {
        props.handleSearchHistoryClicked(id);
        setLoading(true)
    }

    return (
        <Card className="h-100 border-0 ">
            <CardHeader>
                <h3 className='title-bold pb-0 mb-0 text-center'>Search History</h3>
            </CardHeader>
            {loading ?
                <div className="my-5">
                    <Loader loading={loading} />
                </div>
            :
                <CardBody>
                    {history && history.length > 0 && (
                        <>    
                            <table className="table table-borderless table-hover">
                                <thead>
                                    <tr>
                                        <td className="text-center" style={{ width: '30%' }}><b>Search Query</b></td>
                                        <td className="text-center" style={{ width: '10%' }}><b>Date Searched</b></td>
                                        {/* <td className="text-center" style={{ width: '5%' }}><b>All</b></td> */}
                                        <td className="text-center" style={{ width: '5%' }}><b>Specs</b></td>
                                        <td className="text-center" style={{ width: '5%' }}><b>Contracts</b></td>
                                        <td className="text-center" style={{ width: '5%' }}><b>Surveys</b></td>
                                        <td className="text-center" style={{ width: '5%' }}><b>Drawings</b></td>
                                        <td className="text-center" style={{ width: '10%' }}><b>Health & Safety</b></td>
                                        <td className="text-center" style={{ width: '10%' }}><b>Plans & Methods</b></td>
                                        <td className="text-center" style={{ width: '5%' }}><b>Others</b></td>
                                    </tr>
                                </thead>
                                <tbody className='small'>
                                    {history.map((row, index) => (
                                        <tr key={index} className='bg-white'>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.query}
                                            </td>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.created_at}
                                            </td>
                                            {/* <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_refs}</span>
                                                )}
                                            </td> */}
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_spec_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_spec_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_spec_refs}</span>
                                                )}
                                            </td>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_contracts_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_contracts_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_contracts_refs}</span>
                                                )}
                                            </td>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_survey_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_survey_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_survey_refs}</span>
                                                )}
                                            </td>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_drawings_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_drawings_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_drawings_refs}</span>
                                                )}
                                            </td>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_risk_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_risk_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_risk_refs}</span>
                                                )}
                                            </td>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_plans_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_plans_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_plans_refs}</span>
                                                )}
                                            </td>
                                            <td className="text-center" onClick={() => handleRowClick(row.id)} style={{cursor: 'pointer'}}>
                                                {row.num_of_others_refs > 0 ? (
                                                    <span className="badge rounded-pill green">{row.num_of_others_refs}</span>
                                                ) : (
                                                    <span className="badge rounded-pill red">{row.num_of_others_refs}</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                {currentPageNumber > 1 && (
                                    <button className="btn btn-primary btn-sm me-2" onClick={() => paginate(currentPageNumber - 1)}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </button>
                                )}
                                {currentPageNumber} / {totalHistoryPages}
                                {currentPageNumber < totalHistoryPages && (
                                    <button className="btn btn-primary btn-sm ms-2" onClick={() => paginate(currentPageNumber + 1)}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                    {(!history || history.length === 0) && !loading && (
                        <>
                        <h6 className="text-muted text-center pt-3">
                            No search history found.
                        </h6>
                        </>
                    )}
                </CardBody>
            }
        </Card>
    );
};

export default SearcHistory;
