import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardTitle, CardFooter, CardText, CardSubtitle, CardHeader } from 'reactstrap';
import Button from '../Button';  
import CheckListItem from './CheckListItem';
import ArrowListItem from './ArrowListItem';
import httpClientPy from '../../../utils/httpClientPy';
import { logEvent } from '../Mixpanel';

const PricingModal = (props) => { 
    const [pricingVisibility, setPricingVisibility] = useState(true);

    

    const checkout = () => {
        httpClientPy.get('/subscriptions/create/checkout').then(res=>{
            window.location.href = res.data.url;
        }).catch(err=>{
            console.log(err);
        })
    }

    const toggleVisibility = () => {
        setPricingVisibility(!pricingVisibility);
    }


    const requestDemoClick = () => {
        logEvent('Request Demo Clicked');
        let url = "https://calendar.app.google/xSxr57K1VbPEjZRB7"
        window.open(url, '_blank');
    }

    return(
        <Modal isOpen={props.isOpen} className='pricingModal' toggle={props.toggle} size='xl'>
            <ModalHeader className='title-bold border-0 pb-0 mb-0' toggle={props.toggle}>{props.title}</ModalHeader>
            <ModalBody>

                <table className="table table-light table-striped text-start equal-width-columns text-start">
                    <thead>
                    <tr className='border-light'>
                        <th scope="col"></th>
                        <th scope="col">
                        <div className="title-bold h3 mb-0">Individual</div>
                        </th>
                        <th scope="col">
                            <div className="title-bold h3 mb-0">Enterprise</div>
                        </th>
                    </tr>
                    <tr>
                        <td scope="col"></td>
                        <td scope="col">
                        <div><span class="fs-5">$49 </span> <small>/ month</small></div>
                        </td>
                        <td scope="col">
                            <div class="fs-5">Custom</div>
                        </td>
                    </tr>
                    </thead>
                    <tbody>

                    <tr>
                        <th scope="col">File upload limits</th>            
                        <td>150</td>
                        <td>Custom</td>
                    </tr>
                    <tr>
                        <th scope="col">AI Prompts</th>            
                        <td>1500</td>
                        <td>Custom</td>
                    </tr>
                    <tr>
                        <th scope="col">User licenses</th>            
                        <td>1</td>
                        <td>Custom</td>
                    </tr>
                    <tr>
                        <th scope="col">License period</th>            
                        <td>12 months</td>
                        <td>Custom</td>
                    </tr>
                    <tr>
                        <th scope="col">Max file size</th>
                        <td>32MB</td>
                        <td>Custom</td>
                    </tr>
                    <tr>
                        <th scope="col">Sharing with team</th>
                        <td>❌</td>
                        <td>✅</td>
                    </tr>
                    <tr>
                        <th scope="col">API access & integrations</th>
                        <td>❌</td>
                        <td>✅</td>
                    </tr>
                    <tr>
                        <th scope="col">SSO</th>
                        <td>❌</td>
                        <td>✅</td>
                    </tr>
                    <tr>
                        <th scope="col"></th>
                        <td>

                            <Button 
                                type="button"
                                onClick={requestDemoClick}
                                label= {"Get access today"}
                                className="w-100 btn-lg btn btn-primary"
                            />
                        </td>
                        <td>

                        <Button 
                            type="button"
                            onClick={requestDemoClick}
                            label= {"Book a demo"}
                            className="w-100 btn-lg btn btn-primary"
                        />


                        </td>
                    </tr>
                    </tbody>
                </table>
            </ModalBody>
        </Modal>
    )

 }; export default PricingModal;