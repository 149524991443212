import { useEffect, useState} from "react";
import httpClientPy from "../../../utils/httpClientPy.js";
import { Card, CardHeader, CardBody} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faPersonDigging, faFilter, faCloudArrowDown, faCircleInfo, faArrowsRotate, faWandMagicSparkles, faFilePdf, faTrash, faCloudArrowUp, faXmark} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from 'react-select'
import Loader from "../../shared/Loader.js";
import UploadedGeotechList from "./UploadedGeotechList.js";
import DataPageUploader from "../../shared/uploaders/DataPageUploader.js"
import SearchResult from "../detailComponents/SearchResult.js" 
import DataList from "./DataList.js";


library.add(faCopy, faCheck, faCloudArrowDown, faPersonDigging);

const UploadsList = (props) => {

  //boreHoleLogs is used to store the list of borehole logs associated to this user
  const [boreHoleLogs, setBoreHoleLogs] = useState([]);
  const [searchFilter, setSearchFilter] = useState({"file_names": [], "project_names": [], "uuids": []});

  //loading controls a loader for the list
  const [loading, setLoading] = useState(false);
  //modal controls whether the borehole details modal is displayed
  const [modal, setModal] = useState(false);


  //the following states provide details for the borehole details modal, maybe we can consolidate these into one state variable?
  const [SelectedDataItem, setSelectedDataItem] = useState(null);
  const [SelectedData, setSelectedData] = useState(null);
  const [chatEnabled, setChatEnabled] = useState(null);


  // set a state to check if a info is edited
  const [boreHoleInfoUpdate, setBoreHoleInfoUpdate] = useState(false);

  const [projectNames, setProjectNames] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [uuids, setUuids] = useState([]);

  // variable to store if user wants all docs or geotech
  const [task, setTask] = useState(2);

  const fetchBoreHoleLogs = async () => {
    setProjectNames([])
    setFileNames([])
    setLoading(true);
    return httpClientPy
      .get(`/geo/records/meta?project_id=${props.project.id}&geotech=${props.pageView !== undefined ? props.pageView : 2}`)
      .then((result) => {
        let file_names = result.data.file_names
        let project_names = result.data.project_names
        let uuids = result.data.uuids

        let project_names_list = []
        let file_names_list = []
        let uuids_list = []

        project_names.forEach((item) => {
          project_names_list.push({value: item, label: item, group: 'project_name'})
        })

        file_names.forEach((item) => {
          file_names_list.push({value: item, label: item, group: 'file_name'})
        })

        uuids.forEach((item) => {
          uuids_list.push({value: item, label: item, group: 'uuid'})
        })

        setProjectNames(project_names_list)
        setFileNames(file_names_list)
        setUuids(uuids_list)
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if(props.project) {
      fetchBoreHoleLogs();
    }
  }, [props.project, props.pageView]);


  function filterRecords(selections) {
    if (selections.length > 0) {
      selections.forEach((item) => {
        if (item.group == 'project_name') {
          setSearchFilter({...searchFilter, project_names: [...searchFilter.project_names, item.value]})
        } else if (item.group == 'file_name') {
          setSearchFilter({...searchFilter, file_names: [...searchFilter.file_names, item.value]})
        } else {
          setSearchFilter({...searchFilter, uuids: [...searchFilter.uuids, item.value]})
        }
      })
    } else {
      setSearchFilter({"file_names": [], "project_names": [], "uuids": []})
    }
  }

  useEffect(() => {
    if(boreHoleInfoUpdate) {
      fetchBoreHoleLogs()
      if(SelectedDataItem) {
        loadDataItem(SelectedDataItem)
      }
      setBoreHoleInfoUpdate(false)
    }
  }, [boreHoleInfoUpdate]);


  // for chat
  const loadDataItem = async (item) => {
    item.extraction_requested = true;
    setTask(2)
    setSelectedData(item)
    setModal(true);
    setChatEnabled(true)  
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#800080',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#800080',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  
  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const groupedOptions = [
    {
      label: 'Projects',
      options: projectNames,
    },
    {
      label: 'Files',
      options: fileNames,
    },
    {
      label: 'UUIDs',
      options: uuids,
    },
  ];

  const handleChatClose = () => {
    setChatEnabled(false);
  }


  return (
    <>
      <div className='d-flex bg-white min-vh-100'>
        <div id="sidebarParent">
        </div>
        <div  className="document-list-page flex-fill">
          <Card className="h-100 border-0 ">
          <CardHeader style={{backgroundColor:'#fbfbfb'}}>
            <div className='py-2 w-100'>
            <div className="d-flex">
              <div style={{ width: '275px', flexShrink: 0 }}>
                <DataPageUploader
                  mode={props.pageView}
                  uploadUrl="/geo/record/upload"
                  onComplete={() => fetchBoreHoleLogs()}
                  projectId ={props.project.id}
                />
              </div>
              <div className="flex-grow-1 ps-3">
                <h3 className='title-bold ms-2'>{props.pageView === 0 ? "Project Documents & Plans" : props.pageView === 1 ? "Geotech Reports" : props.pageView === 3 ? "Building codes, specs and regulations" : "Standard CAD Details"}</h3>
                <div>
                  <Select
                    className="basic-multi-select filter-record-select-sm text-center ms-2"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="filterFileName"
                    isMulti={true}
                    placeholder={props.pageView === 1 ? "Search for a Project or File Name": "Search for a File Name"}
                    onChange={filterRecords}
                    components={{ ValueContainer }}
                    closeMenuOnSelect={false}
                    isRtl={false}
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                  />
                </div>
              </div>
            </div>
            
            </div>
          </CardHeader>
          {loading === false ?
              <>
              {props.project && props.project.id ?
                  <>
                  {props.pageView !==1 ?
                    <CardBody className="overflow-auto" style={{ zIndex: 0 }}>
                      <DataList 
                          loading={loading} 
                          project={props.project}
                          type={props.pageView}
                          recordClicked={(item)=>loadDataItem(item)} 
                          boreHoleInfoUpdate={(status)=>setBoreHoleInfoUpdate(status)}
                          recordFilters={searchFilter}
                      />
                    </CardBody>
                    :
                    <>
                      <UploadedGeotechList 
                        project={props.project}
                        type={props.pageView}
                        boreHoleInfoUpdate={(status)=>setBoreHoleInfoUpdate(status)}
                        recordFilters={searchFilter}
                      />
                  </>
                  }
              </>
            :
            <div className="container-flex">
              <div className="row">
                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                    <h3><b>You've not uploaded any documents of this type yet</b></h3>
                    <div className="pt-2">Upload some PDF files first to get started, processing takes about 15 minutes per file.</div>
                </div>
              </div>
            </div>
            }
            </>
            :
            <div className="container-flex">
              <div className="row">
                <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                    <Loader loading={loading} size='large'/>
                </div>
              </div>
            </div>
          }
          {props.project && props.project.id && SelectedData && chatEnabled &&
            <div className="container-flex">
              <SearchResult 
                project={props.project} 
                initMessage={null} 
                historicalMsg={null}
                lastId={null}
                handleChatClose={()=>handleChatClose()}
                task={task}
                fileType={SelectedData.geotech}
                selectedData={SelectedData}
              />
            </div>
          }
          </Card>
        </div>
      </div>
    </>
  );
};

export default UploadsList;



const ValueContainer = ({ children, ...props }) => {
  if (children[0] !== null) {
    const length = children[0].length
    let tmpChildren = [...children];
    if(length >= 2){
      tmpChildren[0] = `${length} options selected`
    }

    return (
      <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
    );
  } else {
    return (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    );
  }
};