import { faArrowLeft, faBuilding, faDatabase, faFolder, faCity, faDrawPolygon, faBook, faCloudArrowUp, faMountainSun, faScrewdriverWrench, faEllipsis, faUsers, faList, faGrip, faCocktail, faCoins, faRobot, faArrowUpRightFromSquare, faFolderTree, faBolt, faUserTie, faCaretDown, faListCheck, faPenNib, faUpload, faGraduationCap, faTasks, faTable, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import authenticationHandler from '../../utils/authenticationHandler';
import { Modal, ModalBody, ModalHeader, Collapse, Tooltip } from 'reactstrap';
import { useEffect, useState } from 'react';
import httpClientPy from '../../utils/httpClientPy';
import AccountDropdown from '../shared/AccountDropdown';
import { logEvent, setMixpanelID } from '../shared/Mixpanel';
import AgsUploaderVerify from '../map/dataTab/AgsUploaderVerify';
import NewProjectModal from './SideBarComponents/NewProjectModal';
import UploadModal from '../shared/UploadModal';
import PricingModal from '../shared/payments/Pricing';


const SideBar = (props) => {

    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(true);
    const [toggleNewProjectModal, setToggleNewProjectModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedBtnMain, setSelectedBtnMain] = useState(null);
    const [btnloaded, setBtnLoaded] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const toggleUploadModal = () => setUploadModal(!uploadModal);

    useEffect(() => {
        // get the url
        const url = window.location.pathname;
        // get the last part of the url
        const urlArray = url.split('/');
        const lastUrl = urlArray[urlArray.length - 1];
        // set the selected button
        setSelectedBtnMain(lastUrl);
    }, [props.page]);

    useEffect(() => {
        if (props.page === 'detail' || props.page === 'umanage' || props.page === 'training') { return }
        let selectedPage = localStorage.getItem("selectedPage");
        if (selectedPage) {
            if (selectedPage === 'PROJ') {
                navigateToProjects();
            }
            else if (selectedPage === 'LIBR') {
                navigateToLibraries();
            }
        }
    }, []);

    const handleAddProjectClick = (project_type) => {
        navigate('/map', { state: {project: true, project_type: project_type} });
    }

    const navigateToProjectDetails = (id) => {
        navigate('/project/detail', { state: {id: props.project.id} });
        props.closeChat()
        if (props.page === 'detail') {
            props.setSubpage('overview')
            props.setType(null)
        }
    }

    const navigateToMapWithDetails = () => {
        navigate(`/map`, {state: {project_detail: props.project}});
    }

    const navigateToDesignData = () => {
        logEvent('View Design Data', {"Type": "Design Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'design', type: 0}});
        // if already on /project/data then call props function
        if (props.page === 'detail') {
            props.setSubpage('design')
            props.setType(0)
        }
    }

    const navigateToCadData = () => {
        logEvent('View CAD Data', {"Type": "CAD Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'cad', type: 2}});
        // if already on /project/data then call props function
        if (props.page === 'detail') {
            props.setSubpage('cad')
            props.setType(2)
        }
    }

    const navigateToGeotechData = () => {
        logEvent('View Geotech Data', {"Type": "Geotech Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'geotech', type: 1}});
        if (props.page === 'detail') {
            props.setSubpage('geotech')
            props.setType(1)
        }
    }

    const navigateToRegData = () => {
        logEvent('View Regulations Data', {"Type": "Regulations Data"})
        navigate(`/project/detail`, {state: {project: props.project, subpage: 'reg', type: 3}});
        if (props.page === 'detail') {
            props.setSubpage('reg')
            props.setType(3)
        }
    }

    const navigateToProjects = () => {
        setSelectedBtnMain('projects');
        localStorage.setItem("selectedPage", 'PROJ');
        navigate(`/projects`);
    }

    const navigateToTraining = () => {
        setSelectedBtnMain(null);
        navigate(`/training`);
    }

    const navigateToLibraries = () => {
        setSelectedBtnMain('library');
        localStorage.setItem("selectedPage", 'LIBR');
        navigate(`/library`);
    }

    const handleLogout = () => {
        authenticationHandler.logout();
        navigate("/log-in");
    };


    const toggleNewProject = () => {
        setToggleNewProjectModal(!toggleNewProjectModal);
    }

    const accountSettings = () => {
    // navigate to external website www.civils.ai/account
    window.open('https://www.civils.ai/account');
    };

    const discordCommunity = () => {
        window.open('https://discord.gg/UFaKqhGNmb');
    };


    const subscriptionSettings = () => {
        // window.open('https://www.civils.ai/create-portal-session') with userInfo.customer as input parameter
        window.open(`https://www.civils.ai/create-portal-session?customer=${props.userInfo.customer}`);
    };

    const toggleData = () => setDropdownOpen(prevState => !prevState);

    // check if libbtn is loaded
    useEffect(() => {
        
        if (document.getElementById('libbtn')) {
            setBtnLoaded(true);
        }

    }, [props.userInfo])
    

    return (
        <>
            <Offcanvas show={true} backdrop={false} scroll={true} className='project-sidebar d-none d-md-flex'>
                <Offcanvas.Body className="d-flex flex-column px-2">
                    <div className='logo ps-1'>
                        <img src="assets/logo_white.png" alt="Civils.ai" />
                        <span className="fs-4">Civils.ai</span>
                    </div>
                    <hr className='hr-custom'></hr>

                    {props.userInfo && (props.userInfo.subscription.subscription_id !== 1 || props.userInfo.is_enterprise) ?
                        <>
                        {props.page === 'list' || props.page === 'umanage' || props.page === 'training' ? (
                            <>
                            <a className={`${selectedBtnMain === 'projects' ? 'sub-item-disabled mb-1' : 'sub-item mb-1'}`} onClick={() => navigateToProjects()}>
                                <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
                                <span className="">Projects</span>
                            </a>
                            {props.userInfo && props.userInfo.is_enterprise ? (
                                <a id='libbtn' className={`${selectedBtnMain === 'library' ? 'sub-item-disabled mb-1' : 'sub-item mb-1'}`} onClick={() => navigateToLibraries()}>
                                    <span><FontAwesomeIcon className="me-3" icon={faBook}/></span>
                                    <span className="">Libraries</span>
                                </a>
                            ) : (
                                <>
                                    <a id='libbtn' className={`${selectedBtnMain === 'library' ? 'sub-item-disabled mb-1' : 'sub-item mb-1'}`} onClick={() => null}>
                                        <span><FontAwesomeIcon className="me-3" icon={faBook}/></span>
                                        <span className="">Libraries</span>
                                    </a>
                                    {btnloaded === true && (
                                        <Tooltip target="libbtn" isOpen={tooltipOpen} placement="right" toggle={toggle}>
                                            This feature is only available for enterprise users. Please contact info@civils.ai for more information.
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            </>
                        ) : (
                            <>
                                <a className={`${props.subpage === 'overview' ? 'sub-item-disabled' : 'sub-item'}`} onClick={() => navigateToProjectDetails()}>
                                    <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
                                    <span className="">Project</span>
                                </a>

                                <a className={`sub-item mb-1 d-none`} onClick={() => alert("Tasks")}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <span><FontAwesomeIcon className="me-2" icon={faTasks}/></span>
                                            <span className="">Task</span>
                                        </div>
                                        <span><FontAwesomeIcon className="me-2" icon={faChevronRight}/></span>
                                    </div>
                                </a>
                                <a className={`sub-item mb-1 d-none`} onClick={() => alert("Tasks Output")}>
                                    <span><FontAwesomeIcon className="me-2" icon={faTable}/></span>
                                    <span className="">Task Outputs</span>
                                </a>
                                <hr className='hr-custom'></hr>
                                {props.userInfo && props.userInfo.is_enterprise && props.userInfo.allow_digitise && 
                                    <a className={`${props.subpage === 'geotech' ? 'sub-item-disabled' : 'sub-item'} mb-1`} onClick={() => {props.setType(1); props.setSubpage('geotech')}}>
                                        <span><FontAwesomeIcon className="me-2" icon={faTable}/></span>
                                        <span className="">Geotech</span>
                                    </a>
                                }
                            </>
                        )}
                        </>
                    : (
                        <>
                            <a className={`sub-item mb-1`} onClick={() => props.page === 'umanage' || props.page === 'training' ? navigateToProjects() : props.togglePricingModal()} >
                                <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
                                <span className="">Projects</span>
                            </a>
                            <a className={`sub-item mb-1`} onClick={() => props.page === 'umanage' || props.page === 'training' ? navigateToProjects() : props.togglePricingModal()}>
                                <span><FontAwesomeIcon className="me-3" icon={faBook}/></span>
                                <span className="">Libraries</span>
                            </a>
                        </>
                    )}
                    <div className="mt-auto w-100"> {/* This pushes the logout button to the bottom */}
                        
                        <hr className='hr-custom'></hr>
                        {props.userInfo && (props.userInfo.subscription.subscription_id !==1 || props.userInfo.is_enterprise) ?
                        <>
                            {

                                (props.page === 'detail' || props.page === 'data' || props.page === 'umanage') ? (
                                    <a className={`sub-item mb-1`} onClick={() => navigateToProjects()}>
                                        <span><FontAwesomeIcon className="me-2" icon={faGrip}/></span>
                                        <span className="">Home</span>
                                    </a>
                                ) : (
                                    null
                                )
                            }
                            {
                                (props.page === 'training') ? (
                                    <a className={`sub-item-disabled mb-1`} onClick={() => navigateToTraining()}>
                                        <span><FontAwesomeIcon className="me-2" icon={faGraduationCap}/></span>
                                        <span className="">Training</span>
                                    </a>
                                ) : (
                                    props.page === 'list' &&
                                    <a className={`sub-item mb-1`} onClick={() => navigateToTraining()}>
                                        <span><FontAwesomeIcon className="me-2" icon={faGraduationCap}/></span>
                                        <span className="">Training</span>
                                    </a>
                                )
                            }
                        </>
                        :
                            <a className={`sub-item mb-1`} onClick={() => navigateToTraining()}>
                                <span><FontAwesomeIcon className="me-2" icon={faGraduationCap}/></span>
                                <span className="">Training</span>
                            </a>
                        }

                        <AccountDropdown 
                            userInfo={props.userInfo}
                            admin={props.admin}
                        />
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            {/* <Modal id="newProject" size="l" isOpen={toggleNewProjectModal} toggle={toggleNewProject} centered={true}>
                <NewProjectModal title="Your new project is starting..."
                                toggleModal = {()=> toggleNewProject()} 
                                handleChoiceClick = {(project_type) => handleAddProjectClick(project_type)}/>
            </Modal> */}
            {props.userInfo &&
                <UploadModal 
                    isOpen={uploadModal} 
                    toggle={toggleUploadModal} 
                    userInfo={props.userInfo}
                    project={props.project}
                    extraDetails={props.projectExtraDetails}
                    onComplete={() => props.onComplete()}
                >
                </UploadModal>
            }
        </>
    )
}

export default SideBar;