import { toast } from "react-toastify";
import RunDetailCard from "./RunDetailCard";
import httpClientPy from "../../../../utils/httpClientPy";
import { useEffect, useState } from "react";


const RecentWorkflowRuns = (props) => {

    const [loading, setLoading] = useState(true);
    const [recentRuns, setRecentRuns] = useState([]);

    const fetchRecords = async (limit, names_filter) => {
        
        return httpClientPy
        .post('/workflow/run/outputs',
            {
                project_id: props.project.id,
                limit: limit,
                offset: 0,
                sort_by: 'created_at',
                sort_order: 'desc',
                categories_filter: [],
                names_filter: names_filter
            })
        .then((result) => {
            
            let results = result.data.outputs;

            if (names_filter.length === 0) {
                results = results.filter((result) => {
                    return !["Extract Borehole Data"].includes(result.workflow_name);
                });
            }
            
            results.forEach((res) => {
                
                const formattedDate = new Date(res.created_at).toLocaleDateString('en-GB');
                setRecentRuns((prevRuns) => [...prevRuns, {
                    output_name: res.output_name, 
                    created_at: formattedDate, 
                    icon: res.icon, 
                    num_of_results: res.num_of_results,
                    run_id: res.run_id,
                    workflow_id: res.workflow_id,
                    is_digitise: res.is_digitisation,
                    workflow_name: res.workflow_name
                }]);

            });
        })
        .catch((err) => {
            
            toast.error('Failed to load the list of workflows, please contact info@civils.ai.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})

        }).finally(() => {

            setLoading(false);

        });
    }

    const collectRuns = async () => {
        // request for borehole extraction run first then the other 4 any
        await fetchRecords(1, ["Extract Borehole Data"]);
        await fetchRecords(10, []);
    }

    useEffect(() => {
        collectRuns();
    }, []);

    useEffect(() => {
        if (recentRuns.length > 4) {
            setRecentRuns(recentRuns.slice(0, 4));
        }
    }, [recentRuns]);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <h5 className="mb-0"><b>Recent workflow outputs</b></h5>
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="d-flex mt-2 flex-wrap gap-2">
                        {recentRuns.length > 0 ? (
                            recentRuns.map((run, index) => {
                                return (
                                    <RunDetailCard key={index} name={run.output_name} created_at={run.created_at} 
                                                   icon={run.icon} num_of_results={run.num_of_results} 
                                                   run_id={run.run_id} workflow_id={run.workflow_id}
                                                   project_id={props.project.id} is_digitise={run.is_digitise}
                                                   workflow_name={run.workflow_name}
                                    />
                                )
                            })
                        ) : (
                            <div className="mt-3">
                                <p className="text-center text-muted">No recent workflow runs</p>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    )

}

export default RecentWorkflowRuns;