import Loader from "../../shared/Loader";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import GeoRecordDetailsButton from "../../geoRecords/geoRecordDetailsButton";
import ProgressCounter from "../../map/dataTab/ProgressCounter";
import Button from "../../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Modal, ModalBody, Input, FormGroup, Tooltip, Spinner } from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, faDatabase, faList, faClock,faTriangleExclamation, faCloudArrowUp,faMagnifyingGlassChart, 
        faDownload,
        faRefresh} from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import httpClientPy from "../../../utils/httpClientPy";
import ImageCard from "../../map/dataTab/ImageCard";
import EditCadForm from "../../map/dataTab/EditCadForm";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const DataList = (props) => {
    //geoRecords is used to store and filter the georecords
    const [filteredgeoRecords, setFilteredGeoRecords] = useState();
    
    // geoRecordId is used to store the id of the georecord that is being updated
    const [selected, setSelected] = useState([]);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [cadDownloadLoading, setCadDownloadLoading] = useState(false);
    const [isEditCad, setIsEditCad] = useState(false);
    const [cadEditInfo, setCadEditInfo] = useState({'id': null, 'pdf_url': null});
    const [totalRows, setTotalRows] = useState(100);
    const [loading, setLoading] = useState(false);
    const [allIds, setAllIds] = useState([]);
    const [allReadyIds, setAllReadyIds] = useState([]);
    const [currentSortBy, setCurrentSortBy] = useState('uploaded');
    const [currentSortOrder, setCurrentSortOrder] = useState('desc');
    const link = useRef();

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const fetchRecords = async (page, rowsPerPage, sortBy, sortOrder) => {
        setLoading(true);
        setFilteredGeoRecords([]);
        return httpClientPy
        .post('/geo/records',
            {
            project_id: props.project.id,
            geotech: props.type !== undefined ? props.type : 2,
            limit: rowsPerPage,
            offset: (page-1)*rowsPerPage,
            sort_by: sortBy ? sortBy : currentSortBy,
            sort_order: sortOrder ? sortOrder : currentSortOrder,
            search: props.recordFilters
            })
        .then((result) => {
            setFilteredGeoRecords(result.data.data);
            setTotalRows(result.data.total_rows.length);
            setAllIds(result.data.total_rows);
            setAllReadyIds(result.data.total_rows_ready);
            if (props.type === 1){
                props.setBoreHoleLogs(result.data.data);
            }
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    //use effect which selected is changed
    useEffect(() => {
        if (props.type === 1){
            props.handleCheckboxChange(selected)
        }
    }, [selected])


    const handleCheckboxChange = (item) => {
        if (selected.includes(item)) {
            setSelected(selected.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelected([...selected, item]);
        }
    };

    const handleRowClick = (row) => {
        if (row.geotech === 2 || row.status !== 'ready') {
            return;
        }
        else if (row.geotech===1 && row.status.toLowerCase() === 'ready'){
            handleCheckboxChange(row.id)
            if(row.position && row.position.lat && row.position.lng){
                props.recordClicked(
                    {
                        lat: row.position.lat,
                        lng: row.position.lng,
                    }
                );
            } else{
                props.recordClicked(0);
            }
        }
        else{
            props.recordClicked(row);
        }  
    };


    const triggerDelete = (id, type) => {
        httpClientPy.delete(`/geo/record/delete?geo_record_id=${id}&record_type=${type}&record_id=0`)
        .then((response) => {
            if (response.data.status === true) {
                props.boreHoleInfoUpdate(id);
                fetchRecords(currentPage, currentRowsPerPage, currentSortBy, currentSortOrder);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    // for geotech
    useEffect(() => {
        if(props.downloadSelection === 'visible'){
            if(filteredgeoRecords){
                // let start = 0
                // if (currentPage > 1) {
                //     start = (currentPage-1)*currentRowsPerPage-1;
                // }
                // // index end
                // let end = (currentPage)*currentRowsPerPage;
                
                // clone filteredgeoRecords
                let clone = [...filteredgeoRecords];
                const readyIds = clone.filter(row => row.status.toLowerCase() === 'ready').map(row => row.id);
                setSelected(readyIds);
            }
        }
        else if(props.downloadSelection === 'all'){
            if (filteredgeoRecords) {
                setSelected(allReadyIds);
            }
        } 
        else{
            setSelected([]);
        }
    }, [props.downloadSelection, filteredgeoRecords, currentRowsPerPage]);

    const downloadCadPDF = (id) => {
        setCadDownloadLoading(true);
        link.current = document.createElement("a");
        httpClientPy.get(`/geo/record/fetch/pdf?record_id=${id}&project_id=${props.project.id}`, {
        responseType: 'blob'  // Important: Set response type as 'blob'
        }).then(response => {
            const file = new Blob(
                [response.data], 
                { type: 'application/pdf' }  // Set the MIME type to 'application/pdf'
            );
            let url = URL.createObjectURL(file);
            link.current.href = url;
            link.current.download = url.split("/").pop();
            link.current.click();
            setCadDownloadLoading(false);
        }).catch(error => {
            console.error('Error fetching PDF:', error);
        });
    }

    const editCad = (id, file_url) => {
        setCadEditInfo({'id': id, 'pdf_url': file_url});
        setIsEditCad(true);
    }

    const refreshCad = (id) => {
        setCadDownloadLoading(true);
        httpClientPy.post(`/geo/record/doc/rectify`,
        {
            record_ids: [id],
            to_class: 2,
        })
        .then((response) => {
            if (response.data.status === 'True') {
                props.boreHoleInfoUpdate(true);
                fetchRecords(currentPage, currentRowsPerPage, currentSortBy, currentSortOrder);
                setCadDownloadLoading(false);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const columns = []

    if (props.type === 1) {
        columns.push({
            name: '',
            width: '35px',
            cell: (row) => (
                <>
                    <FormGroup check inline>
                        <Input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(row.id)}
                            checked={selected.includes(row.id)}
                            disabled={row.status.toLowerCase() !== 'ready'}
                        />
                    </FormGroup>
                </>
            ),
        })

        columns.push({
            name: 'File name',
            selector: row => {
                return row.file_name_updated
            },
            minWidth: '100px',
            maxWidth: props.type === 2 ? '450px' : '100%',
            center: true,
            sortable: true,
            id: 'file_name',
            cell: (row) => {
                return row.file_name_updated
            },
        })

        columns.push({
            name: 'Project title',
            id: 'project_title',
            selector: row => row.description,
            minWidth: '100px',
            maxWidth: '200px',
            center: true,
            sortable: true
        })

        columns.push({
            name: 'Added',
            id: 'uploaded',
            selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
            width: '110px',
            center: true,
            sortable: true,
            cell: (row) => (
                <span className="badge rounded-pill darkGrey documentBadge">
                    <span><FontAwesomeIcon icon={faClock} /></span>
                        <span className="ms-1">
                            {row.created_datetime
                            ? moment(row.created_datetime).format('DD/MM/YYYY')
                            : 'Unknown'}
                        </span>
                </span>
            ),
        })

        columns.push({
            name: 'Details',
            width: '90px',
            center: true,
            cell: (row) =>
            row.status.toLowerCase() === 'ready' && (
                <GeoRecordDetailsButton
                    onClick={() => props.onViewDetails(row)}
                    label={<FontAwesomeIcon className="icon-view" icon={faMagnifyingGlassChart} />}
                    className="btn"
                    geoRecord={row}
                />
                )
        })

        columns.push({
            name: 'Status',
            id: 'status',
            width: '110px',
            selector: row => row.status,
            center: true,
            sortable: true,
            cell: (row) => <ProgressCounter status={row.status} requestedDate={row.created_datetime} />,
        })

        columns.push({
            name: '',
            width: '50px',
            center: true,
            cell: (row) => {
                return (
                <>
                    {row.is_private && row.status !== 'pending' && row.status !== 'post-pending' && (
                        <span>
                            <DeleteConfirmation itemId={row.id} type='geotech' onDeleteConfirm={triggerDelete} />
                        </span>
                    )}
                </>
                );
            },
        })

    } else if (props.type === 2) {

        columns.push({
            name: '',
            width: '220px',
            cell: (row) => (
                <>
                {row.image_url &&
                    <ImageCard
                        image_url={row.image_url}
                    />
                }
                </>
            )
        })

        columns.push({
            name: 'File name',
            selector: row => {
                return row.original_file_name;
            },
            minWidth: '100px',
            maxWidth: props.type === 2 ? '450px' : '100%',
            center: true,
            sortable: true,
            id: 'file_name',
            cell: (row) => {
                return row.original_file_name;
            },
        })

        columns.push({
            name: 'Drawing Title',
            id: 'drawing_title',
            width: '220px',
            sortable: true,
            cell: (row) => (
            <>
                {row.drawing_title ? (
                    <span>{row.drawing_title}</span>
                ) : (
                    <span>{row.original_file_name}</span>
                )}
            </>
            )
        })

        columns.push({
            name: 'Project title',
            id: 'project_title',
            selector: row => props.project.name,
            minWidth: '100px',
            maxWidth: '200px',
            center: true,
            sortable: false
        })

        columns.push({
            name: 'Added',
            id: 'uploaded',
            selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
            width: '110px',
            center: true,
            sortable: true,
            cell: (row) => (
                <span className="badge rounded-pill darkGrey documentBadge">
                    <span><FontAwesomeIcon icon={faClock} /></span>
                        <span className="ms-1">
                            {row.created_datetime
                            ? moment(row.created_datetime).format('DD/MM/YYYY')
                            : 'Unknown'}
                        </span>
                </span>
            ),
        })

        columns.push({
            name: 'Status',
            id: 'status',
            width: '110px',
            selector: row => row.status,
            center: true,
            sortable: true,
            cell: (row) => <ProgressCounter status={row.status} requestedDate={row.created_datetime} />,
        })

        columns.push({
            name: 'Data',
            width: '70px',
            cell: (row) => (
            <>
            {row.status === 'ready' ? (
                <>
                {row.drawing_title ? (
                    <Button
                        label={<FontAwesomeIcon icon={faMagnifyingGlassChart}/>}
                        className="btn"
                        onClick={() => editCad(row.id, row.pdf_url)}
                        disabled={cadDownloadLoading}
                    />
                ) : (
                    <>
                    <Button
                        label={<FontAwesomeIcon icon={faRefresh}/>}
                        id="refreshcad"
                        className="btn"
                        onClick={() => refreshCad(row.id)}
                        disabled={cadDownloadLoading}
                    />
                    <Tooltip
                    isOpen={tooltipOpen}
                    target="refreshcad"
                    toggle={toggle}
                    >
                    We updated our CAD extraction, click here to reprocess this CAD.
                    </Tooltip>
                    </>
                )}
                </>
            ) : (
                null
            )}
            </>
            )
        })

        columns.push({
            name: 'Download',
            width: '120px',
            cell: (row) => (
                <>
                <Button
                    label={<FontAwesomeIcon icon={faDownload}/>}
                    className="btn"
                    onClick={() => downloadCadPDF(row.id, row.pdf_url)}
                    disabled={cadDownloadLoading}
                />
                </>
            )
        })

        columns.push({
            name: '',
            width: '50px',
            center: true,
            cell: (row) => {
                return (
                <>
                    {row.is_private && row.status !== 'pending' && row.status !== 'post-pending' && (
                        <div className="d-flex justify-content-center">
                        {cadDownloadLoading ? (
                            <>
                            <Spinner size="sm" color="primary" />
                            </>
                        ) : (
                            <DeleteConfirmation itemId={row.id} type='all' onDeleteConfirm={triggerDelete} disabled={cadDownloadLoading} />
                        )}
                        </div>
                    )}
                </>
                );
            },
        })

    } else {

        columns.push({
            name: 'File name',
            selector: row => {
                return row.original_file_name;
            },
            minWidth: '100px',
            maxWidth: props.type === 2 ? '450px' : '100%',
            center: true,
            sortable: true,
            id: 'file_name',
            cell: (row) => {
                return row.original_file_name;
            },
        })

        columns.push({
            name: 'Project title',
            id: 'project_title',
            selector: row => props.project.name,
            minWidth: '100px',
            maxWidth: '200px',
            center: true,
            sortable: false
        })

        columns.push({
            name: 'Added',
            id: 'uploaded',
            selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
            width: '110px',
            center: true,
            sortable: true,
            cell: (row) => (
                <span className="badge rounded-pill darkGrey documentBadge">
                    <span><FontAwesomeIcon icon={faClock} /></span>
                        <span className="ms-1">
                            {row.created_datetime
                            ? moment(row.created_datetime).format('DD/MM/YYYY')
                            : 'Unknown'}
                        </span>
                </span>
            ),
        })

        columns.push({
            name: 'Status',
            id: 'status',
            width: '110px',
            selector: row => row.status,
            center: true,
            sortable: true,
            cell: (row) => <ProgressCounter status={row.status} requestedDate={row.created_datetime} />,
        })

        columns.push({
            name: '',
            width: '50px',
            center: true,
            cell: (row) => {
                return (
                <>
                    {row.is_private && row.status !== 'pending' && row.status !== 'post-pending' && (
                        <div className="d-flex justify-content-center">
                            <DeleteConfirmation itemId={row.id} type='all' onDeleteConfirm={triggerDelete} />
                        </div>
                    )}
                </>
                );
            },
        })

    }
  
    const customStyles = {
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
                zIndex: 0,
            },
        },
        rows: {
            style: {
                cursor: props.type === 2 ? 'default' : 'pointer',
            },
        },
    };


    const handleSort = (column, sortDirection) => {
        fetchRecords(currentPage, currentRowsPerPage, column.id, sortDirection);
        setCurrentSortBy(column.id);
        setCurrentSortOrder(sortDirection);
    };


    useEffect(() => {
        fetchRecords(currentPage, currentRowsPerPage);
    }, [props.type, props.project, props.recordFilters]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchRecords(page, currentRowsPerPage);
    }

    const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
        setCurrentRowsPerPage(newRowsPerPage);
        fetchRecords(currentPage, newRowsPerPage);
    }


    return (
        <>
        <div>
            {loading &&
                <div className="mt-5">
                    <Loader loading={loading} />
                </div>
            }
            {filteredgeoRecords && filteredgeoRecords.length > 0 && (
            <>
                <DataTable
                    className="sticky-top bg-white text-center geo-records-list-holder custom-nav doc-table-full"
                    fixedHeader
                    pagination
                    paginationServer={true}
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[5, 10, 15, 25]}
                    paginationPerPage={currentRowsPerPage}
                    paginationDefaultPage={currentPage}
                    persistTableHead 
                    sortServer
                    onSort={handleSort}
                    highlightOnHover
                    defaultSortFieldId={currentSortBy}
                    defaultSortAsc={currentSortOrder === 'asc'}
                    columns={columns}
                    data={filteredgeoRecords}
                    onRowClicked={handleRowClick} // Add this line to handle row click
                    customStyles={customStyles}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handlePageChange}
                />
            </>
            )}

            {(!filteredgeoRecords || filteredgeoRecords.length === 0) && !loading && (
            <>
                <div className="container-flex">
                    <div className="row">
                        <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '70vh' }}>
                            <h3><b>You've not uploaded any documents of this type yet</b></h3>
                            <div className="pt-2">Upload some PDF files first to get started, processing takes about 15 minutes per file.</div>
                        </div>
                    </div>
                </div>
            </>
            )}
        </div>
        <Modal id="editCad" centered={true} fullscreen={true} isOpen={isEditCad} toggle={()=>setIsEditCad(!isEditCad)}>
            <ModalBody className='h-100 p-0'>
                <EditCadForm 
                    toggleModal={()=>setIsEditCad(!isEditCad)}
                    fileUrl={cadEditInfo.pdf_url}
                    id={cadEditInfo.id}
                />
            </ModalBody>
        </Modal>
        </>
    );
};

export default DataList;
