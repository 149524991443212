import React, { useState } from "react";
import { useMap } from "react-leaflet";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import localStorageService from "../../utils/localStorageService";
import { useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import '../../styles/EarthAnimation.css'

import { faLayerGroup, faCaretLeft} from "@fortawesome/free-solid-svg-icons";
library.add(faLayerGroup, faCaretLeft);

const SearchBar = (props) => {
  const map = useMap();
  const location = useLocation();
  const params = new URLSearchParams(location.search);


  var mapStateFromRoute =
    params && params.get("lat") && params.get("lng")
      ? { 
          location: [
            parseFloat(params.get("lat")),
            parseFloat(params.get("lng")),
          ],
          zoom: 18,
        }
      : null;

  // if you have coordinates provided in URL then use these, otherwise use from local storage
  const [mapState, setMapState] = useState(
    mapStateFromRoute ?? null
  );


  const handlePlaceChange = (selectedPlace) => {
    if (!selectedPlace) return;

    geocodeByPlaceId(selectedPlace.value.place_id)
      .then((results) => {
        props.toggleIsUK(false)
        results[0].address_components.forEach((component) => {
          
          if (component.long_name.toLowerCase() === "united kingdom") {
            props.toggleIsUK(true)
          } 
        });
        
        const defaultZoom = 18;
        const location = results[0].geometry.location;
        const newState = {
          location: [location.lat(), location.lng()],
          zoom: defaultZoom,
          label: selectedPlace.label,
        };

        setMapState(newState);
        localStorageService.setItem("map_state", newState);
        map.setView(newState.location, defaultZoom);
        props.toggleSearchClicked(false)
      })
      .catch((error) => console.error(error));
  };

  const searchIcon = () => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      background: "url(assets/search.svg) no-repeat center center",
      content: '" "',
      display: "block",
      marginRight: 8,
      marginLeft: 8,
      height: 32,
      width: 32,
    },
  });


  return (
    <div
    >
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        minLengthAutocomplete={3}
        selectProps={{
          defaultInputValue: mapState ? mapState.label : "",
          isClearable: true,
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          },
          menuPlacement: "auto",
          menuPosition: "fixed",
          className: `map-search-autocomplete ${props.addBorehole && 'd-none'}`,
          menuPortalTarget: document.body,
          styles: {
            control: (base, state) => ({
              ...base,
              ...searchIcon(),
              border: state.isFocused ? 0 : 0,
              boxShadow: state.isFocused ? 0 : 0,
              "&:hover": {
                border: state.isFocused ? 0 : 0,
              },
            }),
          },
          onChange: (selectedPlace) => {
            handlePlaceChange(selectedPlace);
          },
          onFocus: () => {
            props.toggleSearchClicked(true)
          },
          placeholder: "Search address or place",
        }}
      />
    </div>
  );
};

export default SearchBar;
