import Loader from "../../shared/Loader";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import moment from "moment";
import { useEffect, useRef, useState} from "react";
import ProgressCounter from "../../map/dataTab/ProgressCounter";
import Button from "../../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Card, CardBody, Modal, ModalBody, Tooltip } from "reactstrap";
import { faFileExcel, faLocationDot, faMapPin, faFilePdf, faFileCode, faEye, faMapLocation, faDatabase, faList, faClock,faTriangleExclamation, faCloudArrowUp, faRefresh} from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import httpClientPy from "../../../utils/httpClientPy";
import { toast, ToastContainer } from "react-toastify";
import EditCadForm from "../../map/dataTab/EditCadForm";

library.add(faFileExcel, faMapPin, faLocationDot, faFilePdf, faMapLocation, faFileCode, faEye, faDatabase,faTriangleExclamation, faList, faClock, faCloudArrowUp);

const DocumentsTable = (props) => {
    const [documents, setDocuments] = useState([]);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const [loading, setLoading] = useState(false);
    const [cadDownloadLoading, setCadDownloadLoading] = useState(false);
    const [currentSortBy, setCurrentSortBy] = useState('uploaded');
    const [currentSortOrder, setCurrentSortOrder] = useState('desc');
    const [isEditCad, setIsEditCad] = useState(false);
    const [cadEditInfo, setCadEditInfo] = useState({});
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const currentPageRef = useRef(currentPage);
    const currentRowsPerPageRef = useRef(currentRowsPerPage);
    const currentSortByRef = useRef(currentSortBy);
    const currentSortOrderRef = useRef(currentSortOrder);
    
    useEffect(() => {
        currentPageRef.current = currentPage;
    }, [currentPage]);

    useEffect(() => {
        currentRowsPerPageRef.current = currentRowsPerPage;
    }, [currentRowsPerPage]);

    useEffect(() => {
        currentSortByRef.current = currentSortBy;
    }, [currentSortBy]);

    useEffect(() => {
        currentSortOrderRef.current = currentSortOrder;
    }, [currentSortOrder]);
        
    const fetchRecords = async (page, rowsPerPage, sortBy, sortOrder, is_trigger) => {
        let trig = is_trigger ? is_trigger : false;
        setLoading(!trig)
        if (!trig) {
            setDocuments([]);
        }
        
        return httpClientPy
        .post('/documents',
            {
                project_id: props.project.id,
                doc_type: props.docType -1,
                limit: rowsPerPage,
                offset: (page-1)*rowsPerPage,
                sort_by: sortBy ? sortBy : currentSortBy,
                sort_order: sortOrder ? sortOrder : currentSortOrder,
                search: props.searchFilter
            })
        .then((result) => {
            props.setCnt(Math.floor(Math.random() * 100) + 1);
            setDocuments(result.data.data);
            setTotalRows(result.data.total_rows.length);
            setLoading(false);
        })
        .catch((err) => {
            toast.error('Failed to load the list of documents, please contact info@civils.ai. Thank you for your understanding.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })
    }


    const handleRowClick = (row) => {
        if (row.status !== 'ready' || (row.drawing_title === null && row.category_name === 'Drawings')) {
            return;
        }
        if (row.category_name === 'Drawings') {
            setCadEditInfo({id: row.id, pdf_url: row.pdf_url})
            setIsEditCad(true)
        } else {
            props.updateTask(2)
        }
        props.updateSelectedData(row)
    };

    const triggerDelete = (id, type) => {
        httpClientPy.delete(`/geo/record/delete?geo_record_id=${id}&record_type=${type}&record_id=0`)
        .then((response) => {
            fetchRecords(currentPage, currentRowsPerPage, currentSortBy, currentSortOrder);
        }).catch((err) => {
            toast.error('Failed to delete the document, please contact info@civils.ai. Thank you for your understanding.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        });
    };

    const refreshCad = (id) => {
        setCadDownloadLoading(true);
        httpClientPy.post(`/geo/record/doc/rectify`,
        {
            record_ids: [id],
            to_class: 2,
        })
        .then((response) => {
            if (response.data.status === 'True') {
                props.boreHoleInfoUpdate(true);
                fetchRecords(currentPage, currentRowsPerPage, currentSortBy, currentSortOrder);
                setCadDownloadLoading(false);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleSort = (column, sortDirection) => {
        fetchRecords(currentPage, currentRowsPerPage, column.id, sortDirection, false);
        setCurrentSortBy(column.id);
        setCurrentSortOrder(sortDirection);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchRecords(page, currentRowsPerPage);
    }

    const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
        setCurrentRowsPerPage(newRowsPerPage);
        fetchRecords(currentPage, newRowsPerPage);
    }

    useEffect(() => {
        fetchRecords(currentPage, currentRowsPerPage, currentSortBy, currentSortOrder);
        // Set up interval to fetch records every 1 minute
        const intervalId = setInterval(() => {
            fetchRecords(currentPageRef.current, currentRowsPerPageRef.current, currentSortByRef.current, currentSortOrderRef.current, true);
        }, 60000); // 60000 milliseconds = 1 minute

        // Clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, [props.docType, props.project, props.searchFilter, props.reload]);

    const columns = []

    columns.push({
        name: 'File name',
        selector: row => {
            return row.file_name_updated
        },
        width: '60%',
        center: true,
        sortable: true,
        id: 'file_name',
        cell: (row) => {
            return row.file_name_updated
        },
    })

    columns.push({
        name: 'Category',
        id: 'category',
        selector: row => row.category_name,
        width: '10%',
        center: true,
        sortable: false
    })

    columns.push({
        name: 'Added',
        id: 'uploaded',
        selector: row => row.created_datetime ? new Date(row.created_datetime) : new Date(0), // Return raw datetime for sorting
        width: '10%',
        center: true,
        sortable: true,
        cell: (row) => (
            <span className="badge rounded-pill darkGrey documentBadge">
                <span><FontAwesomeIcon icon={faClock} /></span>
                    <span className="ms-1">
                        {row.created_datetime
                        ? moment(row.created_datetime).format('DD/MM/YYYY')
                        : 'Unknown'}
                    </span>
            </span>
        ),
    })

    columns.push({
        name: 'Status',
        id: 'status',
        width: '10%',
        selector: row => row.status,
        center: true,
        sortable: true,
        cell: (row) => <ProgressCounter status={row.status} requestedDate={row.created_datetime} isGeotech={false} />
    })

    columns.push({
        name: '',
        width: '10%',
        center: true,
        cell: (row) => {
            return (
            <>
                {row.is_private && row.status !== 'pending' && row.status !== 'post-pending' && (
                    <>
                        {row.drawing_title === null && row.category_name === 'Drawings' && (
                            <>
                                <Button
                                    label={<FontAwesomeIcon icon={faRefresh}/>}
                                    id="refreshcad"
                                    className="btn me-1"
                                    onClick={() => refreshCad(row.id)}
                                    disabled={cadDownloadLoading}
                                />
                                <Tooltip
                                    isOpen={tooltipOpen}
                                    target="refreshcad"
                                    toggle={toggle}
                                >
                                    Click to refresh the CAD drawing extraction process to get the latest version
                                </Tooltip>
                            </>
                        )}
                    
                        <span>
                            <DeleteConfirmation itemId={row.id} type='geotech' onDeleteConfirm={triggerDelete} />
                        </span>
                    </>
                )}
            </>
            );
        },
    })
  
    const customStyles = {
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: 'bold',
                zIndex: 0,
                backgroundColor: '#f8f9fa', // Bootstrap light grey for table headers
            },
        },
        rows: {
            style: {
                cursor: 'pointer',
                backgroundColor: '#f8f9fa', // Bootstrap light grey for table headers

            },
        },
    };

    return (
        <>
        <ToastContainer />
        <div className="d-flex flex-grow-1 flex-column">
            {loading &&

                <div className="my-5">
                    <Loader loading={loading} />
                </div>

            }
            {documents && documents.length > 0 && (
            <>
                <Card
                    className="w-100 border-0 d-flex flex-grow-1 flex-column"
                    style={{ overflowY: 'auto' }}
                >
                    <CardBody className="px-0 pt-0 d-flex flex-grow-1 bg-light flex-column">
                        <div style={{ overflowX: 'auto', width: '100%' }}>
                            <DataTable
                                className="sticky-top text-center geo-records-list-holder custom-nav"
                                pagination
                                paginationServer={true}
                                paginationTotalRows={totalRows}
                                paginationRowsPerPageOptions={[5, 10, 15, 25]}
                                paginationPerPage={currentRowsPerPage}
                                paginationDefaultPage={currentPage}
                                persistTableHead
                                sortServer
                                onSort={handleSort}
                                highlightOnHover
                                defaultSortFieldId={currentSortBy}
                                defaultSortAsc={currentSortOrder === 'asc'}
                                columns={columns}
                                data={documents}
                                onRowClicked={handleRowClick}
                                customStyles={customStyles}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                onChangePage={handlePageChange}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
            )}

            {(!documents || documents.length === 0) && !loading && (
            <>
                <div className="container">
                    <div className="row">
                        <div className='col-md-12 d-flex justify-content-center flex-column align-items-center' style={{ height: '50vh' }}>
                            <h3><b>You've not uploaded any documents of this type yet</b></h3>
                            <div className="pt-2">Upload some PDF files first to get started, processing takes about 15 minutes per file.</div>
                        </div>
                    </div>
                </div>
            </>
            )}
        </div>
        <Modal id="editCad" centered={true} fullscreen={true} isOpen={isEditCad} toggle={()=>setIsEditCad(!isEditCad)}>
            <ModalBody className='h-100 p-0'>
                <EditCadForm 
                toggleModal={()=>setIsEditCad(!isEditCad)}
                fileUrl={cadEditInfo.pdf_url}
                id={cadEditInfo.id}
                />
            </ModalBody>
        </Modal>
        </>
    );
};

export default DocumentsTable;