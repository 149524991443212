import { Card } from "react-bootstrap";
import { NodeType } from "../../../constants/workflow";
import BaseCard from "./BaseCard";

interface NodeCanvasSelectorProps {
  setSelectedNode: (type: NodeType | null) => void;
  data: {
    is_complete: boolean;
    is_fail: boolean;
    sourceNode: string;
  };
}

const NodeCanvasSelector = ({
  setSelectedNode,
  ...rest
}: NodeCanvasSelectorProps) => {
  const sourceNode = rest.data.sourceNode;

  return (
    <BaseCard {...rest} areNodesRunning={false} setAreNodesRunning={() => {}}>
      <div style={{ width: "200px" }} className="d-flex flex-column">
        <h6>
          <b>Add a node</b>
        </h6>
        <Card
          className={`nodes-card-preview ${sourceNode !== "selector" ? "d-none" : ""}`}
          style={{ width: "100%" }}
          onClick={() => setSelectedNode(NodeType.SEARCH)}
        >
          <div className="px-2 py-2">
            <div className="d-flex justify-content-between align-items-center">
              <span>Search</span>
            </div>
          </div>
        </Card>
        <Card
          className={`nodes-card-preview ${sourceNode !== "search" ? "d-none" : ""}`}
          style={{ width: "100%" }}
          onClick={() => setSelectedNode(NodeType.TASK_TABLE)}
        >
          <div className="px-2 py-2">
            <div className="d-flex justify-content-between align-items-center">
              <span>Table Task</span>
            </div>
          </div>
        </Card>
        <Card
          className={`nodes-card-preview mt-1 ${sourceNode !== "search" ? "d-none" : ""}`}
          style={{ width: "100%" }}
          onClick={() => setSelectedNode(NodeType.TASK_SUMMARY)}
        >
          <div className="px-2 py-2">
            <div className="d-flex justify-content-between align-items-center">
              <span>Summary Task</span>
            </div>
          </div>
        </Card>
        <Card
          className={`nodes-card-preview mt-1 ${sourceNode !== "search" ? "d-none" : ""}`}
          style={{ width: "100%" }}
          onClick={() => setSelectedNode(NodeType.TASK_CUSTOM)}
        >
          <div className="px-2 py-2">
            <div className="d-flex justify-content-between align-items-center">
              <span>Custom Task</span>
            </div>
          </div>
        </Card>
      </div>
    </BaseCard>
  );
};

export default NodeCanvasSelector;
