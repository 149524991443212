import React from "react";
import Button from "../shared/Button";

const GeoRecordDetailsButton = (props) => {
  return (
    <Button
      onClick={() => {
        props.onClick();
      }}
      label={props.label}
      className={props.className}
    />
  );
};

export default GeoRecordDetailsButton;
