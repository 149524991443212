import { useState, useEffect, useRef } from "react";
import httpClientPy from "../../../utils/httpClientPy";
import { UncontrolledAlert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { logEvent } from "../Mixpanel";
import GeneralLayout from "./GeneralLayout";

const GenericUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isFreeUser, setIsFreeUser] = useState(false);
  const [agsUpload, setAgsUpload] = useState(false);
  const [numberOfPdf, setNumberOfPdf] = useState(0);
  const [errorText, setErrorText] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const fileSize = useRef();

  useEffect(() => {
    if (
      props.userInfo &&
      !props.userInfo.is_enterprise &&
      props.userInfo.subscription_details &&
      props.userInfo.subscription_details.subscription_type === "free"
    ) {
      setIsFreeUser(true);
    } else {
      setIsFreeUser(false);
    }

    const handleFileDrop = (e) => {
      e.preventDefault();
      const files = Array.from(e.dataTransfer.files);
      handleChange(files);
    };

    window.addEventListener("dragover", (e) => e.preventDefault());
    window.addEventListener("drop", handleFileDrop);
    return () => {
      window.removeEventListener("dragover", (e) => e.preventDefault());
      window.removeEventListener("drop", handleFileDrop);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setProgress((prevCount) => {
          if (prevCount >= 100) {
            clearInterval(intervalId);
            return prevCount;
          } else {
            return prevCount + 1;
          }
        });
      }, 50 * fileSize.current);

      return () => {
        //wait 5 seconds before setting progress 0
        setTimeout(() => {
          clearInterval(intervalId);
          setProgress(0);
        }, 5000);
      };
    }
  }, [loading]);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
  }, [showSuccess]);

  const uploadFiles = () => {
    setShowConfirmation(false);
    setNumberOfPdf(0);
    let total_size_mb = 0;

    const formData = new FormData();

    fileSize.current = 1;
    for (var i = 0; i < uploadedFiles.length; i++) {
      formData.append("file_obj", uploadedFiles[i]);

      if (uploadedFiles[i].type === "application/pdf") {
        setNumberOfPdf(numberOfPdf + 1);
      }

      total_size_mb += uploadedFiles[i].size / 1000000;

      if (uploadedFiles[i].size > 300000) {
        fileSize.current += uploadedFiles[i].size / 2000000;
      }
    }

    let url = `/documents/upload?project_id=${props.projectId}&doc_category=${props.mode}`;

    setLoading(true);
    setShowSuccess(false);
    setShowError(false);
    setAgsUpload(false);
    setErrorText("");

    httpClientPy
      .post(url, formData)
      .then((response) => {
        if (response.data.info.length > 0) {
          setAgsUpload(true);
          props.updateAgsInfo(response.data.info);
        }
      })
      .catch((errorResponse) => {
        if (errorResponse.response.data) {
          let error_code = errorResponse.response.data.detail;
          if (error_code === 2) {
            setErrorText(
              "There is an error with the uploading process, please try again later or contact support at hello@civils.ai"
            );
          } else if (error_code === 3) {
            setErrorText(
              "The uploaded file is not a valid AGS4 format. Please upload a valid AGS4 file."
            );
          } else if (error_code === 4) {
            setErrorText(
              "You have reached the maximum number of documents that can be uploaded. Please upgrade your account to upload more documents. Contact your enterprise admin if you are on enterprise subscription"
            );
          } else if (error_code === 5) {
            setErrorText(
              "User is not part of the project. Please contact the project admin to add you to the project."
            );
          } else {
            setErrorText(
              "There is an error with the uploading process, please try again later or contact support at hello@civils.ai"
            );
          }
          setShowError(true);
        } else {
          setErrorText(
            "There is an error with the uploading process, please try again later or contact support at hello@civils.ai"
          );
          setShowError(true);
        }
      })
      .finally(() => {
        if (!showError) {
          if (props.mode === 0) {
            logEvent("Specifications Upload", {
              Event: "Specifications Upload Success",
            });
          } else if (props.mode === 1) {
            logEvent("Contracts Upload", { Event: "Contracts Upload Success" });
          } else if (props.mode === 2) {
            logEvent("Site Investigation & Surveys Upload", {
              Event: "Site Investigation & Surveys Upload Success",
            });
          } else if (props.mode === 3) {
            logEvent("Drawings Upload", { Event: "Drawings Upload Success" });
          } else if (props.mode === 4) {
            logEvent("Risk Registers Upload", {
              Event: "Risk Registers Upload Success",
            });
          } else if (props.mode === 6) {
            logEvent("Plan & Methods Upload", {
              Event: "Plan & Methods Upload Success",
            });
          } else {
            logEvent("Other Upload", {
              Event: "Other Document Upload Success",
            });
          }
          setShowSuccess(true);
        }
        props.onComplete();
        setLoading(false);
      });
  };

  const handleChange = (files) => {
    if (files.length === 0) {
      return;
    }

    if (props.mode === null) {
      setShowError(true);
      setErrorText("Please select the document type you want to upload.");
      return;
    }

    setUploadedFiles(files);
    setUploadedFilesCount(files.length);
    if (props.mode !== null) {
      setShowConfirmation(true);
    }
  };

  // if there is change to setUploadedFiles and props.mode is null then start upload
  useEffect(() => {
    if (uploadedFiles && props.mode === null) {
      uploadFiles();
    }
  }, [uploadedFiles]);

  return (
    <>
      <div id="chat_input_container" className="w-100 text-dark">
        <div className="w-100">
          {showError && errorText ? (
            <UncontrolledAlert color="warning" className="mb-0">
              <span className="title-bold">{errorText}</span>
            </UncontrolledAlert>
          ) : showSuccess ? (
            <>
              {agsUpload ? (
                <UncontrolledAlert color="success" className="mb-0">
                  <span className="title-bold">
                    All set, your AGS files are extracted{" "}
                  </span>
                  <span>
                    &nbsp;You can view the records in my geotech data.
                  </span>
                </UncontrolledAlert>
              ) : (
                <UncontrolledAlert color="success" className="mb-0">
                  <span className="title-bold">
                    All set, we're extracting the information from your uploaded
                    document.{" "}
                  </span>
                  <span>&nbsp;We'll email you when your file is ready.</span>
                </UncontrolledAlert>
              )}
            </>
          ) : null}
        </div>

        <GeneralLayout
          loading={loading}
          mode={props.mode}
          handleChange={handleChange}
          userInfo={props.userInfo}
          isFreeUser={isFreeUser}
          showError={showError}
          progress={progress}
          setErrorText={setErrorText}
          setShowError={setShowError}
        />
      </div>
      <Modal
        isOpen={showConfirmation}
        toggle={() => setShowConfirmation(!showConfirmation)}
        centered
      >
        <ModalHeader toggle={() => setShowConfirmation(!showConfirmation)}>
          <h4 className="title-bold">Confirm your uploaded files</h4>
        </ModalHeader>
        <ModalBody>
          <p>
            You are uploading{" "}
            <span className="title-bold">
              {uploadedFilesCount && uploadedFilesCount}{" "}
              {props.mode === 0
                ? "Specification(s)"
                : props.mode === 1
                  ? "Contract(s)"
                  : props.mode === 2
                    ? "Site Investigation & Survey(s)"
                    : props.mode === 3
                      ? "Drawing(s)"
                      : props.mode === 4
                        ? "Health and Safety(s)"
                        : props.mode === 6
                          ? "Plans & Method(s)"
                          : "Other Document(s)"}
            </span>{" "}
            to your project library.
          </p>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success me-2"
              onClick={() => uploadFiles()}
            >
              Confirm
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => setShowConfirmation(!showConfirmation)}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default GenericUploader;
