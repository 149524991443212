import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";


const RunDetailCard = (props) => {

    const navigate = useNavigate();

    const handleClick = (run_id, workflow_id) => {

        navigate('/project/workflows', { state: {project_id: props.project_id, 
                                                    workflow_id: workflow_id, 
                                                    run_id: run_id, 
                                                    action: 'run',
                                                    is_digitise: props.is_digitise,
                                                    name: props.workflow_name,
                                                    source_page: 'project_detail' 
                                                } 
                                        }
                );

    }

    return (

        <div className="card mt-2 recent-runs-workflow-card" key={props.key} onClick={() => handleClick(props.run_id, props.workflow_id)}>
            <p className="ms-2 mb-0 mt-2 text-truncate"><b>{capitalize(props.name)}</b></p>
            <div className="ms-2 mt-2 mb-2 me-2">
                <div className="d-flex justify-content-between align-items-center">
                    <span><i className={`fa-solid ${props.icon} font-primary-darker`}></i><small className="ms-3">Run on {props.created_at}</small></span>
                    <span className='badge custom-nav-link-count-box ml-auto'>{props.num_of_results}</span>
                </div>
            </div>
        </div>

    )

}

export default RunDetailCard;