import React, { useState, useEffect } from "react";
import httpClientPy from "../../utils/httpClientPy";
import Button from "../shared/Button";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import { ToastContainer } from "react-toastify";

const AdminEnterpriseUsers = (props) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowCounter, setRowCounter] = useState(1);
  const [error, setError] = useState(null);

  useEffect(() => {
    httpClientPy
      .get(`/enterprises/admins?id=${props.ent.id}`)
      .then((response) => {
        // update the rows state with the response data
        let rows = response.data.admins.map((row, index) => {
          return {
            id: index,
            email: row,
          };
        });
        setRows(rows); // Update the rows state with the response data
        setRowCounter(rows.length); // Update the rowCounter state with the length of the response data
      });
  }, [loading, props.ent.id]);

  const addRow = () => {
    setLoading(false);
    // Create a new row object with a unique ID and blank email
    const newRow = {
      id: rowCounter + 1,
      email: "",
    };

    // Increment the ID counter.
    setRowCounter(rowCounter + 1);

    // Use the setRows function to add the new row to the state.
    setRows([...rows, newRow]);
  };

  const updateRow = (event, id, field) => {
    // Use the setRows function to create a new array of rows with the updated value.
    let inputValue = event.target.value;
    setLoading(false);
    setRows(
      rows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [field]: inputValue,
          };
        }
        return row;
      })
    );
  };

  const removeRow = (id) => {
    // Use the setRows function to filter out the row with the specified ID.
    setRows(rows.filter((row) => row.id !== id));
    setLoading(false);
  };

  const submitAddAdmin = () => {
    setLoading(false);

    httpClientPy
      .post("/enterprises/admin/add", {
        admins: rows,
        enterprise_id: props.ent.id,
      })
      .then((response) => {
        if (response.status === 200) {
          setRows([]);
          setLoading(false);
          props.toggleModal();
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <>
      <ToastContainer />
      <ModalHeader toggle={() => props.toggleModal()}>
        Add {props.ent.name} Admins
      </ModalHeader>
      <ModalBody>
        <table className="table">
          <thead>
            <tr>
              <th className="col-12">Email Address</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                <td>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      required
                      value={row.email}
                      onChange={(event) => updateRow(event, row.id, "email")}
                    />
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeRow(row.id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" className="btn btn-success" onClick={addRow}>
          Add
        </button>
        {/* { errorValidation &&
                    <div className="text-danger text-center mb-2">Only email address with @{adminEmail.split('@')[1]} domains are allowed</div>
                }
                { totalActiveUser + emailAddressRows.length < 10 &&
                    <button type="button" className="btn btn-success" onClick={addEmailAddressRow}>
                        Add
                    </button>
                } */}
        {error && (
          <div className="toast-container position-fixed bottom-0 end-0 p-3 d-none d-sm-block">
            <Toast className="mx-auto">
              <ToastHeader>
                <div className="title-civils">
                  <img
                    src="/assets/george_icon.svg"
                    alt="Digitise your geological records automatically"
                    className="d-inline-block georgeIcon primary"
                  />
                  <span>
                    &nbsp;We encountered error while creating the records
                  </span>
                </div>
              </ToastHeader>
              <ToastBody>
                Please try again creating the record, do make sure all the
                information is correct and complete. If the problem still
                persist please contact us at info@civils.ai
              </ToastBody>
            </Toast>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={() => submitAddAdmin()}
          label="Submit"
          className="w-20 btn btn-primary m-2"
          loading={loading}
        />
      </ModalFooter>
    </>
  );
};

export default AdminEnterpriseUsers;
