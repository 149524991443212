import httpClientPy from "../../../utils/httpClientPy";
import { ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button from "../../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faX } from "@fortawesome/free-solid-svg-icons";


const SaveWorkflow = (props) => {

    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [isPublic, setIsPublic] = useState(false)
    const [workflowName, setWorkflowName] = useState(props.workflow ? props.workflow.name : '')
    const [description, setDescription] = useState('')
    const [inputs, setInputs] = useState("")
    const [loading, setLoading] = useState(false)
    const [catLoading, setCatLoading] = useState(false)

    const getCategories = async () => {

        setCatLoading(true)

        let selectCategories = []

        httpClientPy.get(`/workflow/categories`)
        .then((response) => {
            let categories = response.data.categories
            categories.forEach((category) => {
                selectCategories.push({
                    value: category.id,
                    label: category.name
                })
            })
            setCategories(selectCategories)
        }).catch((error) => {
            toast.error('Error while loading categories', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })

        setCatLoading(false)
    }       

    const getWorkflowInfo = async (id) => {

        setLoading(true)

        httpClientPy.get(`/workflow?workflow_id=${id}&project_id=${props.project.id}`)
        .then((response) => {
            setSelectedCategory({value: response.data.workflow.category_id, label: response.data.workflow.category})
            setIsPublic({value: response.data.workflow.is_public, label: response.data.workflow.is_public ? 'Public' : 'Private'})
            setWorkflowName(props.saveType === 'overwrite' ? props.workflow.name : "My Copy of " + props.workflow.name)
            setDescription(response.data.workflow.description)
            setInputs(response.data.workflow.raw_inputs)
            props.setWorkflow({...props.workflow, name: props.saveType === 'overwrite' ? props.workflow.name : "My Copy of " + props.workflow.name})
        }).catch((error) => {
            toast.error('Error while loading workflow', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })
        setLoading(false)
    }

    const validateInformation = async () => {

        if (selectedCategory === null) {
            toast.error('Please select a category', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            return false
        } else if (workflowName === '' || workflowName === null) {
            toast.error('Please enter a workflow name', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            return false
        } else if (description === '' || description === null) {
            toast.error('Please enter a workflow description', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            return false
        }

        return true
    }


    const newSave = async () => {

        let is_valid = await validateInformation()

        if (!is_valid) {
            return
        }

        let data = {
            workflow: props.workflow,
            is_public: props.superAdmin ? isPublic.value : false,
            category_id: selectedCategory.value,
            project_id: props.project.id,
            workflow_desc: description,
            inputs: inputs,
        }
        httpClientPy.post(`/workflow/save`, data)
        .then((response) => {
            toast.success('Workflow saved successfully', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            props.setAction('edit', response.data.workflow_id)
            props.toggleSaveFinalModal()
        }).catch((error) => {
            toast.error('Error while saving workflow', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })

    }

    const overwriteSave = async () => {

        let is_valid = await validateInformation()
        
        if (!is_valid) {
            return
        }

        let data = {
            workflow_id: props.workflowId,
            workflow: props.workflow,
            is_public: props.superAdmin ? isPublic.value : false,
            category_id: selectedCategory.value,
            project_id: props.project.id,
            workflow_desc: description,
            inputs: inputs,
        }
        httpClientPy.put(`/workflow/update`, data)
        .then((response) => {
            toast.success('Workflow updated successfully', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            props.setAction('edit', response.data.workflow_id)
            props.toggleSaveFinalModal()
        }).catch((error) => {
            toast.error('Error while updating workflow', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })

    }

    const confirmSave = async (is_overwrite) => {

        if (selectedCategory === null) {
            toast.error('Please select a category', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            return
        }

        if (isPublic === null) {
            toast.error('Please select public or private', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            return
        }

        if (is_overwrite) {
            overwriteSave()
        } else {
            newSave()
        }

        localStorage.setItem('is_unsaved', false)
        
    }

    const handleChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
    }

    const handleChangeWorkflowName = (e) => {
        setWorkflowName(e.target.value)
        props.setWorkflow({...props.workflow, name: e.target.value})
    }

    useEffect(() => {
        getCategories()
        if (props.workflowId !== -1 && props.workflowId !== null) {
            getWorkflowInfo(props.workflowId)
        }
    }, [])

    return (
        <>  
            {loading ? (
                <div className="text-center m-4">
                    <Spinner color="primary" />
                </div>
            ) : (
                <>
                    <ModalHeader toggle={props.toggleSaveFinalModal}><b>{props.saveType === 'new' ? 'Save New': 'Overwrite'} Workflow</b></ModalHeader>
                    <ModalBody>
                        <div className="mb-2">
                            <h5 className="text-primary">Workflow Details</h5>
                            <div>
                                <label className="text-bold"><b>Name:</b></label>
                                <input value={workflowName} className="form-control" onChange={handleChangeWorkflowName} />
                            </div>
                            <div className="mt-1">
                                <label className="text-bold"><b>Description:</b></label>
                                <textarea value={description} className="form-control" onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div className="mt-1">
                                {catLoading ? (
                                    <div className="text-center m-4">
                                        <Spinner color="primary" />
                                    </div>
                                ) : (
                                    <>
                                        <label className="text-bold"><b>Category:</b></label>
                                        <Select
                                            className={'select'}
                                            classNamePrefix="select"
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            options={categories}
                                            placeholder="Select Category"
                                            onChange={handleChange}
                                            value={selectedCategory}
                                            required
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="mt-2">
                            <h5 className="text-primary">Workflow Information</h5>
                            <div>
                                <strong>Number of Nodes:</strong> {props.workflow.nodes.length}
                            </div>
                            <div>
                                <strong>Number of Connections:</strong> {props.workflow.edges.length}
                            </div>
                        </div>

                        
                        {props.superAdmin &&
                            <>
                                <div className="mt-2">
                                    <h6 className="text-bold"><b>Note</b></h6>
                                    <span>
                                        If you save this workflow as <strong>public</strong>, other users
                                        will be able to view and use it in their own projects. Please ensure
                                        no sensitive data is included.
                                    </span>
                                </div>
                                <Select
                                    className={'select mt-1'}
                                    classNamePrefix="select"
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    options={[{value: true, label: 'Public'}, {value: false, label: 'Private'}]}
                                    placeholder="Public or Private"
                                    value={isPublic}
                                    onChange={(selectedOption) => setIsPublic(selectedOption)}
                                />
                                <div className="mt-2">
                                    <h6 className="text-bold"><b>Input(s)</b></h6>
                                    <small>
                                        Enter the input documents for this workflow, make sure they are comma separated. 
                                        For example - Tender specs, Contracts, ...
                                    </small>
                                    <input className="form-control mt-1" 
                                           value={inputs}
                                           onChange={(e) => setInputs(e.target.value)} />
                                </div>
                                {/* <div className="mt-2">
                                    <h6 className="text-bold"><b>Output(s)</b></h6>
                                    <small>
                                        Enter the input documents for this workflow, make sure they are comma separated. 
                                        For example - Excel, Word, ...
                                    </small>
                                    <input className="form-control mt-1" onChange={(e) => setOutputs(e.target.value)} />
                                </div> */}
                            </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button label={<><FontAwesomeIcon icon={faX} size="sm" /><span className="ms-2">Cancel</span></>}
                                className='btn btn-sm btn-warning me-1' 
                                onClick={props.toggleSaveFinalModal}
                        />
                        <Button label={<><FontAwesomeIcon icon={faUpload} size="sm" /><span className="ms-2">Confirm</span></>}
                                className='btn btn-sm btn-primary' 
                                onClick={() => confirmSave(props.saveType === 'overwrite')}
                        />
                    </ModalFooter>
                </>
            )}
        </>
    )
}   

export default SaveWorkflow;