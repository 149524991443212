import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import httpClientPy from "../../utils/httpClientPy";
import { Progress, UncontrolledAlert, Tooltip, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faBook, faBoreHole, faCircleInfo, faInfo, faInfoCircle, faLessThanEqual, faLock, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { color } from "d3";
import localStorageService from "../../utils/localStorageService";

const Uploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [progress, setProgress] = useState(0);
  const fileSize = useRef();
  const [numberOfPdf, setNumberOfPdf] = useState(0);

  useEffect(() => {

    const handleFileDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        handleChange(files);
    }

    window.addEventListener("dragover", (e) => e.preventDefault());
    window.addEventListener("drop", handleFileDrop);
    return () => {
      window.removeEventListener("dragover", (e) => e.preventDefault());
      window.removeEventListener("drop", handleFileDrop);
    };

  }, []);

  useEffect(() => {

    if(loading){
      const intervalId = setInterval(() => {
        setProgress((prevCount) => {
          if (prevCount >= 100) {
            clearInterval(intervalId);
            return prevCount;
          }
          else{
            return prevCount + 1;
          }
        });
      }, 50*fileSize.current);

      return () => {
        clearInterval(intervalId);
        setProgress(0)
      };
    }
  }, [loading]);

  const handleChange = (files) => {
    setNumberOfPdf(0);
    let total_size_mb = 0;
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    fileSize.current = 1;
    for (var i = 0; i < files.length; i++) {
      formData.append("file_obj", files[i]);
      // console.log(`file ${i}: ${files[i].name} ${files[i].size} ${files[i].type}`);
      // convert file size to MB
      // increment number of pdf files
      if(files[i].type === "application/pdf"){
        setNumberOfPdf(numberOfPdf+1);
      }
      
      total_size_mb += files[i].size/1000000;

      if(files[i].size>300000) {
        fileSize.current += files[i].size/2000000;
      }
    }

    let url = ""

    url = `${props.uploadUrl}?record_id=${props.geoRecordId}`;
    
    setLoading(true);
    setShowSuccess(false);
    setShowError(false);
    setErrorText("");
    
    httpClientPy
      .post(url, formData)
      .then((response) => {
        
      })
      .catch((errorResponse) => {
        if (errorResponse.response.data) {
          let error_code = errorResponse.response.data.detail;
          if (error_code === 2) {
              setErrorText("There is an error with the uploading process, please try again later or contact support at hello@civils.ai");
          } else if (error_code === 3) {
              setErrorText("The uploaded file is not a valid AGS4 format. Please upload a valid AGS4 file.");
          } else if (error_code === 4) {
              setErrorText("You have reached the maximum number of documents that can be uploaded. Please upgrade your account to upload more documents. Contact your enterprise admin if you are on enterprise subscription");
          } else if (error_code === 5) {
              setErrorText("User is not part of the project. Please contact the project admin to add you to the project.");
          } else {
              setErrorText("There is an error with the uploading process, please try again later or contact support at hello@civils.ai");
          }
          setShowError(true);
        } else {
            setErrorText("There is an error with the uploading process, please try again later or contact support at hello@civils.ai");
            setShowError(true);
        }
      })
      .finally(() => {
        if(!showError) {
          setShowSuccess(true);
          props.onComplete();
        }
        setLoading(false);
      });
  };

  return (
    <>
      <div className="uploader_holder w-100 mx-auto">


        {loading ? (
          <div className="text-center">
            <Progress max={100} value={progress} animated color="primary" className="my-2" style={{ width: '50%', margin: 'auto' }}>{progress}%</Progress>
          </div>
        ) : (
          <>
          
            <div className="drag-drop-uploader-holder">
              <FileUploader
                classes="drag-drop-uploader w-100 mb-2"
                style={{ minWidth: "100%" }}
                label="Upload or drop your files here"
                name="file"
                types={["pdf"]}
                handleChange={handleChange}
                multiple={true}
              />
            </div>
        </>
      )}

      <div className="container">
            {showError && errorText ? (
                <UncontrolledAlert color="warning"  className="mb-0">
                    <span className="title-bold">{errorText}</span>
                </UncontrolledAlert>
            ) : showSuccess ? (
                <>
                    <UncontrolledAlert color="success"  className="mb-0">
                        <span className="title-bold">All set, we're extracting the information from your uploaded document. </span><span>&nbsp;We'll email you when your file is ready.</span>
                    </UncontrolledAlert>
                </>
            ) : null}
        </div>

      </div>
    </>
  );
};

export default Uploader;
