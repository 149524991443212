import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import httpClientPy from "../../../utils/httpClientPy.js";
import JSZip from "jszip";

const DownloadDropdown = (props) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);


  const handleDownload = async (format) => {
    let export_type = 1;
    if (format === 'ags') {
      export_type = 0
    }
    else if (format === 'excel') {
      export_type = 1
    }
    else if (format === 'dxf') {
      export_type = 2
    }
    else if (format === 'shp') {
      export_type = 3
    }

    setDownloading(true);
    const zip = new JSZip();
    
    httpClientPy.post('/geo/records/export', {
      geo_record_ids: props.selectedItems,
      project_id: props.project.id,
      export_type: export_type 
    }).then((response) => {
      if (response.data.ags_str) {
        
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data.ags_str));

        element.setAttribute('download', `${props.project.name}.ags`)

        element.click();
      }
      else if (response.data.dxf_b64) {
        
        const data = response.data.dxf_b64;
        const binaryData = window.atob(data);

        const blob = new Blob([binaryData], { type: 'application/octet-stream' });

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${props.project.name}.dxf`;

        // Append the link to the body
        document.body.appendChild(downloadLink);

        // Simulate a click to trigger the download
        downloadLink.click();

        // Clean up by removing the link
        document.body.removeChild(downloadLink);

      }
      else if (response.data.shp_b64) {
        
        const fileTypes = ['shp', 'shx', 'dbf'];

        fileTypes.forEach(type => {
          const base64 = response.data.shp_b64[type];
          const blob = base64ToBlob(base64, 'application/octet-stream'); // MIME type for binary files
          const url = URL.createObjectURL(blob);
          const fileName = `${props.project.name}.${type}`;
          zip.file(fileName, urlToPromise(url), { binary: true });
        });

        zip.generateAsync({ type: "blob" })
        .then(function (content) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(content);
          link.download = `download.zip`;
          link.click();
        })
        .catch(function (error) {
          console.error(error);
        });


      }
      else if (response.data.csv_b64) {
        const binaryString = window.atob(response.data.csv_b64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = `${props.project.name}.xlsx`;

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();

      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setDownloading(false);
    })
  };

  function base64ToBlob(base64, contentType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
  
    return new Blob([byteArray], {type: contentType});
  }

  const urlToPromise = (url) =>
    new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function () {
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject(new Error(this.statusText));
      }
    };
    xhr.onerror = function () {
      reject(new Error("Network error"));
    };
    xhr.send();
  });



  return (
    <>
    <Dropdown isOpen={dropdownOpen} direction="down" toggle={toggleDropdown} onClick={(event) => event.stopPropagation()}>
      <DropdownToggle className="btn btn-primary text-start" tag="div">
        <span className="text-light">
          <span> 
            &nbsp;Download 
            {props.selectedItems.length>=1 && 
              <span>&nbsp;({props.selectedItems.length})</span>} 
               &nbsp; &nbsp;
              {downloading ? 
                <Spinner size='sm' />
                :
                <FontAwesomeIcon className="icon-sideMenu" icon={faCaretUp}/>
              }
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="project-dropdown-modal" container="body">
        {!downloading ?
        <>
          <DropdownItem className={`btn btn-primary ${props.selectedItems.length < 1 && "disabled" }`} onClick={()=>{handleDownload('excel')}}><span>&nbsp;Combined Excel</span></DropdownItem>
          <DropdownItem className={`btn btn-primary ${props.selectedItems.length < 1 && "disabled" }`} onClick={()=>{handleDownload('ags')}}><span>&nbsp;Combined AGS</span></DropdownItem>
          <DropdownItem className={`btn btn-primary ${props.selectedItems.length < 1 && "disabled" }`} onClick={()=>{handleDownload('dxf')}}><span>&nbsp;Combined DXF</span></DropdownItem>
          <DropdownItem className={`btn btn-primary ${props.selectedItems.length < 1 && "disabled" }`} onClick={()=>{handleDownload('shp')}}><span>&nbsp;Combined GIS</span></DropdownItem>

        </>
        :
        <>
            <DropdownItem className="btn disabled btn-primary" label={
            <span style={{display: 'inline-flex', alignItems: 'center'}}>
                <Spinner size="sm" className="d-inline"/>
                <span style={{marginLeft: '8px'}}>Downloading</span>
            </span>
            }>
            </DropdownItem>
        </>
        }
      </DropdownMenu>
    </Dropdown>

    </>
  );
};

export default DownloadDropdown;
