import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Spinner } from "reactstrap";
import Button from "../../shared/Button";
import { faAngleLeft, faAngleRight, faFileExcel, faGear, faMagnifyingGlassChart, faSortDown, faSortUp, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import httpClientPy from "../../../utils/httpClientPy";
import UploadModal from "../../shared/UploadModal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ProgressCounter from "../../map/dataTab/ProgressCounter";
const ComplianceChecksHistory = (props) => {
  
    const [isAsc, setIsAsc] = useState(true);
    const [history, setHistory] = useState([]);
    const [uploadModal, setUploadModal] = useState(false);
    const [rowLoading, setRowLoading] = useState({});
    const [tableLoading, setTableLoading] = useState(true)
    const [removeProposal, setRemoveProposal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [loadingActions, setLoadingActions] = useState({});

    const toggleUploadModal = () => setUploadModal(!uploadModal);


    useEffect(() => {
            refreshProposalsData()
    }, [props.extraDetails]);

    const setLoadingState = (id, action, isLoading) => {
        setLoadingActions(prev => ({
            ...prev,
            [id]: { ...prev[id], [action]: isLoading }
        }));
    };


    const refreshProposalsData = () => {
        httpClientPy.get(`/project/compliance/checks/history?project_id=${props.project.id}&is_asc=${isAsc}`)
        .then((response) => {
            if (response.data.history.length > 0) {
                setHistory(response.data.history);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setTableLoading(false)
        });
    }

    const downloadExcel = (e, id) => {
        e.stopPropagation();
        setLoadingState(id, 'excel', true);
        httpClientPy.get(`/masterlist/comments/download?project_id=${props.project.id}&report_id=${id}`)
        .then((response) => {
            let file_name = response.data.report_file_name
            let base64 = response.data.b64
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = file_name;
            downloadLink.click();
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoadingState(id, 'excel', false);
        });
    }

    const viewChecks = (e, id) => {
        e.stopPropagation();
        setLoadingState(id, 'checks', true);
        let data = {}
        httpClientPy.get(`/geo/record?geo_record_id=${id}&project_id=${props.project.id}&format=json`)
        .then((response) => {
            data.id = id
            data.original_file_name = response.data.original_file_name
            data.pdf_url = response.data.pdf_url
            data.file_type = response.data.geotech
            props.updateTask(3)
            props.updateSelectedData(data)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoadingState(id, 'checks', false);
        });
    }

    const viewSearches = (e, id) => {
        e.stopPropagation();
        setLoadingState(id, 'searches', true);
        let data = {}
        httpClientPy.get(`/geo/record?geo_record_id=${id}&project_id=${props.project.id}&format=json`)
        .then((response) => {
            data.id = id
            data.original_file_name = response.data.original_file_name
            data.pdf_url = response.data.pdf_url
            data.file_type = response.data.geotech
            props.updateTask(2)
            props.updateSelectedData(data)
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoadingState(id, 'searches', false);
        });
    }

    const handleDeleteProposalClicked = (e, id) => {
        e.stopPropagation();
        setLoadingState(id, 'delete', true);
        setSelectedId(id)
        setRemoveProposal(true)
        setLoadingState(id, 'delete', false);
    }

    const handleDeleteProposal = () => {
        httpClientPy.delete(`/geo/record/delete?geo_record_id=${selectedId}&record_type=all&record_id=0`)
        .then((response) => {
            if (response.data.status === true) {
                refreshProposalsData()
                setSelectedId(null)
            }
        }).catch((err) => {
            console.log(err);
        });
        setRemoveProposal(false)
    }

    return (
        <>
            <Card className="w-100 mt-4 shadow-sm d-flex flex-grow-1 flex-column">
                <CardHeader className="bg-light pb-0 d-flex justify-content-between align-items-center">
                    <h5><b>Project Document Checks</b></h5>
                    <span>
                        {/* <button className="btn btn-light border btn-sm my-2 me-2">
                            <FontAwesomeIcon className="me-2" icon={faGear}/>
                            Configure checks
                        </button> */}
                        <button className="btn btn-primary btn-sm my-2" onClick={() => toggleUploadModal()}>
                            <FontAwesomeIcon className="me-2" icon={faUpload}/>
                            Upload docs
                        </button>
                    </span>
                </CardHeader>
                <CardBody className={'px-0 pt-2'} style={{height: "60vh"}}>
                    {!tableLoading ?
                    <>
                        {history.length > 0 ? (

                            <div className="scrollable-table">
                                <table className="table table-borderless geo-records-list-holder proposals_table">
                                    <thead>
                                        <tr>
                                            <th width={550} className="text-center">Project document</th>
                                            <th width={150} className="text-center">Searches</th>
                                            {/* <th width={150} className="text-center">No of Checks</th> */}
                                            <th width={150} className="text-center">Checks</th>
                                            <th width={150} className="text-center">Checked by</th>
                                            <th width={150} className="text-center">Checked on</th>
                                            <th width={150} className="text-center">Export</th>
                                            <th width={150} className="text-center"></th>
                                        </tr>
                                    </thead>
                                    {props.userInfo &&
                                    <tbody>
                                        {history.map((item, index) => (
                                            <tr onClick={(e)=>item.status === 'ready' ? viewSearches(e, item.id) : null} key={index}>
                                                <td className="text-center">
                                                    <small>
                                                        {item.status !== 'ready' ? (
                                                            <>
                                                                {item.original_file_name.split(".")[0]}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {item.original_file_name.split(".")[0]}
                                                            </>
                                                        )}
                                                    </small>
                                                </td>
                                                <td className="text-center">
                                                    {item.status === 'ready' &&
                                                        <button 
                                                            onClick={(e) => viewSearches(e, item.id)} 
                                                            className="btn border btn-sm d-flex align-items-center mx-auto" // Add flexbox utilities
                                                            disabled={loadingActions[item.id]?.searches}
                                                        >
                                                            <span className="pe-2">View</span>
                                                            {loadingActions[item.id]?.searches ? 
                                                                <Spinner size="sm" color="dark" />
                                                            : 
                                                                <span className="badge rounded-pill blue">
                                                                    {item.num_of_queries ? item.num_of_queries : 0}
                                                                </span>
                                                            }
                                                        </button>
                                                    }
                                                </td>
                                                {/* <td className="text-center"><span className={`badge rounded-pill green `}>{item.num_of_checks}</span></td> */}
                                                <td className="text-center">
                                                    {item.status === 'ready' &&
                                                        <button 
                                                            onClick={(e) => viewChecks(e, item.id)} 
                                                            className="btn border btn-sm d-flex align-items-center mx-auto" // Add flexbox utilities
                                                            disabled={loadingActions[item.id]?.checks}
                                                        >
                                                            <span className="pe-2">View</span>
                                                            {loadingActions[item.id]?.checks ? 
                                                                <Spinner size="sm" color="dark" />
                                                            : 
                                                                <span className={`badge rounded-pill red`}>{item.num_of_comments ? item.num_of_comments : 0}</span>
                                                            }
                                                        </button>
                                                    }                                          
                                                </td>
                                                <td className="text-center">
                                                    {item && item.status === 'ready' && 
                                                        <>
                                                        {(item.n && item.n.length > 0) ?
                                                        <>
                                                            {item.n.map((role, index) => (
                                                                <span className={`badge rounded-pill grey me-2`}>{role}</span>
                                                            ))}
                                                        </>
                                                            :
                                                            '-'
                                                        }
                                                        </>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {item.status === 'ready' &&
                                                        <small>
                                                        {item.updated_at ? 
                                                            item.updated_at
                                                            :
                                                            '-'
                                                        }
                                                        </small>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                {item.num_of_comments > 0 ? (
                                                    <Button 
                                                            onClick={(e) => downloadExcel(e, item.id)}
                                                            label={loadingActions[item.id]?.excel ? <Spinner size="sm" color="dark" /> : <FontAwesomeIcon className='icon-excel' icon={faFileExcel}/>}
                                                            className="btn btn-sm"
                                                            disabled={loadingActions[item.id]?.excel}
                                                        />
                                                    ) : (
                                                        <Button
                                                            label={<FontAwesomeIcon className='icon-excel' icon={faFileExcel}/>}
                                                            disabled
                                                            className="btn disabled btn-sm"
                                                        />
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    {item.status === 'pending' ? (
                                                        <ProgressCounter status={item.status} requestedDate={item.created_datetime}/>
                                                    ) : loadingActions[item.id]?.delete ? (
                                                        <Spinner size="sm" color="dark" />
                                                    ) : (
                                                        <>
                                                            {item.user_profile_id === props.userInfo.george_id && (
                                                                <Button
                                                                    label={<FontAwesomeIcon className='icon-file' icon={faTrash} />}
                                                                    className="btn btn-sm"
                                                                    onClick={(e) => handleDeleteProposalClicked(e, item.id)}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    }
                                </table>
                            </div>
                        ) : (
                            <div className="px-4 text-center">
                                <div className="pt-3">No project documents have been uploaded for searching or checking yet.</div> 
                            </div> 
                        )}
                    </>
                    :
                    <div className="text-center pt-5">
                        <Spinner size="lg" color="dark" />
                    </div>
                }
                </CardBody>
            </Card>
            {props.userInfo && uploadModal &&
                <UploadModal 
                    isOpen={uploadModal} 
                    toggle={toggleUploadModal} 
                    userInfo={props.userInfo}
                    project={props.project}
                    extraDetails={props.extraDetails}
                    initialUploadType={0}
                    onComplete={() => refreshProposalsData()}
                >
                </UploadModal>
            }
            <Modal id="SelectReg" size="l" isOpen={removeProposal} toggle={() => setRemoveProposal(false)} className="modal-dialog-centered">
                <ModalHeader className="border-0 mb-0 pb-0" toggle={() => setRemoveProposal(false)}> Delete Document</ModalHeader>
                <ModalBody>
                    <hr className="mb-4"/>
                    <div className="container-fluid w-100 pb-2 mb-1">
                        <div>Are you sure you want to delete this document? This action cannot be undone.</div>
                    </div>
                    <hr className="mb-4"/>
                    <div className="container-fluid w-100 ">
                        <a className="btn btn-primary w-100 mb-2" onClick={() => handleDeleteProposal()} type="button">
                            Confirm
                        </a>
                        <a className="btn btn-outline-dark w-100" onClick={() => setRemoveProposal(false)} type="button">
                            Cancel
                        </a>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ComplianceChecksHistory;
