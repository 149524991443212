import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BaseCard from './BaseCard';
import { debounce } from 'lodash';
 
const TaskBox = (props) => {
    const [mainTask, setMainTask] = useState(props.data.task);
	const [details, setDetails] = useState(props.data.input);
	const [areNodesRunning, setAreNodesRunning] = useState(false);

	const textAreaRef = useRef(null);

	const debouncedUpdate = useMemo(
		() => debounce((value) => {
			props.updateNodeData(props.id, { input: value });
		}, 500),
		[props.id, props.updateNodeData]
	);

	const adjustHeight = () => {
		const textArea = textAreaRef.current;
		if (textArea) {
			textArea.style.height = 'auto';
			textArea.style.height = textArea.scrollHeight + 'px';
		}
	}

	const onChange = useCallback((evt) => {

		const value = evt.target.value;
		setDetails(value);
        debouncedUpdate(value);
		adjustHeight();

	}, [debouncedUpdate]);

    const updateMainTask = (value) => {
        setMainTask(value);
        props.updateNodeData(props.id, { task: value });
    }

	useEffect(() => {
		return () => {
			debouncedUpdate.cancel();
		}
	}, [debouncedUpdate]);

	return (
		<>	
			<BaseCard {...props} areNodesRunning={areNodesRunning} setAreNodesRunning={setAreNodesRunning} setMainTask={updateMainTask}>
                <textarea
					ref={textAreaRef}
                    id="text"
                    name="details"
                    placeholder={props.data.task === 'custom' ? 'Required Instruction' : 'Optional Instruction'}
                    onChange={onChange}
                    className="nodrag nowheel form-control mt-2"
                    style={{ width: '100%', overflow: 'hidden' }}
                    value={details}
                    required={props.data.task === 'custom'}
                    disabled={props.data.is_running || props.isDigitsationWf || areNodesRunning}
                ></textarea>
			</BaseCard>
		</>
	);
}

export default TaskBox;