import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";


const RunDetailCard = (props) => {

    const navigate = useNavigate();

    const handleClick = (run_id, workflow_id) => {

        navigate('/project/workflows', { state: {project_id: props.project_id, 
                                                    workflow_id: workflow_id, 
                                                    run_id: run_id, 
                                                    action: 'run',
                                                    is_digitise: props.is_digitise,
                                                    name: props.workflow_name,
                                                    source_page: 'project_detail' 
                                                } 
                                        }
                );

    }

    return (
        <div class="col">
            <div className="card h-100 px-3 py-2 recent-runs-workflow-card" key={props.key} onClick={() => handleClick(props.run_id, props.workflow_id)}>
                <p class="fw-bold text-truncate mb-0">
                    {capitalize(props.name)}
                </p>
                <div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                        <small className="text-muted">
                            <span><i className={`fa-solid me-2 ${props.icon} font-primary-darker`}></i></span>

                            {props.num_of_results > 0 &&
                            <>
                                <span>Results found &nbsp;</span>
                                <span className='badge custom-nav-link-count-box-dark'>{props.num_of_results}</span>
                                <span>&nbsp;on&nbsp;</span>
                            </>
                            }
                            <span>{props.created_at}</span>

                        </small>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default RunDetailCard;