import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardTitle, CardFooter, CardText, CardSubtitle, CardHeader } from 'reactstrap';
import Button from './Button';  
import Uploader from './Uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const PricingModal = (props) => { 

    return(
        <Modal isOpen={props.isOpen} className='pricingModal' toggle={props.toggle} size='xl'>
            {/* <ModalHeader className='title-bold border-0' toggle={props.toggle}></ModalHeader> */}
            <div className='d-flex justify-content-end align-items-end mt-2 me-2'>
                <Button label={<><FontAwesomeIcon icon={faX} size="sm" /></>}
                                className='btn btn-sm btn-danger ms-1 me-2' 
                                onClick={() => props.toggle()}
                />
            </div>
            <ModalBody>
                {props.project && props.project.id &&
                    <Uploader
                        userInfo = {props.userInfo}
                        className="mx-auto"
                        uploadUrl="/geo/record/upload"
                        allDocs={true}
                        projectId={props.project.id}
                        onComplete={() => props.onComplete()}
                        extraDetails={props.extraDetails}
                        project={props.project}
                        initialUploadType={props.initialUploadType}
                    />
                }
            </ModalBody>
        </Modal>
    )

 }; export default PricingModal;