import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCity, faEllipsis, faMap, faCube, faLayerGroup, faCompassDrafting, faBuildingColumns, faShareNodes, faPlug, faCaretLeft, faMountain, faPersonDigging, faXmark, faLocationDot, faDrawPolygon, faBuilding, faAnchorCircleExclamation, faHammer, faArrowLeft, faSignOut, faMountainSun, faScrewdriverWrench, faUsers, faList, faCloudArrowUp, faGrip, faArrowUpRightFromSquare, faRobot, faFolderTree, faUserTie, faCaretDown, faListCheck} from "@fortawesome/free-solid-svg-icons";
import Modelling from "./planningTab/Modelling";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Modal, Alert } from "reactstrap";
import AddBoreholeForm from "./dataTab/AddBoreholeForm"
import Button from "../shared/Button";
import localStorageService from "../../utils/localStorageService";
import httpClientPy from "../../utils/httpClientPy";
import AgsUploaderVerify from "./dataTab/AgsUploaderVerify";
import { logEvent } from "../shared/Mixpanel";

library.add(faMap, faLayerGroup, faCompassDrafting, faBuildingColumns, faShareNodes, faPlug, faCaretLeft, faMountain, faPersonDigging, faXmark, faLocationDot, faDrawPolygon);

const SideMenu = (props) => {

  const navigate = useNavigate();

  //selectedTool controls the content of the sidemenu
  const [selectedTool, setSelectedTool] = useState('')
  //state to display add boreholemodal
  const [modalAddBorehole, setModalAddBorehole] = useState('data')
  //display offcanvas side meny
  const [displayOffcanvas, setDisplayOffcanvas] = useState(true)
  // check if user is admin for company
  const [isAdmin, setIsAdmin] = useState(false)
  const [digitiseOnly, setDigitiseOnly] = useState(false)
  const [civilsGPT, setCivilsGPT] = useState(false)
  const [premium, setPremium] = useState(false)
  const [agsInfo, setAgsInfo] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);



  const navigateToProjects = () => {
      navigate(`/projects`);
  }


  useEffect(() => {
    httpClientPy
      .get(`/user`)
      .then((result) => {
        setIsAdmin(result.data.admin);
        setDigitiseOnly(result.data.digitise_only);
        setCivilsGPT(result.data.george_access);
        setPremium(result.data.premium);
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      });
  }, []);

  useEffect(() => {
    const handleEsc = (event) => {
       if (event.keyCode === 27) {
        props.onCancelSearchGeoRecord()
        setSelectedTool('')
        props.addBorehole(false)
        props.onToggleSectionCut(false);
        props.virtualBoreholeStatus(false)
        props.updateBorehole(false)
        props.setNewUser(false)
        localStorageService.setItem('new_user', false)
        if (document.activeElement instanceof HTMLElement)
          document.activeElement.blur();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if(selectedTool=='2d'){
      logEvent('2D Section', {"Step": "Open 2D Section"})
      props.onToggleSectionCut(true);
    }
    else if (selectedTool == '3d'){
      logEvent('3D Model', {"Step": "Open 3D Model"})
      props.virtualBoreholeStatus(true)
    }
    else if(selectedTool == 'addBorehole'){
      props.addBorehole(true)
    }
    else if(selectedTool == 'drawArea'){
      logEvent('Draw Area', {"Step": "Open Draw Area"})
      props.drawArea(true)
    }
  }, [selectedTool]);



  useEffect(() => {
    if(props.fixBorehole && selectedTool==='addBorehole'){
      setModalAddBorehole(true)
    }
    else{
      setModalAddBorehole(false)
    }
  }, [props.fixBorehole]);

  useEffect(() => {
    if(props.addProject || props.updateProjectLocation) {
      // enable the drawArea tool
      props.drawArea(true)
    }
  }, []);


  function onBoreholeAdded(){
    props.addBorehole(false)
    setSelectedTool('')
    setModalAddBorehole(false)
    props.onSearchGeoRecord(null,null)
  }

  // function onToggleMyData(state){
  //   if(!state){
  //     setSelectedTool('')
  //     if(props.newUser) {
  //       props.setNewUser(false)
  //       localStorageService.setItem('new_user', false)
  //     }
  //   }
  // }

  const onToggleAddBorehole = () => {
    setSelectedTool('')
    if(props.fixBorehole){
      props.onShowModal(!modalAddBorehole)
      setModalAddBorehole(!modalAddBorehole);
    }
    props.addBorehole(false)
    props.virtualBoreholeStatus(false)

  }

  function onToggleDigitisePdf(state){
    if(!state){
      setSelectedTool('')
    }
  }
  
  function onToggleModelling(state){
    if(!state){
      setSelectedTool('')
      props.virtualBoreholeStatus(false)
    }
  }

  function updateBorehole(state){
    if(state){
      setSelectedTool('updateBorehole');
    }
    props.updateBorehole(state)
  }

  useEffect(() => {
    if(!props.toggleSectionCut){
      setSelectedTool('')
      props.onToggleSectionCut(false);
    }
  }, [props.toggleSectionCut]);


  useEffect(() => {
    props.onShowModal(modalAddBorehole)
  }, [modalAddBorehole]);

  // useEffect(() => {
  //   setDisplayOffcanvas(props.displaySidemenu)
  //   if(props.onBoardingDone && props.newUser){
  //     setSelectedTool('myData')
  //   }
  // }, [props.displaySidemenu, selectedTool, props.onBoardingDone, props.newUser]);


  function updateSelectionRadiusSlider(value){
    props.updateSelectionRadius(value)
  }

  const navigateToProjectData = () => {
    navigate(`/project/data`, {state: {project: props.projectDetails}});
  }

  const navigateToAutomations = () => {
    navigate(`/project/data`, {state: {project: props.projectDetails, type: 'all'}})
    // window.open('https://civils.ai/ai-software-for-construction-automation', '_blank');
  }
  
  
  const navigateToProjectDetails = (id) => {
      navigate(`/project`, {state: {id: props.projectDetails.id, subpage: 'overview', type: null}});
  }


  const updateAgsInfo = (data) => {
    setAgsInfo(data);
  };

  const onToggleAgsVerifier = () => {
    setAgsInfo([]);
  };

  const toggleData = () => setDropdownOpen(prevState => !prevState);

  const navigateToGeotechData = () => {
      logEvent('View Geotech Data', {"Type": "Geotech Data"})
      navigate('/project/workflows/outputs', { state: {project_id: props.projectDetails.id, 
                                                      workflow_id: props.workFlowId, 
                                                      workflow_run_id: props.workFlowRunId,
                                                      source_page: 'map',}});
  }
  

  return (
    <>
      
      <Offcanvas show={displayOffcanvas && selectedTool!=='digitiser' && selectedTool!=='addBorehole' && selectedTool!=='myData' && selectedTool!=='2d' && selectedTool!=='3d'  && selectedTool!=='updateBorehole'} backdrop={false} scroll={true} className="project-sidebar">
        <Offcanvas.Body className="d-flex flex-column">
          <div className='logo'>
              <img src="assets/logo_white.png" alt="Civils.ai" />
              <span className="fs-3">Civils.ai</span>
          </div>
          <hr className='hr-custom'></hr>
          <a className={`sub-item mb-1`} onClick={() => navigateToProjectDetails(props.projectDetails.id)}>
              <span><FontAwesomeIcon className="me-2" icon={faCity}/></span>
              <span className="">Project</span>
          </a>

          
          {/* <a className={digitiser ? `sub-item-disabled mb-1` : `sub-item mb-1`} onClick={()=>setDigitiser(!digitiser)}>
              <span><FontAwesomeIcon className="me-2" icon={faCloudArrowUp}/></span>
              <span className="">Upload</span>
          </a> */}
          <a onClick={() => navigateToGeotechData()} className="sub-item mb-1">
              <span>
                  <FontAwesomeIcon className="me-2" icon={faListCheck}/>
                  <span><small>Geotech data</small></span>
              </span>
          </a>

          { props.readyMarkers > 0 && (!civilsGPT || digitiseOnly || premium) &&
            
              <> 
                <hr className='hr-custom'></hr>
                <a className={`sub-item d-none mb-1`} onClick={()=>setSelectedTool('addBorehole')}>
                  <span className="">Add borehole</span>
                </a>
                <a className={`sub-item mb-1`} onClick={()=>setSelectedTool('2d')}>
                  <span><FontAwesomeIcon className="me-2" icon={faMountain}/></span>
                  <span className="">2D section</span>
                </a>
                <a className={`sub-item mb-1`} onClick={()=>setSelectedTool('3d')}>
                  <span><FontAwesomeIcon className="me-2" icon={faCube}/></span>
                  <span className="">3D model</span>
                </a> 
              </> 
          }
          {props.projectDetails && props.projectDetails.location.includes('United Kingdom') &&
            <a className={`sub-item mb-1`} onClick={()=>setSelectedTool('drawArea')}>
                <span><FontAwesomeIcon className="me-2" icon={faDrawPolygon}/></span>
                <span className="">Public data</span>
            </a> 
          }
          <div className="mt-auto w-100"> {/* This pushes the logout button to the bottom */}
              <hr className='hr-custom'></hr>
              <a className={`sub-item mb-1`} onClick={() => navigateToProjects()}>
                  <span><FontAwesomeIcon className="me-2" icon={faGrip}/></span>
                  <span className="">Projects</span>
              </a>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* {props.projectDetails &&
          <Uploader
              className="mx-auto"
              uploadUrl="/geo/record/upload"
              updateAgsInfo={(data) => updateAgsInfo(data)}
              allDocs={true}
              projectId={props.projectDetails.id}
              onComplete={() => null}
              digitiser={digitiser}
              toggleModal={()=>setDigitiser(!digitiser)}
          />
        } */}
        { agsInfo.length > 0 && 
            <AgsUploaderVerify
                agsInfo={agsInfo}
                toggleModal={()=>onToggleAgsVerifier()}>
            </AgsUploaderVerify>
        }
      {selectedTool=='addBorehole' &&
      <>
        {props.fixBorehole ?
          <Modal id="addBoreholeModal" size="xl" isOpen={modalAddBorehole} toggle={onToggleAddBorehole} centered={true}>
            <AddBoreholeForm cursorLat={props.cursorLat} cursorLng={props.cursorLng} 
              displayName="Add a borehole record" 
              toggleModal={()=>onToggleAddBorehole}
              onBoreholeAdded = {(status) => {onBoreholeAdded(status)}} />
          </Modal>
        :
          <Alert color="light" className="border text-center m-0" style={{position: 'fixed', bottom: 0, zIndex:150, borderRadius:0}}>
            <Button
              onClick={() => onToggleAddBorehole()}
              label="Cancel (or hit ESC)"
              className="btn btn-danger"
            ></Button>
          </Alert>
        }
      </>
      }

    {selectedTool === '3d' &&
      <>
        {props.virtualBoreholeData &&
          <Modelling 
              zoom={props.zoom} 
              virtualBoreholeData={props.virtualBoreholeData} 
              fixVirtualBorehole={props.fixVirtualBorehole} 
              virtualBoreholeStatus={(status) => props.virtualBoreholeStatus(status)}
              toggleModelling={(state)=>{onToggleModelling(state)}} 
              selectionRadiusSlider={props.selectionRadiusSlider}
              projectId = {props.projectDetails.id}
          />
        }
        <Alert color="light" className="border text-center m-0 w-100" style={{position: 'fixed', bottom: 0, zIndex:150, borderRadius:0}}>
        <div className="d-flex justify-content-center align-items-center">
          <span className="d-inline-block text-center">
            <label htmlFor="customRange2" className="form-label text-muted text-sm mb-0 pb-0 w-100 text-center pe-2">
              <small>Model diameter: {Math.round(257725.8427*2.0006 * Math.exp(-0.693 * props.zoom)*(props.selectionRadiusSlider*0.30105))}m</small>
            </label>
            <input type="range" min="0" max="800" step="10" value={props.selectionRadiusSlider} onChange={(event) => updateSelectionRadiusSlider(event.target.value)} className="form-range pt-0 mt-0" id="customRange2" />
          </span>
          <Button
            onClick={() => { onToggleModelling(false) }}
            label="Cancel (or hit ESC)"
            className="btn btn-danger ms-5"
          ></Button>
        </div>

        </Alert>
      </>
    }
      {selectedTool === 'updateBorehole' &&
        <Alert color="light" className="border text-center m-0" style={{position: 'fixed', bottom: 0, zIndex:150, borderRadius:0}}>
          <Button
            onClick={() => {setSelectedTool(''); props.updateBorehole(false)}}
            label="Cancel (or hit ESC)"
            className="btn btn-danger"
          ></Button>
        </Alert>
      }
      <div className="mobileAddBoreholeButton">
        <a className="btn btn-primary btn-lg d-block d-sm-none" onClick={()=>{setDisplayOffcanvas(true)}}><FontAwesomeIcon className="icon-map-pin" icon={faEllipsis}/></a>
      </div>
    </>
  );
};

export default SideMenu;
