import React, { useState } from "react";
import httpClientPy from "../../utils/httpClientPy.js";
import { logEvent } from "../shared/amplitude";
import Button from "../shared/Button";

const GeoRecordDownloadButton = (props) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = (id, format, project_id) => {
    setLoading(true);

    httpClientPy
      .post("/geo/records/export", {
        geo_record_ids: [id],
        project_id: project_id,
        export_type: 1,
      })
      .then((response) => {
        if (response.data.csv_b64) {
          const binaryString = window.atob(response.data.csv_b64);
          const binaryLen = binaryString.length;
          const bytes = new Uint8Array(binaryLen);
          for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          const blob = new Blob([bytes], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const fileName = `${response.data.file_name}.xlsx`;

          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        logEvent("download-geological-records", { format: "xlsx" });
      });
  };

  return (
    <Button
      onClick={() => {
        handleDownload(props.geoRecord.id, "json", props.projectId);
      }}
      label={props.label}
      className={props.className}
      disabled={loading || props.disabled}
    />
  );
};

export default GeoRecordDownloadButton;
