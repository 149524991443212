import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons'; // Import faTrash
import BaseCard from './BaseCard';
import { useCallback, useState } from 'react';

const OutputBox = (props) => {

    const [areNodesRunning, setAreNodesRunning] = useState(false);
    const [OutputName, setOutputName] = useState(props.data.input !== '' && props.data.input !== null ? props.data.input : 'Untitled');

    const onChange = useCallback((evt) => {
        const value = evt.target.value;
        setOutputName(value);
        props.updateNodeData(props.id, { input: value });
    }, [props.id, props.updateNodeData]);

    return (
        <>
            <BaseCard {...props} areNodesRunning={areNodesRunning} setAreNodesRunning={setAreNodesRunning}>
            <div className="d-flex flex-column">
                {/* Output Name Section */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center" style={{ width: '30%' }}>
                        <b>Output name</b>
                    </div>
                    <div className="d-flex align-items-center" style={{ flexGrow: 1 }}>
                        <input
                            type="text"
                            className="form-control nodrag"
                            value={OutputName ? OutputName : ''}
                            onChange={onChange}
                            disabled={props.data.is_running || areNodesRunning}
                        />
                    </div>
                </div>

                {/* Files Created Section */}
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center" style={{ width: '30%' }}>
                        <b>Files created</b>
                    </div>
                    <div className="d-flex align-items-center" style={{ flexGrow: 1 }}>
                        <FontAwesomeIcon icon={faFileExcel} size="1x" style={{ color: 'green' }} className="me-1" />
                        {props.isDigitsationWf ? (
                            <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" style={{ width: '1em', height: '1em' }}>
                            <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" fill="black" />
                            <text 
                                x="192" 
                                y="350" 
                                textAnchor="middle" 
                                dominantBaseline="middle" 
                                fontFamily="Arial" 
                                fontSize="300" 
                                fill="white"
                            >
                                A
                            </text>
                        </svg>
                        ) : (
                            <FontAwesomeIcon icon={faFileWord} size="1x" style={{ color: 'blue' }} />
                        )}
                    </div>
                </div>
            </div>
        </BaseCard>

        </>
    );
}

export default OutputBox;