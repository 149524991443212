import { faMagnifyingGlass, faBook, faTable, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {useNavigate} from "react-router-dom";
import {Spinner, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import httpClientPy from "../../../../utils/httpClientPy";
import { marked } from 'marked';
import { logEvent } from '../../../shared/Mixpanel';
import { toast, ToastContainer } from 'react-toastify';

marked.setOptions({
    gfm: true,
    tables: true,
  });


const SearchSingleDoc = (props) => {

    const [messages, setMessages] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [answer, setAnswer] = useState("");
    const [typingCivilsGPT, setTypingCivilsGPT] = useState(false);
    const [allRefMsgId, setAllRefMsgId] = useState([]);
    const [groupedMessages, setGroupedMessages] = useState(null);
    const [chatInput, setChatInput] = useState('');

    const docMapper = {
        "0": 'Specifications',
        "1": 'Surveys',
        "2": 'Contracts',
        "3": 'Drawings',
        "4": 'Risk Registers',
        "5": 'Others',
        '6': 'Plans'
    }

    const handleChangeQuery = (e) => {
        setChatInput(e.target.value);
    }

    const [open, setOpen] = useState('');

    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    useEffect(() => {
        httpClientPy.post(`/search/doc/histories`, {
            project_id: props.project.id,
            record_id: props.selectedId
        })
        .then((response) => {
            let history = response.data.messages;
            setMessages(history);
            let chatWindow1 = document.getElementById('chatList2');
            chatWindow1.scrollTop = chatWindow1.scrollHeight;
        })
        .catch((error) => {
            toast.error('Internal problem while loading chat history, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        });    


    }, [props.selectedId]);


    const submitMessage = async (input) => {
        
        if (input === '') {
            return;
        }

        setGenerating(true);
        setChatInput('')
        
        let requestBody
        let api
        const token = localStorage.getItem('auth_token');

        let chatWindow1 = document.getElementById('chatList2');

        let curr_msgs = [...messages];
        let max_id = curr_msgs.length > 0 ? Math.max(...curr_msgs.map((msg) => msg.human.id)) : 0;
        let new_msg = {human: {id: max_id + 1, message: input}};
        curr_msgs.push(new_msg);
        setMessages(curr_msgs);

        toggle(max_id + 1);

        api = '/searc/doc/result'
        requestBody = JSON.stringify({
            project_id: props.project.id,
            search_query: input,
            record_id: props.selectedId
        });

        // wait one second to scroll to bottom
        setTimeout(() => {
            chatWindow1.scrollTop = chatWindow1.scrollHeight;
        }, 1000);

        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL_PY + api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: requestBody,
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let text = '';
            let key_ref = null;
            
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }
                
                let curr_text = decoder.decode(value);
                try {
                    // clear all space and new lines
                    let temp = curr_text.split('\n\n')
                    let json = JSON.parse(temp[2]);
                    
                    if (json.all === false) {
                        text += curr_text;
                        key_ref = json;
                    }
                    
                } catch {
                    text += curr_text;
                    const renderedHtml = marked.parse(text);
                    setAnswer(renderedHtml);
                }
            }

            if (key_ref !== null) {
                let key = Object.keys(key_ref)[0];
                let file = Object.keys(key_ref[key])[0];
                let page = Object.keys(key_ref[key][file])[0];
                let ref_ids = key_ref[key][file][page];
                handleReferenceClick(file, page, ref_ids, docMapper[key]);
            }

            let last_msg = curr_msgs[curr_msgs.length - 1];
            last_msg.ai = {id: max_id + 1, message: text};
            setMessages(curr_msgs);

        } catch (error) {
            console.error('Error:', error);
        }

        setGenerating(false);
        setAnswer("");
        logEvent('Search Single Doc', { 'Query': input, 'File': props.currentFileName });

    }

    // detect if user pressed enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !generating) {
            e.preventDefault();
            if(typingCivilsGPT){
                submitMessage(chatInput);
            }
        }
    }

    // this is the useEffect to handle the keypress event listener
    useEffect(() => {

        // use the handleKeyPress
        window.addEventListener('keypress', handleKeyPress);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener('keypress', handleKeyPress);
        };

    });

    const handleReferenceClick = (file, page, ref_ids, type) => {
        httpClientPy.post('/search/reference', {
            file_name: file,
            ref_ids: ref_ids,
            project_id: props.project.id
        }).then((response) => {
            props.updateHighlights(response.data.highlight_area);
            props.updatePageNumber(parseInt(page));
            props.updateInitialPage(parseInt(page));
        }).catch((error) => {
            toast.error('Internal problem while loading reference, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
        })
        logEvent('Reference Clicked', { 'File': file, 'Page': page, 'Type': type });  
    }

    const handleAllReferenceShow = (msg_id) => {
        setAllRefMsgId([...allRefMsgId, msg_id]);
    }

    const handleAllReferenceHide = (msg_id) => {
        setAllRefMsgId(allRefMsgId.filter((id) => id !== msg_id));
    }

    const renderer = (text, msg_id) => {
        try {
            const json = JSON.parse(text);
            if (json.all === false) {
                delete json.all;
                let keys = Object.keys(json);
                return( 
                    <>
                        <div>
                            {keys.map((key) => {
                                return Object.entries(json[key]).map(([file, pages]) => (
                                    <div key={file}>
                                        {Object.entries(pages).map(([page_num], index, arr) => (
                                            <span key={`${file}-${page_num}`}>
                                                <a
                                                    className='reference-link circle-owner'
                                                    style={{ backgroundColor: '#5b5fcc' }}
                                                    onClick={() => handleReferenceClick(file, page_num, pages[page_num], docMapper[key])}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {page_num}
                                                </a>
                                                {index < arr.length - 1 ? '' : ''}
                                            </span>
                                        ))}
                                    </div>
                                ));
                            })}
                        </div>
                    </>
                )
            } else if (json.all === true) {
                delete json.all;
                let keys = Object.keys(json);
                return (
                    <>
                        {!allRefMsgId.includes(msg_id) &&
                            <button className="btn btn-outline-dark btn-sm mt-2" onClick={() => handleAllReferenceShow(msg_id)}>All references</button>
                        }
                        {allRefMsgId.includes(msg_id) &&
                            <div>
                                <div><b>All Reference(s):</b></div>
                                {keys.map((key) => {
                                    return Object.entries(json[key]).map(([file, pages]) => (
                                        <div key={file}>
                                            {Object.entries(pages).map(([page_num], index, arr) => (
                                                <span key={`${file}-${page_num}`}>
                                                    <a
                                                        className='reference-link circle-owner'
                                                        style={{ backgroundColor: '#5b5fcc' }}
                                                        onClick={() => handleReferenceClick(file, page_num, pages[page_num], docMapper[key])}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {page_num}
                                                    </a>
                                                    {index < arr.length - 1 ? '' : ''}
                                                </span>
                                            ))}
                                        </div>
                                    ));
                                })}
                                <button className="btn btn-outline-dark btn-sm mb-1 mt-2" onClick={() => handleAllReferenceHide(msg_id)}>Hide references</button>
                            </div>
                        }
                    </>
                )
            } 
        } catch (error) {
            const html = marked.parse(text);
            return <div className="markdown-content" dangerouslySetInnerHTML={{ __html: html }} />;
        }
    };    

    return (
        <>
            <ToastContainer />
            <div id="chatList" className="d-flex flex-column w-100">
                <div id="chatList2" 
                    className={`bg-offwhite pe-1 ${typingCivilsGPT ? 'd-none d-sm-flex flex-column flex-grow-1' : 'd-flex flex-column flex-grow-1'}`} 
                    style={{ 
                        overflowY: 'auto',
                        minHeight: '30dvh', 
                    }}
                >
                    <ul className='chat h-100' >
                        {messages && messages.length > 0 ? 
                            <Accordion className={'mb-0'} open={open} toggle={toggle}>
                                {messages.map((pair, index) => (
                                    <AccordionItem  key={pair.human.id} className={(index === messages.length - 1 && generating) ? 'border-0' : ''} >
                                        <AccordionHeader targetId={pair.human.id}>
                                            <b>{pair.human.message.charAt(0).toUpperCase() + pair.human.message.slice(1)}</b>
                                        </AccordionHeader>
                                        <AccordionBody 
                                            accordionId={pair.human.id}
                                            className={index === messages.length - 1 ? 'border-0' : ''}
                                            style={{
                                                height: index === messages.length - 1 && generating ? '0px' : 'auto',
                                                overflow: index === messages.length - 1 && generating ? 'hidden' : 'visible',
                                                transition: 'height 0.3s ease'
                                            }}
                                        >
                                        {pair.ai && pair.ai.message.split('\n\n').map((paragraph, pIndex) => (
                                            <div className="message" key={pIndex}>
                                                {renderer(paragraph, pair.ai.id)}
                                            </div>
                                        ))}
                                        </AccordionBody>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        : !generating &&
                            <div className="h-100 d-flex align-items-center px-5">
                                <div key={0}>
                                <div className='text-center text-muted mb-4'><small>Select a prompt below to start analysing this <strong>document</strong> or write your own.</small></div>
                                    <div className='row w-100'>
                                        <div className='col-4'>
                                            <div className='card h-100 card-button' type="button" onClick={()=>submitMessage('Create a summary of the key points.')}>
                                                <div class="card-body">
                                                    <h5 class="card-title primary"><FontAwesomeIcon icon={faBook}></FontAwesomeIcon></h5>
                                                    Create a summary of the key points.
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='card h-100 card-button' type="button" onClick={()=>submitMessage('Create a table of key risks.')}>
                                                <div class="card-body">
                                                    <h5 class="card-title primary"><FontAwesomeIcon icon={faTable}></FontAwesomeIcon></h5>
                                                    Create a table of key risks.
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='card h-100 card-button' type="button" onClick={()=>submitMessage('List out any constraints or requirements.')}>
                                                <div class="card-body">
                                                    <h5 class="card-title primary"><FontAwesomeIcon icon={faList}></FontAwesomeIcon></h5>
                                                    List out any constraints or requirements.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {generating && answer && (
                            <li className="other">
                                <div className="msg">
                                    <div className="message markdown-content" dangerouslySetInnerHTML={{ __html: answer }} />
                                </div>
                            </li>
                        )}
                        {generating && !answer && (
                            <li className="other">
                                <div className="msg">
                                    <button className="btn border-0 disabled" type="button">
                                        <Spinner size="sm" color="primary" /><span className='ps-2'>I'm searching your project...</span>
                                    </button>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            <div className='container bg-light px-0 mx-auto px-3 py-2'>
                <div className="d-flex align-items-center">
                    <div className="form-floating text-dark flex-grow-1 me-2">
                        <input 
                            id='question_chat' 
                            autoComplete="off" 
                            onFocus={()=>setTypingCivilsGPT(true)}
                            onBlur={()=>setTypingCivilsGPT(false)}
                            onChange={(e) => handleChangeQuery(e)} 
                            type="text" 
                            value={chatInput}
                            className="form-control gpt_input" 
                            placeholder={"Search for info in this open doc"} 
                        />
                        <label htmlFor='question_chat' className='text-secondary'>
                            Search for info in this open doc
                        </label>
                    </div>
                    {(generating || chatInput.length<1) ? (
                        <button className="btn btn-secondary disabled border-0 btn-lg" disabled type="button">
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>  
                    ) : (
                        <button className="btn btn-primary border-0 btn-lg" type="button" onClick={() => submitMessage(chatInput)}>
                            <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
                        </button>    
                    )}
                </div>

            </div>
        </>
    )

}

export default SearchSingleDoc;
