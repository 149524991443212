import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { findMaterial } from '../../shared/algorithms/geoRecordAlgorithms.js'

var scale
var depth

const Model = (props) => {

  //this is all used for generating the threejs model of the underground geology
  const mount = useRef();
  const frameId = useRef();
  const scene = useRef();
  const camera = useRef();
  const renderer = useRef();
  const points = useRef();
  const controls = useRef();
  const labelRenderer = useRef();


  let boreholeData = structuredClone(props.descriptionRows)
  let idToRemove = []
  
  useEffect(() => {
    if(props.inputType == 'thickness'){
      if(boreholeData && boreholeData.length > 0){
        for (let strata of boreholeData){
          if(strata.thickness == '' || parseFloat(strata.thickness) == 0){
              //remove strata from array
              idToRemove.push(strata.id)
          }
        }
      }
    }
    // remove objects from boreholeData using idToRemove
    for (let id of idToRemove){
      boreholeData = boreholeData.filter(function( obj ) {
        return obj.id !== id;
      });
    }
    depth = props.borehole_length
    if(props.inputType == 'thickness'){
      let depthCount = 0
      for (let strata of boreholeData){
          strata.top = depthCount
          strata.base = depthCount + parseFloat(strata.thickness)
          strata.description = strata.description
          depthCount += parseFloat(strata.thickness)

          let descriptionString = strata.description.toLowerCase()
          descriptionString = descriptionString.replace(/[^a-zA-Z ]/g, "")
          let descriptionList = descriptionString.split(" ")
          let material = findMaterial(descriptionList, descriptionString)
          if(material.uscs !== '-'){
              strata.material = material.uscs
          }
          else{
              strata.material = material.grain
          }
          // let boreHolePointCloudHolder = new Array();
          // for ( let m = 0; m < 20; m+=1 ) {
          //   boreHolePointCloudHolder[m] = new Array();
  
          //   for ( let n = 0; n < 20; n+=1 ) {
          //     let topHolder = strata.top
          //     let bottomHolder = strata.base
  
          //     boreHolePointCloudHolder[m][n] = {
          //       top: topHolder,
          //       base: bottomHolder
          //     }
              
          //   }
          // }
          // strata.pointcloud = boreHolePointCloudHolder
      }
    }
    else if (props.inputType == 'level'){
      let ground_level = boreholeData[0].top
      for (let strata of boreholeData){
          strata.top =  Math.abs(strata.top - ground_level)
          strata.base = Math.abs(strata.base - ground_level)
          strata.description = strata.description
      }
    }
    scale = 150/props.borehole_length


    render_scene()
  }, [props.descriptionRows, props.recordName]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  function handleWindowResize() {

      // Get the new width and height of the window
    let width = mount.current.clientWidth;
    let height = mount.current.clientHeight;
      
      // Update the renderer and canvas size
      renderer.current.setSize(width, height);
  
      // Update the camera aspect ratio
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();


      // Update the label renderer and canvas size
      labelRenderer.current.setSize(width, height);

  }

  function render_scene(){
    //before the virtual borehole is loaded initialise a spinning empty ground layer

    let width = mount.current.clientWidth;
    let height = mount.current.clientHeight;

    // Add the event listener to the window
    window.addEventListener("resize", handleWindowResize);

    // if rendering canvas with borehole data
    if(boreholeData && boreholeData.length > 0){

      cancelAnimationFrame(frameId.current);

        // remove all objects from the scene
        if(scene.current){
          if(scene.current.children){
            while (scene.current.children.length > 0){ 
              scene.current.remove(scene.current.children[0]); 
            }
          }
        }
        else{
            scene.current = new THREE.Scene();
            camera.current = new THREE.PerspectiveCamera(
              45,
              width / height,
              0.1,
              200000
            );

            camera.current.position.set(500, scale * (depth / 2), 0);

            renderer.current = new THREE.WebGLRenderer({ antialias: true });



            // Add an instance of OrbitControls to the scene
            controls.current = new OrbitControls(camera.current, renderer.current.domElement);
            // controls.current.autoRotate = true;
            // if (!controls.current.autoRotateSpeed) {
            //     controls.current.autoRotateSpeed = 2; 
            // }
            controls.current.update();
            renderer.current.setClearColor('#28242c');
            renderer.current.setSize(width, height);
        }
        
        var vertices = [];

        
          for ( let i = -50; i < 50; i+=5 ) {
            for ( let l = -50; l < 50; l+=5 ) {

                const x = parseFloat(i);
                const z = parseFloat(l);

                vertices.push( x, 0, z );
            }
        }
        
        var geometry = new THREE.BufferGeometry();
        geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
        
        var material = new THREE.PointsMaterial( {color: 0xc3c3c3, size:1.5} );
        
        points.current = new THREE.Points( geometry, material );
        
        scene.current.add( points.current );

        if(props.water_strike){
          let waterStrike = props.water_strike
          var vertices = [];

        
          for ( let i = -50; i < 50; i+=5 ) {
            for ( let l = -50; l < 50; l+=5 ) {

                const x = parseFloat(i);
                const z = parseFloat(l);

                vertices.push( x, -waterStrike, z );
            }
          }

          var geometry = new THREE.PlaneGeometry(15,15);
          
          var material = new THREE.MeshBasicMaterial( {color: 0x8bcaf1, side: THREE.DoubleSide,transparent: true,opacity:0.5} );          
          points.current = new THREE.Mesh( geometry, material );

          points.current.rotation.x = Math.PI / 2;
          points.current.position.y = -waterStrike

          scene.current.add( points.current );

        }


        if (boreholeData){
            create3DBoreholeLog(scene, props, boreholeData, 0, 0, boreholeData)
            createBoreholeLabel(props.recordName, labelRenderer, scene, 0, 0, 0, width, height, props.borehole_length, props.displayDimensions)
        }
    }

    // if there is not any borehole data provided
    else if (!scene.current) {
      scene.current = new THREE.Scene();
      camera.current = new THREE.PerspectiveCamera(
        45,
        width / height,
        0.1,
        200000
      );
      camera.current.position.set(200, 100, 0 );
      renderer.current = new THREE.WebGLRenderer({ antialias: true });

      const vertices = [];

      for ( let i = -50; i < 50; i+=5 ) {
          for ( let l = -50; l < 50; l+=5 ) {

              const x = parseFloat(i);
              const z = parseFloat(l);

              vertices.push( x, 0, z );
          }
      }
      
      var geometry = new THREE.BufferGeometry();
      geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
      
      var material = new THREE.PointsMaterial( { color: 0xc3c3c3, size:1.5 } );
      
      points.current = new THREE.Points( geometry, material );
      
      scene.current.add( points.current );

    // animations and controls for both cases
    camera.current.position.z = 4;

    // Add an instance of OrbitControls to the scene
    controls.current = new OrbitControls(camera.current, renderer.current.domElement);
    // controls.current.autoRotate = true;
    // if (!controls.current.autoRotateSpeed) {
    //     controls.current.autoRotateSpeed = 2; 
    // }

    controls.current.update();

    renderer.current.setClearColor('#28242c');
    renderer.current.setSize(width, height);
    }
    
    const start = () => {
        frameId.current = requestAnimationFrame(animate);      
    };

    const stop = () => {
      cancelAnimationFrame(frameId.current);
    };

    const animate = () => {
      renderScene();
      frameId.current = window.requestAnimationFrame(animate);
      controls.current.update();
    };

    const renderScene = () => {
      renderer.current.render(scene.current, camera.current);
      if(boreholeData && boreholeData.length > 0){
        labelRenderer.current.render(scene.current, camera.current);
      }
    };

    mount.current.appendChild(renderer.current.domElement);
    if(boreholeData && boreholeData.length > 0){
      mount.current.appendChild(labelRenderer.current.domElement);
    }
    start();

    return () => {
      stop();
      scene.current.remove( points.current );
    };

  }

  return (
    <div className='h-100 rounded overflow-hidden' style={{ position: "relative" }}>
        <div id="overlay" className='bg-dark text-light' style={{
            position: "absolute",
            top: 10,
            left: 10,
            padding: "10px",
            zIndex: 1,
          }}>
            {boreholeData[0].top !== 0 &&
            <div>
              Ground level: {boreholeData[0].top}mAOD
            </div>
            }
            {props.water_strike &&  
              <div>
                Water level: {props.water_strike}mBGL
              </div>  
            }
          </div>
      <div className='modelRender w-100' ref={mount} />
    </div>
  );
}

export default Model;


function create3DBoreholeLog(scene, props, selectedBorehole, scaledXPosition, scaledZPosition, boreholeData){
  let color =[]

  for (let boreholeHolder of selectedBorehole){
    let colordict = {
        "color": [255,255,255],
    }

    if(boreholeHolder.description!=='pending'){
        var boreholeHolderColor = boreholeHolder.color

        color = new THREE.Color();
        const vx = boreholeHolderColor[0]/250;
        const vy = boreholeHolderColor[1]/250;
        const vz = boreholeHolderColor[2]/250;
        color.setRGB( vx, vy, vz );

        colordict = {
            "color": color,
        }
    }

    //scale=150/max_length

    let strataLength = Math.abs(boreholeHolder.baseMBGL - boreholeHolder.topMBGL)*scale

    const geometryVirtual = new THREE.CylinderGeometry( 5, 5, strataLength, 32 );
    const materialVirtual = new THREE.MeshBasicMaterial( colordict );
    const virtualBorehole = new THREE.Mesh( geometryVirtual, materialVirtual );

    virtualBorehole.position.y = -boreholeHolder.topMBGL*scale-Math.abs(boreholeHolder.baseMBGL*scale - boreholeHolder.topMBGL*scale)/2

    virtualBorehole.position.x = scaledXPosition
    virtualBorehole.position.z = scaledZPosition

    scene.current.add( virtualBorehole );

    const edges = new THREE.EdgesGeometry( geometryVirtual, 20 );
    const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x000000 } ) );
    line.position.y = -boreholeHolder.topMBGL*scale-(boreholeHolder.baseMBGL*scale - boreholeHolder.topMBGL*scale)/2
    line.position.x = scaledXPosition
    line.position.z = scaledZPosition
    
    scene.current.add( line );
  } 
}

function createBoreholeLabel(label_top, labelRenderer, scene, x, y, z, width, height, borehole_length, displayDimensions) {
  // Setup labels
  labelRenderer.current = new CSS2DRenderer();

  labelRenderer.current.setSize(width, height);
  labelRenderer.current.domElement.style.position = 'absolute';
  labelRenderer.current.domElement.style.top = '0px';
  labelRenderer.current.domElement.style.zIndex = '4000';
  labelRenderer.current.domElement.style.pointerEvents = 'none';
  labelRenderer.current.domElement.style.color = '#FFFFFF'
  labelRenderer.current.domElement.style.fontSize = 'x-small'

  const labelDiv = document.createElement('div');
  labelDiv.setAttribute('style', 'white-space: pre;');

  labelDiv.className = 'label modelLabel text-white text-center ';
  
  labelDiv.textContent = `${label_top}\r\n`
  labelDiv.style.marginTop = '-1em';
  labelDiv.style.zIndex = '9000';

  const label = new CSS2DObject(labelDiv);
  label.visible = true;
  label.position.set(x, y+5, z );

  let depth_labels = []
  if(borehole_length && displayDimensions){

    let interval_step = 150/9
    let interval = 0

    for (let i = 0; i < 9; i+=1) {
      let label_text = borehole_length * (interval/150)
      let depthlabelDiv = create_label(label_text.toFixed(0), 5, (-i)*interval_step-3, -15,)
      scene.current.add(depthlabelDiv)
      let position_marker = create_marker(0, (-i)*interval_step, -5,)
      interval = interval + interval_step
      scene.current.add( position_marker );
    }
    let finaldepthlabelDiv = create_label(borehole_length, 5, -150-3, -15,)
    scene.current.add(finaldepthlabelDiv)
    let position_marker = create_marker(0, -150, -5,)
    scene.current.add( position_marker );
  }

  scene.current.add(label);
}

function create_label(text, x, y, z){
  let depthlabelDiv = document.createElement('div');
  depthlabelDiv.setAttribute('style', 'white-space: pre;');
  depthlabelDiv.className = 'label modelLabel text-white text-center ';
  depthlabelDiv.textContent = `${text}m`
  depthlabelDiv.style.zIndex = '9000';
  const depthlabel = new CSS2DObject(depthlabelDiv);
  depthlabel.visible = true;
  depthlabel.position.set(x, y, z );
  return depthlabel
}

function create_marker(x, y, z){
  let geo_geometry = new THREE.BoxGeometry(10,1,0.1);
          
  let material_levels = new THREE.MeshBasicMaterial( {color: 0xffffff, side: THREE.DoubleSide,transparent: true,opacity:0.2} );          
  let levels = new THREE.Mesh( geo_geometry, material_levels );

  levels.rotation.y = Math.PI / 2;
  levels.position.y = y
  levels.position.x = x
  levels.position.z = z
  return levels
}
