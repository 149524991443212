import { useState, useEffect, useRef } from "react";
import {Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import LibraryUploader from "./LibraryUploader";
import CustomSelectCategory from "./CustomSelectCategory";
import Button from "../shared/Button";
import httpClientPy from "../../utils/httpClientPy";
import { logEvent } from "../shared/Mixpanel";
import LibraryDeleteConfirm from "./LibraryDeleteConfirm";
import ProgressCounter from "../map/dataTab/ProgressCounter";
import { toast, ToastContainer } from "react-toastify";

const EditLibrary = (props) => {
    
    const [dataLoading, setDataLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [libraryName, setLibraryName] = useState("");
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [readyToSubmit, setReadyToSubmit] = useState(true);
    const fileSize = useRef(0);

    // states related to upload
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorCode, setErrorCode] = useState(0);
    const [errors, setErrors] = useState([]);
    const [progress, setProgress] = useState(0);

    const [toggleDeleteLibraryModal, setToggleDeleteLibraryModal] = useState(false);
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const [selectedFileId, setSelectedFileId] = useState(null);

    useEffect(() => {
        if (props.isOpen) {
            getLibraryData();
        }
    }, [props.isOpen])

    const handleFileAdded = (files) => {
        let list_files = filesToUpload.length > 0 ? filesToUpload : [];
        list_files = files.concat(list_files);
        setFilesToUpload(list_files);
    }

    const toggleDeleteLibrary = () => {
        setToggleDeleteLibraryModal(!toggleDeleteLibraryModal);
    }

    const handleRemoveFile = (index, file_id) => {
        setSelectedFileIndex(index);
        setSelectedFileId(file_id);
        toggleDeleteLibrary();
    }

    const removeFileFromList = (index) => {
        let list_files = filesToUpload.filter((_, i) => i !== index);
        setFilesToUpload(list_files);
        setSelectedFileIndex(null);
        setSelectedFileId(null);
    }

    const getLibraryData = async () => {
        setDataLoading(true);
        try {
            let response = await httpClientPy.get(`/library?library_id=${props.selectedLibrary}`);
            setLibraryName(response.data.library.name);
            setSelectedCategory({value: response.data.library.category_id, label: response.data.library.category_name});
            let files = response.data.library_documents.map((file) => {
                return {
                    id: file.id,
                    name: file.original_file_name,
                    raw_updated_at: file.updated_at,
                    updated_at: file.updated_at,
                    file_size: file.file_size,
                    status: file.status,
                    is_new: file.is_new
                }
            });
            setFilesToUpload(files);
        } catch (error) {
            let error_code = error.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Library does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else {
                toast.error('An error occurred while fetching data.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
        }
        setDataLoading(false);
    }

    const handleCreateLibrary = async (e) => {

        e.preventDefault();

        setErrors([]);
        setShowError(false);
        setShowSuccess(false);
        setProgress(0);

        if (libraryName.length < 1) {
            return;
        }

        setLoading(true);
        let total_size_mb = 0;
        let formData = new FormData();
        for (var i = 0; i < filesToUpload.length; i++) {
            
            if (filesToUpload[i].status === undefined) { 

                formData.append("file_obj", filesToUpload[i]);

                total_size_mb += filesToUpload[i].size/1000000;

                if(filesToUpload[i].size>300000) {
                    fileSize.current += filesToUpload[i].size/2000000;
                }
            }
        }

        formData.append("name" , libraryName);
        formData.append("category_id", selectedCategory.value);

        let url = `/library/update?library_id=${props.selectedLibrary}`;

        try {
            const response = await httpClientPy.post(url, formData, {
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
              },
            });

            toast.success('Library updated successfully', {position: toast.POSITION.TOP_RIGHT, autoClose: 2000})
            logEvent("Library Updated", { "Event": "Library Created" });
            props.libraryUpdated();
            props.toggle();

        } catch (error) {

            let error_code = error.response.data.detail;
            if (error_code === 4) {
                toast.error('Document limit has been exceeded, contact your admin in order to check limit balance.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 3) {
                toast.error('User is not part of enterprise account.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 5) {
                toast.error('Library does not exist or user is not part of the enterprise.', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            } else if (error_code === 1) {
                toast.error('Internal problem has caused creation of library to fail, please contact info@civils.ai for more information. Thank you', {position: toast.POSITION.TOP_RIGHT, autoClose: 3000})
            }
            setLoading(false);
        }

        setLoading(false);
        
    }

    return (
        <>
        <ToastContainer />
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="xl">
            <ModalHeader className='title-bold border-0 pb-0 mb-0' toggle={props.toggle}>Update Existing Library</ModalHeader>
            {dataLoading ? (
                <div className="disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                    <span>
                        <Spinner color="dark" size="sm" className="mx-auto" />
                    </span>
                </div>
            ) : (
            <>
            <ModalBody>
                <form>
                    <div className="form-group">
                        <label htmlFor="libraryName">Name</label>
                        <input type="text" className="form-control" id="libraryName" aria-describedby="libraryNameHelp" placeholder="Enter library name" required onChange={(e) => setLibraryName(e.target.value)} value={libraryName} />
                        <small id="libraryNameHelp" className="form-text text-muted">This is the name of the library that will be created.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="libraryTags">Category</label>
                        <CustomSelectCategory 
                            className = "basic-multi-select filter-record-select-w-pos"
                            isMulti = {false}
                            placeholder = "Select Category"
                            categoriesUpdated = {props.categoriesUpdated}
                            onChange = {(selected) => setSelectedCategory(selected)}
                            selectedOption = {selectedCategory}
                        />
                        <small id="libraryTagsHelp" className="form-text text-muted">Select the category of the library.</small>
                    </div>
                    <div className="form-group">
                        {loading || ((progress > 0 && progress < 100)) ?
                            <div className="drag-drop-uploader disabled d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
                                <span>
                                    <Spinner color="dark" size="sm" className="mx-auto" />
                                    <span>File upload in progress</span>
                                </span>
                            </div>
                        :
                        <LibraryUploader 
                            handeFileAdded = {(files) => handleFileAdded(files)}
                        />
                        }
                    </div>
                    {filesToUpload.length > 0 && !loading &&
                        <div className="files-to-add-table-container mb-2">
                            <table className="table table-bordered">
                                <thead className="table-thead-light">
                                    <tr>
                                        <th style={{width: '40%'}}>Document</th>
                                        <th>Last Updated</th>
                                        <th>Size</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filesToUpload.map((file, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{file.name}</td>
                                                {file.is_new !== undefined ? (
                                                    <>
                                                        <td>{new Date(file.updated_at).toISOString().split('Z')[0].split('.')[0].replace('T', ' ')}</td>
                                                        <td>{parseFloat(file.file_size).toFixed(2)} MB</td>
                                                        <td><ProgressCounter status={file.status} requestedDate={file.raw_updated_at} /></td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td>{new Date(file.lastModifiedDate).toISOString().split('Z')[0].split('.')[0].replace('T', ' ')}</td>
                                                        <td>{(file.size / 1024 / 1024).toFixed(2)} MB</td>
                                                        <td><ProgressCounter status={"new"} requestedDate={file.lastModifiedDate} /></td>
                                                    </>
                                                )}
                                                <td><button type="button" className="btn btn-danger" onClick={() => handleRemoveFile(index, file.id ? file.id : null)}>Remove</button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                    {loading &&
                    <>
                        <div className="">
                            {!showError ? (
                                <div
                                    className="progress my-2"
                                    style={{ height: '1rem', borderRadius: 0, margin: 'auto', transition: 'none' }}
                                >
                                    <div
                                    className={`progress-bar custom-progress-bar ${showSuccess && 'bg-success'}`}
                                    role="progressbar"
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: `${progress}%` }}
                                    >
                                    {progress}%
                                    </div>
                                </div>
                                ) : (
                                <div
                                    className="progress my-2"
                                    style={{ height: '1rem', borderRadius: 0, margin: 'auto', transition: 'none' }}
                                >
                                    <div
                                    className="progress-bar custom-progress-bar"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: '0%' }}
                                    >
                                    {progress}%
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                    }
                    <div className="d-flex justify-content-end">
                        <Button className="btn btn-primary me-2" label={"Update"} disabled={!readyToSubmit} onClick={(e) => handleCreateLibrary(e)} loading={loading} />
                        <button type="button" className="btn btn-danger" onClick={props.toggle}>Cancel</button>
                    </div>
                </form>
            </ModalBody>
            </>
            )}
        </Modal>

        <LibraryDeleteConfirm isOpen={toggleDeleteLibraryModal} 
            toggle={()=>toggleDeleteLibrary()} 
            item={"document"} 
            removeFileFromList={(index) => removeFileFromList(index)} 
            selectedFileIndex={selectedFileIndex} 
            selectedFileId={selectedFileId}
            extraMessage = {"Deleting existing document will remove it from the library. If you want to reuse the document, you will required to upload it again."}
            />
        </>
    );
}

export default EditLibrary;
