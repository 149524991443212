export enum NodeType {
  TEMP = 'temp',
  SEARCH = "search",
  TASK_SUMMARY = "task-summary",
  TASK_TABLE = "task-table",
  TASK_CUSTOM = "task-custom",
  OUTPUT_JSON = "output-json",
  OUTPUT_EXCEL = "output-excel",
  OUTPUT_DOCS = "output-docs",
}
