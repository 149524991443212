import { useState, useRef, useEffect, ChangeEvent } from "react";
import PdfViewer from "../../project/detailComponents/PdfViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, Modal } from "reactstrap";
import { faTimes, faMinus } from "@fortawesome/free-solid-svg-icons";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import httpClientPy from "../../../utils/httpClientPy";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import SaveConfirmModal from "../../shared/layouts/SaveConfirmModal";
import * as React from "react";

interface EditCadFormProps {
  toggleModal: () => void;
  fileUrl: string;
  id: string;
}

interface DrawingInfoItem {
  id: number;
  category: string;
  key: string;
  value: string;
}

interface Category {
  value: string;
  label: string;
}

interface HighlightArea {
  pageIndex: number;
  boundingRect: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}

const textLimit = 5000;

const EditCadForm = (props: EditCadFormProps) => {
  const [openPreview, setOpenPreview] = useState(true);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [pageNumberInput, setPageNumberInput] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [initialPage, setInitialPage] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);
  const [drawingInfo, setDrawingInfo] = useState<DrawingInfoItem[] | null>(
    null
  );
  const [expand, setExpand] = useState({
    description: false,
    notes: false,
  });
  const [categories, setCategories] = useState<Category[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const pdfView = useRef<HTMLDivElement>(null);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();

  const { zoomTo } = zoomPluginInstance;

  const highlightedAreas: HighlightArea[] = [];

  const changePage = (offset: number) => {
    if (pageNumberInput + offset < 1 || pageNumberInput + offset > totalPages)
      return;
    setPageNumberInput(pageNumberInput + offset);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setFileUrl(props.fileUrl);
    }, 500);

    httpClientPy
      .get(`/geo/record/cad/info?record_id=${props.id}`)
      .then((response) => {
        setDrawingInfo(response.data.info);
        const newCategories: string[] = [];
        response.data.info.forEach((row: DrawingInfoItem) => {
          if (!newCategories.includes(row.category)) {
            newCategories.push(row.category);
          }
        });
        setCategories(
          newCategories.map((category) => ({
            value: category,
            label: category,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    if (scale) {
      zoomTo(scale);
    }
  }, [scale]);

  const updateTestValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    field: keyof DrawingInfoItem
  ) => {
    const target = event.target as HTMLTextAreaElement;
    target.style.height = `${target.scrollHeight}px`;

    if (!drawingInfo) return;

    const newDrawingInfo = drawingInfo.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: target.value };
      }
      return row;
    });

    if (
      !categories.map((category) => category.value).includes(target.value) &&
      field === "category"
    ) {
      setCategories([
        ...categories,
        { value: target.value, label: target.value },
      ]);
    }

    setDrawingInfo(newDrawingInfo);
  };

  const removeInfo = (id: number) => {
    if (!drawingInfo) return;
    const newDrawingInfo = drawingInfo.filter((row) => row.id !== id);
    setDrawingInfo(newDrawingInfo);
  };

  const addNewDimRow = () => {
    if (!drawingInfo) return;
    setDrawingInfo([
      ...drawingInfo,
      {
        id: Math.max(...drawingInfo.map((row) => row.id)) + 1,
        category: "dimensions",
        key: "",
        value: "",
      },
    ]);
  };

  const addNewMetadataRow = () => {
    if (!drawingInfo) return;
    setDrawingInfo([
      ...drawingInfo,
      {
        id: Math.max(...drawingInfo.map((row) => row.id)) + 1,
        category: "metadata",
        key: "",
        value: "",
      },
    ]);
  };

  const triggerConfirmModal = () => {
    setIsConfirming(true);
  };

  const toggleSaveConfirmModal = () => {
    setIsConfirming(!isConfirming);
  };

  const adjustAllTextareasHeight = () => {
    const textareas =
      document.querySelectorAll<HTMLTextAreaElement>(".CADtextarea");
    textareas.forEach((textarea) => {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + 3 + "px";
    });
  };

  useEffect(() => {
    adjustAllTextareasHeight();
  }, [drawingInfo]);

  const submitForm = () => {
    if (!drawingInfo) return;
    setIsEditing(false);
    httpClientPy
      .post(`/geo/record/cad/info/update`, {
        info: drawingInfo,
        record_id: props.id,
      })
      .then((response) => {
        setDrawingInfo(response.data.info);
        const newCategories: string[] = [];
        response.data.info.forEach((row: DrawingInfoItem) => {
          if (!newCategories.includes(row.category)) {
            newCategories.push(row.category);
          }
        });
        setCategories(
          newCategories.map((category) => ({
            value: category,
            label: category,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Card
        className={`chat-card chat-card-open shadow`}
        style={{ height: "100vh" }}
      >
        <CardBody className={`py-0 bg-white w-100`}>
          <div className="row h-100">
            <div
              id="pdfView"
              ref={pdfView}
              className={`col-6 border-end ${!openPreview && "d-none"}`}
            >
              <PdfViewer
                fileUrl={fileUrl}
                updateTotalPages={(pages: string) =>
                  setTotalPages(parseInt(pages))
                }
                updatePageNumber={(pageNumber: string) =>
                  setPageNumberInput(parseInt(pageNumber))
                }
                initialPage={initialPage}
                setInitialPage={() => setInitialPage(1)}
                pageNavigationPluginInstance={pageNavigationPluginInstance}
                zoomPluginInstance={zoomPluginInstance}
                highlightArea={highlightedAreas}
                task={0}
              />
            </div>
            <div
              className={`col-6 px-2 pb-2 bg-light vh-100-true overflow-auto`}
            >
              <div className="container py-2 d-flex justify-content-between align-items-center">
                <div>
                  <h5
                    className="title-bold d-inline py-0"
                    data-testid="cad-title"
                  >
                    Extracted CAD Drawing Data
                  </h5>
                </div>
                <div className="d-flex">
                  {isEditing ? (
                    <button
                      className="btn text-dark border"
                      type="button"
                      onClick={() => setIsEditing(false)}
                      data-testid="cancel-btn"
                    >
                      <span>Cancel</span>
                    </button>
                  ) : (
                    <button
                      className="btn text-dark border"
                      type="button"
                      onClick={() => setIsEditing(true)}
                      data-testid="edit-btn"
                    >
                      <span>Edit</span>
                    </button>
                  )}
                  <button
                    className="btn btn-link text-dark ms-2"
                    type="button"
                    onClick={() => props.toggleModal()}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
              <div className={`py-0 w-100`}>
                {drawingInfo && (
                  <>
                    <div className="row mx-1 py-2">
                      {isEditing ? (
                        <>
                          <table className="table no-bordered">
                            <thead>
                              <tr>
                                <th className="col-3">Metadata</th>
                                <th className="col-8">Information</th>
                                <th className="col-1"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {drawingInfo.map((row, index) => (
                                <React.Fragment key={row.id}>
                                  {row.category === "metadata" && (
                                    <tr>
                                      <td>
                                        <div className="input-group">
                                          <input
                                            className="form-control"
                                            required
                                            disabled={
                                              row.key === "Drawing Title" ||
                                              row.key === "Drawing Number" ||
                                              row.key === "Project Title"
                                            }
                                            value={row.key}
                                            onChange={(event) =>
                                              updateTestValue(
                                                event,
                                                row.id,
                                                "key"
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="input-group">
                                          <input
                                            className="form-control"
                                            required
                                            value={row.value}
                                            onChange={(event) =>
                                              updateTestValue(
                                                event,
                                                row.id,
                                                "value"
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        {row.key !== "Drawing Title" &&
                                          row.key !== "Drawing Number" &&
                                          row.key !== "Project Title" && (
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => removeInfo(row.id)}
                                            >
                                              <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                          )}
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                          {!loading && (
                            <div className="bg-none mx-2  text-center">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={addNewMetadataRow}
                                data-testid="add-meta-btn"
                              >
                                Add Metadata
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="px-0">
                          <table className="table">
                            <tbody>
                              {drawingInfo.map((row, index) => (
                                <React.Fragment key={row.id}>
                                  {row.category === "metadata" && (
                                    <tr>
                                      <th>{row.key}</th>
                                      <td>{row.value}</td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      <div className="py-3">
                        <h6 className="title-bold d-inline py-0 mb-2">
                          Description
                        </h6>
                        <textarea
                          data-testid="description-input"
                          className="form-control CADtextarea bg-white"
                          required
                          disabled
                          value={
                            expand.description
                              ? drawingInfo?.filter(
                                  (row) => row.category === "description"
                                )[0]?.value || ""
                              : (
                                  drawingInfo?.filter(
                                    (row) => row.category === "description"
                                  )[0]?.value || ""
                                ).slice(0, textLimit)
                          }
                          onChange={(event) =>
                            updateTestValue(
                              event,
                              drawingInfo?.filter(
                                (row) => row.category === "description"
                              )[0]?.id || 0,
                              "value"
                            )
                          }
                          cols={10}
                        />
                      </div>
                      <div className="tw-flex tw-flex-row tw-justify-end tw-mt-2">
                        {(
                          drawingInfo?.filter(
                            (row) => row.category === "description"
                          )[0]?.value || ""
                        ).length > textLimit && (
                          <button
                            className="btn"
                            onClick={() =>
                              setExpand((prev) => ({
                                ...prev,
                                description: !prev.description,
                              }))
                            }
                            data-testid="description-view-more-btn"
                          >
                            {expand.description ? "Show less" : "Show More"}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row mx-1 py-2">
                      <div className="col-12">
                        <h6 className="title-bold d-inline py-0 mb-2">Notes</h6>
                        <textarea
                          data-testid="note-input"
                          className="form-control CADtextarea bg-white"
                          required
                          disabled={!isEditing}
                          value={
                            expand.notes
                              ? drawingInfo?.filter(
                                  (row) => row.category === "notes"
                                )[0]?.value || ""
                              : (
                                  drawingInfo?.filter(
                                    (row) => row.category === "notes"
                                  )[0]?.value || ""
                                ).slice(0, textLimit)
                          }
                          onChange={(event) =>
                            updateTestValue(
                              event,
                              drawingInfo?.filter(
                                (row) => row.category === "notes"
                              )[0]?.id || 0,
                              "value"
                            )
                          }
                          cols={10}
                          rows={5}
                        />
                      </div>
                      <div className="tw-flex tw-flex-row tw-justify-end tw-mt-2">
                        {(
                          drawingInfo?.filter(
                            (row) => row.category === "notes"
                          )[0]?.value || ""
                        ).length > textLimit && (
                          <button
                            className="btn"
                            onClick={() =>
                              setExpand((prev) => ({
                                ...prev,
                                notes: !prev.notes,
                              }))
                            }
                            data-testid="note-view-more-btn"
                          >
                            {expand.notes ? "Show less" : "Show More"}
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {loading ? (
                <div className="spinner-container">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                isEditing && (
                  <div className="row bg-light mx-1 py-2 ">
                    <button
                      className="btn btn-primary border-0 btn-lg"
                      type="button"
                      onClick={() => triggerConfirmModal()}
                      data-testid="save-btn"
                    >
                      Save Changes
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal
        id="saveConfirm"
        size="sm"
        isOpen={isConfirming}
        toggle={toggleSaveConfirmModal}
        centered={true}
      >
        <SaveConfirmModal
          displayName={`Save Updated CAD Info`}
          text="Are you sure you want to save the updates? This action cannot be undone."
          toggleModal={toggleSaveConfirmModal}
          saveConfirmed={(_status: boolean) => {
            submitForm();
          }}
        />
      </Modal>
    </>
  );
};

export default EditCadForm;
