import { useState } from "react";
import { NodeType } from "../constants/workflow";

export const useFlowBuilder = () => {
  const [selectedNode, setSelectedNode] = useState<NodeType | null>(null);

  const updateSelectedNode = (type: NodeType | null) => setSelectedNode(type);

  return { selectedNode, updateSelectedNode };
};
