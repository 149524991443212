/* eslint-disable react/jsx-no-target-blank */
import { setMixpanelID, logEvent } from "../shared/Mixpanel";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import authenticationHandler from "../../utils/authenticationHandler";
import httpClientPy from "../../utils/httpClientPy";
import GoogleSignIn from "../googleSignIn/GoogleSignIn";
import Button from "../shared/Button";
import ValidationSummary from "../shared/ValidationSummary";
import AuthFormHeader from "../shared/AuthFormHeader";
import { Alert, UncontrolledAlert } from "reactstrap";
import Auth from "../sso/Auth";

const ForgotPw = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState(null);

  const MAPPING = {
    0: {'message': 'An email has been sent to you with instructions on how to reset your password', 'class': 'success'},
    1: {'message': 'User does not exist', 'class': 'warning'},
    2: {'message': 'Internal error, please contact info@civils.ai for futher assistance', 'class': 'danger'},
  }

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (event) => {
    setResetCode(null);
    setLoading(true);
    event.preventDefault();

    httpClientPy
      .post(`/user/forgot/password?email=${email}`)
      .then((response) => {
        setResetCode(response.data.code);
      })
      .catch((errorResponse) => {
        setResetCode(errorResponse.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <main className="form-signin mt-5 m-auto container text-center">
        <AuthFormHeader />
        <h1 className="title-bold pt-4">Reset Password</h1>
        <div className="mt-3">
          <form onSubmit={handleSubmit}>
            <div className="form-floating mb-2">
              <input
                name="email"
                type="email"
                className="form-control"
                id="emailInput"
                placeholder="name@example.com"
                value={email}
                onChange={handleChange}
                required
              />
              <label htmlFor="emailInput">Email address</label>
            </div>

            <div className="vstack gap-2">
              <Button
                type="submit"
                label="Request for password reset"
                className="w-100 btn btn-primary"
                loading={loading}
              />

              <div>
                {resetCode !== null &&
                  <Alert color={MAPPING[resetCode].class} isOpen={true} toggle={() => setResetCode(null)}>
                    <span className="title-bold">{MAPPING[resetCode].message}</span>
                  </Alert>
                }
              </div>
              <div className="text-secondary">
                <small>
                  Already have an account? <NavLink to="/log-in">Log in now</NavLink>
                </small>
              </div>
              <div className="text-secondary">
                <small>
                  Don't have an account? <NavLink to="/sign-up">Sign up now</NavLink>
                </small>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default ForgotPw;
