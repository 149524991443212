import React, { useState, useEffect, useRef} from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import html2canvas from 'html2canvas';


const PdfViewer = (props) => {
    
    const [tooltipOpen4, setTooltipOpen4] = useState(false);
    const [isSnipping, setIsSnipping] = useState(false);
    const [snipArea, setSnipArea] = useState(null);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const pdfContainerRef = useRef(null);

    //FIXME: unused vars
    const toggle4 = () => setTooltipOpen4(!tooltipOpen4);

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();

    const { jumpToPage } = pageNavigationPluginInstance;

    useEffect(() => {
        if (props.initialPage) {
            jumpToPage(props.initialPage - 1);
        }
    }, [props.initialPage]);

    const renderHighlights = (properties) => {
        if (!props.highlightArea || props.highlightArea.length === 0) {
            return null;
        }
    
        return (
            <div>
                {props.highlightArea
                    .filter((area) => area.pageIndex === properties.pageIndex)
                    .map((area, idx) => (
                        <div
                            key={idx}
                            className="highlight-area"
                            style={Object.assign(
                                {},
                                {
                                    background: '#5b5fcc',
                                    opacity: 0.2,
                                },
                                properties.getCssProperties(area, properties.rotation)
                            )}
                        />
                    ))}
            </div>
        );
    };

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.TextSelection,
    });

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const toggleSnipping= () => {
        if (!isSnipping){
            props.updateTask(3)
        }
        setIsSnipping(!isSnipping)
    }


    const CustomToolbar = () => (
        <Toolbar>
            {(slots) => {
                const {
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    CurrentPageInput,
                    ZoomOut,
                    ZoomIn,
                    Download,
                    ShowSearchPopover,
                    // ... other slots you want to keep
                } = slots;
    
                return (
                    <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '4px 2px', display: 'flex', alignItems: 'center' }}>
                                <CurrentPageInput /> / <NumberOfPages />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToNextPage />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomIn />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Download />
                            </div>
                            
                            {/* {props.fileType === 0 &&
                                <div style={{ padding: '0px 2px' }}>
                                        <button aria-label="Crop" className={isSnipping ? 'crop-button-selected' : 'crop-button'} id="cropButton" onClick={() => toggleSnipping()}>
                                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" >
                                                <g clipPath="url(#clip0_15_434)" >
                                                    <rect width="24" height="24" />
                                                    <path d="M5 1V5M5 5H1M5 5V18C5 18.5523 5.44772 19 6 19H16" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M19 23L19 19M19 19L23 19M19 19L19 6C19 5.44772 18.5523 5 18 5L8 5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs >
                                                    <clipPath id="clip0_15_434" >
                                                        <rect width="24" height="24" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            &nbsp; 
                                            <small className='text-muted'>Check
                                            </small>
                                        </button>
                                </div>
                            } */}
                        </div>
                        {props.task === 4 &&
                            <div className='me-2'>
                                <button aria-label="Crop" className={isSnipping ? 'crop-button-selected' : 'crop-button'} id="cropButton" 
                                        onClick={() => props.closePdfViewer()}>
                                    <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" fill="none"/>
                                        <path d="M5 5L19 19" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M19 5L5 19" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        }
                    </div>
                    </>
                );
            }}
        </Toolbar>
    );

    const handleSnipStart = (e) => {
        if (!isSnipping) return;
        // clear any existing snip area
        if (snipArea) {
            pdfContainerRef.current.removeChild(snipArea);
            setSnipArea(null);
        }
        const { clientX, clientY } = e.nativeEvent;
        const rect = pdfContainerRef.current.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;
        setStartX(offsetX);
        setStartY(offsetY);
    
        const area = document.createElement('div');
        area.className = 'snip-area';
        area.style.left = `${offsetX}px`;
        area.style.top = `${offsetY}px`;
        setSnipArea(area);
        pdfContainerRef.current.appendChild(area);
    };
    
    const handleSnipping = (e) => {
        if (!isSnipping || !snipArea) return;
        const { clientX, clientY } = e.nativeEvent;
        const rect = pdfContainerRef.current.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;
        snipArea.style.width = `${Math.abs(offsetX - startX)}px`;
        snipArea.style.height = `${Math.abs(offsetY - startY)}px`;
        snipArea.style.left = `${Math.min(startX, offsetX)}px`;
        snipArea.style.top = `${Math.min(startY, offsetY)}px`;
    };
    
    const handleSnipEnd = () => {
        if (!isSnipping || !snipArea) return;
        setIsSnipping(false);
    
        snipArea.style.backgroundColor = 'transparent';
    
        html2canvas(pdfContainerRef.current, {
            x: parseInt(snipArea.style.left, 10),
            y: parseInt(snipArea.style.top, 10),
            width: parseInt(snipArea.style.width, 10),
            height: parseInt(snipArea.style.height, 10),
            useCORS: true,
            backgroundColor: null,
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            props.updateBase64img(imgData);
            // Restore the background color
            snipArea.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
            // Remove snip area after capturing
            pdfContainerRef.current.removeChild(snipArea);
            setSnipArea(null);
            setStartX(0);
            setStartY(0);
            setIsSnipping(false);
        });

    };

    const style = {
        cursor: isSnipping ? 'crosshair' : 'default', // Change cursor based on the state
      };

    return (
        <>
            <div>
                <CustomToolbar />
            </div>
            <div id="pdf_viewer" 
                className=""
                onMouseDown={handleSnipStart}
                onMouseMove={handleSnipping}
                onMouseUp={handleSnipEnd}
                ref={pdfContainerRef}
                style={style}
            >
                    {props.fileUrl &&
                        <Viewer 
                            fileUrl={props.fileUrl} 
                            initialPage={props.initialPage - 1}
                            enableSmoothScroll={true}
                            plugins={[
                                pageNavigationPluginInstance, 
                                highlightPluginInstance,
                                toolbarPluginInstance,
                            ]} 
                        />
                    }
            </div>
        </>
    );
}

export default PdfViewer;
