import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import FileSelectorTable from "./FileSelectorTable";
import FileSelectorFilter from "./FileSelectorFilter";
import Button from "../../../shared/Button";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const FileSelectorModal = (props) => {

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(props.isDigitsationWf ? 3: 0);
    const [displaySearch, setDisplaySearch] = useState(false);
    const [searchFilter, setSearchFilter] = useState({file_names: []});

    const toggleTab = (tab) => {
        if (loading) { return }
        if (props.isDigitsationWf) { return }
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    function filterRecords(selections) {
        if (selections.length > 0) {
            selections.forEach((item) => {
                setSearchFilter({...searchFilter, file_names: [...searchFilter.file_names, item.value]})
            })
        } else {
            setSearchFilter({"file_names": []})
        }
    }
    
    return (
        <Modal isOpen={props.toggleFileSelector} toggle={() => props.setToggleFileSelector(!props.toggleFileSelector)} fullscreen>
            <ModalHeader close={<Button label={<><FontAwesomeIcon icon={faX} size="sm" /></>} 
                                        className='btn btn-sm btn-danger ms-1 me-2' 
                                        onClick={() => props.setToggleFileSelector(!props.toggleFileSelector)}>
                                </Button>}>
                                Select which document(s) you'd like to use in this workflow
            </ModalHeader>
            <ModalBody className={'py-0'}>
                <>
                    <div className='row py-0 px-2'>
                        <div id='side-out' className={`border-end col-2 bg-light d-flex flex-column`}>
                            <div className="row d-flex flex-column gap-2 p-2">
                                <a className={`${activeTab === 0 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`} 
                                    disabled={loading}
                                    onClick={() => toggleTab(0)}>All <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_data}</span></a>
                                <a className={`${activeTab === 1 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                    disabled={loading}
                                    onClick={() => toggleTab(1)}>Specs <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_specs_data}</span></a>
                                <a className={`${activeTab === 2 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                    disabled={loading}
                                    onClick={() => toggleTab(2)}>Contracts <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_contracts_data}</span></a>
                                <a className={`${activeTab === 3 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                    disabled={loading}
                                    onClick={() => toggleTab(3)}>Site Investigations <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_site_inv_data}</span></a>
                                <a className={`${activeTab === 4 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                    disabled={loading}
                                    onClick={() => toggleTab(4)}>Drawings <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_drawings_data}</span></a>
                                <a className={`${activeTab === 5 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                    disabled={loading}
                                    onClick={() => toggleTab(5)}>Health & Safety<span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_risk_regs_data}</span></a>
                                <a className={`${activeTab === 7 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                    disabled={loading}
                                    onClick={() => toggleTab(7)}>Plans & Methods <span className='badge custom-nav-link-count-box ms-2'>{props.extraDetails.all_plans_data}</span></a>
                                <a className={`${activeTab === 6 ? 'custom-nav-link-active' : !loading ? 'custom-nav-link' : 'custom-nav-link-disabled'}`}
                                    disabled={loading}
                                    onClick={() => toggleTab(6)}>Other <span className='badge custom-nav-link-count-box-dark ms-2'>{props.extraDetails.all_others_data}</span></a>
                            </div>
                        </div>
                        <div id='main-out' className='col-10 px-0 flex-column chatList-open'>
                            <FileSelectorFilter activeTab={activeTab}
                                                displaySearch={displaySearch}
                                                setDisplaySearch={setDisplaySearch}
                                                filterRecords={filterRecords}
                                                project = {props.project}
                            />
                            
                            <FileSelectorTable  docType = {activeTab} 
                                                project = {props.project}
                                                searchFilter = {searchFilter} 
                                                filesSelected = {props.filesSelected}
                                                setFileSelected = {props.setFilesSelected}
                                                setOuterLoading = {setLoading}
                            />
                        </div>
                    </div>
                </>
                </ModalBody>
                <ModalFooter>
                    <div className="text-center w-100">
                        <button className="btn btn-lg btn-primary" 
                                onClick={() => props.setToggleFileSelector(!props.toggleFileSelector)}>
                                    
                            {props.filesSelected.length > 0 ? 'Confirm' + ` (${props.filesSelected.length})` : 'Close'}
                        </button>
                    </div>
                </ModalFooter>
        </Modal>
    );
}

export default FileSelectorModal;
