
import React, { useState, useEffect } from "react";
import { ModalBody, ModalHeader, ModalFooter, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy, faCheck, faRobot, faCircleInfo, faSatellite, faLocationDot, faLocationCrosshairs} from "@fortawesome/free-solid-svg-icons";
import {findMaterial } from "../../shared/algorithms/geoRecordAlgorithms.js"
import httpClientPy from "../../../utils/httpClientPy.js";
import Loader from "../../shared/Loader";
import ModelSingleBH from "./ModelSingleBH";
import {findBoreholeColor} from './findBoreholeColor'

library.add(faCopy, faCheck, faRobot, faCircleInfo, faLocationDot, faLocationCrosshairs);


const AddBoreholeForm = ({ ...props }) => {

    const [geoTableDisplay, setGeoTableDisplay] = useState("geology");
    const [copiedTable, setCopiedTable] = useState(false);
    const [descriptionRows, setDescriptionRows] = useState([    { id: 1, description: 'Made ground', thickness: 1, material:'Fill', color: [188,167,153] },  ]);
    const [testRows, setTestRows] = useState([    { id: 1, name: '', depth: '', result: '' },  ]);
    const [groundLevel, setGroundLevel] = useState(0);
    const [elevationLoading, setElevationLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modelLoading, setModelLoading] = useState(true);
    const [recordName, setRecordName] = useState('Borehole');
    const [warning, setWarning] = useState(false);

    const submitForm = () => {
      setLoading(true);

      var geo_record_strata = []
      var top_holder = groundLevel

      if(recordName === ''){
        setWarning('Enter a borehole ID')
        setLoading(false);
        return;
      }

      if(!groundLevel){
        setWarning('Enter a goundlevel')
        setLoading(false);
        return;
      }

      if (!descriptionRows || descriptionRows.length === 0) {
        setWarning('Add a layer')
        setLoading(false);
        return;
      }

      for (let row of descriptionRows) {
        if (!row.thickness || !row.description) {
          setWarning('Check all layers have thickness and description')
          setLoading(false);
          return;
        }
      }

      setWarning(false)


      for (let row of descriptionRows){
        geo_record_strata.push({
          top: top_holder,
          base: top_holder - row.thickness,
          description: row.description,
          created_datetime: "2023-01-02T07:34:16.263Z",
          last_updated_datetime: "2023-01-02T07:34:16.263Z",
          is_active: 1,
          grouping: ""
        })
        top_holder -= row.thickness
      }

      var geo_record_test = []
      for (let row of testRows){
        geo_record_test.push({
          test: row.name,
          result: row.result,
          depth: row.depth,
          created_datetime: "2023-01-02T07:34:16.263Z",
          last_updated_datetime: "2023-01-02T07:34:16.263Z",
          is_active: 1
        })
      }


      // if the length of geo record test is less than 1 then create a default test
      var geo_record_test = []
      if (geo_record_test.length < 1){
        geo_record_test.push({
          test: "default",
          result: 0.0,
          depth: 0,
          created_datetime: "2023-01-02T07:34:16.263Z",
          last_updated_datetime: "2023-01-02T07:34:16.263Z",
          is_active: 0
        })
      }

      return httpClientPy
      .post(`/geo/record/manual/new`, {
        geo_coordinate_system: "OSGB36",
        easting: props.cursorLng,
        northing:  props.cursorLat,
        sampling_date: "2023-01-02T07:34:16.263Z",
        status: "ready",
        file_path: "",
        original_file_name: "",
        created_datetime: "2023-01-02T07:34:16.263Z",
        last_updated_datetime: "2023-01-02T07:34:16.263Z",
        is_active: 1,
        user_profile_id: 12,
        email_notification_sent: 1,
        length: 522639,
        description: recordName,
        link: "",
        reference: "",
        geo_record_strata: geo_record_strata,
        geo_record_test: geo_record_test,
      })
      .then((response) => {
        setLoading(false);
        props.onBoreholeAdded(true);
      })
    }

    const handleLevelChange = (e) => {
      setGroundLevel(e.target.value);
    };

    const handleNameChange = (e) => {
      setRecordName(e.target.value);
    };


    const getElevation = () =>{

      setElevationLoading(true)
      fetch(`https://www.elevation.civils.ai/v1/srtm30m?locations=${props.cursorLat},${props.cursorLng}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: "GET",
      })
        .then(response => response.json())
        .then(data => {
          setElevationLoading(false)
          setGroundLevel(data.results[0].elevation.toFixed(1))
        })
    }

    useEffect(() => {
      getElevation()

      //set timeout 2 seconds
      setTimeout(() => {
        setModelLoading(false)
      }
      , 1000);
      //clear timeout
      return () => clearTimeout();

    }, [])


  // This function adds a new row to the table.
  const addDescriptionRow = () => {
    // Create a new row object with a unique ID and blank name and age values.
    const newDescriptionRow = {
      id: descriptionRows.length + 1,
      description: '',
      thickness: '',
    };

    let descriptionHolder = [...descriptionRows, newDescriptionRow]
    // Use the setRows function to add the new row to the state.
    setDescriptionRows(descriptionHolder);
  };

  // This function removes a row from the table.
  const removeDescriptionRow = (id) => {
    // Use the setRows function to filter out the row with the specified ID.
    setDescriptionRows(descriptionRows.filter((row) => row.id !== id));
  };

  // This function updates the name or age value for a row.
  const updateDescriptionValue = (event, id, field) => {
    let descriptionHolder = descriptionRows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [field]: event.target.value,
        };
      }
      return row;
    });
  
    for (let strata of descriptionHolder){
      let descriptionString = strata.description.toLowerCase()
      descriptionString = descriptionString.replace(/[^a-zA-Z ]/g, "")
      let descriptionList = descriptionString.split(" ")
      let material = findMaterial(descriptionList, descriptionString)
      if(material.uscs !== '-'){
        strata.material = material.uscs
      }
      else{
        strata.material = material.grain
      }
    }

    // Additional properties can be added to descriptionHolder here
    descriptionHolder = findBoreholeColor(descriptionHolder)
    
    setDescriptionRows(descriptionHolder);
  };

  // This function adds a new row to the table.
  const addTestRow = () => {
    // Create a new row object with a unique ID and blank name and age values.
    const newTestRow = {
      id: testRows.length + 1,
      name: '',
      depth: '',
      result: '',
    };

    // Use the setRows function to add the new row to the state.
    setTestRows([...testRows, newTestRow]);
  };

  // This function removes a row from the table.
  const removeTestRow = (id) => {
    // Use the setRows function to filter out the row with the specified ID.
    setTestRows(testRows.filter((row) => row.id !== id));
  };

  // This function updates the name or age value for a row.
  const updateTestValue = (event, id, field) => {
    // Use the setRows function to create a new array of rows with the updated value.
    setTestRows(
        testRows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [field]: event.target.value,
          };
        }
        return row;
      })
    );
  };


    return (
        <>  
        <ModalHeader className="border-0 mb-0 pb-0" toggle={props.toggleModal(false)}><FontAwesomeIcon className="icon-satellite" icon={faLocationCrosshairs}/> { props.displayName && props.displayName}</ModalHeader>
        <ModalBody>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="addBoreholeModelHolder">
              { modelLoading ?
              <Loader size="large" loading={modelLoading} />
              :
              <ModelSingleBH 
                zoom={1000}
                recordName={recordName} 
                descriptionRows={descriptionRows} 
                id="geoModel"
                inputType="thickness"
              ></ModelSingleBH>
              }
            </div>
          </div>
          <div className="col-12 col-lg-6">
              <div
                className="btn-group dimmed d-none"
                role="group"
                aria-label="Toggle geological data"
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="geoRecordDetailRadioButton"
                  id="geoRecordDetailRadioButtonAll"
                  autoComplete="off"
                  checked={geoTableDisplay === "geology"}
                  onChange={() => {
                    setGeoTableDisplay("geology");
                    setCopiedTable(false)
                  }}
                />
                <label
                  className="btn btn-outline-dark shadow-none"
                  htmlFor="geoRecordDetailRadioButtonAll"
                >
                  Geology
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="geoRecordDetailRadioButton"
                  id="geoRecordDetailRadioButtonTest"
                  autoComplete="off"
                  checked={geoTableDisplay === "test"}
                  onChange={() => {
                    setGeoTableDisplay("test");
                    setCopiedTable(false)
                  }}
                />
                <label
                  className="btn btn-outline-dark shadow-none"
                  htmlFor="geoRecordDetailRadioButtonTest"
                >
                  Testing results
                </label>

                </div>



                {geoTableDisplay === "geology" ?
                  <div  className="addGeoRecordModal overflow-auto">
                  <div className="input-group  mt-3 mt-lg-0 mb-2 mb-sm-4" >

                  <div className="form-floating" style={{maxWidth: "150px"}}>
                        <input
                            name="recordName"
                            type="text"
                            className="form-control"
                            id="recordName"
                            value={recordName}
                            onChange={handleNameChange}
                            autoComplete="off"
                            required
                        />
                      <label htmlFor="groundLevel">Borehole ID</label>
                    </div>

                      <div className="form-floating" style={{maxWidth: "150px"}}>
                        <input
                            name="groundLevel"
                            type="number"
                            className="form-control"
                            id="groundLevel"
                            value={groundLevel}
                            onChange={handleLevelChange}
                            autoComplete="off"
                            step="0.1"
                            required
                        />
                      <label htmlFor="groundLevel">Ground level (m)</label>
                    </div>
                    <span type="button" onClick={()=>{getElevation()}} className="align-baseline input-group-text btn btn-light p-3" style={{border: "1px solid #ced4da"}} id="basic-addon1"><FontAwesomeIcon className="icon-satellite"icon={faSatellite}/></span>

                  </div>



                <div className="bg-white ">

                <Table>
                  <thead>
                  <tr>
                      <th className="col-8"><small>Geology description</small></th>
                      <th><small><span>Thk</span> (m)</small></th>
                  </tr>
                  </thead>
                  <tbody>
                  {descriptionRows.map((row) => (
                      <tr key={row.id}>
                      <td>
                          <div className="input-group">
                          <input
                              type="text"
                              className="form-control"
                              required
                              value={row.description}
                              onChange={(event) => updateDescriptionValue(event, row.id, 'description')}
                          />
                          </div>
                      </td>
                      <td>
                          <div className="input-group">
                          <input
                              type="number"
                              className="form-control"
                              required
                              value={row.thickness}
                              onChange={(event) => updateDescriptionValue(event, row.id, 'thickness')}
                          />
                          </div>
                      </td>
                      <td>
                          <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => removeDescriptionRow(row.id)}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                          </button>
                      </td>
                      </tr>
                  ))}
                  </tbody>
              </Table>
              <button type="button" className="btn btn-outline-success" onClick={addDescriptionRow}>
                  Add layer
              </button>
            </div>
            </div>
            :
            <div  className="addGeoRecordModal overflow-auto">
            <table className="table">
              <thead>
              <tr>
                  <th><small>Test type</small></th>
                  <th><small>Depth (m)</small></th>
                  <th><small>Result</small></th>
              </tr>
              </thead>
              <tbody>
              {testRows.map((row) => (
                  <tr key={row.id}>
                  <td>
                      <div className="input-group">
                      <input
                          type="text"
                          className="form-control"
                          required
                          value={row.name}
                          onChange={(event) => updateTestValue(event, row.id, 'name')}
                      />
                      </div>
                  </td>
                  <td>
                      <div className="input-group">
                      <input
                          type="number"
                          className="form-control"
                          required
                          value={row.depth}
                          onChange={(event) => updateTestValue(event, row.id, 'depth')}
                      />
                      </div>
                  </td>
                  <td>
                      <div className="input-group">
                      <input
                          type="number"
                          className="form-control"
                          value={row.result}
                          onChange={(event) => updateTestValue(event, row.id, 'result')}
                      />
                      </div>
                  </td>
                  <td>
                      <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => removeTestRow(row.id)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                      </button>
                  </td>
                  </tr>
              ))}
              </tbody>
          </table>
          <button type="button" className="btn btn-outline-success" onClick={addTestRow}>
              Add
          </button>
            </div>
            }
          </div>
      </div>
      </ModalBody>
      <ModalFooter className="border-0 pt-0 pt-sm-2">

      {warning &&
        <div className="container mx-auto text-center">
          <span className="text-danger mb-2 text-center">{warning}</span>
          </div>
      }

      <div className="container mx-auto mb-2" style={{maxWidth:'200px'}}>

        {loading ?
          <a className="btn btn-primary w-100 disabled" onClick={()=>submitForm()} type="button">
            <Loader loading={loading} />
          </a>
        :
        <a className="btn btn-primary btn-lg w-100" onClick={()=>submitForm()} type="button">
          <span>&nbsp; Save to map</span>
        </a>
        }
      </div>
      </ModalFooter>
      </>
    );
  };
  
  export default AddBoreholeForm;
  