import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import ProgressCounter from "../map/dataTab/ProgressCounter";

const LibraryDocSelector = (props) => {
  const [files, setFiles] = useState([]);
  //FIXME: unused
  const [tooltipOpen1, setTooltipOpen1] = useState(false);

  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen, setTooltipOpen] = useState({
    info: false,
  });

  const toggle = (tooltip) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [tooltip]: !prevState[tooltip],
    }));
  };

  useEffect(() => {
    let files = props.files;
    files = files.filter((file) => file.status !== "fail");
    // if (files.length === 1) {
    //     props.handleFileClick(files[0].record_id);
    // }
    setFiles(files);
  }, [props.files]);

  return (
    <>
      <img
        src="assets/bookshelf.png"
        alt="placeholder"
        className="mx-auto d-block mt-3"
        style={{ width: "20vh" }}
      />
      <Card
        className="d-flex mt-3 mx-auto overflow-hidden shadow-sm"
        style={{ maxWidth: "90%" }}
      >
        <CardHeader className="bg-light border-0">
          <span
            style={{ maxWidth: "100%" }}
            className="d-inline-block title-bold text-truncate"
          >
            Documents within this library ({files.length})
          </span>
        </CardHeader>
        <CardBody className="bg-light">
          <div style={{ overflowY: "auto", maxHeight: "55vh" }}>
            <table className="table table-bordered">
              <tbody>
                {files.map((file) => (
                  <tr key={file.id} className="cursor-pointer">
                    <td
                      className={`${file.status !== "ready" && "text-muted"}`}
                    >
                      <div className="d-flex justify-content-between">
                        <small
                          style={{
                            "overflow": "hidden",
                            "text-overflow": "ellipsis",
                          }}
                        >
                          {file.name}
                        </small>
                        <small className="text-muted">
                          <ProgressCounter
                            status={file.status}
                            requestedDate={file.updated_at}
                          />
                        </small>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default LibraryDocSelector;
