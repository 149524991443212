import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components } from 'react-select'
import httpClientPy from "../../../../utils/httpClientPy";
import { useEffect, useState } from "react";

const FileSelectorFilter = (props) => {

    const [loading, setLoading] = useState(false);
    const [documentNameSelector, setDocumentNameSelector] = useState([]);

    const fetchDocumentsNames = async () => {
        return httpClientPy
        .get(`/documents/meta?project_id=${props.project.id}&doc_type=${props.activeTab - 1}`)
        .then((result) => {
            let options = result.data.file_names.map((item) => {
                return {
                    value: item,
                    label: item
                }
            });
            setDocumentNameSelector(options);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        setLoading(true);
        fetchDocumentsNames().then(() => {
            setLoading(false);
        });
    }, [props.activeTab]);

    return (
        <div className="text-end mt-2 mx-2">
            {documentNameSelector.length > 0 && !loading &&
            <>
                {props.displaySearch ?
                    <>
                        <Select
                            className="basic-multi-select d-inline-block filter-record-select-sm text-center me-2"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="searchDocs"
                            isMulti={true}
                            placeholder={"Search Document"}
                            components={{ ValueContainer }}
                            closeMenuOnSelect={false}
                            isRtl={false}
                            options={documentNameSelector}
                            onChange={props.filterRecords}
                            autoFocus={true}
                        />
                        <div className="btn btn-sm btn-outline-dark rounded"
                            onClick={() => props.setDisplaySearch(!props.displaySearch)}
                        >
                            <FontAwesomeIcon icon={faFilterCircleXmark}/>
                        </div>
                    </>
                    :
                    <div className="btn btn-sm btn-outline-secondary"
                        onClick={() => props.setDisplaySearch(!props.displaySearch)}
                    >
                        <FontAwesomeIcon className="pe-2" icon={faFilter}/>
                        Filter Doc(s)
                    </div>
                }
            </>
            }
        </div>
    )

}

export default FileSelectorFilter;

const ValueContainer = ({ children, ...props }) => {
    if (children[0] !== null) {
      const length = children[0].length
      let tmpChildren = [...children];
      if(length >= 2){
        tmpChildren[0] = `${length} options selected`
      }
  
      return (
        <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>{children}</components.ValueContainer>
      );
    }
};